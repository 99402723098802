/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Table from "../../EntryFile/datatable";
// import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import {
  ClosesIcon,
  Excel,
  Filter,
  Pdf,
  Eye1,
  EditIcon,
  Calendar,
  Printer,
  search_whites,
  PlusIcon,
  Search,
  MacbookIcon,
  OrangeImage,
  PineappleImage,
  StawberryImage,
  AvocatImage,
  Product1,
  Product7,
  Product8,
  Product9,
} from "../../EntryFile/imagePath";
import Select2 from "react-select2-wrapper";
import axios from "axios";
import BaseUrl from "../../utils/BaseUrl";
import separateNumber from "../../utils/separateur";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import ExportExcel from "../../utils/ExcelExport";
import can from "../../utils/Can";
import moment from "moment";
import Select from "react-select";

const url = BaseUrl();
const VariationdeStock = () => {
  const [errors,setErrors] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [startDate1, setStartDate1] = useState(new Date());
  const [inputfilter, setInputfilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productsList,setProductList] = useState([]);

  const [productItem,setProductItem] = useState([]);
  const [technicalsheet,setTechnicalsheet] = useState([]);
  const [quantity,setQuantity] = useState(0);
  const [excelData,setExcelData] = useState({});
  const [selectOption,setSelectOption] = useState(null);


  useEffect(()=>{
    try {
      axios.get(url.base+'/me',{
               headers:{
                   'Content-Type':'application/json',
                   "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                   
               },
           }).then((resp)=>{

              if(!can(resp.data.data.permissions,"stock-production") ){
                  window.location.href = '/boulangerie/dashboard';
              }
  
           })
       } catch (error) {
           // setLoading(false);
       }
  },[]);


  useEffect(()=>{
    productList();
    technical_sheet();
  },[]);

  function productList(){
    try {
      axios.get(url.base+'/sale-stock',{
          headers:{
              'Content-Type':'application/json',
              "Authorization": `Bearer ${localStorage.getItem('_token_')}`
              
          },
      }).then((resp)=>{
  
          if(resp.status == 200){
            setProductList(resp.data.data);
            const transArray = [];
            resp.data.data.forEach(item => { 
              transArray.push({
                "PRODUIT":item.product_name,
                "STOCK":item.quantity,
                "PRIX DE VENTE":item.price,
                "MONTANT":item.quantity*item.price,
                // "Statut":item.status,
            });
            });
            setExcelData(transArray)
          }
          // setLoading(false);
      })
  } catch (error) {
      // setLoading(false); 
  }
  }

  function technical_sheet(){
    try {
      axios.get(url.base+'/technical-sheet-instock',{
               headers:{
                   'Content-Type':'application/json',
                   "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                   
               },
           }).then((resp)=>{

             setTechnicalsheet(resp.data.data);
  
           })
       } catch (error) {
           // setLoading(false);
       }
  }

  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    if (searchValue.trim() === "") {
      productList();
    } else {
      const filtered = productsList.filter(item =>
        Object.values(item).some(value =>
          typeof value === 'string' && value.toLowerCase().includes(searchValue)
        )
      );
      setProductList(filtered);
    }
  
  };

  const handleChangeOption=(option)=>{
    setSelectOption(option.value);
  
  }

  const optionsheets = technicalsheet.map((opt)=>({
    value: opt.id,
    label:`Production du ${moment(opt.date).format('Do MMMM')} ${opt.time}`
  }));

  const togglefilter = (value) => {
    setInputfilter(value);
  };
  const [data] = useState([
    {
      id:1,
      image: MacbookIcon,
      productName: "INV001",
      name: "Thomas21",
      date: "29-03-2022",
      quantity: 1500,
      Paid: 1500,
      due: 1500,
      Status: "Paid",
    },

  ]);

  const columns = [
    {
      title: "Nom du produit",
      className: "text-center",
      dataIndex: "product_name",
      render: (text, record) => (
        <div className="productimgname">
      
            <img src={`${url.image+record.image}`} width={50} height={50} className="rounded"/>
    
          <Link to="#" style={{ fontSize: "15px", marginLeft: "10px" }}>
            {record.product_name} 
          </Link>
        </div>
      ),
      sorter: (a, b) => a.productName.length - b.productName.length,
    },
    {
      title: "Stock",
      dataIndex: "quantity",
      className: "text-center",
      render: (text, record) => (
        <>
              <b className={text > 10 ? "text-center fw-bold text-success" : "text-center fw-bold text-danger"}>{separateNumber(text)}</b> {record.unit_name}
        </>
      ),
      sorter: (a, b) => a.quantity.length - b.quantity.length,
    },

    {
      title: "Prix (FCFA)",
      className: "text-center",
      dataIndex: "price",
      sorter: (a, b) => a.price.length - b.price.length,
    },

    {
      title: "Montant du stock (FCFA)",
      className: "text-center",
      dataIndex: "price",
      render: (text, record) => (
        <>
          <b className="text-center fw-bold">{separateNumber(text*record.quantity) }</b>
        </>
      ),
      sorter: (a, b) => a.due.length - b.due.length,
    },
    {
      title: "Stock retour",
      className: "text-center",
      dataIndex: "quantity_return",
      render: (text, record) => (
        <>
          <b className="text-center fw-bold text-danger">{separateNumber(text) }</b>
        </>
      ),
      sorter: (a, b) => a.quantity_return.length - b.quantity_return.length,
    },

    {
      title: "Boulangerie",
      dataIndex: "bakehouse",
      className: "text-center",
      render: (text, record) => (
        <b>{record.bakehouse}</b>
      ),
      sorter: (a, b) => a.bakehouse.length - b.bakehouse.length,
    },


    {
      title: "Action",
      className: "text-center",
      render: (text, record) => (
        <>
                {/* <button type="button" className="btn " onClick={()=>openModalAddStock(record)}>
                <img src={PlusIcon} className="me-0" alt="img" />
                </button> */}

                <Link className="btn " to={`/boulangerie/production/history-stock/${record.uuid}`}>
                <img src={Eye1} className="me-0" alt="img" />
                </Link>

        </>
      ),
    },
  ];


  const openModalAddStock=(item)=>{
    setQuantity()
    setProductItem(item);
    window.$('#createpayment').modal('show');
  }

  const submitForm= (e)=>{
    e.preventDefault();
  
    if(quantity > 0 && selectOption !== null){
      const _formData = new FormData();
      _formData.append('quantity',quantity);
      _formData.append('product_id',productItem?.product_id);
      _formData.append('technical_sheet_id',selectOption);
      console.log(_formData);
  
      setLoading(true);
      try {
          axios.post(url.base+'/production-histories',_formData,
          {
              headers:{
                  'Content-Type':'multipart/form-data',
                  "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                  
              },
              // credentials:'include'
          }
          ).then((resp)=>{
              if(resp.status == 200){
                setLoading(false);
                  setErrors({});
                  toast.success("Le stock a été mis à jour avec succès.")

                  setQuantity(0);
                    productList();
                    setProductItem([]);
                    window.$('#createpayment').modal('hide');
              }else{
                setLoading(false);
                toast.error("Echec de l'enregistrement");
              }
          }).catch((error)=>{  
            // console.log(error.response);              
            setLoading(false);
              if (error.response.status == 422) {
                  setErrors(error.response.data.errors);
              }
              // console.log(errors);
          })
      } catch (error) {
        setLoading(false);
    // console.log(error);  
      }
    }else{
      console.log(selectOption);
      if(selectOption == null){
        toast.error("Désolé aucune production n'a été selectionné.");
      }else if(quantity <= 0 || quantity == undefined){
        toast.error("Veillez entrer une valeur supérieur à 0");
      }
      
    }

  }


  return (

    <>
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Gestion du stock</h4>
          </div>
        </div>
        {/* /product list */}
        <div className="card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-path">
                  <a
                    className={` btn ${
                      inputfilter ? "btn-filter setclose" : "btn-filter"
                    } `}
                    id="filter_search"
                    onClick={() => togglefilter(!inputfilter)}
                  >
                    <img src={Filter} alt="img" />
                    <span>
                      <img src={ClosesIcon} alt="img" />
                    </span>
                  </a>
                </div>
                <div className="search-input">
                  <input
                    className="form-control form-control-sm search-icon"
                    type="search"
                    placeholder="Search..."
                    onChange={handleSearch}
                  />
                  <a className="btn btn-searchset">
                    <img src={Search} alt="img" />
                  </a>
                </div>
              </div>
              <div className="wordset">
                <ul>
                  <li>
                    <a
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="pdf"
                    >
                      <img src={Pdf} alt="img" />
                    </a>
                  </li>
                  <li>
                  <ExportExcel excelData={excelData} filename={"stock"}/>
                  </li>
                  {/* <li>
                    <a
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="print"
                    >
                      <img src={Printer} alt="img" />
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
            {/* /Filter */}
            <div
              className={`card mb-0 ${inputfilter ? "toggleCls" : ""}`}
              id="filter_inputs"
              style={{ display: inputfilter ? "block" : "none" }}
            >
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="form-group">
                      <div className="input-groupicon">
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                        />
                        <div className="addonset">
                          <img src={Calendar} alt="img" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="form-group">
                      <div className="input-groupicon">
                        <DatePicker
                          selected={startDate1}
                          onChange={(date) => setStartDate1(date)}
                        />
                        <div className="addonset">
                          <img src={Calendar} alt="img" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-1 col-sm-6 col-12 ms-auto">
                    <div className="form-group">
                      <a className="btn btn-filters ms-auto">
                        <img src={search_whites} alt="img" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Filter */}
            <div className="table-responsive">
              <Table
              columns={columns}
              dataSource={productsList}
              rowKey={(record) => record.id}
              />
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>
    </div>

    <>
    <div
          className="modal fade"
          id="createpayment"
          tabIndex={-1}
          aria-labelledby="createpayment"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title"><u>Produit :</u> <b className="text-success">{productItem.product_name}</b></h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form className="row" onSubmit={submitForm}>

                <div className="col-lg-12 col-sm-12 col-12 mb-3">
                  <label>Selectionner une production</label>
                  <div className="input-groupicon">
                      <Select placeholder="Ajouter une production ..." options={optionsheets} onChange={handleChangeOption}/>
                  </div>
              </div>

                  <div className="col-lg-12 col-sm-12 col-12">
                    <div className="form-group">
                      <label>Quantité produite</label>
                      <input type="text" value={quantity} onChange={(e)=>setQuantity(e.target.value)}/>
                    </div>
                  </div>
                 
                  
                  <div className="modal-footer">
                      {loading &&
                      <button className="btn btn-submit" type="button" disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            En chargement...
                        </button>
                    }
                    {!loading &&
                    <button type="submit" className="btn btn-submit me-2" disabled={quantity <= 0  ? "disabled" : ""}>
                      Valider
                    </button>
                    }
                    {/* <button type="submit" className="btn btn-submit">
                      Valider
                    </button> */}
                    <button
                      type="button"
                      className="btn btn-cancel"
                      data-bs-dismiss="modal"
                    >
                      Fermer
                    </button>
                </div>
                </form>
                
              </div>
          
            </div>
          </div>
    </div>
    </>
  </>
  
  );
};

export default VariationdeStock;
