/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React,{useEffect, useState} from 'react';
import { LoginImage, Logo, MailIcon, GoogleIcon, FacebookIcon } from '../EntryFile/imagePath'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup';
import axios from 'axios';
import BaseUrl from '../utils/BaseUrl';
import { toast } from 'react-toastify';

const url = BaseUrl();
const SignInPage = (props) => {

    const [isAuthToken, setIsAuthToken] = useState(localStorage.getItem('_token_'));
    const [msgerrorAlert,setMsgerrorAlert] = useState('');
    const [loading,setLoading] = useState(false);
    const[eye,seteye]=useState(true);


    const [loginData, setLoginData] = useState({
        'phone':'',
        'password':''
      });
    
      const handleChange=(event)=>{
        setLoginData({
            ...loginData,
            [event.target.name] : event.target.value
        });
      }

      const submitLogin=async (e)=>{
        e.preventDefault(); 
    // console.log(url.base);
        setLoading(true);
        setMsgerrorAlert('');
        if(loginData.phone !== "" && loginData.password !==""){
          const _formData = new FormData();
            _formData.append('phone',loginData.phone);
            _formData.append('password',loginData.password);
            console.log(_formData);
    
            try {
              await axios.post(url.base+'/signin',_formData,{
                  headers:{
                      'Content-Type':'application/json',
                      "Authorization": `Bearer ${isAuthToken}`
                      
                  },
                  credentials:'include'
              }
              ).then((resp)=>{
                  setLoading(false);
                  if(resp.status == 200){                  
                      localStorage.setItem('_token_',resp.data.access_token);
                      props.history.push('/boulangerie/dashboard');
                      // window.location.reload();
                  }
              })
          } catch (error) {
            setLoading(false);

            // console.log(error.response);
              if (error.response.status == 422) {
                  setMsgerrorAlert(error.response.data.message);
              }else{
                  setMsgerrorAlert("Un problème est subvenu !");
              }
          }
        }else{
            setLoading(false);
            toast.error("Identtifiant ou mot de passe vide");
        }
    
      }

    const onEyeClick = () =>{
        seteye(!eye)
        }

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Email est obligatoire')
            .email('Email est invalide'),
        password: Yup.string()
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters')
            .max(20, 'Password must not exceed 20 characters'),
    })

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });


    const onSubmit = (data) => {
        // console.log(JSON.stringify(data, null, 2));
        props.history.push('/boulangerie/dashboard')
    };
    // useEffect(() => {
    //     console.log('hi');
    //     window.location.reload();
    //   }, []);
    
    useEffect(() => {
        if (localStorage.getItem('_token_') !== null) {
            window.location.href = '/boulangerie/dashboard';
        }
    }, []);

    return (
        <>
            <div className="main-wrapper">
                <Helmet>
                    <title>Page de connexion</title>
                    <meta name="description" content="SignIn page" />
                </Helmet>
                <div className="account-content">
                    <div className="login-wrapper">
                        <div className="login-content">
                            <div className="login-userset">
                                <form onSubmit={submitLogin}>
                                    <div className="login-logo">
                                        <img src={Logo} alt="img" />
                                    </div>
                                    <div className="login-userheading">
                                        <h3>Authentification</h3>
                                        {msgerrorAlert !=="" && <h4 className='text-danger'>{msgerrorAlert}</h4>}
                                    </div>
                                    <div className="form-login">
                                        <label>Identifiant</label>
                                        <div className="form-addons">
                                            <input
                                                name='phone'
                                                type="text"
                                                {...register('phone')}
                                                // className={` ${errors.email ? 'is-invalid' : ''}`}
                                                placeholder="Veuillez entrer votre numéro"
                                                onChange={handleChange}
                                            />
                                            {/* <img src={MailIcon} alt="img" /> */}
                                            {/* <div className="invalid-feedback">{errors.email?.message}</div> */}

                                        </div>
                                    </div>
                                    <div className="form-login">
                                        <label>Mot de passe</label>
                                        <div className="pass-group">
                                            <input
                                                type={eye ? "password" : "text"}
                                                className={` ${errors.password ? 'is-invalid' : ''}`}
                                                placeholder="Veuillez entrer votre mot de passe"
                                                {...register('password')}
                                                name='password'
                                                onChange={handleChange}
                                            />
                                            <span  onClick={onEyeClick} className={`fas toggle-password ${eye ? "fa-eye-slash":"fa-eye"} `} />
                                            <div className="invalid-feedback">{errors.password?.message}</div>

                                        </div>

                                    </div>
                                    <div className="form-login">
                                        <div className="alreadyuser">
                                            <h4>
                                                <Link to="/forgetPassword" className="hover-a">
                                                    Mot de passe oublié?
                                                </Link>
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="form-login">
                                        {loading &&
                                         <button className="btn btn-login" type="button" disabled>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                En chargement...
                                            </button>
                                        }
                                        {!loading && 
                                            <button className="btn btn-login" type='submit'>
                                            Se Connecter
                                        </button>
                                        }
                                      
                                    </div>
                                </form>
                                {/* <div className="signinform text-center">
                                    <h4>
                                        Don’t have an account?{" "}
                                        <Link to="/signUp" className="hover-a">
                                            Sign Up
                                        </Link>
                                    </h4>
                                </div> */}
                                {/* <div className="form-setlogin">
                                    <h4>Or sign up with</h4>
                                </div>
                                <div className="form-sociallink">
                                    <ul>
                                        <li>
                                            <Link to="/signin">
                                                <img
                                                    src={GoogleIcon}
                                                    className="me-2"
                                                    alt="google"
                                                />
                                                Sign Up using Google
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/signin">
                                                <img
                                                    src={FacebookIcon}
                                                    className="me-2"
                                                    alt="google"
                                                />
                                                Sign Up using Facebook
                                            </Link>
                                        </li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                        <div className="login-img">
                            <img src={LoginImage} alt="img" />
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default SignInPage;