/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Table from "../../EntryFile/datatable";
// import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import {
  ClosesIcon,
  Excel,
  Filter,
  Pdf,
  Eye1,
  EditIcon,
  Calendar,
  Printer,
  search_whites,
  PlusIcon,
  Search,
  MacbookIcon,
  OrangeImage,
  PineappleImage,
  StawberryImage,
  AvocatImage,
  Product1,
  Product7,
  Product8,
  Product9,
} from "../../EntryFile/imagePath";
import Select2 from "react-select2-wrapper";
import axios from "axios";
import BaseUrl from "../../utils/BaseUrl";
import separateNumber from "../../utils/separateur";
import moment from "moment";
import can from "../../utils/Can";

const url = BaseUrl();
const HistoriqueVersementAll = () => {


  const [startDate, setStartDate] = useState(new Date());
  const [startDate1, setStartDate1] = useState(new Date());
  const [inputfilter, setInputfilter] = useState(false);

  const [productsList,setProductList] = useState([]);


  
useEffect(()=>{
  try {
    axios.get(url.base+'/me',{
             headers:{
                 'Content-Type':'application/json',
                 "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                 
             },
         }).then((resp)=>{

            if(!can(resp.data.data.permissions,"voir-tous-les-encaissements") ){
                window.location.href = '/boulangerie/dashboard';
            }

         })
     } catch (error) {
         // setLoading(false);
     }
},[]);


  useEffect(()=>{
    productList();
  },[]);

  function productList(){
    try {
      axios.get(url.base+'/transactions/historiques',{
          headers:{
              'Content-Type':'application/json',
              "Authorization": `Bearer ${localStorage.getItem('_token_')}`
              
          },
      }).then((resp)=>{
  
          if(resp.status == 200){
            setProductList(resp.data.data);
          }
          // setLoading(false);
      })
  } catch (error) {
      // setLoading(false); 
  }
  }
  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    if (searchValue.trim() === "") {
      productList();
    } else {
      const filtered = productsList.filter(item =>
        Object.values(item).some(value =>
          typeof value === 'string' && value.toLowerCase().includes(searchValue)
        )
      );
      setProductList(filtered);
    }
  
};
  const togglefilter = (value) => {
    setInputfilter(value);
  };


  const columns = [
    {
      title: "Date du versement",
      dataIndex: "created_at",
      className: "text-center",
      render: (text, record) => (
        <>
              <b >{moment(text).format("Do MMMM YYYY HH:mm")}</b> 
        </>
      ),
      sorter: (a, b) => a.created_at.length - b.created_at.length,
    },
    {
      title: "Reference",
      dataIndex: "reference",
      className:"text-center",
      sorter: (a, b) => a.reference.length - b.reference.length,
    },

    {
      title: "Auteur du versement",
      render: (text, record) => (
        <div className="productimgname">
            {/* {record.customer?.first_name} {record.customer?.last_name} */}
            {record.customer ? `${record.customer?.first_name} ${record.customer?.last_name}` : `${record.livreur?.first_name} ${record.livreur?.last_name}`}
        </div>
      ),
      sorter: (a, b) => a.customer.length - b.customer.length,
    },
    // {
    //   title: "Revendeur",
    //   dataIndex: "livreur",
    //   render: (text, record) => (
    //     <div className="productimgname">
    //         {record.livreur?.first_name} {record.livreur?.last_name}
    //     </div>
    //   ),
    //   sorter: (a, b) => a.livreur.length - b.livreur.length,
    // },
    {
      title: "Montant versé",
      dataIndex: "total_amount",
      className: "text-center",
      render: (text, record) => (
        <>
              <b className="text-center fw-bold text-danger">{separateNumber(text)}</b> 
        </>
      ),
      sorter: (a, b) => a.total_amount.length - b.total_amount.length,
    },


    {
      title: "Type de versement",
      className: "text-center",
      dataIndex: "type_payment",
      render: (text, record) => (
        <>
        {text == 1 && <b className="text-center fw-bold">E-PAYMENT</b>}
        {text == 0 && <b className="text-center fw-bold">CASH</b>}
          
        </>
      ),
      sorter: (a, b) => a.type_payment.length - b.type_payment.length,
    },

    {
      title: "Reception",
      className: "text-center",
      dataIndex: "reception",
      render: (text, record) => (
        <>
          {record.reception.name} {record.reception.last_name}
        </>
      ),
      sorter: (a, b) => a.reception.length - b.reception.length,
    },

    {
      title: "Commentaire",
      className: "text-center",
      dataIndex: "note",
      render: (text, record) => (
        <>
          {text}
        </>
      ),
      sorter: (a, b) => a.note.length - b.note.length,
    },





  ];







  return (

    <>
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Tous les encaissements</h4>
          </div>
        </div>
        {/* /product list */}
        <div className="card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-path">
                  <a
                    className={` btn ${
                      inputfilter ? "btn-filter setclose" : "btn-filter"
                    } `}
                    id="filter_search"
                    onClick={() => togglefilter(!inputfilter)}
                  >
                    <img src={Filter} alt="img" />
                    <span>
                      <img src={ClosesIcon} alt="img" />
                    </span>
                  </a>
                </div>
                <div className="search-input">
                  <input
                    className="form-control form-control-sm search-icon"
                    type="search"
                    placeholder="Search..."
                    onChange={handleSearch}
                  />
                  <a className="btn btn-searchset">
                    <img src={Search} alt="img" />
                  </a>
                </div>
              </div>
              <div className="wordset">
                <ul>
                  <li>
                    <a
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="pdf"
                    >
                      <img src={Pdf} alt="img" />
                    </a>
                  </li>
                  <li>
                    <a
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="excel"
                    >
                      <img src={Excel} alt="img" />
                    </a>
                  </li>
                  <li>
                    <a
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="print"
                    >
                      <img src={Printer} alt="img" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/* /Filter */}
            <div
              className={`card mb-0 ${inputfilter ? "toggleCls" : ""}`}
              id="filter_inputs"
              style={{ display: inputfilter ? "block" : "none" }}
            >
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="form-group">
                      <div className="input-groupicon">
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                        />
                        <div className="addonset">
                          <img src={Calendar} alt="img" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="form-group">
                      <div className="input-groupicon">
                        <DatePicker
                          selected={startDate1}
                          onChange={(date) => setStartDate1(date)}
                        />
                        <div className="addonset">
                          <img src={Calendar} alt="img" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-1 col-sm-6 col-12 ms-auto">
                    <div className="form-group">
                      <a className="btn btn-filters ms-auto">
                        <img src={search_whites} alt="img" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Filter */}
            <div className="table-responsive">
              <Table
              columns={columns}
              dataSource={productsList}
              rowKey={(record) => record.id}
              />
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>
    </div>


  </>
  
  );
};

export default HistoriqueVersementAll;
