/* eslint-disable no-unused-vars */
/* eslint-disable no-dupe-keys */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  PlusIcon,
  Search,
  Excel,
  Printer,
  Pdf,
  DeleteIcon,
  EditIcon,
  Filter,
  ClosesIcon,
} from "../../EntryFile/imagePath";
import Table from "../../EntryFile/datatable";
import Select2 from "react-select2-wrapper";
import Swal from "sweetalert2";
import "react-select2-wrapper/css/select2.css";
import axios from "axios";
import BaseUrl from "../../utils/BaseUrl";
import { toast } from "react-toastify";
import can from "../../utils/Can";

const url = BaseUrl();
const PermissionPage = () => {

  const [errors,setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [uniteData,setUniteData] = useState({
    "name":""
  });
  const [editUnit,setEditUnit] = useState(null);

  const [uniteList,setUniteList] = useState([]);

  useEffect(()=>{
    try {
      axios.get(url.base+'/me',{
               headers:{
                   'Content-Type':'application/json',
                   "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                   
               },
           }).then((resp)=>{
  
              if(!can(resp.data.data.permissions,"voir-permissions") ){
                  window.location.href = '/boulangerie/dashboard';
              }
  
           })
       } catch (error) {
           // setLoading(false);
       }
  },[]);

  useEffect(()=>{
    fetchUnitsList()
  },[])


  const handleChange=(e)=>{
    setUniteData({
        ...uniteData,
        [e.target.name]:e.target.value
    });
  }

  const confirmText = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: !1,
    }).then(function (t) {
      t.value &&
        Swal.fire({
          type: "success",
          title: "Deleted!",
          text: "Your file has been deleted.",
          confirmButtonClass: "btn btn-success",
        });
    });
  };
  const options = [
    { id: 1, text: "Active", text: "Active" },
    { id: 2, text: "Inactive", text: "Inactive" },
  ];
  const [data] = useState([
    {
      id: 1,
      Name: "Cash",
      Status: "checkbox",
    },
    {
      id: 2,
      Name: "Card",
      Status: "",
    },
    {
      id: 3,
      Name: "Credit",
      Status: "checkbox",
    },
  ]);


  const showModalEdit=(unit)=>{

    setEditUnit(unit);
    setUniteData({
      "name":unit.name
    });
    window.$("#editpayment").modal('show');
  }

  const columns = [
    {
      title: "Libelle",
      dataIndex: "name",
      className: "text-center",
      render: (text, record) => <div className="text-center">{text}</div>,
      sorter: (a, b) => a.name.length - b.name.length,
    },
    // {
    //   title: "Statut",
    //   dataIndex: "is_deleted",
    //   render: (text, record) => (
    //     <>
    //       <div className="status-toggle d-flex justify-content-between align-items-center">
    //         <input
    //           type="checkbox"
    //           id="user1"
    //           className="check"
    //           defaultChecked={text==0 ? text = 1 : text = 0}
    //         />
    //         <label htmlFor="user1" className="checktoggle">
    //           checkbox
    //         </label>
    //       </div>
    //     </>
    //   ),
    //   sorter: (a, b) => a.is_deleted.length - b.is_deleted.length,
    // },
    {
      title: "Action",
      className: "text-center",
      render: (text, record) => (
        <div className="text-center">
          <button
            className="me-3 btn"
            onClick={()=>showModalEdit(record)}
          >
            <img src={EditIcon} alt="img" />
          </button>
          <button className="me-2 confirm-text btn" onClick={()=>deleteUnit(record.id)}>
            <img src={DeleteIcon} alt="img" />
          </button>
        </div>
      ),
      // sorter: (a, b) => a.Action.length - b.Action.length,
    },
  ];

  const fetchUnitsList = async ()=>{
    setLoading(true);
    try {
        axios.get(url.base+'/permissions',{
            headers:{
                'Content-Type':'application/json',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                
            },
        }).then((resp)=>{

            if(resp.status == 200){
                setUniteList(resp.data.data);
            }
            setLoading(false);
        })
    } catch (error) {
        setLoading(false);
    }
}
const handleSearch = (e) => {
  const searchValue = e.target.value.toLowerCase();
  if (searchValue.trim() === "") {
    fetchUnitsList();
  } else {
    const filtered = uniteList.filter(item =>
      Object.values(item).some(value =>
        typeof value === 'string' && value.toLowerCase().includes(searchValue)
      )
    );
    setUniteList(filtered);
  }

};
  const submitForm= (e)=>{
    e.preventDefault();

    const _formData = new FormData();
    _formData.append('name',uniteData.name);

    setLoading(true);
    try {
        axios.post(url.base+'/permissions',_formData,
        {
            headers:{
                'Content-Type':'application/json',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                
            },
            // credentials:'include'
        }
        ).then((resp)=>{
            setLoading(false);
            if(resp.status == 200){

                setErrors({});
                toast.success("Permission enregistrée avec succès !");
                  setUniteData({
                    'name':''
                  });
                  fetchUnitsList()
            }else{
              toast.error("Désolé, un problème est subvenu");
            }
        }).catch((error)=>{  
          // console.log(error.response);              
            setLoading(false);

            if (error.response.status == 422) {
                setErrors(error.response.data.errors);
            }
            // console.log(errors);
        })
    } catch (error) {
        setLoading(false);
// console.log(error);  
    }
  }

  const submitFormEdit= (e)=>{
    e.preventDefault();

    const _formData = new FormData();
    _formData.append('name',uniteData.name);

    setLoading(true);
    try {
        axios.put(url.base+'/permissions/'+editUnit?.id,_formData,
        {
            headers:{
                'Content-Type':'application/json',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                
            },
            // credentials:'include'
        }
        ).then((resp)=>{
            setLoading(false);
            if(resp.status == 200){

                setErrors({});
                toast.success("Permission modifiée avec succès !");
                  setUniteData({
                    'name':''
                  });
                  window.$("#editpayment").modal('hide');
                  fetchUnitsList();
            }else{
              toast.error("Désolé, un problème est subvenu");
            }
        }).catch((error)=>{  
          // console.log(error.response);              
            setLoading(false);

            if (error.response.status == 422) {
                setErrors(error.response.data.errors);
            }
            // console.log(errors);
        })
    } catch (error) {
        setLoading(false);
// console.log(error);  
    }
  }

  const deleteUnit=(id)=>{
    Swal.fire({
      title: 'ATTENTION !',
      text: 'Êtes-vous sûrs de vouloir supprimer cet élément.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonText:"NON",
      confirmButtonText: 'OUI',
      cancelButtonColor:"red"
    }).then((result) => {
      if (result.isConfirmed) {
          try {
              axios.delete(url.base+'/permissions/'+id,
                  {
                      headers:{
                          'Content-Type':'application/json',
                          "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                          
                      },
                  }
              ).then((resp)=>{
                  // console.log(resp.data);
                  if(resp.status == 200)
                  {
                      if(resp.data.status == 600)
                      {
                        toast.error("Désolé, un problème est subvenu");
                      }else{
                        toast.success("Suppression efectuée avec succès.");
                      }
                     
                  }
                  fetchUnitsList();
              })
          } catch (error) {
              console.log(error);
          }

      
      }
    }); 
  }
  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Gestion des permissions</h4>
              {/* <h6>Manage Payment Settings</h6> */}
            </div>
            <div className="page-btn">
              <a
                className="btn btn-added"
                data-bs-toggle="modal"
                data-bs-target="#addpayment"
              >
                <img src={PlusIcon} alt="img" className="me-2" />
                Ajouter une permissions
              </a>
            </div>
          </div>
          {/* /product list */}
          <div className="card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      className="form-control form-control-sm search-icon"
                      type="search"
                      placeholder="Recherche..."
                      onChange={handleSearch}
                    />
                    <a className="btn btn-searchset">
                      <img src={Search} alt="img" />
                    </a>
                  </div>
                </div>
                <div className="wordset">
                  <ul>
                    <li>
                      <a
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="pdf"
                      >
                        <img src={Pdf} alt="img" />
                      </a>
                    </li>
                    <li>
                      <a
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="excel"
                      >
                        <img src={Excel} alt="img" />
                      </a>
                    </li>
                    <li>
                      <a
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="print"
                      >
                        <img src={Printer} alt="img" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={uniteList}
                  rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      {/* add Modal */}
      <div
        className="modal fade"
        id="addpayment"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Ajouter une permission</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <form onSubmit={submitForm}>
              <div className="modal-body">
              
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label>Libelle</label>
                      <input type="text" name="name" onChange={handleChange} value={uniteData.name}/>
                      {errors && errors.name && (
                            <span className="text-danger">{errors.name[0]}</span>
                        )}
                    </div>
                  </div>
                  {/* <div className="col-12">
                    <div className="form-group mb-0">
                      <label>Status</label>
                      <Select2
                        className="select"
                        data={options}
                        options={{
                          placeholder: "Choose Status",
                        }}
                      />
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="modal-footer ">
                <button type="submit" className="btn btn-submit">
                  Enregister
                </button>
                <button
                  type="button"
                  className="btn btn-cancel"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </form>
          
          </div>
        </div>
      </div>
      {/* edit Modal */}
      <div
        className="modal fade"
        id="editpayment"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Editer</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <form onSubmit={submitFormEdit}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label>Libelle</label>
                      <input type="text" name="name" onChange={handleChange} value={uniteData.name}/>
                    </div>
                  </div>
                  {/* <div className="col-12">
                    <div className="form-group mb-0">
                      <label>Status</label>
                      <Select2
                        className="select"
                        data={options}
                        options={{
                          placeholder: "Choose Status",
                        }}
                      />
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-submit">
                  Modifier
                </button>
                <button
                  type="button"
                  className="btn btn-cancel"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PermissionPage;
