/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Pdf,
  Excel,
  Product7,
  Printer,
  EditIcon,
  Calendar,
  Product8,
  Product1,
} from "../../EntryFile/imagePath";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import BaseUrl from "../../utils/BaseUrl";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import moment from "moment";
import can from "../../utils/Can";

const url = BaseUrl();
const ProductionDetail = () => {
  const {uuid} = useParams();  
  const [productionDetail,setProductionDetail] = useState([]);
  
  useEffect(()=>{
    try {
      axios.get(url.base+'/me',{
               headers:{
                   'Content-Type':'application/json',
                   "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                   
               },
           }).then((resp)=>{

              if(!can(resp.data.data.permissions,"mes-productions") ){
                  window.location.href = '/boulangerie/dashboard';
              }
  
           })
       } catch (error) {
           // setLoading(false);
       }
  },[]);    

  useEffect(()=>{
    try {
      axios.get(url.base+'/technical-sheet/'+uuid,{
          headers:{
              'Content-Type':'application/json',
              "Authorization": `Bearer ${localStorage.getItem('_token_')}`
              
          },
      }).then((resp)=>{

          if(resp.status == 200){
            setProductionDetail(resp.data.data);
          }
          // setLoading(false);
      })
  } catch (error) {
      // setLoading(false);
  }
  },[]);

  const Downloadpdf= (e,uuid)=>{
    e.preventDefault();
  
  
  
    try {
        axios.get(url.base+'/export-technical-pdf/'+uuid,
        {
            headers:{
                'Content-Type':'application/pdf',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`,
                
            },
            responseType:'blob',
            // credentials:'include'
        }
        ).then((response)=>{
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'achats.pdf');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
    } catch (error) {
  // console.log(error);  
    }
  }

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Production du {moment(productionDetail?.date).format('Do MMMM  YYYY')} </h4>
            
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="card-sales-split">
              <h2>Heure de production : {productionDetail?.time} </h2>
              <ul>
                {/* <li>
                  <Link to="#">
                    <img src={EditIcon} alt="img" />
                  </Link>
                </li> */}
                <li>
                  <Link to="#" onClick={(e)=>Downloadpdf(e,productionDetail.uuid)}>
                    <img src={Pdf} alt="img" />
                  </Link>
                </li>
                {/* <li>
                  <Link to="#">
                    <img src={Excel} alt="img" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img src={Printer} alt="img" />
                  </Link>
                </li> */}
              </ul>
            </div>
            <div
              className="invoice-box table-height"
              style={{
                maxWidth: 1600,
                width: "100%",
                overflow: "auto",
                margin: "15px auto",
                padding: 0,
                fontSize: 14,
                lineHeight: "24px",
                color: "#555",
              }}
            >
              <table
                cellPadding={0}
                cellSpacing={0}
                style={{
                  width: "100%",
                  lineHeight: "24px",
                  textAlign: "left",
                }}
              >
                <tbody>
                <td
                              style={{
                                padding: 5,
                                verticalAlign: "top",
                                textAlign: "left",
                                paddingBottom: 20,
                              }}
                            >
                              <font
                                style={{
                                  verticalAlign: "top",
                                  marginBottom: 25,
                                }}
                              >
                                <font
                                  style={{
                                    verticalAlign: "top",
                                    fontSize: 14,
                                    color: "#7367F0",
                                    fontWeight: 600,
                                    lineHeight: "35px",
                                  }}
                                >
                                  Detail de la production
                                </font>
                              </font>
                              <br />
                              <font style={{ verticalAlign: "top" }}>
                                <font
                                  style={{
                                    verticalAlign: "top",
                                    fontSize: 14,
                                    color: "#000",
                                    fontWeight: 400,
                                  }}
                                >
                                  {" Libelle : "}
                                  Production du {moment(productionDetail?.date).format('Do MMMM')} {productionDetail?.time}
                                </font>
                              </font>
                              <br />
                              <font style={{ verticalAlign: "top" }}>
                                <font
                                  style={{
                                    verticalAlign: "top",
                                    fontSize: 14,
                                    color: "#000",
                                    fontWeight: 400,
                                  }}
                                >
                                  {"Produit fini: "}
                                  {productionDetail?.product?.name}
                                </font>
                              </font>
                              <br />
                              <font style={{ verticalAlign: "top" }}>
                                <font
                                  style={{
                                    verticalAlign: "top",
                                    fontSize: 14,
                                    color: "#000",
                                    fontWeight: 400,
                                  }}
                                >
                                  {"Boulangerie: "}
                                  {productionDetail?.bakehouse?.name}
                                </font>
                              </font>
                              <br />
                              <font style={{ verticalAlign: "top" }}>
                                <font
                                  style={{
                                    verticalAlign: "top",
                                    fontSize: 14,
                                    color: "#000",
                                    fontWeight: 400,
                                  }}
                                >
                                  {"Quantité estimée: "}
                                  {productionDetail?.estimation}
                                </font>
                              </font>
                              <br />
                              <font style={{ verticalAlign: "top" }}>
                                <font
                                  style={{
                                    verticalAlign: "top",
                                    fontSize: 14,
                                    color: "#000",
                                    fontWeight: 400,
                                  }}
                                >
                                  {"Quantité finale produite : "}
                                  {productionDetail?.nb_produits}
                                </font>
                              </font><br />
                              <font style={{ verticalAlign: "top" }}>
                                <font
                                  style={{
                                    verticalAlign: "top",
                                    fontSize: 14,
                                    color: "#000",
                                    fontWeight: 400,
                                  }}
                                >
                                  Statut{" : "}
                                </font>
                              </font>
                              <font style={{ verticalAlign: "top" }}>
                                {productionDetail.in_stock == 1 &&
                                    <font
                                    style={{
                                      verticalAlign: "top",
                                      fontSize: 14,
                                      color: "#2E7D32",
                                      fontWeight: 400,
                                    }}
                                  >
                                        {" "}
                                        Terminer
                                      </font>
                                }

                                {productionDetail.in_stock == 0 &&
                                    <font
                                    style={{
                                      verticalAlign: "top",
                                      fontSize: 14,
                                      color: "red",
                                      fontWeight: 400,
                                    }}
                                  >
                                        {" "}
                                        En attente
                                      </font>
                                }
                            
                              </font>
                            </td>
                  <tr className="heading " style={{ background: "#F3F2F7" }}>
                    <td
                      style={{
                        padding: 10,
                        verticalAlign: "middle",
                        fontWeight: 600,
                        color: "#5E5873",
                        fontSize: 14,
                      }}
                    >
                      Nom du produit
                    </td>
                    <td
                      style={{
                        padding: 10,
                        verticalAlign: "middle",
                        fontWeight: 600,
                        color: "#5E5873",
                        fontSize: 14,
                      }}
                    >
                      unité
                    </td>
                    <td
                      style={{
                        padding: 10,
                        verticalAlign: "middle",
                        fontWeight: 600,
                        color: "#5E5873",
                        fontSize: 14,
                      }}
                    >
                      Quantité
                    </td>
             
                  </tr>
                  {productionDetail?.technical_sheet_details?.map((product,index)=>
                      <tr key={index}
                        className="details"
                        style={{ borderBottom: "1px solid #E9ECEF" }}
                      >
                        <td
                          style={{
                            padding: 10,
                            verticalAlign: "top",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={`${url.image}${product?.product?.image}`}
                            alt="img"
                            className="me-2"
                            style={{ width: 40, height: 40 }}
                          />
                         {product?.product?.name}
                        </td>
                        <td style={{ padding: 10, verticalAlign: "top" }}>{product?.unit?.name}</td>
                        <td style={{ padding: 10, verticalAlign: "top" }}>
                        {product?.quantity}
                        </td>
            
                      </tr>
                  )}
            
                </tbody>
              </table>
            </div>
     
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductionDetail;
