/* eslint-disable no-unused-vars */
/* eslint-disable no-dupe-keys */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  PlusIcon,
  Search,
  Excel,
  Printer,
  Pdf,
  DeleteIcon,
  EditIcon,
  Filter,
  ClosesIcon,
} from "../../EntryFile/imagePath";
import Table from "../../EntryFile/datatable";
import Select2 from "react-select2-wrapper";
import Swal from "sweetalert2";
import "react-select2-wrapper/css/select2.css";
import axios from "axios";
import BaseUrl from "../../utils/BaseUrl";
import can from "../../utils/Can";

const url = BaseUrl();
const LogSettings = () => {

  const [errors,setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [uniteData,setUniteData] = useState({
    "name":""
  });
  const [editUnit,setEditUnit] = useState(null);

  const [uniteList,setUniteList] = useState([]);
  useEffect(()=>{
    try {
      axios.get(url.base+'/me',{
               headers:{
                   'Content-Type':'application/json',
                   "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                   
               },
           }).then((resp)=>{
  
              if(!can(resp.data.data.permissions,"log-page") ){
                  window.location.href = '/boulangerie/dashboard';
              }
  
           })
       } catch (error) {
           // setLoading(false);
       }
  },[]);

  useEffect(()=>{
    fetchUnitsList()
  },[])

  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    if (searchValue.trim() === "") {
      fetchUnitsList();
    } else {
      const filtered = uniteList.filter(item =>
        Object.values(item).some(value =>
          typeof value === 'string' && value.toLowerCase().includes(searchValue)
        )
      );
      setUniteList(filtered);
    }
  
};
  const handleChange=(e)=>{
    setUniteData({
        ...uniteData,
        [e.target.name]:e.target.value
    });
  }

  const confirmText = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: !1,
    }).then(function (t) {
      t.value &&
        Swal.fire({
          type: "success",
          title: "Deleted!",
          text: "Your file has been deleted.",
          confirmButtonClass: "btn btn-success",
        });
    });
  };
  const options = [
    { id: 1, text: "Active", text: "Active" },
    { id: 2, text: "Inactive", text: "Inactive" },
  ];
  const [data] = useState([
    {
      id: 1,
      Name: "Cash",
      Status: "checkbox",
    },
    {
      id: 2,
      Name: "Card",
      Status: "",
    },
    {
      id: 3,
      Name: "Credit",
      Status: "checkbox",
    },
  ]);


  const showModalEdit=(unit)=>{

    setEditUnit(unit);
    setUniteData({
      "name":unit.name
    });
    window.$("#editpayment").modal('show');
  }

  const columns = [
    {
      title: "log name",
      dataIndex: "log_name",
      className: "text-center",
      render: (text, record) => <div className="text-center">{text}</div>,
      sorter: (a, b) => a.log_name.length - b.log_name.length,
    },
    {
      title: "event",
      dataIndex: "description",
      className: "text-center",
      render: (text, record) => <div className="text-success">{text}</div>,
      sorter: (a, b) => a.description.length - b.description.length,
    },

    {
      title: "causer par",
      dataIndex: "causer_id",
      className: "text-center",
      render: (text, record) => <div className="text-center">{text}</div>,
      sorter: (a, b) => a.causer_id.length - b.causer_id.length,
    },
   
    {
      title: "properties",
      dataIndex: "properties",
      render: (text, record) => (
        <>
            <div className="overflow-scroll mt-5 pb-5 bg-light" style={{"max-width": "360px", "max-height": "80px"}}>
            {JSON.stringify(record.properties.attributes) }
            </div>
        </>
      ),
      sorter: (a, b) => a.properties.length - b.properties.length,
    },

  ];

  const fetchUnitsList = async ()=>{
    setLoading(true);
    try {
        axios.get(url.base+'/bakehouses/logs',{
            headers:{
                'Content-Type':'application/json',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                
            },
        }).then((resp)=>{

            if(resp.status == 200){
                setUniteList(resp.data?.data);
            }
            setLoading(false);
        })
    } catch (error) {
        setLoading(false);
    }
}

  const submitForm= (e)=>{
    e.preventDefault();

    const _formData = new FormData();
    _formData.append('name',uniteData.name);

    setLoading(true);
    try {
        axios.post(url.base+'/units',_formData,
        {
            headers:{
                'Content-Type':'application/json',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                
            },
            // credentials:'include'
        }
        ).then((resp)=>{
            setLoading(false);
            if(resp.status == 200){

                setErrors({});
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title:  "Enregistrer avec succès",
                    showConfirmButton: false,
                    timer: 3000,
                    toast:true,
                    position:'top-right',
                    timerProgressBar:true
                  });
                  setUniteData({
                    'name':''
                  });
                  fetchUnitsList()
            }else{
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title:  "Echec de l'enregistrement",
                    showConfirmButton: false,
                    timer: 3000,
                    toast:true,
                    position:'top-right',
                    timerProgressBar:true
                  });
            }
        }).catch((error)=>{  
          // console.log(error.response);              
            setLoading(false);

            if (error.response.status == 422) {
                setErrors(error.response.data.errors);
            }
            // console.log(errors);
        })
    } catch (error) {
        setLoading(false);
// console.log(error);  
    }
  }

  const submitFormEdit= (e)=>{
    e.preventDefault();

    const _formData = new FormData();
    _formData.append('name',uniteData.name);

    setLoading(true);
    try {
        axios.put(url.base+'/units/'+editUnit?.id,_formData,
        {
            headers:{
                'Content-Type':'application/json',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                
            },
            // credentials:'include'
        }
        ).then((resp)=>{
            setLoading(false);
            if(resp.status == 200){

                setErrors({});
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title:  "Modification effectuée avec succès",
                    showConfirmButton: false,
                    timer: 3000,
                    toast:true,
                    position:'top-right',
                    timerProgressBar:true
                  });
                  setUniteData({
                    'name':''
                  });
                  window.$("#editpayment").modal('hide');
                  fetchUnitsList();
            }else{
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title:  "Echec de la modification",
                    showConfirmButton: false,
                    timer: 3000,
                    toast:true,
                    position:'top-right',
                    timerProgressBar:true
                  });
            }
        }).catch((error)=>{  
          // console.log(error.response);              
            setLoading(false);

            if (error.response.status == 422) {
                setErrors(error.response.data.errors);
            }
            // console.log(errors);
        })
    } catch (error) {
        setLoading(false);
// console.log(error);  
    }
  }

  const deleteUnit=(id)=>{
    Swal.fire({
      title: 'ATTENTION !',
      text: 'Êtes-vous sûrs de vouloir supprimer cet élément.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonText:"NON",
      confirmButtonText: 'OUI',
      cancelButtonColor:"red"
    }).then((result) => {
      if (result.isConfirmed) {
          try {
              axios.delete(url.base+'/units/'+id,
                  {
                      headers:{
                          'Content-Type':'application/json',
                          "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                          
                      },
                  }
              ).then((resp)=>{
                  // console.log(resp.data);
                  if(resp.status == 200)
                  {
                      if(resp.data.status == 600)
                      {
                          Swal.fire(
                              'Attention',
                              'Un problème est subvenu',
                              'error'
                            )
                      }else{
                          Swal.fire(
                              'Supprimé',
                              'Unité supprimé avec succès',
                              'success'
                            )
                      }
                     
                  }
                  fetchUnitsList();
              })
          } catch (error) {
              console.log(error);
          }

      
      }
    }); 
  }
  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Logs page</h4>
              {/* <h6>Manage Payment Settings</h6> */}
            </div>
            {/* <div className="page-btn">
              <a
                className="btn btn-added"
                data-bs-toggle="modal"
                data-bs-target="#addpayment"
              >
                <img src={PlusIcon} alt="img" className="me-2" />
                Ajouter une unité
              </a>
            </div> */}
          </div>
          {/* /product list */}
          <div className="card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      className="form-control form-control-sm search-icon"
                      type="search"
                      placeholder="Recherche..."
                      onChange={handleSearch}
                    />
                    <a className="btn btn-searchset">
                      <img src={Search} alt="img" />
                    </a>
                  </div>
                </div>
                <div className="wordset">
                  <ul>
                    <li>
                      <a
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="pdf"
                      >
                        <img src={Pdf} alt="img" />
                      </a>
                    </li>
                    <li>
                      <a
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="excel"
                      >
                        <img src={Excel} alt="img" />
                      </a>
                    </li>
                    <li>
                      <a
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="print"
                      >
                        <img src={Printer} alt="img" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={uniteList}
                  rowKey={(record) => record?.id}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      {/* add Modal */}
      <div
        className="modal fade"
        id="addpayment"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Ajouter unité</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <form onSubmit={submitForm}>
              <div className="modal-body">
              
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label>Libelle</label>
                      <input type="text" name="name" onChange={handleChange} value={uniteData.name}/>
                      {errors && errors.name && (
                            <span className="text-danger">{errors.name[0]}</span>
                        )}
                    </div>
                  </div>
                  {/* <div className="col-12">
                    <div className="form-group mb-0">
                      <label>Status</label>
                      <Select2
                        className="select"
                        data={options}
                        options={{
                          placeholder: "Choose Status",
                        }}
                      />
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="modal-footer ">
                <button type="submit" className="btn btn-submit">
                  Enregister
                </button>
                <button
                  type="button"
                  className="btn btn-cancel"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </form>
          
          </div>
        </div>
      </div>
      {/* edit Modal */}
      <div
        className="modal fade"
        id="editpayment"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Editer</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <form onSubmit={submitFormEdit}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label>Libelle</label>
                      <input type="text" name="name" onChange={handleChange} value={uniteData.name}/>
                    </div>
                  </div>
                  {/* <div className="col-12">
                    <div className="form-group mb-0">
                      <label>Status</label>
                      <Select2
                        className="select"
                        data={options}
                        options={{
                          placeholder: "Choose Status",
                        }}
                      />
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-submit">
                  Modifier
                </button>
                <button
                  type="button"
                  className="btn btn-cancel"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default LogSettings;
