/* eslint-disable no-unused-vars */
/* eslint-disable no-dupe-keys */
import React, { useEffect, useState } from 'react'
import { Upload } from '../../EntryFile/imagePath';
import Select2 from 'react-select2-wrapper';
import 'react-select2-wrapper/css/select2.css';
import Select from 'react-select';
import axios from 'axios';
import BaseUrl from '../../utils/BaseUrl';
import Swal from 'sweetalert2';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import can from '../../utils/Can';
// import { useNavigate} from 'react-router-dom';


const url = BaseUrl();
const EditProductBoulangerie = () => {

    const {productId} = useParams();

  const [errors,setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [itemData,setItemData] = useState({
    "name":"",
    "stock_alert":"",
    "price":"",
    "sous_famille_id":"",
    "quantity":"0",
    "image":""
  });
  const [editItem,setEditItem] = useState(null);
  const [familles,setFamilles] = useState([]);
  const [optFamille,setOptFamille] = useState(null);
  // const navigate = useNavigate();
  const history = useHistory();
  const [bakehouses,setBakehouses] = useState([]);
  const [selectBakehouse,setSelectBakehouse] = useState(null);

  useEffect(()=>{
    try {
      axios.get(url.base+'/me',{
               headers:{
                   'Content-Type':'application/json',
                   "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                   
               },
           }).then((resp)=>{
            setBakehouses(resp.data.data.bakehouses)
              if(!can(resp.data.data.permissions,"gestion-produit") ){
                  window.location.href = '/boulangerie/dashboard';
              }
  
           })
       } catch (error) {
           // setLoading(false);
       }
  },[]);

  useEffect(()=>{
    try {
      axios.get(url.base+'/products/'+productId,{
          headers:{
              'Content-Type':'application/json',
              "Authorization": `Bearer ${localStorage.getItem('_token_')}`
              
          },
      }).then((resp)=>{

          if(resp.status == 200){
            // console.log(resp.data);
            setItemData({
                    "name":resp.data.data.name,
                    "stock_alert":resp.data.data.stock_alert,
                    "price":resp.data.data.price,
                    // "sous_famille_id":resp.data.data.,
                    "quantity":resp.data.data.quantity,
                    // "image":resp.data.data?.image
                })
            setEditItem(resp.data.data);
            setOptFamille(resp.data.data.unit_id);
            setSelectBakehouse(resp.data.data.bakehouse_id)
          }
          // setLoading(false);
      })
  } catch (error) {
      // setLoading(false);
  }
  },[]);

  useEffect(()=>{
    try {
      axios.get(url.base+'/units',{
          headers:{
              'Content-Type':'application/json',
              "Authorization": `Bearer ${localStorage.getItem('_token_')}`
              
          },
      }).then((resp)=>{

          if(resp.status == 200){
            setFamilles(resp.data.data);
          }
          // setLoading(false);
      })
  } catch (error) {
      // setLoading(false);
  }
  },[]);
//   console.log(url.image+itemData?.image);
  const optionfamilles = familles.map((opt)=>({
    value: opt.id,
    label:`${opt.name}`
  }));

  const handleChange=(e)=>{
    setItemData({
        ...itemData,
        [e.target.name]:e.target.value
    });
  }

const optionbakehouses = bakehouses?.map((option) => ({
    label:`${option.name}`,
    value:option.id
  }));
  const handleChangeBakehouse=(option)=>{
    setSelectBakehouse(option.value);
  }
  const handleFileChange=(event)=>{
    setItemData({
        ...itemData,
        [event.target.name]:event.target.files[0]
    });
}
const handleChangeFamille=(option)=>{
  setOptFamille(option.value);
}
const submitForm= (e)=>{
  e.preventDefault();

  const _formData = new FormData();
  _formData.append('name',itemData.name);
  _formData.append('stock_alert',itemData.stock_alert !=null ? itemData.stock_alert : editItem.stock_alert);
  _formData.append('price',itemData.price);
  _formData.append('bakehouse_id',selectBakehouse);
  if(editItem?.type == 0){  _formData.append('unit_id',optFamille);}


  if(itemData.image !== undefined){
    const currentTimeInSeconds = Math.floor(new Date().getTime() / 1000);
    const originalExtension = itemData?.image?.name.split('.').pop();
    const newFileName = `${currentTimeInSeconds}_image_.${originalExtension}`;
    var photo = new File([itemData?.image], newFileName, { type: itemData.image?.type });

    _formData.append("image",photo);
  }else{
    // _formData.append("image",editItem.image);
  }



  

  console.log(_formData);
  setLoading(true);
  try {
      axios.post(`${url.base}/products-update/${editItem.uuid}`,_formData,
      {
          headers:{
              'Content-Type':'multipart/form-data',
              "Authorization": `Bearer ${localStorage.getItem('_token_')}`
              
          },
          // credentials:'include'
      }
      ).then((resp)=>{
          setLoading(false);
          if(resp.status == 200){

              setErrors({});
              toast.success("Modification effectuée avec succès");

                // history.push('/boulangerie/transfer/produits-boulangerie')
          }else{
            toast.error("Echec de l'enregistrement");

          }
      }).catch((error)=>{  
        // console.log(error.response);              
          setLoading(false);

          if (error.response.status == 422) {
              setErrors(error.response.data.errors);
          }
          // console.log(errors);
      })
  } catch (error) {
      setLoading(false);
// console.log(error);  
  }
}
    return (
        <>
            <div className="page-wrapper">
                <div className="content">
                    <div className="page-header">
                        <div className="page-title">
                            <h4>Modifier un produit </h4>
                            {/* <h6>Create new product</h6> */}
                        </div>
                    </div>
                    {/* /add */}
                    <div className="card">
                        <div className="card-body">
                            <form className="row" onSubmit={submitForm}>
                                <div className="col-lg-6 col-sm-12 col-6">
                                    <div className="form-group">
                                        <label>Nom du produit</label>
                                        <input type="text" name='name' onChange={handleChange} value={itemData.name}/>
                                        {errors && errors.name && (
                                            <span className="text-danger">{errors.name[0]}</span>
                                        )}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6 col-12 mb-3">
                                    <label className='mb-2'>Boulangerie</label>
                                    <Select 
                                        value={optionbakehouses.find(option1 => option1.value === selectBakehouse)}
                                        options={optionbakehouses} 
                                        defaultValue={[{label: selectBakehouse === selectBakehouse ? optionbakehouses.find(obj1 => obj1.value === selectBakehouse) : ""}]}
                                        onChange={handleChangeBakehouse}/>
                                       
                                
                            </div>
                                {/* <div className="col-lg-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label>Category</label>
                                        <Select2
                                          className="select"
                                          data={options}
                                          options={{
                                            placeholder: 'Choose Category',
                                          }} />

                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label>Sub Category</label>
                                        <Select2
                              className="select"
                              data={options1}
                              options={{
                                placeholder: 'Choose Sub Category',
                              }} />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label>Brand</label>
                                        <Select2
                              className="select"
                              data={options2}
                              options={{
                                placeholder: 'Choose Brand',
                              }} />
                                    </div>
                                </div> */}
                                {editItem?.type == 0 &&
                                <div className="col-lg-6 col-sm-6 col-12">

                                <label className='mb-2'>Unité</label>
                                <Select
                                className="select"
                                value={optionfamilles.find(option => option.value === optFamille)}
                                options={optionfamilles}
                                defaultValue={[{label: optFamille == optFamille ? optionfamilles.find(obj => obj.value === optFamille) : ""}]}
                                onChange={handleChangeFamille}
                                placeholder="Choisir ..."
                                />
                                
                        
                        </div> 
                                }

                                <div className="col-lg-6 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label>Prix de vente</label>
                                        <input type="text" name='price' onChange={handleChange} value={itemData.price}/>
                                        {errors && errors.price && (
                                              <span className="text-danger">{errors.price[0]}</span>
                                          )}
                                    </div>
                                </div>
                                {/* <div className="col-lg-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label>Quantité</label>
                                        <input type="text" name='quantity' onChange={handleChange} value={itemData.quantity}/>
                                        {errors && errors.quantity && (
                                              <span className="text-danger">{errors.quantity[0]}</span>
                                          )}
                                    </div>
                                </div> */}
                                <div className="col-lg-6 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label>Stock alert</label>
                                        <input type="text" name='stock_alert' onChange={handleChange} value={itemData.stock_alert}/>
                                        {errors && errors.stock_alert && (
                                              <span className="text-danger">{errors.stock_alert[0]}</span>
                                          )}
                                    </div>
                                </div>
                                {/* <div className="col-lg-12">
                                    <div className="form-group">
                                        <label>Description</label>
                                        <textarea className="form-control" defaultValue={""} />
                                    </div>
                                </div> */}
                                {/* <div className="col-lg-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label>Tax</label>
                                        <Select2
                              className="select"
                              data={options4}
                              options={{
                                placeholder: 'Choose Tax',
                              }} />

                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label>Discount Type</label>
                                        <Select2
                              className="select"
                              data={options5}
                              options={{
                                placeholder: 'Percentage',
                              }} />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label>Price</label>
                                        <input type="text" />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label> Status</label>
                                        <Select2
                              className="select"
                              data={options6}
                              options={{
                                placeholder: 'Choose Product',
                              }} />
                                    </div>
                                </div> */}
                                                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label> Image du produit </label>
                                        <div className="image-upload">
                                            <input type="file" name='image' onChange={handleFileChange}/>
                                            <div className="image-uploads">
                                               
                                                <h4>Associer une image au produit</h4>
                                            </div>
                                         <img src={itemData.image ? URL.createObjectURL(itemData?.image): url.image+editItem?.image} width="20%" height="30%" />
                                        </div>
                                        {errors && errors.image && (
                                              <span className="text-danger">{errors.image[0]}</span>
                                          )}
                                    </div>
                                </div>
                                
                                <div className="col-lg-12">
                                    <button className="btn btn-submit me-2" type='submit'>
                                        Modifier
                                    </button>
                                    {/* <button className="btn btn-cancel">
                                        Retour
                                    </button> */}
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* /add */}
                </div>
            </div>
        </>
    )
}
export default EditProductBoulangerie;