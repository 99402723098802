/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  PlusIcon,
  Search,
  Excel,
  Printer,
  Pdf,
  DeleteIcon,
  EditIcon,
} from "../../EntryFile/imagePath";
import Table from "../../EntryFile/datatable";
import "react-select2-wrapper/css/select2.css";
import Swal from "sweetalert2";
import axios from "axios";
import BaseUrl from "../../utils/BaseUrl";
import { toast } from "react-toastify";
import can from "../../utils/Can";

const url = BaseUrl();
const GroupPermission = () => {
  const [errors,setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [itemData,setItemData] = useState({
    "name":""
  });
  const [edit,setEdit] = useState(null);

  const [dataList,setDataList] = useState([]);

  const confirmText = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: !1,
    }).then(function (t) {
      t.value &&
        Swal.fire({
          type: "success",
          title: "Deleted!",
          text: "Your file has been deleted.",
          confirmButtonClass: "btn btn-success",
        });
    });
  };
  const options = [
    { id: 1, text: "Active", text: "Active" },
    { id: 2, text: "Inactive", text: "Inactive" },
  ];
  const [data] = useState([
    {
      id: 1,
      Role: "Admin",
      Description: "Owner",
    },
    {
      id: 2,
      Role: "Purchase",
      Description: "Purchase Permission",
    },
    {
      id: 3,
      Role: "Sales",
      Description: "Sales Permission",
    },
  ]);

  const columns = [
    {
      title: "Rôle",
      dataIndex: "name",
      render: (text, record) => <>{text}</>,
      sorter: (a, b) => a.name.length - b.name.length,
    },
    // {
    //   title: "Description",
    //   dataIndex: "Description",
    //   render: (text, record) => <div>{text}</div>,
    //   sorter: (a, b) => a.Description.length - b.Description.length,
    // },
    // {
    //   title: "Status",
    //   dataIndex: "Status",
    //   render: (text, record) => (
    //     <>
    //       <span className="badges bg-lightgreen">Active</span>
    //     </>
    //   ),
    //   sorter: (a, b) => a.Status.length - b.Status.length,
    // },
    {
      title: "Action",
      className: "text-end",
      render: (text, record) => (
        <div className="text-end">
          <Link className="me-3" to={`/boulangerie/settings/edit-role/${record.id}`}>
            <img src={EditIcon} alt="img" />
          </Link>
          <Link className="me-3 confirm-text" to="#" onClick={()=>deleteUnit(record.id)}>
            <img src={DeleteIcon} alt="img" />
          </Link>
        </div>
      ),
      sorter: (a, b) => a.Amount.length - b.Amount.length,
    },
  ];

  useEffect(()=>{
    try {
      axios.get(url.base+'/me',{
               headers:{
                   'Content-Type':'application/json',
                   "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                   
               },
           }).then((resp)=>{
  
              if(!can(resp.data.data.permissions,"voir-roles") ){
                  window.location.href = '/boulangerie/dashboard';
              }
  
           })
       } catch (error) {
           // setLoading(false);
       }
  },[]);

  useEffect(()=>{
    fetchUnitsList()
  },[])
  const fetchUnitsList = async ()=>{
    setLoading(true);
    try {
        axios.get(url.base+'/roles',{
            headers:{
                'Content-Type':'application/json',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                
            },
        }).then((resp)=>{

            if(resp.status == 200){
              setDataList(resp.data.data);
            }
            setLoading(false);
        })
    } catch (error) {
        setLoading(false);
    }
}
const handleSearch = (e) => {
  const searchValue = e.target.value.toLowerCase();
  if (searchValue.trim() === "") {
    fetchUnitsList();
  } else {
    const filtered = dataList.filter(item =>
      Object.values(item).some(value =>
        typeof value === 'string' && value.toLowerCase().includes(searchValue)
      )
    );
    setDataList(filtered);
  }

};
const deleteUnit=(id)=>{
  Swal.fire({
    title: 'ATTENTION !',
    text: 'Êtes-vous sûrs de vouloir supprimer cet élément.',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonText:"NON",
    confirmButtonText: 'OUI',
    cancelButtonColor:"red"
  }).then((result) => {
    if (result.isConfirmed) {
        try {
            axios.delete(url.base+'/roles/'+id,
                {
                    headers:{
                        'Content-Type':'application/json',
                        "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                        
                    },
                }
            ).then((resp)=>{
                // console.log(resp.data);
                if(resp.status == 200)
                {
                    if(resp.data.status == 600)
                    {
                      toast.error("Désolé, un problème est subvenu");
                    }else{
                      toast.success("Suppression efectuée avec succès.");
                      fetchUnitsList();
                    }
                   
                }
                fetchUnitsList();
            })
        } catch (error) {
            console.log(error);
        }

    
    }
  }); 
}
  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Gestion des rôles</h4>
              {/* <h6>Manage Group Permissions</h6> */}
            </div>
            <div className="page-btn">
              <Link
                to="/boulangerie/settings/create-roles"
                className="btn btn-added"
              >
                <img src={PlusIcon} alt="img" className="me-2" />
                Ajouter un rôle
              </Link>
            </div>
          </div>
          {/* /product list */}
          <div className="card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      className="form-control form-control-sm search-icon"
                      type="search"
                      placeholder="Search..."
                      onChange={handleSearch}
                    />
                    <a className="btn btn-searchset">
                      <img src={Search} alt="img" />
                    </a>
                  </div>
                </div>
                <div className="wordset">
                  <ul>
                    <li>
                      <a
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="pdf"
                      >
                        <img src={Pdf} alt="img" />
                      </a>
                    </li>
                    <li>
                      <a
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="excel"
                      >
                        <img src={Excel} alt="img" />
                      </a>
                    </li>
                    <li>
                      <a
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="print"
                      >
                        <img src={Printer} alt="img" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={dataList}
                  rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </>
  );
};

export default GroupPermission;
