/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Route, withRouter } from "react-router-dom";

import routerService from "../../Router";
import Header from "./Header";
import Sidebar from "./Sidebar"
import BaseUrl from "../../utils/BaseUrl";
import axios from "axios";

const url = BaseUrl();
const DefaultLayout = (props) => {
  const { match } = props;

  const [user,setUser] = useState([]);

  useEffect(()=>{
    try {
       axios.get(url.base+'/me',{
                headers:{
                    'Content-Type':'application/json',
                    "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                    
                },
            }).then((resp)=>{

              setUser(resp.data.data);
   
            })
        } catch (error) {
            // setLoading(false);
        }
  },[]);


  return (
    <>
      <div className="main-wrapper">
        <Header user={user}/>
        <div>
          {routerService &&
            routerService.map((route, key) => (
              <Route
                key={key}
                path={`${match.url}/${route.path}`}
                component={route.component}
              />
            ))}
        </div>
        <Sidebar permissions={user.permissions}/>
      </div>
      <div className="sidebar-overlay"></div>
    </>
  );
}

export default withRouter(DefaultLayout);
