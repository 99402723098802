/* eslint-disable react/prop-types */
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom';
import ProductionList from './productionList';
import AddProduction from './addproduction';
import VariationdeStock from './variationstock';
import ProductionDetail from './productiondetails';
import AjustementList from './ajustementList';
import AddAjustement from './addAjustement';
import RetourStockList from './retourStockList';
import AddRetourStockList from './addRetourStock';
import CommandeList from './commandeList';
import AddCommandes from './addcommande';
import LivraisonList from './livraisonList';
import AddLivraisons from './addLivraison';
import CommandeDetail from './commandedetails';
import LivraisonDetail from './livraisondetails';
import VariationdeStockDetailProduct from './variationstock_detail_product';
import RetourDetail from './retourdetails';
import HistoriqueSaleList from './histosaleList';
import VenteDetail from './ventedetails';
import HistoriqueSaleCaisseList from './histosaleListCaisse';
import VenteRetourList from './VenteRetourList';
import AddVenteRetours from './addventeretours';
import RetourVenduDetail from './retourventedetails';








const ProductionIndex = ({ match}) =>(
    <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/generalsettings`} />

        <Route path={`${match.url}/production-list`} component={ProductionList} />
        <Route path={`${match.url}/production-add`} component={AddProduction} />
        <Route path={`${match.url}/stock-production`} component={VariationdeStock} />
        <Route path={`${match.url}/production-details/:uuid`} component={ProductionDetail} />
        <Route path={`${match.url}/production-ajustement-list`} component={AjustementList} />

        <Route path={`${match.url}/production-retour-list`} component={RetourStockList} />
        <Route path={`${match.url}/production-add-ajustement`} component={AddAjustement} />
        <Route path={`${match.url}/production-add-retour`} component={AddRetourStockList} />
        <Route path={`${match.url}/production-detail-retour/:uuid`} component={RetourDetail} />


        <Route path={`${match.url}/commandes-list`} component={CommandeList} />
        <Route path={`${match.url}/commandes-details/:uuid`} component={CommandeDetail} />
        <Route path={`${match.url}/commandes-add`} component={AddCommandes} />

        <Route path={`${match.url}/livraisons-list`} component={LivraisonList} />
        <Route path={`${match.url}/livraisons-add`} component={AddLivraisons} />
        <Route path={`${match.url}/livraisons-details/:uuid`} component={LivraisonDetail} />


        <Route path={`${match.url}/history-stock/:uuid`} component={VariationdeStockDetailProduct} />

        <Route path={`${match.url}/sales-list`} component={HistoriqueSaleList} />
        <Route path={`${match.url}/sales-caisse-list`} component={HistoriqueSaleCaisseList} />
        <Route path={`${match.url}/sales-datail/:uuid`} component={VenteDetail} />

        <Route path={`${match.url}/vente-retour-list`} component={VenteRetourList} />
        <Route path={`${match.url}/vente-retour-add`} component={AddVenteRetours} />
        <Route path={`${match.url}/vente-retour-details/:uuid`} component={RetourVenduDetail} />

        {/* <Route path={`${match.url}/saleslist`} component={Saleslist} />
        <Route path={`${match.url}/add-sales`} component={Addsales} />
        <Route path={`${match.url}/edit-sales`} component={Editsales} />
        <Route path={`${match.url}/sales-details`} component={Saledetails} />
        <Route path={`${match.url}/salesreturnlist-return`} component={SalesReturnList} />
        <Route path={`${match.url}/addsalesreturn-return`} component={AddSalesReturn} /> */}



    </Switch>
)

export default ProductionIndex