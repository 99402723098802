/* eslint-disable no-undef */
/* eslint-disable no-dupe-keys */
// eslint-disable-next-line no-unused-vars

import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Plus,
  // Scanner,
  // Product7,
  DeleteIcon,
  Calendar,
  // Product8,
  // Product1,
} from "../../EntryFile/imagePath";
// import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
// import { useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import { useEffect, useState } from "react";
import BaseUrl from "../../utils/BaseUrl";
import separateNumber from "../../utils/separateur";
import moment from "moment/moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import can from "../../utils/Can";

const url = BaseUrl();
const AddAchatFournisseur = () => {
  const history = useHistory();
  const [startDate, setStartDate] = useState(new Date());
  const [products,setProducts] = useState([]);
  const [listCart,setListCart] = useState([]);
  const [errors,setErrors] = useState({});
  // const [quantity,setQuantity] = useState();
  // const [prixUnitaire,setPrixUnitaire] = useState();
  // const [totalItem,setTotalItem] = useState();
  const [sousTotal,setSousTotal] = useState(0);
  const [grandTotal,setGrandTotal] = useState(0);
  const [fournisseurs,setFournisseurs] = useState([]);
  const [selectOptionFournisseur,setSelectOptionFournisseur] = useState(null);
  const [selectOption,setSelectOption] = useState(null);
  const [bakehouses,setBakehouses] = useState([]);
  const [selectBakehouse,setSelectBakehouse] = useState(null);
  const [familles,setFamilles] = useState([]);
  const [optFamille,setOptFamille] = useState(null);

  const [loading, setLoading] = useState(false);
  const [fournisseurData,setFournisseurData] = useState({
    "phone":"",
    "first_name":"",
    "last_name":"",
    "address":"",
    "email":"",
    "company":"",
  });

  // const [purchaseData,setPurchase] = useState({
  //     "delivery_date":""
  // });


useEffect(()=>{ 
 
fetchFournisseurList();
fetchFamille();
},[listCart])

useEffect(() => {
  try {
    axios.get(url.base+'/me',{
             headers:{
                 'Content-Type':'application/json',
                 "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                 
             },
         }).then((resp)=>{
            setBakehouses(resp.data.data.bakehouses)
            if(!can(resp.data.data.permissions,"voir-page-achats") ){
                window.location.href = '/boulangerie/dashboard';
            }

         })
     } catch (error) {
         // setLoading(false);
     }
},[]);

const fetchFournisseurList = async ()=>{

  try {
      axios.get(url.base+'/suppliers',{
          headers:{
              'Content-Type':'application/json',
              "Authorization": `Bearer ${localStorage.getItem('_token_')}`
              
          },
      }).then((resp)=>{

          if(resp.status == 200){
            setFournisseurs(resp.data.data);
          }
          // setLoading(false);
      })
  } catch (error) {
      // setLoading(false);
  }
}
const fetchFamille= async()=> {
  try {
    axios.get(url.base+'/familles',{
             headers:{
                 'Content-Type':'application/json',
                 "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                 
             },
         }).then((resp)=>{

            setFamilles(resp.data.data)

         })
     } catch (error) {
         // setLoading(false);
     }
}
const handleChangeQty=(e,i)=>{

  const updatedProd = [...listCart];

  updatedProd[i].quantity=+ e

  updatedProd[i].product_discount= updatedProd[i].quantity * updatedProd[i].unit_price

  const newSousTotal = updatedProd.reduce((acc, item) => acc + item.product_discount, 0);
  
  setSousTotal(newSousTotal);
  setGrandTotal(newSousTotal)

  setListCart(updatedProd)

}

const handleChangePrixUnitaire=(value,i)=>{
  const updatedProd = [...listCart];

  updatedProd[i].unit_price =+ value

  updatedProd[i].product_discount= updatedProd[i].quantity * updatedProd[i].unit_price
  
  const newSousTotal = updatedProd.reduce((acc, item) => acc + item.product_discount, 0);
  
  setSousTotal(newSousTotal);
  setGrandTotal(newSousTotal);

  setListCart(updatedProd);
}

const removeItem = (i) => {
  const updatedProd = [...listCart];
  setSousTotal(sousTotal - updatedProd[i].product_discount);
  setGrandTotal(grandTotal - updatedProd[i].product_discount);

  updatedProd.splice(i, 1);

  setListCart(updatedProd);
};
 
const handleChangeFournisseur=(option)=>{
  setSelectOptionFournisseur(option.value);

}
console.log(selectOptionFournisseur);
const handleChangeOption=(option)=>{
  setSelectOption(option.value);

}
console.log(selectOption,selectBakehouse); 

const handleChangeFournisseurData=(e)=>{
  setFournisseurData({
      ...fournisseurData,
      [e.target.name]:e.target.value
  });
}

const optionbakehouses = bakehouses?.map((option) => ({
  label:`${option.name}`,
  value:option.id
}));
const handleChangeBakehouse=(option)=>{
  setSelectBakehouse(option.value);

  try {
    axios.get(url.base+'/products-bakehouses/'+option.value,{
        headers:{
            'Content-Type':'application/json',
            "Authorization": `Bearer ${localStorage.getItem('_token_')}`
            
        },
    }).then((resp)=>{

        if(resp.status == 200){
          setProducts(resp.data.data);
        }
        // setLoading(false);
    })
} catch (error) {
    // setLoading(false);
}
}

const optionfamilles = familles.map((opt)=>({
  value: opt.id,
  label:`${opt.name} `
}));

const handleChangeFamille=(option)=>{
  setOptFamille(option.value);
}
  const optionsproducts = products.map((opt)=>({
    value: opt.id,
    label:`${opt.name} (${opt.bakehouse?.name})`
  }));

  const optionfournisseurs = fournisseurs.map((opt)=>({
    value: opt.id,
    label:`${opt.first_name} ${opt.last_name} (${opt.phone}) / ${opt.category}`
  }));

  const options = [
    { value: 0, label: "En attente"},
    { value: 1, label: "Livré"},
  ];

  // console.log(optionfamilles);
  const handleChangeProduct=(option)=>{

    let product_check = products.find((product) => product.id === option.value);
    
    if(listCart.find((list)=> list.product_id === product_check.id)){
      console.log("oops")
    }else{
      const itemCart = {
        "product_id":product_check.id,
        "image":product_check.image,
        "name":`${product_check.name}(${product_check.bakehouse?.name})`,
        "quantity":0,
        "unit_price":0,
        "product_discount":0,
        "product_tax":0,
        "unit":product_check.unit?.name,
        "unit_id":product_check.unit?.id
      }
      setListCart([...listCart, itemCart]);
    }

  }


  const submitFormFournisseur= (e)=>{
    e.preventDefault();
    if(fournisseurData.first_name !="" && optFamille != null && selectBakehouse != null && fournisseurData.phone !="" && (/^\d{9,10}$/).test(fournisseurData.phone) && fournisseurData.last_name !=""){

      const _formData = new FormData();
      _formData.append('first_name',fournisseurData.first_name);
      _formData.append('phone',fournisseurData.phone);
      _formData.append('last_name',fournisseurData.last_name);
      _formData.append('address',fournisseurData.address);
      _formData.append('bakehouse_id',selectBakehouse);

      _formData.append('email',fournisseurData.email);
      _formData.append('company',fournisseurData.company);
      _formData.append('famille_id',optFamille);
  
      setLoading(true);
      try {
          axios.post(url.base+'/suppliers',_formData,
          {
              headers:{
                  'Content-Type':'application/json',
                  "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                  
              },
              // credentials:'include'
          }
          ).then((resp)=>{
              setLoading(false);
              if(resp.status == 200){
  
                  setErrors({});
                  toast.success("Enregistrer avec succès");

                    setFournisseurData({
                      'first_name':'',
                      'phone':'',
                      'last_name':'',
                      'address':'',
                      'email':'',
                      'company':'',
                    });
                    fetchFournisseurList()
              }else{
                setLoading(false);
                toast.error("Echec de l'enregistrement");

              }
          })
      } catch (error) {
          setLoading(false);         
      }
    }else{
      if(fournisseurData.first_name == ""){
        toast.error("Le nom est obligatoire");
      }else if(fournisseurData.last_name == ""){
        toast.error("Le prénoms est obligatoire");
      }else if(selectBakehouse == null){
        toast.error("La boulangerie est obligatoire");
      }else if(optFamille == null){
        toast.error("La catégorie est obligatoire");
      }else if(fournisseurData.phone == ""){
        toast.error("Le numéro de téléphone est obligatoire");
      }else if(!(/^\d{9,10}$/).test(fournisseurData.phone)){
        toast.error("Le numéro de téléphone saisi est invalid");
      }
    }
  }

  const submitForm=(e)=>{
    e.preventDefault();

    const _formData = new FormData();


    if(selectOption !== null && selectBakehouse !== null){
      if(selectOptionFournisseur !==null){
        _formData.append('supplier_id',selectOptionFournisseur);
      }
  
      _formData.append('subtotal',0);
      _formData.append('delivery_date',moment(startDate).format("YYYY-MM-DD") );
      _formData.append('total',grandTotal);
      _formData.append('status',selectOption);
      _formData.append('procurement_items',JSON.stringify(listCart));
  
      _formData.append('totaltax',0);
      _formData.append('totaldiscount',0);
      _formData.append('bakehouse_id',selectBakehouse);
  
      setLoading(true);
      try {
        axios.post(url.base+'/procurement-store',_formData,
        {
            headers:{
                'Content-Type':'application/json',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                
            },
            // credentials:'include'
        }
        ).then((resp)=>{
            setLoading(false);
            if(resp.status == 200){
  
                toast.success("Votre achat a été enregistré avec succès!")
                history.push('/boulangerie/approvisionnement/list-achat-fournisseur')
            }else{
              toast.error("Désolé, un problème est subvenu.")
            }
        })
    } catch (error) {
        setLoading(false);
    }
    }else{
      toast.error("Statut vide ou boulangerie n'a pas été selectionné")
    }


  }



  const showModalFournisseur=()=>{
    window.$("#addpayment").modal('show');
  }


  return (
    <>
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Ajout un achat</h4>
            {/* <h6>Add your new sale</h6> */}
          </div>
        </div>
        <div className="card">
          <form className="card-body" onSubmit={submitForm}>
            <div className="row"> 
              <div className="col-lg-6 col-sm-6 col-12 mt-2">
                {/* <div className="form-group"> */}
                  <label>Fournisseur</label>
                  <div className="row">
                    <div className="col-lg-10 col-sm-10 col-10">
                      <Select options={optionfournisseurs} onChange={handleChangeFournisseur}/>
                    </div>
                    <div className="col-lg-2 col-sm-2 col-2 ps-0">
                      <div className="add-icon" onClick={()=>showModalFournisseur()}>
                        <span>
                          <img src={Plus} alt="img" />
                        </span>
                      </div>
                    </div>
                  </div>
                {/* </div> */}
              </div>
              <div className="col-lg-3 col-sm-6 col-12">
                <div className="form-group">
                  <label>Date</label>
                  <div className="input-groupicon">
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                    />
                    <Link to="#" className="addonset">
                      <img src={Calendar} alt="img" />
                    </Link>
                  </div>
                </div>
              </div>
               <div className="col-lg-3 col-sm-6 col-12 mt-2">
                  <label>Boulangerie</label>
                  <div>
                  <Select
                    className="select"
                    // data={options1}
                    options={optionbakehouses}
                    onChange={handleChangeBakehouse}
                  />
                  </div>
                  
              </div> 
              <div className="col-lg-12 col-sm-6 col-12 mb-3">
                {/* <div className="form-group"> */}
                  <label>Selectionner un produit</label>
                  <div className="input-groupicon">
                      <Select placeholder="Ajouter un produit ..." options={optionsproducts} onChange={handleChangeProduct}/>
                  </div>
                {/* </div> */}
              </div>
            </div>
            <div className="row">
              <div className="table-responsive mb-3">
                <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Nom du produit</th>
                      <th style={{width:200}}>Prix</th>
                      <th style={{width:100}}>Quantité</th>
                      <th>Unité</th>
                     {/*  <th>Tax</th> */}
                      <th>Total</th>
                      <th>Action</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {listCart && 
                        listCart.map((item,index)=>
                        <tr key={index}>
                          <td>{index}</td>
                          <td className="productimgname">
                            <Link to="#" className="product-img">
                              <img src={`${url.image}${item.image}`} alt="product" />
                            </Link>
                            <Link to="#">{item.name}</Link>
                          </td>
                          <td><input type="number"   onChange={(e)=>handleChangePrixUnitaire(e.target.value,index)} className="form-control" value={item.unit_price}/> </td>
                          <td>
                          <input type="number"  onChange={(e)=>handleChangeQty(e.target.value,index)} className="form-control" value={item.quantity}/> 
                          </td>
                          <td>{item.unit}</td>
                          <td>
                            <b>
                              {separateNumber(item.product_discount)}
                            </b>
                          </td>
                          <td>
                            <button  type="button" className="btn" onClick={()=>removeItem(index)}>
                              <img src={DeleteIcon} alt="svg" />
                            </button>
                          </td>
                      </tr>
                    )}
                    
                   
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-sm-6 col-12">
                {/* <div className="form-group">
                  <label>Order Tax</label>
                  <input type="text" />
                </div> */}
              </div>
              <div className="col-lg-3 col-sm-6 col-12">
                {/* <div className="form-group">
                  <label>Discount</label>
                  <input type="text" />
                </div> */}
              </div>
           
              <div className="col-lg-6 col-sm-6 col-12">
                {/* <div className="form-group"> */}
                  <label>{"Statut de l'achat"} <span className="text-danger">(obligatoire)</span></label>
                  <Select options={options} onChange={handleChangeOption}/>
                {/* </div> */}
              </div>
              <div className="row">
                <div className="col-lg-6 ">
                  {/* <div className="total-order w-100 max-widthauto m-auto mb-4">
                    <ul>
                      <li>
                        <h4>Order Tax</h4>
                        <h5>$ 0.00 (0.00%)</h5>
                      </li>
                      <li>
                        <h4>Discount </h4>
                        <h5>$ 0.00</h5>
                      </li>
                    </ul>
                  </div> */}
                </div>
                <div className="col-lg-6 ">
                  <div className="total-order w-100 max-widthauto m-auto mb-4">
                    <ul>
                      <li>
                        <h4>Sous-total</h4>
                        <h5>{separateNumber(sousTotal)} F</h5>
                      </li>
                      <li className="total">
                        <h4>Grand Total</h4>
                        <h5>{separateNumber(grandTotal)} F</h5>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
              {loading &&
                  <button className="btn btn-submit" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        En chargement...
                    </button>
                }
                {!loading &&
                <button type="submit" className="btn btn-submit me-2" disabled={grandTotal <= 0  ? "disabled" : ""}>
                  Enregistrer
                </button>
                }
                <Link to="/boulangerie/approvisionnement/list-achat-fournisseur" className="btn btn-cancel">
                  Retour
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    {/* // add fournisseur */}
      <div
      className="modal fade"
      id="addpayment"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Ajouter fournisseur</h5>
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <form onSubmit={submitFormFournisseur}>
          <div className="modal-body">
              
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>Nom <span className="text-danger">*</span></label>
                    <input type="text" name="first_name" onChange={handleChangeFournisseurData} value={fournisseurData.first_name}/>
                    {errors && errors.first_name && (
                          <span className="text-danger">{errors.first_name}</span>
                      )}
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>Prénoms<span className="text-danger">*</span></label>
                    <input type="text" name="last_name" onChange={handleChangeFournisseurData} value={fournisseurData.last_name}/>
                    {errors && errors.last_name && (
                          <span className="text-danger">{errors.last_name}</span>
                      )}
                  </div>
                </div>
                <div className="col-6 mb-3">
                    <label>Boulangerie</label>
                    <Select options={optionbakehouses} onChange={handleChangeBakehouse}/>
                </div>
                <div className="col-6 mb-3">
                    <label>Catégorie</label>
                    <Select options={optionfamilles} onChange={handleChangeFamille}/>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label>Email</label>
                    <input type="email" name="email" className="form-control" onChange={handleChangeFournisseurData} value={fournisseurData.email}/>
                   
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>Adresse</label>
                    <input type="text" name="address" onChange={handleChangeFournisseurData} value={fournisseurData.address}/>
                   
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>Téléphone<span className="text-danger">*</span></label>
                    <input type="text" name="phone" onChange={handleChangeFournisseurData} value={fournisseurData.phone}/>
                    {errors && errors.phone && (
                          <span className="text-danger">{errors.phone}</span>
                      )}
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label>Entreprise</label>
                    <input type="text" name="company" onChange={handleChangeFournisseurData} value={fournisseurData.company}/>
                    
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer ">
            {loading &&
                <button className="btn btn-submit" type="button" disabled>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      En chargement...
                  </button>
              }
              {!loading &&
                <button type="submit" className="btn btn-submit">
                  Enregister
                </button>
              }
              <button
                type="button"
                className="btn btn-cancel"
                data-bs-dismiss="modal"
              >
                Fermer
              </button>
            </div>
          </form>
        
        </div>
      </div>
    </div>
  </>
  );
};

export default AddAchatFournisseur;
