/* eslint-disable no-undef */
/* eslint-disable no-dupe-keys */
// eslint-disable-next-line no-unused-vars

import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Plus,
  // Product7,
  DeleteIcon,
  Calendar,

} from "../../EntryFile/imagePath";
import "react-select2-wrapper/css/select2.css";

import Select from "react-select";
import BaseUrl from "../../utils/BaseUrl";
import separateNumber from "../../utils/separateur";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import can from "../../utils/Can";
import Swal from "sweetalert2";

const url = BaseUrl();
const AddVenteRetours = () => {
  const [startDate, setStartDate] = useState(new Date());
  const history = useHistory();
  const [products,setProducts] = useState([]);
  const [listCart,setListCart] = useState([]); 
  const [sousTotal,setSousTotal] = useState(0);
  const [errors,setErrors] = useState({});
  const [selectOptionCustomer,setselectOptionCustomer] = useState();
  const [customers,setcustomers] = useState([]);
  const [selectOption,setSelectOption] = useState();
  const [montantVerse,setMontantVerse] = useState(0);
  const [urlPayment,setUrlPayment] = useState(null);
  const [loading, setLoading] = useState(false);
  const [customerData,setcustomerData] = useState({
    "phone":"",
    "first_name":"",
    "last_name":"",
    "address":"",

  });
  const [bakehouses,setBakehouses] = useState([]);
  const [selectBakehouse,setSelectBakehouse] = useState(null);

  // const [intervalId, setIntervalId] = useState(null);
  // const [transid, setTransId] = useState();
  // const [orderid, setOrderId] = useState();

  useEffect(()=>{
    try {
      axios.get(url.base+'/me',{
               headers:{
                   'Content-Type':'application/json',
                   "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                   
               },
           }).then((resp)=>{
            setBakehouses(resp.data.data.bakehouses)
              if(!can(resp.data.data.permissions,"retour-add-vente") ){
                  window.location.href = '/boulangerie/dashboard';
              }
  
           })
       } catch (error) {
           // setLoading(false);
       }
  },[]); 

  useEffect(()=>{
    fetchCustomerList();
  },[listCart]);

  const fetchCustomerList = async ()=>{

    try {
        axios.get(url.base+'/customers',{
            headers:{
                'Content-Type':'application/json',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                
            },
        }).then((resp)=>{
  
            if(resp.status == 200){
              setcustomers(resp.data.data);
            }
            // setLoading(false);
        })
    } catch (error) {
        // setLoading(false);
    }
  }

  function productListBakehouse(id){
    try {
      axios.get(url.base+'/sale-stock-bakehouse/'+id,{
          headers:{
              'Content-Type':'application/json',
              "Authorization": `Bearer ${localStorage.getItem('_token_')}`
              
          },
      }).then((resp)=>{
  
          if(resp.status == 200){
            setProducts(resp.data.data);
          }
          // setLoading(false);
      })
  } catch (error) {
      // setLoading(false);
  }
  }
  const handleChangeCustomerData=(e)=>{
    setcustomerData({
        ...customerData,
        [e.target.name]:e.target.value
    });
  }
  const handleChangeProduct=(option)=>{

    let product_check = products.find((product) => product.id === option.value);
    
    if(listCart.find((list)=> list.product_id === product_check.id)){
      console.log("oops")
    }else{
      const itemCart = {
        "product_id":product_check.product_id,
        "image":product_check.image,
        "product_name":product_check.product_name,
        "quantity":0,
        "qty":product_check.quantity_return,
        "total_amount":0,
        "price":product_check.price,
        "stock":product_check.quantity,
      }
      setListCart([...listCart, itemCart]);
    }

  }

  const handleChangeOption=(option)=>{
    setSelectOption(option.value);
    // if(option.value == 1){
    //   // console.log(option)
    //   // window.$("#validateversement").modal('show');
    // }
  }

  const optionbakehouses = bakehouses?.map((option) => ({
    label:`${option.name}`,
    value:option.id
  }));
  const handleChangeBakehouse=(option)=>{
    setSelectBakehouse(option.value);
    productListBakehouse(option.value);
  }



  const ValidateCashfunction=()=>{
    window.$("#validateversement").modal('show');
  }



  const optionsproducts = products.map((opt)=>({
    value: opt.id,
    label:`${opt.product_name}`
  }));

  const optioncustomers = customers.map((opt)=>({
    value: opt.id,
    label:`${opt.first_name} ${opt.last_name}`
  }));

  const options = [
    { value: 0, label: "En attente"},
    { value: 1, label: "Confirmer et Soldé"},
  ];

  const handleChangeQty=(e,i)=>{

    const updatedProd = [...listCart];
    if(e > updatedProd[i].qty){
      toast.error("Quantité superieur à la valuer en stock.")
    }else{
      updatedProd[i].quantity =+ e;
      updatedProd[i].total_amount= updatedProd[i].quantity * updatedProd[i].price
  
      const newSousTotal = updatedProd.reduce((acc, item) => acc + item.total_amount, 0);
      
      setSousTotal(newSousTotal);
      setMontantVerse(newSousTotal);
      setListCart(updatedProd)
    }

  
  }
  
  const handleChangePrixUnitaire=(value,i)=>{
    const updatedProd = [...listCart];
  
    updatedProd[i].price =+ value
  
    updatedProd[i].total_amount= updatedProd[i].quantity * updatedProd[i].price
    
    const newSousTotal = updatedProd.reduce((acc, item) => acc + item.total_amount, 0);
    
    setSousTotal(newSousTotal);
    setMontantVerse(newSousTotal);
  
    setListCart(updatedProd);
  }
  
  const removeItem = (i) => {
    const updatedProd = [...listCart];

    setSousTotal(sousTotal - updatedProd[i].total_amount);
    setMontantVerse(sousTotal - updatedProd[i].total_amount);

    updatedProd.splice(i, 1);
  
    setListCart(updatedProd);
  };

  const handleChangeFournisseur=(option)=>{
    setselectOptionCustomer(option.value);
  
  }
  console.log(selectOptionCustomer);
  const submitFormFournisseur= (e)=>{
    e.preventDefault();

    const _formData = new FormData();
    _formData.append('first_name',customerData.first_name);
    _formData.append('phone',customerData.phone);
    _formData.append('last_name',customerData.last_name);
    _formData.append('address',customerData.address);
    _formData.append('bakehouse_id',selectBakehouse);


    // setLoading(true);
    if(selectBakehouse !== null && customerData.first_name !=="" && customerData.last_name !=="" && customerData.address !=="" && customerData.phone !=="" && (/^\d{9,10}$/).test(customerData.phone)){
      try {
        axios.post(url.base+'/customers',_formData,
        {
            headers:{
                'Content-Type':'application/json',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                
            },
            // credentials:'include'
        }
        ).then((resp)=>{
            // setLoading(false);
            if(resp.status == 200){

                setErrors({});
                toast.success("Enregistrement effectué avec succès")
                  setcustomerData({
                    'first_name':'',
                    'phone':'',
                    'last_name':'',
                    'address':'',
         
                  });
                  fetchCustomerList();
                  setSelectBakehouse(null);
                  window.$("#addpayment").modal('hide');
            }else{
              toast.error("Echec de l'enregistrement");
                // Swal.fire({
                //     position: 'top-end',
                //     icon: 'error',
                //     title:  "Echec de l'enregistrement",
                //     showConfirmButton: false,
                //     timer: 3000,
                //     toast:true,
                //     position:'top-right',
                //     timerProgressBar:true
                //   });
            }
        })
    } catch (error) {
        // setLoading(false);
// console.log(error);  
    }
    }else{
      if(selectBakehouse== null){
        toast.error("Veuillez selectionner une boulangerie");
      }else if(customerData.first_name == ""){
        toast.error("Le nom est obligatoire");   
      }else if(customerData.last_name == ""){
        toast.error("Le prénom est obligatoire");        
      }else if(customerData.phone == ""){
        toast.error("Le numéro est obligatoire");        
      }else if(!(/^\d{9,10}$/).test(customerData.phone)){
        toast.error("Le numéro de téléphone saisi est invalid");
      }else if(customerData.address == ""){
        toast.error("L'adresse est obligatoire")
      }
    }


  }

  const submitForm=(e)=>{
    e.preventDefault();

    const _formData = new FormData();
    
      _formData.append('customer_id',selectOptionCustomer);
      _formData.append('total_amount',sousTotal);
      // _formData.append('delivery_date',moment(startDate).format("YYYY-MM-DD") );
      _formData.append('status',selectOption);
      _formData.append('product_items',JSON.stringify(listCart));
      _formData.append('retourStatus',1);
  
      _formData.append('amount_versement',montantVerse);
      _formData.append('bakehouse_id',selectBakehouse);
  
      console.log(_formData);
      
      if(selectOption !== undefined && selectOptionCustomer !==undefined){
        setLoading(true);
      try {
        axios.post(url.base+'/orders',_formData,
        {
            headers:{
                'Content-Type':'application/json',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                
            },
            // credentials:'include'
        }
        ).then((resp)=>{
          console.log(resp.status);
            setLoading(false);
            if(resp.status === 200){
              toast.success("Enregistrer avec succès");

                window.$("#validateversement").modal('hide');
                  history.push('/boulangerie/production/vente-retour-list')
            }else{
              toast.error("Echec de l'enregistrement");

            }
        })
    } catch (error) {
        setLoading(false);
      console.log(error);  
    }
    }else{
      toast.error("client ou statut de la commande vide");
    }
  }
  
  const generateUrlPayment=()=>{
    if(selectOption !== undefined && selectOptionCustomer !==undefined){

        const _formData = new FormData();
      _formData.append('customer_id',selectOptionCustomer);
      _formData.append('total_amount',sousTotal);
      // _formData.append('status',selectOption);
      _formData.append('product_items',JSON.stringify(listCart));
      _formData.append('amount_versement',montantVerse);
      _formData.append('bakehouse_id',selectBakehouse);

      // startInterval()
      setLoading(true);
      try {
        axios.post(url.base+'/orders-store-epayment',_formData,
        {
            headers:{
                'Content-Type':'application/json',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                
            },
            // credentials:'include'
        }
        ).then((resp)=>{
          
            setLoading(false);
            if(resp.data.data.code !== 400){
              if(resp.data.data.code == 201){
                setUrlPayment(resp.data.data.payment_url);
                window.$("#validateUrl").modal('show');

                const intervalId = setInterval(()=>{

                  const _formData = new FormData();
                  _formData.append('transaction_id',resp.data.data.transaction_id);
                  _formData.append('order_id',resp.data.data.order_id);
              
                  try {
                    axios.post(url.base+'/orders-update-epayment',_formData,
                    {
                        headers:{
                            'Content-Type':'application/json',
                            "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                            
                        },
                        // credentials:'include'
                    }
                    ).then((resp)=>{
                      
                        if(resp.data.data == "OK"){
                          console.log(resp.data.data);
                          window.$("#validateUrl").modal('hide');
                          toast.success("Votre paiement est effectué avec succès !");
                          
                          
                          setTimeout(() => {
                            window.location.reload();
                            history.push('/boulangerie/production/vente-retour-list');
                          }, 4000);

                        }else if(resp.data.data == "ECHEC"){
                          
                          // toast.error("Le paiement a echoué, veuillez réessayer !");
                          Swal.fire({
                            title: 'Désolé',
                            text: 'Le paiement à echoué, veuillez reessayer svp !',
                            confirmButtonText:"OK,compris!",
                            confirmButtonColor:"red"
                          }).then((result) => {
                            if (result.isConfirmed) {
                              window.location.reload();              
                            
                            }
                          }); 
                          setTimeout(() => {
                            window.location.reload();
                          }, 2 * 60 * 1000);

                        }else{
                          console.log(resp.data.data);
                        }
                    })
                } catch (error) {
                  console.log(error);  
                }
                },5000);

                setTimeout(() => {
                  clearInterval(intervalId);

                  toast.info("Désolé le delai de paiement est passé.");
                  window.$("#validateUrl").modal('hide');
                  history.push('/boulangerie/production/vente-retour-list');
                },5 * 60 * 1000);

            }else{
              toast.error("Un problème est subvenu, veuillez resseyer plus tard.");
            }

            }else{
             toast.error(resp.data.data.msg);

            }
        })
    } catch (error) {
        setLoading(false);
      console.log(error);  
    }
    }else{
      toast.error("client ou statut de la commande vide");
    }
  }
  const showModalFournisseur=()=>{
    window.$("#addpayment").modal('show');
  }


  return (
    <>
        <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Enregistrer une vente</h4>
          </div>
        </div>
        <div className="card">
          <form className="card-body" onSubmit={submitForm}>
            <div className="row">

            <div className="col-lg-3 col-sm-6 col-12">
                <div className="form-group">
                  <label>Date</label>
                  <div className="input-groupicon">
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                    />
                    <Link to="#" className="addonset">
                      <img src={Calendar} alt="img" />
                    </Link>
                  </div>
                </div>
              </div>


        


              <div className="col-lg-5 col-sm-6 col-12 mt-2">
                  <label>Choisir un client</label>
                  <div className="row">
                    <div className="col-lg-10 col-sm-10 col-10">
                    <Select
                    className="select"
                    options={optioncustomers}
                    onChange={handleChangeFournisseur}
                   
                  />
                    </div>
                    <div className="col-lg-2 col-sm-2 col-2 ps-0">
                      <div className="add-icon" onClick={()=>showModalFournisseur()}>
                        <span>
                          <img src={Plus} alt="img" />
                        </span>
                      </div>
                    </div>
                  </div>
              </div>

              <div className="col-lg-4 col-sm-6 col-12 mt-2">
                  <label>Choisir une boulangerie</label>
                  <div className="row">
                    <div className="col-lg-10 col-sm-10 col-10">
                    <Select
                    className="select"
                    options={optionbakehouses}
                    onChange={handleChangeBakehouse}
                   
                  />
                    </div>
                   
                  </div>
              </div>

             
              <div className="col-lg-12 col-sm-6 col-12 mb-3">
                {/* <div className="form-group"> */}
                  <label>Selectionner un produit</label>
                  <div className="input-groupicon">
                      <Select placeholder="Ajouter un produit ..." options={optionsproducts} onChange={handleChangeProduct}/>
                  </div>
                {/* </div> */}
              </div>
            </div>
            <div className="row">
              <div className="table-responsive mb-3">
                <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Nom du produit</th>
                      <th className="text-center">Qty en stock</th>
                      <th style={{width:200}}>Prix en fcfa</th>
                      <th style={{width:200}}>Quantité</th>
                      {/* <th>Discount</th>
                      <th>Tax</th> */}
                      <th className="text-center">Total en fcfa</th>
                      <th>Action</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {listCart && 
                        listCart.map((item,index)=>
                        <tr key={index}>
                          <td>{index+1}</td>
                          <td className="productimgname">
                            <Link to="#" className="product-img">
                              <img src={`${url.image}${item.image}`} alt="product" />
                            </Link>
                            <Link to="#">{item.product_name}</Link>
                          </td>
                          <td className="text-center">
                          <b className="fw-bold text-danger">
                              {separateNumber(item.qty)}
                            </b>
                            
                          </td>
                          <td><input type="number"   onChange={(e)=>handleChangePrixUnitaire(e.target.value,index)} className="form-control" value={item.price}/> </td>
                          <td>
                            <input type="number"  onChange={(e)=>handleChangeQty(e.target.value,index)} className="form-control" value={item.quantity}/> 
                          </td>
                         
                          <td className="text-center">
                          <b className="fw-bold">
                              {separateNumber(item.total_amount)}
                            </b>
                            
                          </td>
                          <td>
                            <button type="button" className="btn" onClick={()=>removeItem(index)}>
                              <img src={DeleteIcon} alt="svg" />
                            </button>
                          </td>
                      </tr>
                    )}
                    
                   
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-sm-6 col-12">
                {/* <div className="form-group">
                  <label>Order Tax</label>
                  <input type="text" />
                </div> */}
              </div>
              <div className="col-lg-3 col-sm-6 col-12">
                {/* <div className="form-group">
                  <label>Discount</label>
                  <input type="text" />
                </div> */}
              </div>
            
              <div className="col-lg-6 col-sm-6 col-12">
                  <label>Statut de la vente</label>
                  <Select
                    className="select"
                    options={options}
                    onChange={handleChangeOption}
                  />
              </div>
              <div className="row">
                <div className="col-lg-6 ">
                  {/* <div className="total-order w-100 max-widthauto m-auto mb-4">
                    <ul>
                      <li>
                        <h4>Order Tax</h4>
                        <h5>$ 0.00 (0.00%)</h5>
                      </li>
                      <li>
                        <h4>Discount </h4>
                        <h5>$ 0.00</h5>
                      </li>
                    </ul>
                  </div> */}
                </div>
                <div className="col-lg-6 ">
                  <div className="total-order w-100 max-widthauto m-auto mb-4">
                    <ul>
                      
                      <li className="total">
                        <h4>Grand Total</h4>
                        <h5>{separateNumber(sousTotal)} FCFA</h5>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                {loading && selectOption == 0 | selectOption == 1 ?
                  <button className="btn btn-submit" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        En chargement...
                    </button>
                : ""}
                {!loading && selectOption == 0 &&
                <button type="submit" className="btn btn-submit me-2" disabled={sousTotal <= 0  ? "disabled" : ""} style={{"backgroundColor":"green"}}>
                  Enregistrer
                </button>
                }
            
                {!loading && selectOption == 1 &&
                <>
                <button type="button" className="btn btn-submit me-2" disabled={sousTotal <= 0  ? "disabled" : ""} style={{"backgroundColor":"green"}} onClick={()=>ValidateCashfunction()}>
                CASH
                </button>
                <button type="button" className="btn btn-cancel me-2" onClick={()=>generateUrlPayment()} disabled={sousTotal <= 0  ? "disabled" : ""} style={{"backgroundColor":"red"}}>
                E-PAYMENT
                </button>
                </>
           
                }
                <Link to="/boulangerie/production/vente-retour-list" className="btn btn-submit">
                  Annuler
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

        {/* // add fournisseur */}
        <div
      className="modal fade"
      id="addpayment"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Ajouter un client</h5>
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <form onSubmit={submitFormFournisseur}>
            <div className="modal-body">
            
            <div className="col-12 mb-2">
                    <label>Boulangerie <span className="text-danger">*</span></label>
                    <Select
                    className="select"
                    options={optionbakehouses}
                    onChange={handleChangeBakehouse}
                  />
                   
                </div>

              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>Nom <span className="text-danger">*</span></label>
                    <input type="text" name="first_name" onChange={handleChangeCustomerData} value={customerData.first_name}/>
                    {errors && errors.name && (
                          <span className="text-danger">{errors.first_name[0]}</span>
                      )}
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>Prénoms<span className="text-danger">*</span></label>
                    <input type="text" name="last_name" onChange={handleChangeCustomerData} value={customerData.last_name}/>
                    {errors && errors.name && (
                          <span className="text-danger">{errors.last_name[0]}</span>
                      )}
                  </div>
                </div>
            
                <div className="col-6">
                  <div className="form-group">
                    <label>Adresse<span className="text-danger">*</span></label>
                    <input type="text" name="address" onChange={handleChangeCustomerData} value={customerData.address}/>
                    {errors && errors.address && (
                          <span className="text-danger">{errors.address[0]}</span>
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>Téléphone<span className="text-danger">*</span></label>
                    <input type="text" name="phone" onChange={handleChangeCustomerData} value={customerData.phone}/>
                    {errors && errors.name && (
                          <span className="text-danger">{errors.phone[0]}</span>
                      )}
                  </div>
                </div>
              
              </div>
            </div>
            <div className="modal-footer ">
              <button type="submit" className="btn btn-submit">
                Enregister
              </button>
              <button
                type="button"
                className="btn btn-cancel"
                data-bs-dismiss="modal"
              >
                Fermer
              </button>
            </div>
          </form>
        
        </div>
      </div>
        </div>

        <div
              className="modal fade"
              id="validateversement"
              tabIndex={-1}
              aria-labelledby="validateversement"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-md modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Faire le versement </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="row" onSubmit={submitForm}>

                    

                      <div className="col-lg-12 col-sm-12 col-12">
                        <div className="form-group">
                          <label>Montant du versement</label>
                          <input type="text" onChange={(e)=>setMontantVerse(e.target.value)} value={montantVerse}/>
                        </div>
                      </div>
                    
                      
                      <div className="modal-footer">
                          {loading &&
                          <button className="btn btn-submit" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                En chargement...
                            </button>
                          }
                        {!loading &&
                          <button type="submit" className="btn btn-submit me-2" disabled={montantVerse <= 0  ? "disabled" : ""}>
                            Valider
                          </button>
                        }
                        {/* <button type="submit" className="btn btn-submit">
                          Valider
                        </button> */}
                        <button
                          type="button"
                          className="btn btn-cancel"
                          data-bs-dismiss="modal"
                        >
                          Fermer
                        </button>
                    </div>
                    </form>
                    
                  </div>
              
                </div>
              </div>
        </div>

        <div
              className="modal fade"
              id="validateUrl"
              tabIndex={-1}
              aria-labelledby="validateUrl"
              aria-hidden="true"
              data-bs-backdrop="static" 
              // data-keyboard="false"
            >
              <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">E-PAYMENT</h5>
                    <button
                      type="button"
                      className="btn-close"
                      // data-bs-dismiss="modal"
                      // aria-label="Close"
                      onClick={()=>window.location.reload()}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                  <iframe src={urlPayment} width="100%" height="600" allow="payment; fullscreen">
                    </iframe>
                    
                  </div>
              
                </div>
              </div>
        </div>

      {/* <div class="modal fade" id="echecPaiement" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"  data-bs-backdrop="static">
        <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">Confirmation de suppression </h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body pt-4 pb-4">
                <h6>Etes-vous sure ?<br/> cette action va supprimer </h6>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Fermer</button>
                <button type="button" class="btn btn-danger" wire:click="confirmeDelete">Oui,Je confirme</button>
            </div>
        </div>
        </div>
    </div> */}
    </>

  );
};

export default AddVenteRetours;
