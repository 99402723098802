/* eslint-disable no-undef */
/* eslint-disable no-dupe-keys */
// eslint-disable-next-line no-unused-vars
import { Link } from "react-router-dom";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  // Plus,
  // Scanner,
  // Product7,
  DeleteIcon,
  Calendar,
  // Product8,
  // Product1,
} from "../../EntryFile/imagePath";
// import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import BaseUrl from "../../utils/BaseUrl";
import Select from "react-select";
import separateNumber from "../../utils/separateur";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import can from "../../utils/Can";

const url= BaseUrl();
const EditAchatFournisseur = () => {
  const {uuid} = useParams();
  const [startDate, setStartDate] = useState();
  const history = useHistory();
  const [products,setProducts] = useState([]);
  const [productItem,setProductItem] = useState([]);
  const [listCart,setListCart] = useState(
    [{
      "product_id":null,
      "image":"",
      "name":"",
      "quantity":0,
      "unit_price":0,
      "product_discount":0,
      "product_tax":0,
      "unit":"",
      "unit_id":null
    }]
  );

  const [sousTotal,setSousTotal] = useState(0);
  const [grandTotal,setGrandTotal] = useState(0);
  const [fournisseurs,setFournisseurs] = useState([]);
  const [selectOptionFournisseur,setSelectOptionFournisseur] = useState(null);
  const [selectOption,setSelectOption] = useState(null);
  const [loading, setLoading] = useState(false);

  // const [bakehouses,setBakehouses] = useState([]);
  const [selectBakehouse,setSelectBakehouse] = useState(null);

  useEffect(()=>{
    try {
      axios.get(url.base+'/me',{
               headers:{
                   'Content-Type':'application/json',
                   "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                   
               },
           }).then((resp)=>{
            // setBakehouses(resp.data.data.bakehouses)
              if(!can(resp.data.data.permissions,"voir-page-achats") ){
                  window.location.href = '/boulangerie/dashboard';
              }
  
           })
       } catch (error) {
           // setLoading(false);
       }
  },[]);


  useEffect(()=>{ 
    try {
      axios.get(url.base+'/products',{
          headers:{
              'Content-Type':'application/json',
              "Authorization": `Bearer ${localStorage.getItem('_token_')}`
              
          },
      }).then((resp)=>{
  
          if(resp.status == 200){
            setProducts(resp.data.data);
          }
      })
  } catch (error) {
    // console.log(error);
  }

  try {
    axios.get(url.base+'/procurement/view/'+uuid,{
        headers:{
            'Content-Type':'application/json',
            "Authorization": `Bearer ${localStorage.getItem('_token_')}`
            
        },
    }).then((resp)=>{

        if(resp.status == 200){
          setProductItem(resp.data.data);
          setStartDate(moment(resp.data.data?.delivery_date).format('Do/MM/YYYY'));
          setSousTotal(resp.data.data?.total_amount);
          setSelectOptionFournisseur(resp.data.data?.supplier_id);
          setSelectBakehouse(resp.data.data?.bakehouse_id);
          // resp.data.data?.procurement_details?.map((product)=>{
          //   // const itemCart = {
          //   //   "product_id":product.id,
          //   //   "image":product.product?.image,
          //   //   "name":product.name,
          //   //   "quantity":product.quantity,
          //   //   "unit_price":product.unit_price,
          //   //   "product_discount":0,
          //   //   "product_tax":0,
          //   // }
          //   setListCart(resp.data.data?.procurement_details);
          // })
          const transArray = [];
          resp.data.data?.procurement_details?.forEach(item => { 
            transArray.push({
              "product_id":item.product?.id,
              "image":item.product?.image,
              "name":item.product?.name,
              "quantity":item.quantity,
              "unit_price":item.unit_price,
              "product_discount":0,
              "product_tax":0, 
              "unit":item.unit?.name,
              "unit_id":item.product?.unit_id,
            })
          })
          setListCart(transArray);
        }
    })
} catch (error) {
  // console.log(error);
}
  fetchFournisseurList();
  },[]);

  useEffect(()=>{

  },[listCart]);


  const fetchFournisseurList = async ()=>{

    try {
        axios.get(url.base+'/suppliers',{
            headers:{
                'Content-Type':'application/json',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                
            },
        }).then((resp)=>{
  
            if(resp.status == 200){
              setFournisseurs(resp.data.data);
            }
            // setLoading(false);
        })
    } catch (error) {
        // setLoading(false);
    }
  }

  const optionsproducts = products.map((opt)=>({
    value: opt.id,
    label:`${opt.name}`
  }));

  const optionfournisseurs = fournisseurs.map((opt)=>({
    value: opt.id,
    label:`${opt.first_name} ${opt.last_name} / ${opt.category}`
  }));

  const options = [
    { value: 0, label: "En attente"},
    { value: 1, label: "Livré"},
  ];
  const handleChangeQty=(e,i)=>{

    const updatedProd = [...listCart];
  
    updatedProd[i].quantity=+ e
  
    updatedProd[i].product_discount= updatedProd[i].quantity * updatedProd[i].unit_price
  
    const newSousTotal = updatedProd.reduce((acc, item) => acc + item.product_discount, 0);
  //   if(productItem?.total_amount > 0){
  //     var newSousTotal = productItem?.total_amount + updatedProd.reduce((acc, item) => acc + item.product_discount, 0);
  // }else{
  //    newSousTotal = updatedProd.reduce((acc, item) => acc + item.product_discount, 0);
  // }

    
    setSousTotal(newSousTotal);
    setGrandTotal(newSousTotal)
  
    setListCart(updatedProd)
  
  }
  
  const handleChangePrixUnitaire=(value,i)=>{
    const updatedProd = [...listCart];
  
    updatedProd[i].unit_price =+ value
  
    updatedProd[i].product_discount= updatedProd[i].quantity * updatedProd[i].unit_price
    

    const newSousTotal = updatedProd.reduce((acc, item) => acc + item.product_discount, 0);

    
    setSousTotal(newSousTotal);
    setGrandTotal(newSousTotal);
  
    setListCart(updatedProd);
  }
  
  const removeItem = (i) => {
    const updatedProd = [...listCart];
    setSousTotal(sousTotal - updatedProd[i].product_discount);
    setGrandTotal(grandTotal - updatedProd[i].product_discount);
  
    updatedProd.splice(i, 1);
  
    setListCart(updatedProd);
  };
   

  console.log(selectOptionFournisseur);
  const handleChangeOption=(option)=>{
    setSelectOption(option);
  
  }
  console.log(selectOption,selectBakehouse);


  const handleChangeFournisseur=(option)=>{
    setSelectOptionFournisseur(option.value);
  
  }
  // const optionbakehouses = bakehouses?.map((option) => ({
  //   label:`${option.name}`,
  //   value:option.id
  // }));
  // const handleChangeBakehouse=(option)=>{
  //   setSelectBakehouse(option.value);
  // }
  

  const handleChangeProduct=(option)=>{

    let product_check = products.find((product) => product.id === option.value);
    
    if(listCart.find((list)=> list.product_id === product_check.id)){
      console.log("oops")
    }else{
      const itemCart = {
        "product_id":product_check.id,
        "image":product_check.image,
        "name":product_check.name,
        "quantity":0,
        "unit_price":product_check.price,
        "product_discount":0,
        "product_tax":0,
        "unit":product_check.unit?.name,
        "unit_id":product_check.unit?.id
      }
      setListCart([...listCart, itemCart]);
    }

  }

  const submitForm=(e)=>{
    e.preventDefault();

    const _formData = new FormData();


      _formData.append('supplier_id',selectOptionFournisseur == null ? productItem?.supplier_id : selectOptionFournisseur);
      _formData.append('subtotal',sousTotal);
      _formData.append('delivery_date',moment(startDate).format("YYYY-MM-DD") );
      _formData.append('total',sousTotal);
      _formData.append('status',selectOption  == null ? productItem?.status : selectOption);
      _formData.append('procurement_items',JSON.stringify(listCart));
  
      _formData.append('totaltax',0);
      _formData.append('product_tax',0);
      _formData.append('sub_total',0);
      _formData.append('totaldiscount',0);
      _formData.append('sub_total',0);
      _formData.append('bakehouse_id',selectBakehouse);
  
      console.log(_formData);
      setLoading(true);
      try {
        axios.put(url.base+'/procurement-update/'+uuid,_formData,
        {
            headers:{
                'Content-Type':'application/json',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                
            },
            // credentials:'include'
        }
        ).then((resp)=>{
            setLoading(false);
            if(resp.status == 200){
  
                toast.success("Votre achat a été modifié avec succès!")
                history.push('/boulangerie/approvisionnement/list-achat-fournisseur')
            }else{
              toast.error("Désolé, un problème est subvenu.")
            }
        })
    } catch (error) {
        setLoading(false);
    }
  

  }

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>{"Modifier l'achat"}</h4>
            {/* <h6>Add your new sale</h6> */}
          </div>
        </div>
        <div className="card">
          <form className="card-body" onSubmit={submitForm}>
            <div className="row"> 
              <div className="col-lg-6 col-sm-6 col-12 mt-2">
                {/* <div className="form-group"> */}
                  <label>Fournisseur</label>
                  <div className="row">
                    <div className="col-lg-10 col-sm-10 col-10">
                    <Select 
                      value={optionfournisseurs.find(option => option.value === selectOptionFournisseur)}
                      options={optionfournisseurs} 
                      defaultValue={[{label: selectOptionFournisseur == selectOptionFournisseur ? optionfournisseurs.find(obj => obj.value === selectOptionFournisseur) : ""}]}
                      onChange={handleChangeFournisseur}/>
                      {/* <select className="select2 form-control" onChange={(e)=>handleChangeFournisseur(e.target.value)}>
                         <option value="">---</option>
                         {optionfournisseurs.map((fournisseur,index)=>
                            <option key={index} value={fournisseur.value == productItem.supplier_id ? productItem.supplier_id : fournisseur.value} selected={fournisseur.value == productItem.supplier_id ? "selected" : ""}>{fournisseur.label}</option> 
                         )}
                         
                      </select> */}
                    </div>
                    {/* <div className="col-lg-2 col-sm-2 col-2 ps-0">
                      <div className="add-icon" onClick={()=>showModalFournisseur()}>
                        <span>
                          <img src={Plus} alt="img" />
                        </span>
                      </div>
                    </div> */}
                  </div>
                {/* </div> */}
              </div>
              <div className="col-lg-3 col-sm-6 col-12">
                <div className="form-group">
                  <label>Date</label>
                  <div className="input-groupicon">
                    <input className="form-control" type="text" onChange={(e)=>setStartDate(e.target.value)} value={startDate}/>
                    {/* <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      dateFormat="dd/MM/yyyy"
                    /> */}
                    <Link to="#" className="addonset">
                      <img src={Calendar} alt="img" />
                    </Link>
                  </div>
                </div>
              </div>

              {/* <div className="col-lg-3 col-sm-6 col-12 mt-2">
                  <label>Boulangerie</label>
                  <div>
                  <Select 
                      value={optionbakehouses.find(option1 => option1.value === selectBakehouse)}
                      options={optionbakehouses} 
                      defaultValue={[{label: selectBakehouse === selectBakehouse ? optionbakehouses.find(obj1 => obj1.value === selectBakehouse) : ""}]}
                      onChange={handleChangeBakehouse}/>
                  </div>
                  
              </div>  */}
              {/* <div className="col-lg-3 col-sm-6 col-12">
                <div className="form-group">
                  <label>Supplier</label>
                  <Select2
                    className="select"
                    data={options1}
                    options={{
                      placeholder: "Choose",
                    }}
                  />
                </div>
              </div> */}
              <div className="col-lg-12 col-sm-6 col-12 mb-3">
                {/* <div className="form-group"> */}
                  <label>Selectionner un produit</label>
                  <div className="input-groupicon">
                      <Select placeholder="Ajouter un produit ..." options={optionsproducts} onChange={handleChangeProduct}/>
                  </div>
                {/* </div> */}
              </div>
            </div>
            <div className="row">
              <div className="table-responsive mb-3">
                <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Nom du produit</th>
                      <th style={{width:200}}>Prix</th>
                      <th style={{width:100}}>Quantité</th>
                      <th>Unité</th>
                      {/* <th>Discount</th>
                      <th>Tax</th> */}
                      <th>Total</th>
                      <th>Action</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {listCart && 
                        listCart.map((item,index)=>
                        <tr key={index}>
                          <td>{index}</td>
                          <td className="productimgname">
                            <Link to="#" className="product-img">
                              <img src={`${url.image}${item.image ? item.image : item.product?.image}`} alt="product" />
                            </Link>
                            <Link to="#">{item.name ? item.name : item.product?.name}</Link>
                          </td>
                          <td><input type="number"   onChange={(e)=>handleChangePrixUnitaire(e.target.value,index)} className="form-control" value={item.unit_price}/> </td>
                          <td>
                          <input type="number"  onChange={(e)=>handleChangeQty(e.target.value,index)} className="form-control" value={item.quantity}/> 
                          </td>
                          <td>{item.unit?.name ? item.unit?.name : item.unit}</td>
                          <td>
                            <b>
                              {separateNumber(item.product_discount = item.unit_price * item.quantity)}
                            </b>
                          </td>
                          <td>
                            <button type="button" className="btn" onClick={()=>removeItem(index)}>
                              <img src={DeleteIcon} alt="svg" />
                            </button>
                          </td>
                      </tr>
                    )}
                    
                   
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-sm-6 col-12">
                {/* <div className="form-group">
                  <label>Order Tax</label>
                  <input type="text" />
                </div> */}
              </div>
              <div className="col-lg-3 col-sm-6 col-12">
                {/* <div className="form-group">
                  <label>Discount</label>
                  <input type="text" />
                </div> */}
              </div>
           
              <div className="col-lg-6 col-sm-6 col-12">
                {/* <div className="form-group"> */}
                  <label>{"Statut de l'achat"} </label>
                  {/* <Select 
                    value={options.find((option)=>option.value === productItem?.status)}
                    options={options} 
                    onChange={handleChangeOption}
                  /> */}

                      <select className="select2 form-control" onChange={(e)=>handleChangeOption(e.target.value)}>
                         <option value="">---</option>
                         {options.map((option,index)=>
                            <option key={index} value={option.value} selected={option.value == productItem.status ? "selected" : ""}>{option.label}</option> 
                         )}
                         
                      </select>
                {/* </div> */}
              </div>
              <div className="row">
                <div className="col-lg-6 ">
                  {/* <div className="total-order w-100 max-widthauto m-auto mb-4">
                    <ul>
                      <li>
                        <h4>Order Tax</h4>
                        <h5>$ 0.00 (0.00%)</h5>
                      </li>
                      <li>
                        <h4>Discount </h4>
                        <h5>$ 0.00</h5>
                      </li>
                    </ul>
                  </div> */}
                </div>
                <div className="col-lg-6 ">
                  <div className="total-order w-100 max-widthauto m-auto mb-4">
                    <ul>
                      <li>
                        <h4>Sous-total</h4>
                        <h5>{separateNumber(sousTotal)} F</h5>
                      </li>
                      <li className="total">
                        <h4>Grand Total</h4>
                        <h5>{separateNumber(sousTotal)} F</h5>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
              {loading &&
                  <button className="btn btn-submit" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        En chargement...
                    </button>
                }
                {!loading &&
                <button type="submit" className="btn btn-submit me-2" disabled={productItem?.status == 1 ? "disabled":""}>
                  Enregistrer
                </button>
                }
                <Link to="/boulangerie/approvisionnement/list-achat-fournisseur" className="btn btn-cancel">
                  Retour
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditAchatFournisseur;
