/* eslint-disable no-unused-vars */
/* eslint-disable no-dupe-keys */
import React, { useEffect, useState } from "react";
import { DeleteIcon, Upload } from "../../EntryFile/imagePath";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import Select from "react-select";
import BaseUrl from "../../utils/BaseUrl";
import { Link, useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import can from "../../utils/Can";

const options = [
  { id: 1, text: "India", text: "India" },
  { id: 2, text: "Australia", text: "Australia" },
];
const options1 = [
  { id: 1, text: "City1", text: "City1" },
  { id: 2, text: "City2", text: "City2" },
];

const url = BaseUrl();
const EditUsers = () => {
  const history = useHistory();
  const {id} = useParams();
  const {name} = useParams();

  const [loading,setLoading] = useState(false);
  const [boulangeries,setBoulangeries] = useState([]);
  const [roles,setRoles] = useState([]);
  const [selectRole,setSelectRole] = useState(null);
  const [selectBoulangerie,setSelectBoulangerie] = useState([]);
  const [productList,setProductList] = useState([]);

  const [userData,setUserData] = useState({
    "email":"",
    "last_name":"",
    "first_name":"",
    "bakehouse_id":"",
    "phone":"",
  });

  const [passwordData,setPassWordData] = useState({
    'new_password':'',
    'CNewpassword':''
  })

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  useEffect(()=>{
    try {
      axios.get(url.base+'/me',{
               headers:{
                   'Content-Type':'application/json',
                   "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                   
               },
           }).then((resp)=>{
  
              if(!can(resp.data.data.permissions,"gestion-utilisateurs") ){
                  window.location.href = '/boulangerie/dashboard';
              }
  
           })
       } catch (error) {
           // setLoading(false);
       }
  },[]);
  useEffect(()=>{
    bakehouseList();
    roleList();
    try {
      axios.get(url.base+'/users/'+id,{
          headers:{
              'Content-Type':'application/json',
              "Authorization": `Bearer ${localStorage.getItem('_token_')}`
              
          },
      }).then((resp)=>{
  
          if(resp.status == 200){
            setUserData({
              "bakehouse_id":resp.data.data.user.bakehouse_id,
              "email":resp.data.data.user.email,
              "first_name":resp.data.data.user.first_name,
              "last_name":resp.data.data.user.last_name,
              "phone":resp.data.data.user.phone
            });
            // console.log(resp.data.data.bakehouses)
            setSelectBoulangerie(resp.data.data.bakehouses);
            setSelectRole(resp.data.data.userRole[0])
            setProductList(resp.data.data?.products)
          }
          // setLoading(false);
      })
  } catch (error) {
      // setLoading(false);
  }
  },[]);

  function bakehouseList(){
    try {
      axios.get(url.base+'/bakehouses',{
          headers:{
              'Content-Type':'application/json',
              "Authorization": `Bearer ${localStorage.getItem('_token_')}`
              
          },
      }).then((resp)=>{
  
          if(resp.status == 200){
            setBoulangeries(resp.data.data);
          }
          // setLoading(false);
      })
  } catch (error) {
      // setLoading(false);
  }
  }

  function roleList(){
    try {
      axios.get(url.base+'/get-roles',{
          headers:{
              'Content-Type':'application/json',
              "Authorization": `Bearer ${localStorage.getItem('_token_')}`
              
          },
      }).then((resp)=>{
  
          if(resp.status == 200){
            setRoles(resp.data.data);
          }
          // setLoading(false);
      })
  } catch (error) {
      // setLoading(false);
  }
  }

  const handleChange=(event)=>{
    setUserData({
      ...userData,
      [event.target.name]:event.target.value
    })
  }

  const handleChangePassword=(event)=>{
    setPassWordData({
      ...passwordData,
      [event.target.name]:event.target.value
    })
  }

  const handleChangeRole=(selectoption)=>{
    setSelectRole(selectoption.value);
  }

  const handleChangeBoulangerie=(selectoption)=>{
    setSelectBoulangerie(selectoption.map(option => option.value));
  }

  const optionsboulangies = boulangeries?.map((opt)=>({
    value: opt.id,
    label:`${opt.name}`
  }));

  const optionsroles = roles?.map((opt)=>({
    value: opt.name,
    label:`${opt.name}`
  }));


  const submitForm=(e)=>{
    e.preventDefault();

    const pattern_email = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/;
    const bakehouseTabs = selectBoulangerie.map(option =>option);
    if(selectBoulangerie.length > 0 && userData.first_name !=="" && userData.last_name !=="" && userData.phone !=="" && userData.email !==""  && userData.password !==""  && pattern_email.test(userData.email) && selectRole !==null && (/^\d{9,10}$/).test(userData.phone)){
      const _formData = new FormData();
      _formData.append('first_name',userData.first_name);
      _formData.append('last_name',userData.last_name);
      _formData.append('name',`${userData.first_name} ${userData.last_name}`);
      _formData.append('phone',userData.phone);
      _formData.append('email',userData.email);
      _formData.append('roles',selectRole);
      _formData.append('bakehouseTabs',bakehouseTabs);
      
  
      console.log(_formData);
      setLoading(true);
      try {
        axios.put(url.base+'/users/'+id,_formData,
        {
            headers:{
                'Content-Type':'application/json',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`                
            },
            // credentials:'include'
        }
        ).then((resp)=>{
            setLoading(false);
            if(resp.status == 200){
  
                  toast.success("Modification effectué avec succès");
                  history.push('/boulangerie/comptes/users-list')
            }else{
                toast.error("Un problème est subvenu !");
            }
        }).catch((error)=>{  
          console.log(error.response);              
            setLoading(false);  
          
        })
    } catch (error) {
        setLoading(false);
        console.log(error);  
    }
    }else{
      
      if(userData.first_name == ""){
        toast.error("Le nom est obligatoire");
      }else if(userData.last_name == ""){
        toast.error("Le prénom est obligatoire");        
      }else if(userData.phone == ""){
        toast.error("Le numéro de téléphone est obligatoire");
      }else if(!(/^\d{9,10}$/).test(userData.phone)){
        toast.error("Le numéro de téléphone saisi est invalid");
      }else if(userData.email == ""){
        toast.error("L'adresse e-mail est obligatoire");
      }else if(!pattern_email.test(userData.email)){
        toast.error("L'adresse e-mail saisi est invalid ('@', '.')");
      }else if(selectRole == null){
        toast.error("Le rôle est obligatoire");
      }else if(selectBoulangerie == 0){
        toast.error("Veuillez choisir une boulangerie");
      }
    }


  }

  const updatePassword=()=>{
    window.$('#validateUrl').modal('toggle');
  }

  const submitFormPassword=(e)=>{
    e.preventDefault();


    if(passwordData.new_password.length >= 5 && passwordData.new_password === passwordData.CNewpassword && passwordData.new_password !=="" && passwordData.CNewpassword !=="" ){
      const _formData = new FormData();
      _formData.append('new_password',passwordData.new_password);
      _formData.append('user_id',id);
  
  
      console.log(_formData);
      try {
        axios.post(url.base+'/update-password-users/',_formData,
        {
            headers:{
                'Content-Type':'application/json',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`                
            },
            // credentials:'include'
        }
        ).then((resp)=>{
            setLoading(false);
            if(resp.status == 200){
                  setPassWordData({
                      "new_password":"",
                      "new_password":""
                  });
                  toast.success("Modification effectué avec succès");
                  window.$('#validateUrl').modal('hide');
            }else{
                toast.error("Un problème est subvenu !");
            }
        }).catch((error)=>{  
          console.log(error.response);              
          
        })
    } catch (error) {
        console.log(error);  
    }
    }else{
      
      if(passwordData.new_password == ""){
        toast.error("Veuillez entrer un nouveau mot de passe.");        
      }else if(passwordData.CNewpassword == ""){
        toast.error("Veuillez confirmer le mot de passe.");
      }else if(passwordData.new_password !== passwordData.CNewpassword){
        toast.error("Le mot de passe de correspond pas");
      }else if(passwordData.new_password.length <= 5 ){
        toast.error("Le mot de passe doit contenir au moins 5 caractères");
      }

    }


  }
  // console.log(selectBoulangerie);
  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Modifier un utlisateur</h4>
              {/* <h6>Add/Update Supplier</h6> */}
            </div>
          </div>
          {/* /add */}
          <div className="card">
            <form className="card-body" onSubmit={submitForm}>
              <div className="row">
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Nom</label>
                    <input type="text" name="first_name" onChange={handleChange} value={userData.first_name}/>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Prénoms</label>
                    <input type="text" name="last_name" onChange={handleChange} value={userData.last_name}/>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Email</label>
                    <input type="email" className="form-control" name="email" onChange={handleChange} value={userData.email}/>
                  </div>
                </div>
                {/* <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Password</label>
                    <div className="pass-group">
                      <input
                        type={passwordShown ? "text" : "password"}
                        className=" pass-input"
                      />
                      <span
                        className={`fas toggle-password ${
                          passwordShown ? "fa-eye" : "fa-eye-slash"
                        }`}
                        onClick={togglePassword}
                      />
                    </div>
                  </div>
                </div> */}
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Téléphone</label>
                    <input type="text" name="phone" onChange={handleChange} value={userData.phone} placeholder="Ex: 0141079236"/>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                    <label>Selectionner une boulangerie</label>
                    <Select 
                      value={optionsboulangies.filter(obj => selectBoulangerie?.includes(obj.value))}
                      options={optionsboulangies}
                      defaultValue={optionsboulangies.filter(obj => selectBoulangerie?.includes(obj.value))}
                      onChange={handleChangeBoulangerie} 
                      isMulti
                      isDisabled={selectRole == "livreur" ? true :false}

                    />
                     {/* <Select 
                      value={optionsboulangies.find(obj => obj.value === selectBoulangerie)}
                      options={optionsboulangies}
                      defaultValue={[{label: selectBoulangerie == selectBoulangerie ? optionsboulangies.find(obj => obj.value === selectBoulangerie) : ""}]}
                      onChange={handleChangeBoulangerie} 

                    /> */}
                </div>
              <div className="col-lg-6 col-sm-6 col-12 mb-4">
                    <label>Selectionner un rôle</label>
                    <Select 
                      value={optionsroles.find(obj => obj.value === selectRole)}
                      options={optionsroles} 
                      defaultValue={[{label: selectRole == selectRole ? optionsroles.find(obj => obj.value === selectRole) : ""}]}
                      onChange={handleChangeRole}
                      isDisabled={selectRole == "livreur" ? true : false}
                    />
                </div>
                {selectRole == "livreur" &&
                  <>


                <div className="row">
                      <div className="table-responsive mb-3">
                        <h5>Produits revendeurs</h5>
                        <table className="table table-striped">
                          <thead>
                            <tr >
                              <th>#</th>
                              <th>Nom du produit</th>
                              {/* <th style={{width:200}}>Prix du produit FCFA</th>                */}
                              <th style={{width:200}}>Prix revendeur FCFA</th>               
                              {/* <th>Action</th> */}
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            {productList && 
                                productList.map((item,index)=>
                                <tr key={index}>
                                  <td>{index+1}</td>
                                  <td className="productimgname">
                                    <Link to="#" className="product-img">
                                      <img src={`${url.image}${item.product.image}`} alt="product" />
                                    </Link>
                                    <Link to="#">{item.product.name}</Link>
                                  </td>
                                  <td className="fw-bold">{item.price_seller}</td>
                                  {/* <td><input type="number"    className="form-control" placeholder="saisir le prix" /> </td> */}

                                  {/* <td>
                                    <button type="button" className="btn" >
                                      <img src={DeleteIcon} alt="svg" />
                                    </button>
                                  </td> */}
                              </tr>
                            )}
                            
                          
                          </tbody>
                        </table>
                      </div>
                  </div> 
                  </>
                }


                <div className="col-lg-12">
                      {loading &&
                        <button className="btn btn-submit me-2" type="button" disabled>
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              En chargement...
                          </button>
                      }
                      {!loading &&
                      <button type="submit" className="btn btn-submit me-2" >
                        Modifier
                      </button>
                      }
                      <button type="button" className="btn btn-submit me-2" style={{"backgroundColor":"brown"}} onClick={()=>updatePassword()}>
                        Modifier le mot de passe
                      </button>
                  <Link className="btn btn-cancel" to="/boulangerie/comptes/users-list">Retour</Link>
                </div>
              </div>
            </form>
          </div>
          {/* /add */}
        </div>
      </div>

      <div
              className="modal fade"
              id="validateUrl"
              tabIndex={-1}
              aria-labelledby="validateUrl"
              aria-hidden="true"
              data-bs-backdrop="static" 
              // data-keyboard="false"
            >
              <div className="modal-dialog modal-md modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Modifier mot de passe</h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="card-body" onSubmit={submitFormPassword}>
                        
                      <div className="col-lg-12 col-sm-12 col-12">
                          <div className="form-group">
                            <label>Nouveau mot de passe  <span
                                className={`fas toggle-password ${
                                  passwordShown ? "fa-eye" : "fa-eye-slash"
                                }`}
                                onClick={togglePassword}
                              /></label>
                            <input
                                type={passwordShown ? "text" : "password"}
                                className=" pass-input"
                                name="new_password"
                                onChange={handleChangePassword}
                              />
                             
                            {/* <input type="password" name="new_password" onChange={handleChangePassword}  className="form-control"/> */}
                          </div>
                        </div>
                        <div className="col-lg-12 col-sm-12 col-12">
                          <div className="form-group">
                          <label>Confirmer le nouveau mot de passe </label>
                            <input
                                type={passwordShown ? "text" : "password"}
                                className=" pass-input"
                                name="CNewpassword"
                                onChange={handleChangePassword}
                              />
                          </div>
                        </div>

                        <button type="submit" className="btn btn-submit me-2 form-control" >
                        Modifier
                      </button>
                    </form>
                    
                  </div>
              
                </div>
              </div>
        </div>
    </>
  );
};

export default EditUsers;
