/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Table from "../../EntryFile/datatable";
import Tabletop from "../../EntryFile/tabletop"
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import {
  ClosesIcon,
  Noimage,
  Excel,
  Filter,
  Pdf,
  PlusIcon,
  Printer,
  Search,
  search_whites,
  EditIcon,
  DeleteIcon,
} from "../../EntryFile/imagePath";
import axios from "axios";
import BaseUrl from "../../utils/BaseUrl";
import { toast } from "react-toastify";
import can from "../../utils/Can";

const url = BaseUrl();
const UsersList = () => {
  const [inputfilter, setInputfilter] = useState(false);
  const [users,setUsers] = useState([]);
  const [excelData,setExcelData] = useState({});

  const togglefilter = (value) => {
    setInputfilter(value);
  };

  const [data] = useState([
    {
      id: 1,
      supplierName: "Apex Computers",
      code: "201",
      phone: "+12163547758",
      email: "thomas@example.com",
      country: "USA",
    },
    {
      id: 2,
      supplierName: "AIM Infotech",
      code: "202",
      phone: "123-456-776",
      email: "benjamin@example.com",
      country: "Germany",
    },

  ]);

    useEffect(()=>{
    try {
      axios.get(url.base+'/me',{
               headers:{
                   'Content-Type':'application/json',
                   "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                   
               },
           }).then((resp)=>{
  
              if(!can(resp.data.data.permissions,"gestion-utilisateurs") ){
                  window.location.href = '/boulangerie/dashboard';
              }
  
           })
       } catch (error) {
           // setLoading(false);
       }
  },[]);

  useEffect(()=>{
    usersList();
  },[]);

  function usersList(){
      try {
        axios.get(url.base+'/select-users-abonnes',{
            headers:{
                'Content-Type':'application/json',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                
            },
        }).then((resp)=>{
    
            if(resp.status == 200){
              setUsers(resp.data.data);
              const transArray = [];
              resp.data.data.forEach(item => { 
                transArray.push({
                  "NOM ET PRENOMS":`${item.complet_name}`,
                  "TELEPHONE":item.phone,
                  "BOULANGERIE":item.name,
    
              });
                      });
            setExcelData(transArray)
            }
            // setLoading(false);
        })
    } catch (error) {
        // setLoading(false);
    }
  }


  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    if (searchValue.trim() === "") {
      usersList();
    } else {
      const filtered = users.filter(item =>
        Object.values(item).some(value =>
          typeof value === 'string' && value.toLowerCase().includes(searchValue)
        )
      );
      setUsers(filtered);
    }
  
};
  const columns = [
    {
      title: "Nom et prénoms",
      dataIndex: "complet_name",
      render: (text, record) => (
        <div className="productimgname">{text}</div>
      ),
      sorter: (a, b) => a.complet_name.length - b.complet_name.length,
      width: "250px",
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: "Téléphone",
      dataIndex: "phone",
      sorter: (a, b) => a.phone.length - b.phone.length,
    },
    {
      title: "Boulangerie",
      dataIndex: "bakehouses",
      className:"text-center",
      render: (text, record) => (
        <>
     
            <span className="fw-bold">{record.bakehouses?.length}</span>
          
        </>
      ),
      sorter: (a, b) => a.active.length - b.active.length,
  
    },
    {
      title: "Statut",
      className: "text-center",
      dataIndex: "active",
      render: (text, record) => (
        <>
          {text === 1 && (
            <span className="badges bg-lightgreen">Activé</span>
          )}
          {text === 0 && (
            <span className="badges bg-danger">Désactivé</span>
          )}
        </>
      ),
      sorter: (a, b) => a.active.length - b.active.length,
    },
    {
      title: "Action",
      render: (text, record) => (
        <>
          <Link className="me-3" to={`/boulangerie/comptes/edit-users/${record.id}/${record.first_name.toLowerCase()}`}>
            <img src={EditIcon} alt="img" />
          </Link>
          {record.active == 1 && <button className="btn btn-success btn-sm pb-0 mb-1" to="#" onClick={()=>DesactiveFunctionUser(record.id)}><i className="fa fa-unlock"></i></button>}
          {record.active == 0 &&  <button className="btn btn-danger btn-sm pb-0 mb-1" to="#" onClick={()=>activeFunctionUser(record.id)}><i className="fa fa-lock"></i></button>}

         
        </>
      ),
    },
  ];



  const activeFunctionUser=(id)=>{
    Swal.fire({
      title: 'INFO !',
      text: 'Cette action va activer cet utilisateur.',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonText:"NON",
      confirmButtonText: 'OUI',
      cancelButtonColor:"red"
    }).then((result) => {
      if (result.isConfirmed) {
          try {
              axios.get(url.base+'/activate-or-desactivate-user/'+id,
                  {
                      headers:{
                          'Content-Type':'application/json',
                          "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                          
                      },
                  }
              ).then((resp)=>{
                  // console.log(resp.data);
                  if(resp.status == 200)
                  {
             
                      toast.success('Utilisateur activé avec succès');
                     
                  }
                  usersList();
              })
          } catch (error) {
              console.log(error);
          }

      
      }
    }); 
  }

  const DesactiveFunctionUser=(id)=>{
    Swal.fire({
      title: 'INFO !',
      text: "Cette action va désactiver cet utilisateur.",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonText:"NON",
      confirmButtonText: 'OUI',
      cancelButtonColor:"red"
    }).then((result) => {
      if (result.isConfirmed) {
          try {
              axios.get(url.base+'/activate-or-desactivate-user/'+id,
                  {
                      headers:{
                          'Content-Type':'application/json',
                          "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                          
                      },
                  }
              ).then((resp)=>{
                  // console.log(resp.data);
                  if(resp.status == 200)
                  {
             
                      toast.success('Utilisateur desactivé avec succès');
                     
                  }
                  usersList();
              })
          } catch (error) {
              console.log(error);
          }

      
      }
    }); 
  }
  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Liste des utilisateurs</h4>
            </div>
            <div className="page-btn">
              <Link
                to="/boulangerie/comptes/add-users"
                className="btn btn-added"
              >
                <img src={PlusIcon} alt="img" className="me-1" />
                Ajouter un utilisateur
              </Link>
            </div>
          </div>
          {/* /product list */}
          <div className="card">
            <div className="card-body">
            <Tabletop inputfilter={inputfilter} togglefilter={togglefilter} excelData={excelData} filename={"utilisateurs"} handleSearch={handleSearch}/>
              {/* /Filter */}
              <div
                className={`card mb-0 ${ inputfilter ? "toggleCls" : ""}`}
                id="filter_inputs"
                style={{ display: inputfilter ? "block" :"none"}}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="form-group">
                        <input type="text" placeholder="Enter Supplier Code" />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="form-group">
                        <input type="text" placeholder="Enter Supplier" />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="form-group">
                        <input type="text" placeholder="Enter Phone" />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="form-group">
                        <input type="text" placeholder="Enter Email" />
                      </div>
                    </div>
                    <div className="col-lg-1 col-sm-6 col-12 ms-auto">
                      <div className="form-group">
                        <a className="btn btn-filters ms-auto">
                          <img src={search_whites} alt="img" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
                <Table columns={columns} dataSource={users} />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </>
  );
};
export default UsersList;
 