/* eslint-disable no-undef */
/* eslint-disable no-dupe-keys */
// eslint-disable-next-line no-unused-vars

import { Link } from "react-router-dom";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  // Plus,
  // Scanner,
  // Product7,
  DeleteIcon,
  // Calendar,
  // Product8,
  // Product1,
} from "../../EntryFile/imagePath";
// import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
// import { useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import { useEffect, useState } from "react";
import BaseUrl from "../../utils/BaseUrl";
// import separateNumber from "../../utils/separateur";
// import moment from "moment/moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import can from "../../utils/Can";

const url = BaseUrl();
const AddAjustement = () => {
  const history = useHistory();
  // const [startDate, setStartDate] = useState(new Date());
  const [products,setProducts] = useState([]);
  const [listCart,setListCart] = useState([]);
  // const [errors,setErrors] = useState({});
  // const [quantity,setQuantity] = useState();
  // const [prixUnitaire,setPrixUnitaire] = useState();
  // const [totalItem,setTotalItem] = useState();
  const [sousTotal,setSousTotal] = useState(0);
  const [grandTotal,setGrandTotal] = useState(0);
  // const [selectOptionFournisseur,setSelectOptionFournisseur] = useState();
  // const [selectOption,setSelectOption] = useState();
  const [loading, setLoading] = useState(false);

  const [bakehouses,setBakehouses] = useState([]);
  const [selectBakehouse,setSelectBakehouse] = useState(null);

  useEffect(()=>{
    try {
      axios.get(url.base+'/me',{
               headers:{
                   'Content-Type':'application/json',
                   "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                   
               },
           }).then((resp)=>{
            setBakehouses(resp.data.data.bakehouses)
              if(!can(resp.data.data.permissions,"ajustement-retour-produit") ){
                  window.location.href = '/boulangerie/dashboard';
              }
  
           })
       } catch (error) {
           // setLoading(false);
       }
  },[]);

useEffect(()=>{

},[listCart])

useEffect(() => {
  $(document).on("click", ".delete-set", function () {
    $(this).parent().parent().hide();
  });
});

function productListBakehouse(id){
  try {
    axios.get(url.base+'/sale-stock-bakehouse/'+id,{
        headers:{
            'Content-Type':'application/json',
            "Authorization": `Bearer ${localStorage.getItem('_token_')}`
            
        },
    }).then((resp)=>{

        if(resp.status == 200){
          setProducts(resp.data.data);
        }
        // setLoading(false);
    })
} catch (error) {
    // setLoading(false);
}
}

const handleChangeQty=(e,i)=>{

  const updatedProd = [...listCart];

  updatedProd[i].after_quantity=+ e

  setListCart(updatedProd)

}

// const handleChangePrixUnitaire=(value,i)=>{
//   const updatedProd = [...listCart];

//   updatedProd[i].unit_price =+ value

//   updatedProd[i].product_discount= updatedProd[i].quantity * updatedProd[i].unit_price
  
//   const newSousTotal = updatedProd.reduce((acc, item) => acc + item.product_discount, 0);
  
//   setSousTotal(newSousTotal);
//   setGrandTotal(newSousTotal);

//   setListCart(updatedProd);
// }

const removeItem = (i) => {
  const updatedProd = [...listCart];
  setSousTotal(sousTotal - updatedProd[i].product_discount);
  setGrandTotal(grandTotal - updatedProd[i].product_discount);

  updatedProd.splice(i, 1);

  setListCart(updatedProd);
};
 

// const handleChangeOption=(option)=>{
//   setSelectOption(option.value);

// }
// console.log(selectOption);



const optionbakehouses = bakehouses?.map((option) => ({
  label:`${option.name}`,
  value:option.id
}));
const handleChangeBakehouse=(option)=>{
  setSelectBakehouse(option.value);
  productListBakehouse(option.value);
}
  const optionsproducts = products.map((opt)=>({
    value: opt.id,
    label:`${opt.product_name}`
  }));

  // const optionfournisseurs = fournisseurs.map((opt)=>({
  //   value: opt.id,
  //   label:`${opt.first_name} ${opt.first_name} / ${opt.company}`
  // }));

  // const options = [
  //   { value: 0, label: "En attente"},
  //   { value: 1, label: "Livré"},
  // ];

  // console.log(optionfamilles);
  const handleChangeProduct=(option)=>{

    let product_check = products.find((product) => product.id === option.value);
    
    if(listCart.find((list)=> list.product_id === product_check.id)){
      console.log("oops")
    }else{
      const itemCart = {
        "product_id":product_check.product_id,
        "image":product_check.image,
        "product_name":product_check.product_name,
        "before_quantity":product_check.quantity,
        "after_quantity":0,
        // "product_tax":0,
      }
      setListCart([...listCart, itemCart]);
    }

  }



  const submitForm=(e)=>{
    e.preventDefault();

    if(listCart.length > 0 ){
      const _formData = new FormData();
      _formData.append('product_items',JSON.stringify(listCart));
      _formData.append('bakehouse_id',selectBakehouse);
  
      console.log(_formData);
      setLoading(true);
      try {
        axios.post(url.base+'/ajustements',_formData,
        {
            headers:{
                'Content-Type':'application/json',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                
            },
            // credentials:'include'
        }
        ).then((resp)=>{
            setLoading(false);
            if(resp.status == 200){
  
                  toast.success("Ajustement effectué avec succès");
                  history.push('/boulangerie/production/production-ajustement-list')
            }else{
                toast.error("Un problème est subvenu !");
            }
        }).catch((error)=>{  
          // console.log(error.response);              
            setLoading(false);
  
            if (error.response.status == 422) {
                setErrors(error.response.data.errors);
            }
            // console.log(errors);
        })
    } catch (error) {
        setLoading(false);
  // console.log(error);  
    }
    }else{
      setLoading(false);
    }
  }



  // const showModalFournisseur=()=>{
  //   window.$("#addpayment").modal('show');
  // }


  return (
    <>
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Faire un ajustement du stock</h4>
            {/* <h6>Add your new sale</h6> */}
          </div>
        </div>
        <div className="card">
          <form className="card-body" onSubmit={submitForm}>
            <div className="row">
            <div className="col-lg-4 col-sm-6 col-6 mb-3">
                  <label>Choisir une boulangerie</label>
                  <div className="row">
                    <div className="col-lg-10 col-sm-10 col-10">
                    <Select
                    className="select"
                    options={optionbakehouses}
                    onChange={handleChangeBakehouse}
                   
                  />
                    </div>
                   
                  </div>
              </div>
              {/* <div className="col-lg-3 col-sm-6 col-12">
                <div className="form-group">
                  <label>Date</label>
                  <div className="input-groupicon">
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                    />
                    <Link to="#" className="addonset">
                      <img src={Calendar} alt="img" />
                    </Link>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-lg-3 col-sm-6 col-12">
                <div className="form-group">
                  <label>Supplier</label>
                  <Select2
                    className="select"
                    data={options1}
                    options={{
                      placeholder: "Choose",
                    }}
                  />
                </div>
              </div> */}
              <div className="col-lg-12 col-sm-6 col-12 mb-3">
                {/* <div className="form-group"> */}
                  <label>Selectionner un produit</label>
                  <div className="input-groupicon">
                      <Select placeholder="Ajouter un produit ..." options={optionsproducts} onChange={handleChangeProduct}/>
                  </div>
                {/* </div> */}
              </div>
            </div>
            <div className="row">
              <div className="table-responsive mb-3">
                <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Nom du produit</th>
                      <th style={{width:200}}>Quantité dans le système</th>
                      <th style={{width:200}}>Quantité physique</th>
                      {/* <th>Discount</th>
                      <th>Tax</th> */}
                      {/* <th>Total</th> */}
                      <th>Action</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {listCart && 
                        listCart.map((item,index)=>
                        <tr key={index}>
                          <td>{index}</td>
                          <td className="productimgname">
                            <Link to="#" className="product-img">
                              <img src={`${url.image}${item.image}`} alt="product" />
                            </Link>
                            <Link to="#">{item.product_name}</Link>
                          </td>
                          <td className="text-center">
                            <b className="fw-bold">{item.before_quantity}</b>
                          </td>
                          <td>
                          <input type="number"  onChange={(e)=>handleChangeQty(e.target.value,index)} className="form-control" /> 
                          </td>
                         
                          <td>
                            <button type="button" className="btn" onClick={()=>removeItem(index)}>
                              <img src={DeleteIcon} alt="svg" />
                            </button>
                          </td>
                      </tr>
                    )}
                    
                   
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-sm-6 col-12">
                {/* <div className="form-group">
                  <label>Order Tax</label>
                  <input type="text" />
                </div> */}
              </div>
              <div className="col-lg-3 col-sm-6 col-12">
                {/* <div className="form-group">
                  <label>Discount</label>
                  <input type="text" />
                </div> */}
              </div>
           
              
              <div className="row">
                <div className="col-lg-6 ">
      
                </div>

              </div>
              <div className="col-lg-12 mt-3">
              {loading &&
                  <button className="btn btn-submit me-2" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        En chargement...
                    </button>
                }
                {!loading &&
                <button type="submit" className="btn btn-submit me-2" disabled={listCart.length == 0  ? "disabled" : ""}>
                  Enregistrer
                </button>
                }
                <Link to="/boulangerie/production/production-ajustement-list" className="btn btn-cancel">
                  Retour
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    {/* // add fournisseur */}
      {/* <div
      className="modal fade"
      id="addpayment"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Ajouter fournisseur</h5>
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <form onSubmit={submitFormFournisseur}>
            <div className="modal-body">
            
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>Nom <span className="text-danger">*</span></label>
                    <input type="text" name="first_name" onChange={handleChangeFournisseurData} value={fournisseurData.first_name}/>
                    {errors && errors.name && (
                          <span className="text-danger">{errors.first_name[0]}</span>
                      )}
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>Prénoms<span className="text-danger">*</span></label>
                    <input type="text" name="last_name" onChange={handleChangeFournisseurData} value={fournisseurData.last_name}/>
                    {errors && errors.name && (
                          <span className="text-danger">{errors.last_name[0]}</span>
                      )}
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label>Email<span className="text-danger">*</span></label>
                    <input type="text" name="email" onChange={handleChangeFournisseurData} value={fournisseurData.email}/>
                    {errors && errors.email && (
                          <span className="text-danger">{errors.email[0]}</span>
                      )}
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>Adresse<span className="text-danger">*</span></label>
                    <input type="text" name="address" onChange={handleChangeFournisseurData} value={fournisseurData.address}/>
                    {errors && errors.address && (
                          <span className="text-danger">{errors.address[0]}</span>
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>Téléphone<span className="text-danger">*</span></label>
                    <input type="text" name="phone" onChange={handleChangeFournisseurData} value={fournisseurData.phone}/>
                    {errors && errors.name && (
                          <span className="text-danger">{errors.phone[0]}</span>
                      )}
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label>Entreprise</label>
                    <input type="text" name="company" onChange={handleChangeFournisseurData} value={fournisseurData.company}/>
                    {errors && errors.company && (
                          <span className="text-danger">{errors.company[0]}</span>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer ">
              <button type="submit" className="btn btn-submit">
                Enregister
              </button>
              <button
                type="button"
                className="btn btn-cancel"
                data-bs-dismiss="modal"
              >
                Fermer
              </button>
            </div>
          </form>
        
        </div>
      </div>
    </div> */}
  </>
  );
};

export default AddAjustement;
