/* eslint-disable no-unused-vars */
/* eslint-disable no-dupe-keys */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  PlusIcon,
  Search,
  Excel,
  Printer,
  Pdf,
  DeleteIcon,
  EditIcon,
  Filter,
  ClosesIcon,
  EyeIcon,
} from "../../EntryFile/imagePath";
import Table from "../../EntryFile/datatable";
import Select2 from "react-select2-wrapper";
import Swal from "sweetalert2";
import "react-select2-wrapper/css/select2.css";
import axios from "axios";
import BaseUrl from "../../utils/BaseUrl";
import can from "../../utils/Can";
import { toast } from 'react-toastify';
import Select from 'react-select';


const url = BaseUrl();
const PaymentIDPage = () => {

  const [errors,setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [uniteData,setUniteData] = useState({
    'param_site_id' : '',
    'param_apikey' : '',
    'param_secret_key' : '',
    'libelle' : '',
  });

  const [uniteDataEdit,setUniteDataEdit] = useState({
    'param_site_id' : '',
    'param_apikey' : '',
    'param_secret_key' : '',
    'libelle' : '',
  });
  const [editUnit,setEditUnit] = useState(null);
  const [uniteList,setUniteList] = useState([]);
  const [bakehouses,setBakehouses] = useState([]);
  const [selectBakehouse,setSelectBakehouse] = useState(null);

  useEffect(()=>{
    try {
      axios.get(url.base+'/me',{
               headers:{
                   'Content-Type':'application/json',
                   "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                   
               },
           }).then((resp)=>{
  
              if(!can(resp.data.data.permissions,"paiement-id-param") ){
                  window.location.href = '/boulangerie/dashboard';
              }
  
           })
       } catch (error) {
           // setLoading(false);
       }
  },[]);

  useEffect(()=>{
    fetchUnitsList();
    fetchBakehouseList();
  },[])

  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    if (searchValue.trim() === "") {
      fetchUnitsList();
    } else {
      const filtered = uniteList.filter(item =>
        Object.values(item).some(value =>
          typeof value === 'string' && value.toLowerCase().includes(searchValue)
        )
      );
      setUniteList(filtered);
    }
  
};
  const handleChange=(e)=>{
    setUniteData({
        ...uniteData,
        [e.target.name]:e.target.value
    });
  }

  const handleChangeEdit=(e)=>{
    setUniteDataEdit({
        ...uniteDataEdit,
        [e.target.name]:e.target.value
    });
  }

  const confirmText = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: !1,
    }).then(function (t) {
      t.value &&
        Swal.fire({
          type: "success",
          title: "Deleted!",
          text: "Your file has been deleted.",
          confirmButtonClass: "btn btn-success",
        });
    });
  };
  const options = [
    { id: 1, text: "Active", text: "Active" },
    { id: 2, text: "Inactive", text: "Inactive" },
  ];
  const [data] = useState([
    {
      id: 1,
      Name: "Cash",
      Status: "checkbox",
    },
    {
      id: 2,
      Name: "Card",
      Status: "",
    },
    {
      id: 3,
      Name: "Credit",
      Status: "checkbox",
    },
  ]);


  const showModalEdit=(unit)=>{

    setEditUnit(unit);
    setUniteDataEdit({
      "param_site_id":unit.param_site_id,
      "param_apikey":unit.param_apikey,
      "param_secret_key":unit.param_secret_key,
      "libelle":unit.libelle,
    });
    window.$("#editpayment").modal('show');
  }

  const showModalAddBakouse=(unit)=>{

    setEditUnit(unit);   
    window.$("#editpaymentBakehouse").modal('show');
  }


  const columns = [
    {
      title: "Libelle",
      dataIndex: "libelle",
      className: "text-center",
      render: (text, record) => <div className="text-center">{text}</div>,
      sorter: (a, b) => a.libelle.length - b.libelle.length,
    },
    {
      title: "SITE ID",
      dataIndex: "param_site_id",
      className: "text-center",
      render: (text, record) => <div className="text-center">{text}</div>,
      sorter: (a, b) => a.param_site_id.length - b.param_site_id.length,
    },
    {
      title: "API KEY",
      dataIndex: "param_apikey",
      className: "text-center",
      render: (text, record) => <div className="text-center">{text}</div>,
      sorter: (a, b) => a.param_apikey.length - b.param_apikey.length,
    },
    // {
    //   title: "SECRET KEY",
    //   dataIndex: "param_secret_key",
    //   className: "text-center",
    //   render: (text, record) => <div className="text-center">{text}</div>,
    //   sorter: (a, b) => a.param_secret_key.length - b.param_secret_key.length,
    // },
    // {
    //   title: "Statut",
    //   dataIndex: "is_deleted",
    //   render: (text, record) => (
    //     <>
    //       <div className="status-toggle d-flex justify-content-between align-items-center">
    //         <input
    //           type="checkbox"
    //           id="user1"
    //           className="check"
    //           defaultChecked={text==0 ? text = 1 : text = 0}
    //         />
    //         <label htmlFor="user1" className="checktoggle">
    //           checkbox
    //         </label>
    //       </div>
    //     </>
    //   ),
    //   sorter: (a, b) => a.is_deleted.length - b.is_deleted.length,
    // },
    {
      title: "Action",
      className: "text-center",
      render: (text, record) => (
        <div className="text-center">
          <button
            className="btn"
            onClick={()=>showModalAddBakouse(record)}
          >
            <img src={PlusIcon} alt="img" />
          </button>
          <button
            className=" btn"
            onClick={()=>showModalEdit(record)}
          >
            <img src={EditIcon} alt="img" />
          </button>
          <Link className="confirm-text btn" to={`/boulangerie/settings/param-bakehouse-list/${record.uuid}`}>
              <img src={EyeIcon} alt="img" />
          </Link>
        </div>
      ),
      // sorter: (a, b) => a.Action.length - b.Action.length,
    },
  ];

  const fetchUnitsList = async ()=>{
    setLoading(true);
    try {
        axios.get(url.base+'/parametres',{
            headers:{
                'Content-Type':'application/json',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                
            },
        }).then((resp)=>{

            if(resp.status == 200){
                setUniteList(resp.data.data);
            }
            setLoading(false);
        })
    } catch (error) {
        setLoading(false);
    }
}

const fetchBakehouseList = async ()=>{
  setLoading(true);
  try {
      axios.get(url.base+'/bakehouses',{
          headers:{
              'Content-Type':'application/json',
              "Authorization": `Bearer ${localStorage.getItem('_token_')}`
              
          },
      }).then((resp)=>{

          if(resp.status == 200){
              setBakehouses(resp.data.data);
          }
          setLoading(false);
      })
  } catch (error) {
      setLoading(false);
  }
}

  const submitForm= (e)=>{
    e.preventDefault();

    const _formData = new FormData();
    _formData.append('libelle',uniteData.libelle);
    _formData.append('param_apikey',uniteData.param_apikey);
    _formData.append('param_secret_key',uniteData.param_secret_key);
    _formData.append('param_site_id',uniteData.param_site_id);

    setLoading(true);
    try {
        axios.post(url.base+'/parametres',_formData,
        {
            headers:{
                'Content-Type':'application/json',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                
            },
            // credentials:'include'
        }
        ).then((resp)=>{
            setLoading(false);
            if(resp.status == 200){

                setErrors({});
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title:  "Enregistrer avec succès",
                    showConfirmButton: false,
                    timer: 3000,
                    toast:true,
                    position:'top-right',
                    timerProgressBar:true
                  });
                  setUniteData({
                    'param_site_id' : '',
                    'param_apikey' : '',
                    'param_secret_key' : '',
                    'libelle' : '',
                  });
                  fetchUnitsList()
            }else{
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title:  "Echec de l'enregistrement",
                    showConfirmButton: false,
                    timer: 3000,
                    toast:true,
                    position:'top-right',
                    timerProgressBar:true
                  });
            }
        }).catch((error)=>{  
          // console.log(error.response);              
            setLoading(false);

            if (error.response.status == 422) {
                setErrors(error.response.data.errors);
            }
            // console.log(errors);
        })
    } catch (error) {
        setLoading(false);
// console.log(error);  
    }
  }

  const submitFormEdit= (e)=>{
    e.preventDefault();

    const _formData = new FormData();
    _formData.append('libelle',uniteDataEdit.libelle);
    _formData.append('param_apikey',uniteDataEdit.param_apikey);
    _formData.append('param_secret_key',uniteDataEdit.param_secret_key);
    _formData.append('param_site_id',uniteDataEdit.param_site_id);

    setLoading(true);
    try {
        axios.put(url.base+'/parametres/'+editUnit?.id,_formData,
        {
            headers:{
                'Content-Type':'application/json',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                
            },
            // credentials:'include'
        }
        ).then((resp)=>{
            setLoading(false);
            if(resp.status == 200){

                setErrors({});
                  toast.success("Modification effectuée avec succès");
                  setUniteDataEdit({
                    'param_site_id' : '',
                    'param_apikey' : '',
                    'param_secret_key' : '',
                    'libelle' : '',
                  });
                  window.$("#editpayment").modal('hide');
                  fetchUnitsList();
            }else{
               toast.error('Echec de la modification');
            }
        }).catch((error)=>{  
          // console.log(error.response);              
            setLoading(false);

            if (error.response.status == 422) {
                setErrors(error.response.data.errors);
            }
            // console.log(errors);
        })
    } catch (error) {
        setLoading(false);
// console.log(error);  
    }
  }

  const deleteUnit=(id)=>{
    Swal.fire({
      title: 'ATTENTION !',
      text: 'Êtes-vous sûrs de vouloir supprimer cet élément.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonText:"NON",
      confirmButtonText: 'OUI',
      cancelButtonColor:"red"
    }).then((result) => {
      if (result.isConfirmed) {
          try {
              axios.delete(url.base+'/units/'+id,
                  {
                      headers:{
                          'Content-Type':'application/json',
                          "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                          
                      },
                  }
              ).then((resp)=>{
                  // console.log(resp.data);
                  if(resp.status == 200)
                  {
                      if(resp.data.status == 600)
                      {
                          toast.error("Un problème est subvenu")
                      }else{
                          toast.success('Un problème est subvenu')
                      }
                     
                  }
                  fetchUnitsList();
              })
          } catch (error) {
              console.log(error);
          }

      
      }
    }); 
  }

  const handleChangeBoulangerie=(selectoption)=>{
    setSelectBakehouse(selectoption.value);
  }

  const optionsboulangies = bakehouses?.map((opt)=>({
    value: opt.id,
    label:`${opt.name}`
  }));

  const submitFormAddParams= (e)=>{
    e.preventDefault();

    const _formData = new FormData();
    _formData.append('bakehouse_id',selectBakehouse);

    if(selectBakehouse !== null)
    {
      setLoading(true);
      try {
          axios.post(url.base+'/store-params-payment-bakehouse/'+editUnit?.id,_formData,
          {
              headers:{
                  'Content-Type':'application/json',
                  "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                  
              },
              // credentials:'include'
          }
          ).then((resp)=>{
              setLoading(false);
              if(resp.data.code !== 400){
  
                  setErrors({});
                  Swal.fire({
                      position: 'top-end',
                      icon: 'success',
                      title:  "Enregistrer avec succès",
                      showConfirmButton: false,
                      timer: 3000,
                      toast:true,
                      position:'top-right',
                      timerProgressBar:true
                    });
                    setSelectBakehouse(null);
                    fetchUnitsList();
                    window.$("#editpaymentBakehouse").modal('hide');
              }else{
                toast.error("Cette boulangerie existe dejà");
              }
          }).catch((error)=>{  
            // console.log(error.response);              
              setLoading(false);
  
              if (error.response.status == 422) {
                  setErrors(error.response.data.errors);
              }
              // console.log(errors);
          })
      } catch (error) {
          setLoading(false);
  // console.log(error);  
      }
    }else{
      toast.error("La boulangerie est obligatoire");
    }

  }


  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Gestion des paramètres de paiment</h4>
              {/* <h6>Manage Payment Settings</h6> */}
            </div>
            <div className="page-btn">
              <a
                className="btn btn-added"
                data-bs-toggle="modal"
                data-bs-target="#addpayment"
              >
                <img src={PlusIcon} alt="img" className="me-2" />
                Ajouter
              </a>
            </div>
          </div>
          {/* /product list */}
          <div className="card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      className="form-control form-control-sm search-icon"
                      type="search"
                      placeholder="Recherche..."
                      onChange={handleSearch}
                    />
                    <a className="btn btn-searchset">
                      <img src={Search} alt="img" />
                    </a>
                  </div>
                </div>
                <div className="wordset">
                  <ul>
                    <li>
                      <a
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="pdf"
                      >
                        <img src={Pdf} alt="img" />
                      </a>
                    </li>
                    <li>
                      <a
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="excel"
                      >
                        <img src={Excel} alt="img" />
                      </a>
                    </li>
                    <li>
                      <a
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="print"
                      >
                        <img src={Printer} alt="img" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={uniteList}
                  rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      {/* add Modal */}
      <div
        className="modal fade"
        id="addpayment"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">PAIEMENT ID</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <form onSubmit={submitForm}>
              <div className="modal-body">
              
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label>Libelle</label>
                      <input type="text" name="libelle" onChange={handleChange} value={uniteData.libelle}/>
                      {errors && errors.libelle && (
                            <span className="text-danger">{errors.libelle[0]}</span>
                        )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label>SITE ID</label>
                      <input type="text" name="param_site_id" onChange={handleChange} value={uniteData.param_site_id}/>
                      {errors && errors.param_site_id && (
                            <span className="text-danger">{errors.param_site_id[0]}</span>
                        )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label>APIKEY</label>
                      <input type="text" name="param_apikey" onChange={handleChange} value={uniteData.param_apikey}/>
                      {errors && errors.param_apikey && (
                            <span className="text-danger">{errors.param_apikey[0]}</span>
                        )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label>SECRET KEY</label>
                      <input type="text" name="param_secret_key" onChange={handleChange} value={uniteData.param_secret_key}/>
                      {errors && errors.param_secret_key && (
                            <span className="text-danger">{errors.param_secret_key[0]}</span>
                        )}
                    </div>
                  </div>
                  
                </div>
              </div>
              <div className="modal-footer ">
                <button type="submit" className="btn btn-submit">
                  Enregister
                </button>
                <button
                  type="button"
                  className="btn btn-cancel"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </form>
          
          </div>
        </div>
      </div>
      {/* edit Modal */}
      <div
        className="modal fade"
        id="editpayment"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Editer</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <form onSubmit={submitFormEdit}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label>Libelle</label>
                      <input type="text" name="libelle" onChange={handleChangeEdit} value={uniteDataEdit.libelle}/>
                      {errors && errors.libelle && (
                            <span className="text-danger">{errors.libelle[0]}</span>
                        )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label>SITE ID</label>
                      <input type="text" name="param_site_id" onChange={handleChangeEdit} value={uniteDataEdit.param_site_id}/>
                      {errors && errors.param_site_id && (
                            <span className="text-danger">{errors.param_site_id[0]}</span>
                        )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label>APIKEY</label>
                      <input type="text" name="param_apikey" onChange={handleChangeEdit} value={uniteDataEdit.param_apikey}/>
                      {errors && errors.param_apikey && (
                            <span className="text-danger">{errors.param_apikey[0]}</span>
                        )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label>SECRET KEY</label>
                      <input type="text" name="param_secret_key" onChange={handleChangeEdit} value={uniteDataEdit.param_secret_key}/>
                      {errors && errors.param_secret_key && (
                            <span className="text-danger">{errors.param_secret_key[0]}</span>
                        )}
                    </div>
                  </div>
                  
                </div>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-submit">
                  Modifier
                </button>
                <button
                  type="button"
                  className="btn btn-cancel"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="editpaymentBakehouse"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Ajouter une boulangerie</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <form onSubmit={submitFormAddParams}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12">

                    <div className="form-group">
                      <label>Selectionner une boulangerie</label>
                      <Select options={optionsboulangies} onChange={handleChangeBoulangerie}/>
                      {errors && errors.bakehouse_id && (
                            <span className="text-danger">{errors.bakehouse_id[0]}</span>
                        )}
                    </div>
                  </div>
                  
                </div>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-submit">
                  Valider
                </button>
                <button
                  type="button"
                  className="btn btn-cancel"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentIDPage;
