/* eslint-disable no-undef */

import React from "react";
import { createRoot } from "react-dom/client";
import Main from './EntryFile/Main';
// import { jwtDecode } from "jwt-decode";
import axios from "axios";


createRoot(document.getElementById('app')).render(<Main />);


axios.interceptors.response.use(
  undefined,
  function (error){
    if (error.response?.status === 401) {
      localStorage.removeItem('_token_');
      window.location.href = '/signIn';
    }
    return Promise.reject(error); // Re-throw the error for proper handling
  }
);

if (module.hot) {
  module.hot.accept();
}
