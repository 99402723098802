/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Pdf,
  Excel,
  Product7,
  Printer,
  EditIcon,
  Calendar,
  Product8,
  Product1,
} from "../../EntryFile/imagePath";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import BaseUrl from "../../utils/BaseUrl";
import axios from "axios";
import moment from "moment";
import separateNumber from "../../utils/separateur";
import can from "../../utils/Can";

const url = BaseUrl();
const RetourVenduDetail = () => {
  const {uuid} = useParams();

  const [achatDetail,setAchatDetail] = useState([]);

  useEffect(()=>{
    try {
      axios.get(url.base+'/me',{
               headers:{
                   'Content-Type':'application/json',
                   "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                   
               },
           }).then((resp)=>{

              if(!can(resp.data.data.permissions,"retour-detail-vendu") ){
                  window.location.href = '/boulangerie/dashboard';
              }
  
           })
       } catch (error) {
           // setLoading(false);
       }
  },[]); 


  useEffect(()=>{
    try {
      axios.get(url.base+'/orders/'+uuid,{
          headers:{
              'Content-Type':'application/json',
              "Authorization": `Bearer ${localStorage.getItem('_token_')}`
              
          },
      }).then((resp)=>{

          if(resp.status == 200){
            setAchatDetail(resp.data.data);
          }
          // setLoading(false);
      })
  } catch (error) {
      // setLoading(false);
  }
  },[])
  const options = [
    { id: 1, text: "Completed", text: "Completed" },
    { id: 2, text: "Inprogess", text: "Inprogess" },
  ];
  const Downloadpdf= (e,uuid)=>{
    e.preventDefault();
  
  
  
    try {
        axios.get(url.base+'/export-commande-pdf/'+uuid,
        {
            headers:{
                'Content-Type':'application/pdf',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`,
                
            },
            responseType:'blob',
            // credentials:'include'
        }
        ).then((response)=>{
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'achats.pdf');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
    } catch (error) {
  // console.log(error);  
    }
  }
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Detail</h4>
            {/* <h6>View sale details</h6> */}
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="card-sales-split">
              <h2>Reference : {achatDetail.reference}</h2>
              <ul>
                {/* <li>
                  <Link to="#">
                    <img src={EditIcon} alt="img" />
                  </Link>
                </li> */}
                <li>
                  <Link to="#" onClick={(e)=>Downloadpdf(e,achatDetail.uuid)}>
                    <img src={Pdf} alt="img" />
                  </Link>
                </li>
                {/* <li>
                  <Link to="#">
                    <img src={Excel} alt="img" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img src={Printer} alt="img" />
                  </Link>
                </li> */}
              </ul>
            </div>
            <div
              className="invoice-box table-height"
              style={{
                maxWidth: 1600,
                width: "100%",
                overflow: "auto",
                margin: "15px auto",
                padding: 0,
                fontSize: 14,
                lineHeight: "24px",
                color: "#555",
              }}
            >
              <table
                cellPadding={0}
                cellSpacing={0}
                style={{
                  width: "100%",
                  lineHeight: "24px",
                  textAlign: "left",
                }}
              >
                <tbody>
                  <tr className="top">
                    <td
                      colSpan={6}
                      style={{ padding: 5, verticalAlign: "top" }}
                    >
                      <table
                        style={{
                          width: "100%",
                          lineHeight: "24px",
                          textAlign: "left",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                padding: 5,
                                verticalAlign: "top",
                                textAlign: "left",
                                paddingBottom: 20,
                              }}
                            >
                              <font
                                style={{
                                  verticalAlign: "top",
                                  marginBottom: 25,
                                }}
                              >
                                <font
                                  style={{
                                    verticalAlign: "top",
                                    fontSize: 14,
                                    color: "#7367F0",
                                    fontWeight: 600,
                                    lineHeight: "35px",
                                  }}
                                >
                                  Information du client
                                </font>
                              </font>
                              <br />
                              <font style={{ verticalAlign: "top" }}>
                                <font
                                  style={{
                                    verticalAlign: "top",
                                    fontSize: 14,
                                    color: "#000",
                                    fontWeight: 400,
                                  }}
                                >
                                  {"Nom et prénoms :  "}
                                  {achatDetail?.customer?.first_name} {achatDetail?.customer?.last_name}
                                </font>
                              </font>
                              <br />
                              <font style={{ verticalAlign: "top" }}>
                                <font
                                  style={{
                                    verticalAlign: "top",
                                    fontSize: 14,
                                    color: "#000",
                                    fontWeight: 400,
                                  }}
                                >
                                  {"Télephone :  "}
                                  {achatDetail?.customer?.phone}
                                </font>
                              </font>
                              <br />
                              <font style={{ verticalAlign: "top" }}>
                                <font
                                  style={{
                                    verticalAlign: "top",
                                    fontSize: 14,
                                    color: "#000",
                                    fontWeight: 400,
                                  }}
                                >
                                  {"Adresse :  "}
                                  {achatDetail?.customer?.address}
                                </font>
                              </font>

                              <br />
                              <font style={{ verticalAlign: "top" }}>
                                <font
                                  style={{
                                    verticalAlign: "top",
                                    fontSize: 14,
                                    color: "#000",
                                    fontWeight: 400,
                                  }}
                                >
                                  {"Boulangerie :  "}
                                  {achatDetail?.bakehouse?.name}
                                </font>
                              </font>
                       
                            </td>

                            <td
                              style={{
                                padding: 5,
                                verticalAlign: "top",
                                textAlign: "right",
                                paddingBottom: 20,
                              }}
                            >
                              <font
                                style={{
                                  verticalAlign: "top",
                                  marginBottom: 25,
                                }}
                              >
                                <font
                                  style={{
                                    verticalAlign: "top",
                                    fontSize: 14,
                                    color: "#7367F0",
                                    fontWeight: 600,
                                    lineHeight: "35px",
                                  }}
                                >
                                  &nbsp;
                                </font>
                              </font>
                              <br />
                              <font style={{ verticalAlign: "top" }}>
                                <font
                                  style={{
                                    verticalAlign: "top",
                                    fontSize: 14,
                                    color: "#000",
                                    fontWeight: 400,
                                  }}
                                >
                                  Statut{" "}
                                </font>
                              </font>
                              {/* <br />
                              <font style={{ verticalAlign: "top" }}>
                                <font
                                  style={{
                                    verticalAlign: "top",
                                    fontSize: 14,
                                    color: "#2E7D32",
                                    fontWeight: 400,
                                  }}
                                >
                                  {" "}
                                  Paid
                                </font>
                              </font> */}
                              <br />
                              <font style={{ verticalAlign: "top" }}>
                                {achatDetail.status == 1 &&
                                    <font
                                    style={{
                                      verticalAlign: "top",
                                      fontSize: 14,
                                      color: "#2E7D32",
                                      fontWeight: 400,
                                    }}
                                  >
                                        {" "}
                                        Livré
                                      </font>
                                }

                                {achatDetail.status == 0 &&
                                    <font
                                    style={{
                                      verticalAlign: "top",
                                      fontSize: 14,
                                      color: "red",
                                      fontWeight: 400,
                                    }}
                                  >
                                        {" "}
                                        En attente
                                      </font>
                                }
                            
                              </font>
                              <br />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr className="heading " style={{ background: "#F3F2F7" }}>
                    <td
                      style={{
                        padding: 10,
                        verticalAlign: "middle",
                        fontWeight: 600,
                        color: "#5E5873",
                        fontSize: 14,
                      }}
                    >
                      Nom du produit
                    </td>
                    <td
                      style={{
                        padding: 10,
                        verticalAlign: "middle",
                        fontWeight: 600,
                        color: "#5E5873",
                        fontSize: 14,
                      }}
                    >
                      Quantité
                    </td>
                    <td
                      style={{
                        padding: 10,
                        verticalAlign: "middle",
                        fontWeight: 600,
                        color: "#5E5873",
                        fontSize: 14,
                      }}
                    >
                      Prix unitaire (FCFA)
                    </td>
                   
                    <td
                      style={{
                        padding: 10,
                        verticalAlign: "middle",
                        fontWeight: 600,
                        color: "#5E5873",
                        fontSize: 14,
                      }}
                    >
                      Total (FCFA)
                    </td>
                  </tr>
                  {achatDetail?.order_details?.map((product,index)=>
                  <tr key={index}
                  className="details"
                  style={{ borderBottom: "1px solid #E9ECEF" }}
                >
                  <td
                    style={{
                      padding: 10,
                      verticalAlign: "top",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={`${url.image}${product?.product?.image}`}
                      alt="img"
                      className="me-2"
                      style={{ width: 40, height: 40 }}
                    />
                    {product?.product?.name}
                  </td>
                  <td style={{ padding: 10, verticalAlign: "top" }}>
                  {product?.quantity}
                  </td>
                  <td style={{ padding: 10, verticalAlign: "top" }}>
                    {separateNumber(product?.price)}
                  </td>
                  <td style={{ padding: 10, verticalAlign: "top" }}>
                  {separateNumber(product?.quantity * product?.price)}
                  </td>
                </tr>
                  )}
                  
                 
                </tbody>
              </table>
            </div>
            <div className="row">
       
              <div className="row">
                <div className="col-lg-6 ">
                  <div className="total-order w-100 max-widthauto m-auto mb-4">
                    {/* <ul>
                      <li>
                        <h4>Order Tax</h4>
                        <h5>$ 0.00 (0.00%)</h5>
                      </li>
                      <li>
                        <h4>Discount </h4>
                        <h5>$ 0.00</h5>
                      </li>
                    </ul> */}
                  </div>
                </div>
                <div className="col-lg-6 ">
                  <div className="total-order w-100 max-widthauto m-auto mb-4">
                    <ul>
                      <li>
                        <h4>Sous-total</h4>
                        <h5>{separateNumber(achatDetail.total_amount)} FCFA</h5>
                      </li>
                      <li className="total">
                        <h4>Total</h4>
                        <h5>{separateNumber(achatDetail.total_amount)} FCFA</h5>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                {/* <Link to="#" className="btn btn-submit me-2">
                  Update
                </Link> */}
                <Link to="/boulangerie/production/vente-retour-list" className="btn btn-cancel">
                  Retour
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RetourVenduDetail;
