/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Link } from "react-router-dom";
const Ribbon = () => {
  return (
    <div className="page-wrapper cardhead">
      <div className="content">
        {/* Page Header */}
        <div className="page-header">
          <div className="row">
            <div className="col-sm-12">
              <h3 className="page-title">Ribbons</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/boulangerie/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">Ribbons</li>
              </ul>
            </div>
          </div>
        </div>
        {/* /Page Header */}
        <div className="row">
          {/* Ribbon */}
          <div className="col-md-4 col-sm-6">
            <div className="ribbon-wrapper card">
              <div className="card-body">
                <div className="ribbon ribbon-primary">Ribbon</div>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text. Lorem Ipsum is simply dummy text of the
                  printing and typesetting industry.
                </p>
              </div>
            </div>
          </div>
          {/* /Ribbon */}
          {/* Ribbon */}
          <div className="col-md-4 col-sm-6">
            <div className="ribbon-wrapper card">
              <div className="card-body">
                <div className="ribbon ribbon-secondary">Ribbon</div>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text. Lorem Ipsum is simply dummy text of the
                  printing and typesetting industry.
                </p>
              </div>
            </div>
          </div>
          {/* /Ribbon */}
          {/* Ribbon */}
          <div className="col-md-4 col-sm-6">
            <div className="ribbon-wrapper card">
              <div className="card-body">
                <div className="ribbon ribbon-success">Ribbon</div>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text. Lorem Ipsum is simply dummy text of the
                  printing and typesetting industry.
                </p>
              </div>
            </div>
          </div>
          {/* /Ribbon */}
          {/* Ribbon */}
          <div className="col-md-4 col-sm-6">
            <div className="ribbon-wrapper card">
              <div className="card-body">
                <div className="ribbon ribbon-primary ribbon-right">Ribbon</div>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text. Lorem Ipsum is simply dummy text of the
                  printing and typesetting industry.
                </p>
              </div>
            </div>
          </div>
          {/* /Ribbon */}
          {/* Ribbon */}
          <div className="col-md-4 col-sm-6">
            <div className="ribbon-wrapper card">
              <div className="card-body">
                <div className="ribbon ribbon-secondary  ribbon-right">
                  Ribbon
                </div>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text. Lorem Ipsum is simply dummy text of the
                  printing and typesetting industry.
                </p>
              </div>
            </div>
          </div>
          {/* /Ribbon */}
          {/* Ribbon */}
          <div className="col-md-4 col-sm-6">
            <div className="ribbon-wrapper card">
              <div className="card-body">
                <div className="ribbon ribbon-success ribbon-right">Ribbon</div>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text. Lorem Ipsum is simply dummy text of the
                  printing and typesetting industry.
                </p>
              </div>
            </div>
          </div>
          {/* /Ribbon */}
          {/* Ribbon */}
          <div className="col-md-4 col-sm-6">
            <div className="ribbon-vertical-left-wrapper card">
              <div className="card-body">
                <div className="ribbon ribbon-primary ribbon-vertical-left">
                  <i className="fas fa-gift" />
                </div>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text. Lorem Ipsum is simply dummy text of the
                  printing and typesetting industry.
                </p>
              </div>
            </div>
          </div>
          {/* /Ribbon */}
          {/* Ribbon */}
          <div className="col-md-4 col-sm-6">
            <div className="ribbon-vertical-left-wrapper card">
              <div className="card-body">
                <div className="ribbon ribbon-secondary  ribbon-vertical-left">
                  <i className="fas fa-gift" />
                </div>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text. Lorem Ipsum is simply dummy text of the
                  printing and typesetting industry.
                </p>
              </div>
            </div>
          </div>
          {/* /Ribbon */}
          {/* Ribbon */}
          <div className="col-md-4 col-sm-6">
            <div className="ribbon-vertical-left-wrapper card">
              <div className="card-body">
                <div className="ribbon ribbon-success ribbon-vertical-left">
                  <i className="fas fa-gift" />
                </div>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text. Lorem Ipsum is simply dummy text of the
                  printing and typesetting industry.
                </p>
              </div>
            </div>
          </div>
          {/* /Ribbon */}
          {/* Ribbon */}
          <div className="col-md-4 col-sm-6">
            <div className="ribbon-vertical-right-wrapper card">
              <div className="card-body">
                <div className="ribbon ribbon-primary ribbon-vertical-right">
                  <i className="fas fa-gift" />
                </div>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text. Lorem Ipsum is simply dummy text of the
                  printing and typesetting industry.
                </p>
              </div>
            </div>
          </div>
          {/* /Ribbon */}
          {/* Ribbon */}
          <div className="col-md-4 col-sm-6">
            <div className="ribbon-vertical-right-wrapper card">
              <div className="card-body">
                <div className="ribbon ribbon-secondary  ribbon-vertical-right">
                  <i className="fas fa-gift" />
                </div>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text. Lorem Ipsum is simply dummy text of the
                  printing and typesetting industry.
                </p>
              </div>
            </div>
          </div>
          {/* /Ribbon */}
          {/* Ribbon */}
          <div className="col-md-4 col-sm-6">
            <div className="ribbon-vertical-right-wrapper card">
              <div className="card-body">
                <div className="ribbon ribbon-success ribbon-vertical-right">
                  <i className="fas fa-gift" />
                </div>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text. Lorem Ipsum is simply dummy text of the
                  printing and typesetting industry.
                </p>
              </div>
            </div>
          </div>
          {/* /Ribbon */}
        </div>
      </div>
    </div>
  );
};
export default Ribbon;
