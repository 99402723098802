/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Table from "../../EntryFile/datatable";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import Tabletop from "../../EntryFile/tabletop";
import Swal from "sweetalert2";
import "react-datepicker/dist/react-datepicker.css";
import {
  ClosesIcon,
  Excel,
  Filter,
  Pdf,
  Eye1,
  Calendar,
  Printer,
  search_whites,
  Search,
  PlusIcon,
  EditIcon,
  Dollar1,
  plusCircle,
  Download,
  delete1,
  DeleteIcon,
  datepicker,
} from "../../EntryFile/imagePath";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import BaseUrl from "../../utils/BaseUrl";
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import can from "../../utils/Can";
import Select from "react-select";
import separateNumber from "../../utils/separateur";

const url = BaseUrl();
const ProductionList = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [startDate1, setStartDate1] = useState(new Date());
  const [inputfilter, setInputfilter] = useState(false);
  const [productionList,setProductionList] = useState([]);
  const [excelData,setExcelData] = useState({});
  const [loading, setLoading] = useState(false);

  const [quantity,setQuantity] = useState(0);
  const [productionItem,setProductionItem] = useState([]);

  const togglefilter = (value) => {
    setInputfilter(value);
  };
  useEffect(()=>{
    try {
      axios.get(url.base+'/me',{
               headers:{
                   'Content-Type':'application/json',
                   "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                   
               },
           }).then((resp)=>{

              if(!can(resp.data.data.permissions,"mes-productions") ){
                  window.location.href = '/boulangerie/dashboard';
              }
  
           })
       } catch (error) {
           // setLoading(false);
       }
  },[]);  


  useEffect(()=>{
    productList();
  },[]);

  function productList(){
    try {
      axios.get(url.base+'/technical-sheet',{
          headers:{
              'Content-Type':'application/json',
              "Authorization": `Bearer ${localStorage.getItem('_token_')}`
              
          },
      }).then((resp)=>{
  
          if(resp.status == 200){
            setProductionList(resp.data.data);
            const transArray = [];
            resp.data.data.forEach(item => { 
              transArray.push({
                "Date":`${moment(item.date).format("Do MMMM YYYY")}`,
                "Libelle de production":`Production de ${item.time}`,
                "Heure de production":`${item.time}` ,

            });
            });
            setExcelData(transArray)
          }
          // setLoading(false);
      })
  } catch (error) {
      // setLoading(false);
  }
  }

  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    if (searchValue.trim() === "") {
      productList();
    } else {
      const filtered = productionList.filter(item =>
        Object.values(item).some(value =>
          typeof value === 'string' && value.toLowerCase().includes(searchValue)
        )
      );
      setProductionList(filtered);
    }
  
  };
  const columns = [

    {
      title: "Date de production",
      className: "text-center",
      dataIndex: "date",
      render: (text, record) => (
        <>       
            <div>{moment(text).format('Do MMMM  YYYY')}</div>      
        </>
      ),
      sorter: (a, b) => a.date.length - b.date.length,
    },
    {
      title: "Libelle de production",
      className: "text-center",
      dataIndex: "date",
      render: (text, record) => (
        <>       
            <div>Production du {moment(text).format('Do MMMM')} {record.time}</div>      
        </>
      ),
      sorter: (a, b) => a.date.length - b.date.length,
    },
    {
      title: "Produit",
      className: "text-center",
      dataIndex: "product",
      render: (text, record) => (
        <>       
            <div>{record.product?.name}</div>      
        </>
      ),
      sorter: (a, b) => a.product.length - b.product.length,
    },
    {
      title: "Boulangerie",
      className: "text-center",
      dataIndex: "bakehouse",
      render: (text, record) => (
        <>       
            <div>{record.bakehouse?.name}</div>      
        </>
      ),
      sorter: (a, b) => a.bakehouse.length - b.bakehouse.length,
    },

    {
      title: "Quantité estimée",
      className: "text-center",
      dataIndex: "estimation",
      render: (text, record) => (
        <>       
            <div>{text}</div>      
        </>
      ),
      sorter: (a, b) => a.estimation.length - b.estimation.length,
    },

    {
      title: "Quantité produite",
      className: "text-center",
      dataIndex: "nb_produits",
      render: (text, record) => (
        <>       
            <div>{text}</div>      
        </>
      ),
      sorter: (a, b) => a.nb_produits.length - b.nb_produits.length,
    },
    {
      title: "Pourcentage reussite(%)",
      className: "text-center bg-warning",
      render: (text, record) => (
        <>       
            <b className="fw-bold">{(record.nb_produits * 100 / record.estimation).toFixed(2) } %</b>      
        </>
      ),
    },
    {
      title: "Montant de la production",
      className: "text-center",
      render: (text, record) => (
        <>       
            <b className="fw-bold">{separateNumber(record.product?.price * record.nb_produits) } FCFA</b>      
        </>
      ),
    },

    {
      title: "Statut",
      dataIndex: "in_stock",
      className:"text-center",
      render: (text, record) => (
        <>
          {text == 1 && (
            <span className="badges bg-lightgreen">Terminer</span>
          )}
          {text == 0 && (
            <span className="badges bg-danger">En attente</span>
          )}
      
        </>
      ),
      sorter: (a, b) => a.in_stock.length - b.in_stock.length,
    },

    {
      title: "Action",
      className: "text-center",
      render: (text, record) => (
        <>
          <div className="text-center">
            <Link
              className="action-set"
              to="#"
              data-bs-toggle="dropdown"
              aria-expanded="true"
            >
              <i className="fa fa-ellipsis-v" aria-hidden="true" />
            </Link>
            <ul className="dropdown-menu">
              <li>
                <Link
                  to={`/boulangerie/production/production-details/${record.uuid}`}
                  className="dropdown-item"
                >
                  <img src={Eye1} className="me-2" alt="img" />
                  Voir
                </Link>
              </li>
              {record.in_stock==0 &&
                <li>
                  <Link
                    to="#"
                    className="dropdown-item"
                    onClick={()=>openModalAddStock(record)}
                  >
                    <img src={PlusIcon} className="me-2" alt="img" />
                    Mise en stock
                  </Link>
               </li>
              }
              
              <li>
                <Link to="#" className="dropdown-item" onClick={(e)=>Downloadpdf(e,record.uuid)}>
                  <img src={Download} className="me-2" alt="img" />
                  Télécharger PDF
                </Link>
              </li>
              {/* <li>
                <button
                  className="dropdown-item confirm-text btn"
                  onClick={null}
                >
                  <img src={delete1} className="me-2" alt="img" />
                  Supprimer
                </button>
              </li> */}
            </ul>
          </div>
        </>
      ),
    },
  ];

  const deleteItem=(id)=>{
    Swal.fire({
      title: 'ATTENTION !',
      text: 'Êtes-vous sûrs de vouloir supprimer cet élément.',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonText:"NON",
      confirmButtonText: 'OUI',
      cancelButtonColor:"red"
    }).then((result) => {
      if (result.isConfirmed) {
          try {
              axios.delete(url.base+'/technical-sheet/'+id,
                  {
                      headers:{
                          'Content-Type':'application/json',
                          "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                          
                      },
                  }
              ).then((resp)=>{
                  // console.log(resp.data);
                  if(resp.status == 200)
                  {
                      if(resp.data.status == 600)
                      {
                        toast.error('Un problème est subvenu');
                          // Swal.fire(
                          //     'Attention',
                          //     'Un problème est subvenu',
                          //     'error'
                          //   )
                      }else{
                          toast.success('élement supprimé avec succès');
                      }
                     
                  }
                  productList();
              })
          } catch (error) {
              console.log(error);
          }

      
      }
    }); 
  }

  const Downloadpdf= (e,uuid)=>{
    e.preventDefault();
  
  
  
    try {
        axios.get(url.base+'/export-technical-pdf/'+uuid,
        {
            headers:{
                'Content-Type':'application/pdf',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`,
                
            },
            responseType:'blob',
            // credentials:'include'
        }
        ).then((response)=>{
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'achats.pdf');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
    } catch (error) {
  // console.log(error);  
    }
  }

  const openModalAddStock=(item)=>{
    setQuantity()
    setProductionItem(item);
    window.$('#createpayment').modal('show');
  }

  const submitForm= (e)=>{
    e.preventDefault();
  
    if(quantity > 0){
      const _formData = new FormData();
      _formData.append('quantity',quantity);
      _formData.append('product_id',productionItem?.product_id);
      _formData.append('technical_sheet_id',productionItem.id);
      _formData.append('bakehouse_id',productionItem.bakehouse_id);
      console.log(_formData);
  
      setLoading(true);
      try {
          axios.post(url.base+'/production-histories',_formData,
          {
              headers:{
                  'Content-Type':'multipart/form-data',
                  "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                  
              },
              // credentials:'include'
          }
          ).then((resp)=>{
              if(resp.status == 200){
                setLoading(false);
                  toast.success("Le stock a été mis à jour avec succès.")

                  setQuantity(0);
                    productList();
                    setProductionItem([]);
                    window.$('#createpayment').modal('hide');
              }else{
                setLoading(false);
                toast.error("Echec de l'enregistrement");
              }
          }).catch((error)=>{  
            // console.log(error.response);              
            setLoading(false);
            
          })
      } catch (error) {
        setLoading(false);
    // console.log(error);  
      }
    }else{
     
      if(quantity <= 0 || quantity == undefined){
        toast.error("Veillez entrer une valeur supérieur à 0");
      }
      
    }

  }

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Liste des productions</h4>
            </div>
            <div className="page-btn">
              <Link to="/boulangerie/production/production-add" className="btn btn-added">
                <img src={PlusIcon} alt="img" className="me-1" />
               Ajouter une production
              </Link>
            </div>
          </div>
          {/* /product list */}
          <div className="card">
            <div className="card-body">
              <Tabletop inputfilter={inputfilter} togglefilter={togglefilter} excelData={excelData} filename={"productions"} handleSearch={handleSearch}/>
              {/* /Filter */}
              <div
                className={`card mb-0 ${inputfilter ? "toggleCls" : ""}`}
                id="filter_inputs"
                style={{ display: inputfilter ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="form-group">
                        <input type="text" placeholder="Enter Name" />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="form-group">
                        <input type="text" placeholder="Enter Reference No" />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="form-group">
                        <Select2
                          className="select"
                          data={[]}
                          options={{
                            placeholder: "Choose Suppliers",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="form-group">
                        <Link to="#" className="btn btn-filters ms-auto">
                          <img src={search_whites} alt="img" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
                <Table
                  props={props} columns={columns}
                  dataSource={productionList}
                  rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>

      <div
          className="modal fade"
          id="createpayment"
          tabIndex={-1}
          aria-labelledby="createpayment"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Production du {moment(productionItem.date).format('Do MMMM')} {productionItem.time} | <u>Produit :</u> <b className="text-success">{productionItem.product?.name}</b></h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form className="row" onSubmit={submitForm}>

                {/* <div className="col-lg-12 col-sm-12 col-12 mb-3">
                  <label>Selectionner une production</label>
                  <div className="input-groupicon">
                      <Select placeholder="Ajouter une production ..." options={optionsheets} onChange={handleChangeOption}/>
                  </div>
              </div> */}

                  <div className="col-lg-12 col-sm-12 col-12">
                    <div className="form-group">
                      <label>Quantité produite</label>
                      <input type="text" value={quantity} onChange={(e)=>setQuantity(e.target.value)}/>
                    </div>
                  </div>
                 
                  
                  <div className="modal-footer">
                      {loading &&
                      <button className="btn btn-submit" type="button" disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            En chargement...
                        </button>
                    }
                    {!loading &&
                    <button type="submit" className="btn btn-submit me-2" disabled={quantity <= 0  ? "disabled" : ""}>
                      Valider
                    </button>
                    }
                    {/* <button type="submit" className="btn btn-submit">
                      Valider
                    </button> */}
                    <button
                      type="button"
                      className="btn btn-cancel"
                      data-bs-dismiss="modal"
                    >
                      Fermer
                    </button>
                </div>
                </form>
                
              </div>
          
            </div>
          </div>
    </div>
    </>
  );
};

export default ProductionList;
