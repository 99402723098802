/* eslint-disable react/prop-types */

import React from 'react';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { Excel } from '../EntryFile/imagePath';


const ExportExcel = ({excelData, filename}) => {
    // const ws = XLSX.utils.json_to_sheet(excelData)
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToExcel=async()=>{
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = {Sheets: {'data':ws}, SheetNames: ['data']};
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, filename + fileExtension);
    }


    return (
        <a data-bs-toggle="tooltip" data-bs-placement="top" title="excel" onClick={()=>exportToExcel(filename)}> <img src={Excel} alt="img" /></a>
    )
}

export default ExportExcel;