/* eslint-disable no-undef */
/* eslint-disable no-dupe-keys */
// eslint-disable-next-line no-unused-vars

import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  // Plus,
  // Scanner,
  // Product7,
  DeleteIcon,
  Calendar,
} from "../../EntryFile/imagePath";
import "react-select2-wrapper/css/select2.css";
// import { TimePicker } from "antd";
import Select from "react-select";
import BaseUrl from "../../utils/BaseUrl";
import { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import Swal from "sweetalert2";
import { toast } from "react-toastify";
import can from "../../utils/Can";
// import Swal from "sweetalert2";

const url = BaseUrl();
const AddProduction = () => {
  const [startDate, setStartDate] = useState(new Date());
  // const [startTime, setStartTime] = useState();
  const [products,setProducts] = useState([]);
  const [productsend,setProductsEnd] = useState([]);
  const [listCart,setListCart] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [unites,setUnites] = useState([]);
  // const [errors,setErrors] = useState({});
  const history = useHistory();
  const [bakehouses,setBakehouses] = useState([]);
  const [selectBakehouse,setSelectBakehouse] = useState(null);
  const [selectProductEnd,setSelectProductEnd] = useState(null);
  const [estimation,setEstimation] = useState(0);


  useEffect(()=>{
    try {
      axios.get(url.base+'/me',{
               headers:{
                   'Content-Type':'application/json',
                   "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                   
               },
           }).then((resp)=>{
            setBakehouses(resp.data.data.bakehouses)
              if(!can(resp.data.data.permissions,"mes-productions") ){
                  window.location.href = '/boulangerie/dashboard';
              }
  
           })
       } catch (error) {
           // setLoading(false);
       }
  },[]);  


  useEffect(()=>{
      // productList();
      // unitesList();
  },[])



  function productList(id){
    try {
      axios.get(url.base+'/production-stock-bakehouse/'+id,{
          headers:{
              'Content-Type':'application/json',
              "Authorization": `Bearer ${localStorage.getItem('_token_')}`
              
          },
      }).then((resp)=>{
  
          if(resp.status == 200){
            setProducts(resp.data.data);
          }
          // setLoading(false);
      })
  } catch (error) {
      // setLoading(false);
  }
  }

  function productListBakehouse(id){
    try {
      axios.get(url.base+'/products-production-bakehouses/'+id,{
          headers:{
              'Content-Type':'application/json',
              "Authorization": `Bearer ${localStorage.getItem('_token_')}`
              
          },
      }).then((resp)=>{
  
          if(resp.status == 200){
            setProductsEnd(resp.data.data);
          }
          // setLoading(false);
      })
  } catch (error) {
      // setLoading(false);
  }
  }



  const optionsproducts = products.map((opt)=>({
    value: opt.product_id,
    label:`${opt.product_name}`
  }));

  const optionsproductsend = productsend.map((opt)=>({
    value: opt.id,
    label:`${opt.name}`
  }));


  const optionbakehouses = bakehouses?.map((option) => ({
    label:`${option.name}`,
    value:option.id
  }));

  const handleChangeBakehouse=(option)=>{
    setSelectBakehouse(option.value);
  
    productList(option.value);
    productListBakehouse(option.value);

  }

  const handleChangeProductEnd=(option)=>{
    setSelectProductEnd(option.value);
  }
console.log(selectProductEnd);

  const handleChangeProduct=(option)=>{

    let product_check = products.find((product) => product.product_id === option.value);
    
    if(listCart.find((list)=> list.product_id === product_check.product_id)){
      console.log("oops")
    }else{
      const itemCart = {
        "product_id":product_check.product_id,
        "image":product_check.image,
        "name":product_check.product_name,
        "quantity":0,
        "unit_id":product_check.unit_id,
        "unit":product_check.unit_name,
        "stock":product_check.quantity,

      }
      setListCart([...listCart, itemCart]);
    }

  }

  // const handleChangeUnits=(value,i)=>{
  //   const updatedProd = [...listCart];
  
  //   updatedProd[i].unit_id = value;
  
  //   setListCart(updatedProd);
  // }

  const handleChangeQty=(e,i)=>{ 

    const updatedProd = [...listCart];
    if(e > updatedProd[i].stock){
      toast.error("Quantité superieur à la valuer en stock.")
    }else{
      updatedProd[i].quantity=+ e;
      setListCart(updatedProd)
    }



  }

  const removeItem = (i) => {
    const updatedProd = [...listCart];
  
    updatedProd.splice(i, 1);
  
    setListCart(updatedProd);
  };

  const submitForm=(e)=>{
    e.preventDefault();

        if(estimation > 0 && selectBakehouse !== null && selectProductEnd !== null){
                const _formData = new FormData();
                _formData.append('time',moment(startDate).format("HH:mm"));
                _formData.append('date',moment(startDate).format("YYYY-MM-DD") );
                _formData.append('product_details',JSON.stringify(listCart));
                _formData.append('product_id',selectProductEnd);
            
                _formData.append('bakehouse_id',selectBakehouse);
                _formData.append('estimation',estimation);
            
                setLoading(true);
                try {
                  axios.post(url.base+'/technical-sheet',_formData,
                  {
                      headers:{
                          'Content-Type':'application/json',
                          "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                          
                      },
                      // credentials:'include'
                  }
                  ).then((resp)=>{
                    console.log(resp);
                      if(resp.status == 200){
                        setLoading(false);
                        toast.success("Enregistrer avec succès");
                            history.push('/boulangerie/production/production-list')
                      }else{
                        setLoading(false);
                        toast.success("Echec de l'enregistrement");
                      }
                  })
              } catch (error) {
                  setLoading(false);
            // console.log(error);  
              }
        }else{
          if(selectBakehouse == null){
            toast.error("Veuillez selectionner une boulangerie");
          }else if(selectProductEnd == null){
            toast.error("Vous n'avez pas selectionné de produit fini");
          }else if(estimation <= 0) {
            toast.error("Veuillez defini une estimation de votre production");
          }
        }

  }


  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Ajouter une nouvelle production</h4>
          </div>
        </div>
        <div className="card">
          <form className="card-body" onSubmit={submitForm}>
           
            <div className="col-lg-6 col-sm-6 col-6">
                <div className="form-group">
                  <label>Date de production</label>
                  <div className="input-groupicon">
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      locale="pt-FR"
                      showTimeSelect
                      timeFormat="p"
                      timeIntervals={15}
                      dateFormat="Pp"
                    />
                    <Link to="#" className="addonset" style={{"right":"313px"}}>
                      <img src={Calendar} alt="img" />
                    </Link>
                  </div>
                  
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-sm-6 col-6 mt-2 mb-3">
                    <label>Boulangerie</label>
                    <div>
                    <Select
                      className="select"
                      // data={options1}
                      options={optionbakehouses}
                      onChange={handleChangeBakehouse}
                    />
                    </div>
                    
                </div> 
                <div className="col-lg-6 col-sm-6 col-6 mt-2 mb-3">
                    <label>Selectionner le produit fini à produire</label>
                    <div>
                    <Select
                      className="select"
                      // data={options1}
                      options={optionsproductsend}
                      onChange={handleChangeProductEnd}
                    />
                    </div>
                    
                </div> 
              </div>
              {/* <div className="col-lg-3 col-sm-3 col-3">
                <div className="form-group">
                  <label>Heure de production</label>
                  <div className="input-groupicon" >
                    <TimePicker
                      selected={startTime}
                      onChange={(time) => setStartTime(time)}
                      format="h:mm aa"
                      style={{"paddingBottom":"14px"}}
                    />
                    
                </div>
                </div>
              </div> */}
              

              <div className="col-lg-12 col-sm-6 col-12">
                <div className="form-group">
                  <label>Produits en stock</label>
                  <div className="input-groupicon">
                  <Select placeholder="Ajouter un produit ..." options={optionsproducts} onChange={handleChangeProduct}/>
                  </div>
                </div>
              </div>
           
            <div className="row">
              <div className="table-responsive mb-3">
                <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Nom du produit </th>
                      <th style={{width:200}}>Quantité</th>
                      <th style={{width:100}}>Stock Disponible</th>
                      <th style={{width:300}}>Unité</th>
                      
                      <th>Action</th>
            
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                  {listCart && 
                        listCart.map((item,index)=>
                    <tr key={index}>
                     <td>{index}</td>
                      <td className="productimgname">
                        <Link to="#" className="product-img">
                          <img src={`${url.image}${item.image}`} alt="product" />
                        </Link>
                        <Link to="#">{item.name}</Link>
                      </td>
                      <td>
                      <input type="number"  onChange={(e)=>handleChangeQty(e.target.value,index)} className="form-control text-end" value={item.quantity}/> 
                      </td>
                      <td className="text-center">
                        <b className="fw-bold text-danger ">{item.stock}</b>
                        {/* <select className="form-control" onChange={(e)=>handleChangeUnits(e.target.value,index)}>
                            <option value="" selected>---</option>
                            {unites.map((option,idx)=>
                                <option key={idx} value={option.id}>{option.name}</option>
                            )}
                            
                        </select> */}
                      </td>
                      <td>
                        <b className="fw-bold">{item.unit}</b>
                        {/* <select className="form-control" onChange={(e)=>handleChangeUnits(e.target.value,index)}>
                            <option value="" selected>---</option>
                            {unites.map((option,idx)=>
                                <option key={idx} value={option.id}>{option.name}</option>
                            )}
                            
                        </select> */}
                      </td>
                    

                      <td>
                          <button type="button" className="btn" onClick={()=>removeItem(index)}>
                              <img src={DeleteIcon} alt="svg" />
                            </button>
                      </td>
                    </tr>
                        )}
                  </tbody>
                </table>
              </div>
            </div>
            <hr />
            <div className="row">
               <div className="col-lg-3 col-sm-6 col-12">
                <div className="form-group">
                  <label>Estimation nombre de produit fini</label>
                  <input type="text" onChange={(e)=>setEstimation(e.target.value)} value={estimation}/>
                </div>
              </div>
              {/* <div className="col-lg-3 col-sm-6 col-12">
                <div className="form-group">
                  <label>Discount</label>
                  <input type="text" />
                </div>
              </div>  */}
              {/* <div className="col-lg-3 col-sm-6 col-12">
                <div className="form-group">
                  <label>Shipping</label>
                  <input type="text" />
                </div>
              </div> */}
              {/* <div className="col-lg-3 col-sm-6 col-12">
                <div className="form-group">
                  <label>Statut</label>
                  <Select2
                    className="select"
                    data={options2}
                    options={{
                      placeholder: "Choose Status",
                    }}
                  />
                </div>
              </div> */}
              {/* <div className="row">
                <div className="col-lg-6 ">
                  <div className="total-order w-100 max-widthauto m-auto mb-4">
                    <ul>
                      <li>
                        <h4>Order Tax</h4>
                        <h5>$ 0.00 (0.00%)</h5>
                      </li>
                      <li>
                        <h4>Discount </h4>
                        <h5>$ 0.00</h5>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6 ">
                  <div className="total-order w-100 max-widthauto m-auto mb-4">
                    <ul>
                      <li>
                        <h4>Shipping</h4>
                        <h5>$ 0.00</h5>
                      </li>
                      <li className="total">
                        <h4>Grand Total</h4>
                        <h5>$ 0.00</h5>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
              <div className="col-lg-12">
              {loading &&
                  <button className="btn btn-submit" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        En chargement...
                    </button>
                }
                {!loading &&
                  <button type="submit" className="btn btn-submit  me-2" disabled={listCart.length <= 0 ? "disabled" : ""}>
                    Enregister
                  </button>
                }
                {/* <button type="submit" className="btn btn-submit me-2">
                  Enregistrer
                </button> */}
                <Link className="btn btn-cancel" to="/boulangerie/production/production-list">
                  Retour
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddProduction;
