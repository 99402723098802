/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Table from "../../EntryFile/datatable";
import Tabletop from "../../EntryFile/tabletop";
import Swal from "sweetalert2";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import { Link } from "react-router-dom";
import {
  ClosesIcon,
  Excel,
  Filter,
  Pdf,
  PlusIcon,
  Printer,
  Search,
  search_whites,
  EditIcon,
  DeleteIcon,
} from "../../EntryFile/imagePath";
import axios from "axios";
import BaseUrl from "../../utils/BaseUrl";
import Select from "react-select";

const url = BaseUrl();
const SousFamillePage = () => {
  const [errors,setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [itemData,setItemData] = useState({
    "name":"",
    "famille_id":""
  });
  const [editItem,setEditItem] = useState(null);

  const [itemList,setItemList] = useState([]);
  const [familles,setFamilles] = useState([]);
  const [optFamille,setOptFamille] = useState(null);


  useEffect(()=>{
    fetchSousFamilleList()
  },[])


  const handleChange=(e)=>{
    setItemData({
        ...itemData,
        [e.target.name]:e.target.value
    });
  }

  useEffect(()=>{
    try {
      axios.get(url.base+'/familles',{
          headers:{
              'Content-Type':'application/json',
              "Authorization": `Bearer ${localStorage.getItem('_token_')}`
              
          },
      }).then((resp)=>{

          if(resp.status == 200){
            setFamilles(resp.data.data);
          }
          // setLoading(false);
      })
  } catch (error) {
      // setLoading(false);
  }
  },[]);

  const optionfamilles = familles.map((opt)=>({
    value: opt.id,
    label:`${opt.name}`
  }));

  const handleChangeFamille=(option)=>{
    setOptFamille(option.value);
  }

  const showModalEdit=(unit)=>{

    setEditItem(unit);
    setItemData({
      "name":unit.name,
    });

    window.$("#editpayment").modal('show');
  }

  const columns = [
    {
      title: "Libelle",
      dataIndex: "name",
      className: "text-center",
      render: (text, record) => <div className="text-center">{text}</div>,
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Famille",
      dataIndex: "nom_famille",
      className: "text-center",
      render: (text, record) => <div className="text-center">{text}</div>,
      sorter: (a, b) => a.nom_famille.length - b.nom_famille.length,
    },
    {
      title: "Statut",
      dataIndex: "is_deleted",
      render: (text, record) => (
        <>
          <div className="status-toggle d-flex justify-content-between align-items-center">
            <input
              type="checkbox"
              id="user1"
              className="check"
              defaultChecked={text==0 ? text = 1 : text = 0}
            />
            <label htmlFor="user1" className="checktoggle">
              checkbox
            </label>
          </div>
        </>
      ),
      sorter: (a, b) => a.is_deleted.length - b.is_deleted.length,
    },
    {
      title: "Action",
      className: "text-center",
      render: (text, record) => (
        <div className="text-center">
          <button
            className="me-3 btn"
            onClick={()=>showModalEdit(record)}
          >
            <img src={EditIcon} alt="img" />
          </button>
          <button className="me-2 confirm-text btn" onClick={()=>deleteItem(record.id)}>
            <img src={DeleteIcon} alt="img" />
          </button>
        </div>
      ),
      // sorter: (a, b) => a.Action.length - b.Action.length,
    },
  ];

  const fetchSousFamilleList = async ()=>{
    setLoading(true);
    try {
        axios.get(url.base+'/sous-familles',{
            headers:{
                'Content-Type':'application/json',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                
            },
        }).then((resp)=>{

            if(resp.status == 200){
                setItemList(resp.data.data);
            }
            setLoading(false);
        })
    } catch (error) {
        setLoading(false);
    }
}

  const submitForm= (e)=>{
    e.preventDefault();

    const _formData = new FormData();
    _formData.append('name',itemData.name);
    _formData.append('famille_id',optFamille);

    setLoading(true);
    try {
        axios.post(url.base+'/sous-familles',_formData,
        {
            headers:{
                'Content-Type':'application/json',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                
            },
            // credentials:'include'
        }
        ).then((resp)=>{
            setLoading(false);
            if(resp.status == 200){

                setErrors({});
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title:  "Enregistrer avec succès",
                    showConfirmButton: false,
                    timer: 3000,
                    toast:true,
                    position:'top-right',
                    timerProgressBar:true
                  });
                  setItemData({
                    'name':''
                  });
                  setOptFamille(null);
                  fetchSousFamilleList()
            }else{
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title:  "Echec de l'enregistrement",
                    showConfirmButton: false,
                    timer: 3000,
                    toast:true,
                    position:'top-right',
                    timerProgressBar:true
                  });
            }
        }).catch((error)=>{  
          // console.log(error.response);              
            setLoading(false);

            if (error.response.status == 422) {
                setErrors(error.response.data.errors);
            }
            // console.log(errors);
        })
    } catch (error) {
        setLoading(false);
// console.log(error);  
    }
  }

  const submitFormEdit= (e)=>{
    e.preventDefault();

    const _formData = new FormData();
    _formData.append('name',itemData.name);
    // _formData.append('famille_id',optFamille);

    setLoading(true);
    try {
        axios.put(url.base+'/sous-familles/'+editItem?.id,_formData,
        {
            headers:{
                'Content-Type':'application/json',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                
            },
            // credentials:'include'
        }
        ).then((resp)=>{
            setLoading(false);
            if(resp.status == 200){

                setErrors({});
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title:  "Modification effectuée avec succès",
                    showConfirmButton: false,
                    timer: 3000,
                    toast:true,
                    position:'top-right',
                    timerProgressBar:true
                  });
                  setItemData({
                    'name':''
                  });
                  setOptFamille(null);
                  window.$("#editpayment").modal('hide');
                  fetchSousFamilleList()
            }else{
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title:  "Echec de la modification",
                    showConfirmButton: false,
                    timer: 3000,
                    toast:true,
                    position:'top-right',
                    timerProgressBar:true
                  });
            }
        }).catch((error)=>{  
          // console.log(error.response);              
            setLoading(false);

            if (error.response.status == 422) {
                setErrors(error.response.data.errors);
            }
            // console.log(errors);
        })
    } catch (error) {
        setLoading(false);
// console.log(error);  
    }
  }

  const deleteItem=(id)=>{
    Swal.fire({
      title: 'ATTENTION !',
      text: 'Êtes-vous sûrs de vouloir supprimer cet élément.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonText:"NON",
      confirmButtonText: 'OUI',
      cancelButtonColor:"red"
    }).then((result) => {
      if (result.isConfirmed) {
          try {
              axios.delete(url.base+'/sous-familles/'+id,
                  {
                      headers:{
                          'Content-Type':'application/json',
                          "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                          
                      },
                  }
              ).then((resp)=>{
                  // console.log(resp.data);
                  if(resp.status == 200)
                  {
                      if(resp.data.status == 600)
                      {
                          Swal.fire(
                              'Attention',
                              'Un problème est subvenu',
                              'error'
                            )
                      }else{
                          Swal.fire(
                              'Supprimé',
                              'Unité supprimé avec succès',
                              'success'
                            )
                      }
                     
                  }
                  fetchSousFamilleList();
              })
          } catch (error) {
              console.log(error);
          }

      
      }
    }); 
  }
  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Gestion des Sous familles</h4>
              {/* <h6>Manage Payment Settings</h6> */}
            </div>
            <div className="page-btn">
              <a
                className="btn btn-added"
                data-bs-toggle="modal"
                data-bs-target="#addpayment"
              >
                <img src={PlusIcon} alt="img" className="me-2" />
                Ajouter sous-famille
              </a>
            </div>
          </div>
          {/* /product list */}
          <div className="card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      className="form-control form-control-sm search-icon"
                      type="search"
                      placeholder="Recherche..."
                    />
                    <a className="btn btn-searchset">
                      <img src={Search} alt="img" />
                    </a>
                  </div>
                </div>
                <div className="wordset">
                  <ul>
                    <li>
                      <a
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="pdf"
                      >
                        <img src={Pdf} alt="img" />
                      </a>
                    </li>
                    <li>
                      <a
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="excel"
                      >
                        <img src={Excel} alt="img" />
                      </a>
                    </li>
                    <li>
                      <a
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="print"
                      >
                        <img src={Printer} alt="img" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={itemList}
                  rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      {/* add Modal */}
      <div
        className="modal fade"
        id="addpayment"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Ajouter sous-famille</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <form onSubmit={submitForm}>
              <div className="modal-body">
              
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label>Libelle</label>
                      <input type="text" name="name" onChange={handleChange} value={itemData.name}/>
                      {errors && errors.name && (
                            <span className="text-danger">{errors.name[0]}</span>
                        )}
                    </div>
                  </div>
                  <div className="col-12">
                    {/* <div className="form-group mb-0"> */}
                      <label>Famille</label>
                      <Select
                        className="select"
                        options={optionfamilles}
                        placeholder='choisir une famille'
                        onChange={handleChangeFamille}
                        // options={{
                        //   placeholder: "Choose Status",
                        // }}
                      />
                    {/* </div> */}
                  </div>
                </div>
              </div>
              <div className="modal-footer ">
                <button type="submit" className="btn btn-submit">
                  Enregister
                </button>
                <button
                  type="button"
                  className="btn btn-cancel"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </form>
          
          </div>
        </div>
      </div>
      {/* edit Modal */}
      <div
        className="modal fade"
        id="editpayment"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Editer </h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <form onSubmit={submitFormEdit}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label>Libelle</label>
                      <input type="text" name="name" onChange={handleChange} value={itemData.name}/>
                    </div>
                  </div>
                  {/* <div className="col-12">
                    <div className="form-group mb-0">
                      <label>Famille</label>
                      <Select
                        className="select"
                        options={optionfamilles}
                        placeholder='choisir une famille'
                        onChange={handleChangeFamille}
                        // options={{
                        //   placeholder: "Choose Status",
                        // }}
                      />
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-submit">
                  Modifier
                </button>
                <button
                  type="button"
                  className="btn btn-cancel"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default SousFamillePage;
