/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { DeleteIcon, Upload } from "../../EntryFile/imagePath";
import Select from "react-select";
import "react-select2-wrapper/css/select2.css";
import { Link, useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import axios from "axios";
import BaseUrl from "../../utils/BaseUrl";
import { toast } from "react-toastify";
import can from "../../utils/Can";

const options = [
    { id: 1, text: "India", text: "India" },
    { id: 2, text: "Australia", text: "Australia" },
  ];
  const options1 = [
    { id: 1, text: "City1", text: "City1" },
    { id: 2, text: "City2", text: "City2" },
  ];
const url = BaseUrl();
const EditDelivery = () => {
  const {id} = useParams();
  const {name} = useParams();
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const history = useHistory();

  const [loading,setLoading] = useState(false);
  // const [roles,setRoles] = useState([]);
  const [userData,setUserData] = useState({
    "email":"",
    "last_name":"",
    "first_name":"",
    "phone":"",
  });

  const [passwordData,setPassWordData] = useState({
    'new_password':'',
    'CNewpassword':''
  })
  const [selectRole,setSelectRole] = useState(null);
  const [bakehouses,setBakehouses] = useState([]);
  const [selectBakehouse,setSelectBakehouse] = useState([]);
  const [products,setProducts] = useState([]);

  const [loading1,setLoading1] = useState(false);
  const [listCart,setListCart] = useState(
    [{
      "product_id":null,
      "image":"",
      "product_name":"",
      "price":0,
      "price_seller":0,
    }]
  );

  useEffect(()=>{
    try {
      axios.get(url.base+'/me',{
               headers:{
                   'Content-Type':'application/json',
                   "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                   
               },
           }).then((resp)=>{
            setBakehouses(resp.data.data.bakehouses);
              if(!can(resp.data.data.permissions,"gestion-livreurs") ){
                  window.location.href = '/boulangerie/dashboard';
              }
  
           })
       } catch (error) {
           // setLoading(false);
       }
  },[]);


  useEffect(()=>{
    try {
      axios.get(url.base+'/users/'+id,{
          headers:{
              'Content-Type':'application/json',
              "Authorization": `Bearer ${localStorage.getItem('_token_')}`
              
          },
      }).then((resp)=>{
  
          if(resp.status == 200){
            setUserData({
              "email":resp.data.data.user.email,
              "first_name":resp.data.data.user.first_name,
              "last_name":resp.data.data.user.last_name,
              "phone":resp.data.data.user.phone
            });
            setSelectBakehouse(resp.data.data.bakehouses);
            const transArray = [];
            resp.data.data?.products?.forEach(item => {
              transArray.push({
                "product_id":item?.product_id,
                "image":item?.product?.image,
                "product_name":item?.product?.name,
                "price":item?.product?.price,
                "price_seller":item?.price_seller,
              });
            });
            setListCart(transArray);
          }
          // setLoading(false);
      })
  } catch (error) {
      // setLoading(false);
  }
  },[]);

  // function roleList(){
  //   try {
  //     axios.get(url.base+'/roles',{
  //         headers:{
  //             'Content-Type':'application/json',
  //             "Authorization": `Bearer ${localStorage.getItem('_token_')}`
              
  //         },
  //     }).then((resp)=>{
  
  //         if(resp.status == 200){
  //           setRoles(resp.data.data);
  //         }
  //         // setLoading(false);
  //     })
  // } catch (error) {
  //     // setLoading(false);
  // }
  // }

  const handleChange=(event)=>{
    setUserData({
      ...userData,
      [event.target.name]:event.target.value
    })
  }

  const handleChangePassword=(event)=>{
    setPassWordData({
      ...passwordData,
      [event.target.name]:event.target.value
    })
  }

  const handleChangeBakehouse=(selectoption)=>{
    setSelectBakehouse(selectoption.map(option => option.value));
  }
  const optionbakehouses = bakehouses?.map((option) => ({
    label:`${option.name}`,
    value:option.id
  }));
  const handleChangePrixUnitaire=(value,i)=>{
    const updatedProd = [...listCart];
    updatedProd[i].price_seller = value
  
    setListCart(updatedProd);
  }

  const removeItem = (i) => {
    const updatedProd = [...listCart];
    updatedProd.splice(i, 1);
  
    setListCart(updatedProd);
  };
  function productListBakehouse(){
    const _formData = new FormData();
    const bakehouseTabs = selectBakehouse;
    _formData.append('bakehouseIds',bakehouseTabs);
    console.log(_formData);
    setLoading1(true);
    try {
      axios.post(url.base+'/products-choose',_formData,{
          headers:{
              'Content-Type':'application/json',
              "Authorization": `Bearer ${localStorage.getItem('_token_')}`
              
          },
      }).then((resp)=>{
        setLoading1(false);
          if(resp.status == 200){
            setProducts(resp.data.data);
          }
          // setLoading(false);
      })
  } catch (error) {
    setLoading1(false);
      // setLoading(false);
  }
  } 

  const handleChangeProducts=(option)=>{
    let product_check = products.find((product) => product.id === option.value);
    
    if(listCart.find((list)=> list.product_id === product_check.id)){
      console.log("oops")
    }else{
      const itemCart = {
        "product_id":product_check.id,
        "image":product_check.image,
        "product_name":`${product_check.name} (${product_check.bakehouse.name})`,
        "price":product_check.price,
        "price_seller":0,
      }
      setListCart([...listCart, itemCart]);
  }
}
  const optionsproducts = products.map((opt)=>({
    value: opt.id,
    label:`${opt.name} (${opt.bakehouse.name})`
  }));

  // const handleChangeRole=(selectoption)=>{
  //   setSelectRole(selectoption.value);
  // }
  // const optionsroles = roles.map((opt)=>({
  //   value: opt.name,
  //   label:`${opt.name}`
  // }));

  const submitForm=(e)=>{
    e.preventDefault();

    const pattern_email = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/;
    const bakehouseTabs = selectBakehouse.map(option =>option);
    if(selectBakehouse.length > 0 && userData.first_name !=="" && userData.last_name !=="" && userData.phone !=="" && userData.email !==""  && pattern_email.test(userData.email) && (/^\d{9,10}$/).test(userData.phone)){
     
      if(listCart.length <= 0)
        {
            toast.error("Veuillez selectionner un produit pour le revendeur");
            return;
        }
      const _formData = new FormData();
      _formData.append('first_name',userData.first_name);
      _formData.append('last_name',userData.last_name);
      _formData.append('name',`${userData.first_name} ${userData.last_name}`);
      _formData.append('phone',userData.phone);
      _formData.append('email',userData.email);
      _formData.append('bakehouseTabs',bakehouseTabs);
      _formData.append('product_revendeurs',JSON.stringify(listCart));
      console.log(_formData);
      setLoading(true);
      try {
        axios.put(url.base+'/users/'+id,_formData,
        {
            headers:{
                'Content-Type':'application/json',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`                
            },
            // credentials:'include'
        }
        ).then((resp)=>{
            setLoading(false);
            if(resp.status == 200){
     
                toast.success("Modification effectué avec succès");
                history.push('/boulangerie/comptes/delivries-list')

                  
            }else{
                toast.error("Un problème est subvenu !");
            }
        }).catch((error)=>{  
          console.log(error.response);              
            setLoading(false);  
          
        })
    } catch (error) {
        setLoading(false);
        console.log(error);  
    }
    }else{
      
      if(userData.first_name == ""){
        toast.error("Le nom est obligatoire");
      }else if(userData.last_name == ""){
        toast.error("Le prénom est obligatoire");        
      }else if(selectBakehouse == 0){
        toast.error("Veuillez choisir une boulangerie");
      }else if(userData.phone == ""){
        toast.error("Le numéro de téléphone est obligatoire");
      }else if(!(/^\d{9,10}$/).test(userData.phone)){
        toast.error("Le numéro de téléphone saisi est invalid");
      }else if(userData.email == ""){
        toast.error("L'adresse e-mail est obligatoire");
      }else if(!pattern_email.test(userData.email)){
        toast.error("L'adresse e-mail saisi est invalid ('@', '.')");
      }
    }


  }

  const updatePassword=()=>{
    window.$('#validateUrl').modal('toggle');
  }

  const submitFormPassword=(e)=>{
    e.preventDefault();


    if(passwordData.new_password.length >= 5 && passwordData.new_password === passwordData.CNewpassword && passwordData.new_password !=="" && passwordData.CNewpassword !=="" ){
      const _formData = new FormData();
      _formData.append('new_password',passwordData.new_password);
      _formData.append('user_id',id);
  
  
      console.log(_formData);
      try {
        axios.post(url.base+'/update-password-users/',_formData,
        {
            headers:{
                'Content-Type':'application/json',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`                
            },
            // credentials:'include'
        }
        ).then((resp)=>{
            setLoading(false);
            if(resp.status == 200){
                  setPassWordData({
                      "new_password":"",
                      "new_password":""
                  });
                  toast.success("Modification effectué avec succès");
                  window.$('#validateUrl').modal('hide');
            }else{
                toast.error("Un problème est subvenu !");
            }
        }).catch((error)=>{  
          console.log(error.response);              
          
        })
    } catch (error) {
        console.log(error);  
    }
    }else{
      
      if(passwordData.new_password == ""){
        toast.error("Veuillez entrer un nouveau mot de passe.");        
      }else if(passwordData.CNewpassword == ""){
        toast.error("Veuillez confirmer le mot de passe.");
      }else if(passwordData.new_password !== passwordData.CNewpassword){
        toast.error("Le mot de passe de correspond pas");
      }else if(passwordData.new_password.length <= 5 ){
        toast.error("Le mot de passe doit contenir au moins 5 caractères");
      }

    }


  }
  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Modifier un Revendeur</h4>
              {/* <h6>Add/Update Supplier</h6> */}
            </div>
          </div>
          {/* /add */}
          <div className="card">
            <form className="card-body" onSubmit={submitForm}>
              <div className="row">
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Nom</label>
                    <input type="text" name="first_name" onChange={handleChange} value={userData.first_name}/>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Prénoms</label>
                    <input type="text" name="last_name" onChange={handleChange} value={userData.last_name}/>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12 col-12 mb-3">
                  <label>Choisir une boulangerie</label>
                  <div className="row">
                    <Select
                    className="select"
                    value={optionbakehouses.filter(obj => selectBakehouse.includes(obj.value))}
                    options={optionbakehouses}
                    defaultValue={optionbakehouses.filter(obj => selectBakehouse.includes(obj.value))}
                    onChange={handleChangeBakehouse}
                    isMulti
                   
                  />

                    <div className="col-lg-12 col-sm-6 col-12">
                        <label>Choisir les produits du revendeur 
                          {!loading1 && <button type="button" className=" btn btn-secondary p-0 px-1 fw-bold mb-2 mx-3" onClick={()=>productListBakehouse()}>Charger les produits <b className="text-primar">({products.length})</b>  </button>} 
                          {loading1 && <button className="btn btn-primary" type="button" disabled>
                                          <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                          Recherche en cours...
                                      </button>}
                        </label>
                        <Select options={optionsproducts} onChange={handleChangeProducts}  />
                    </div> 

                    <div className="row mt-1">
                      <div className="table-responsive mb-3">
                        <table className="table table-striped">
                          <thead >
                            <tr className="bg-danger">
                              <th className="bg-danger">#</th>
                              <th className="bg-danger">Nom du produit</th>
                              <th style={{width:200}} className="bg-danger">Prix du produit FCFA</th>   
                              <th style={{width:200}} className="bg-danger">Prix revendeur FCFA</th>               
                              <th className="bg-danger">Action</th>
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            {listCart && 
                                listCart.map((item,index)=>
                                <tr key={index}>
                                  <td>{index+1}</td>
                                  <td className="productimgname">
                                    <Link to="#" className="product-img">
                                      <img src={`${url.image}${item.image}`} alt="product" />
                                    </Link>
                                    <Link to="#">{item.product_name}</Link>
                                  </td>
                                  <td className="fw-bold">{item.price}</td>
                                  <td><input type="number"   onChange={(e)=>handleChangePrixUnitaire(e.target.value,index)} className="form-control" placeholder="saisir le prix" value={item.price_seller} /> </td>

                                  <td>
                                    <button type="button" className="btn" onClick={()=>removeItem(index)}>
                                      <img src={DeleteIcon} alt="svg" />
                                    </button>
                                  </td>
                              </tr>
                            )}
                            
                          
                          </tbody>
                        </table>
                      </div>
                    </div>
                   
                  </div>
              </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Email</label>
                    <input type="text" name="email" onChange={handleChange} value={userData.email}/>
                  </div>
                </div>

                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Téléphone</label>
                    <input type="text" name="phone" onChange={handleChange} value={userData.phone} placeholder="Ex: 0141079236"/>
                  </div>
                </div>


                <div className="col-lg-12">
                    {loading &&
                        <button className="btn btn-submit me-2" type="button" disabled>
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              En chargement...
                          </button>
                      }
                      {!loading &&
                      <button type="submit" className="btn btn-submit me-2" >
                        Modifier
                      </button>
                      }

                      <button type="button" className="btn btn-submit me-2" style={{"backgroundColor":"brown"}} onClick={()=>updatePassword()}>
                        Modifier le mot de passe
                      </button>

                  <Link className="btn btn-cancel" to="/boulangerie/comptes/delivries-list">Retour</Link>
                </div>
              </div>
            </form>
          </div>
          {/* /add */}
        </div>
      </div>

      <div
              className="modal fade"
              id="validateUrl"
              tabIndex={-1}
              aria-labelledby="validateUrl"
              aria-hidden="true"
              data-bs-backdrop="static" 
              // data-keyboard="false"
            >
              <div className="modal-dialog modal-md modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Modifier mot de passe</h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="card-body" onSubmit={submitFormPassword}>
                        
                      <div className="col-lg-12 col-sm-12 col-12">
                          <div className="form-group">
                            <label>Nouveau mot de passe  <span
                                className={`fas toggle-password ${
                                  passwordShown ? "fa-eye" : "fa-eye-slash"
                                }`}
                                onClick={togglePassword}
                              /></label>
                            <input
                                type={passwordShown ? "text" : "password"}
                                className=" pass-input"
                                name="new_password"
                                onChange={handleChangePassword}
                              />
                             
                            {/* <input type="password" name="new_password" onChange={handleChangePassword}  className="form-control"/> */}
                          </div>
                        </div>
                        <div className="col-lg-12 col-sm-12 col-12">
                          <div className="form-group">
                          <label>Confirmer le nouveau mot de passe </label>
                            <input
                                type={passwordShown ? "text" : "password"}
                                className=" pass-input"
                                name="CNewpassword"
                                onChange={handleChangePassword}
                              />
                          </div>
                        </div>

                        <button type="submit" className="btn btn-submit me-2 form-control" >
                        Modifier
                      </button>
                    </form>
                    
                  </div>
              
                </div>
              </div>
        </div>
    </>
  );
};

export default EditDelivery;
