/* eslint-disable react/prop-types */
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom';
import Genaral from './genaral'
import Email from './email'
import UniteSettings from './unite'
import Currency from './currency'
import Grouppermission from './grouppermission'
import Createpermission from './createpermission'
import Editpermission from './Editpermission'
import Taxrates from './taxrates'
import PermissionPage from './permissions';
import EditRolepermission from './editrolepermission';
import CatFournisseurs from './CatFournisseur';
import LogSettings from './logs';
import PaymentIDPage from './PaiementId';
import ListParamsBakehouse from './listbakehouseparams';






const UserIndex = ({ match}) =>(
    <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/generalsettings`} />
        <Route path={`${match.url}/store-info`} component={Genaral} />
        <Route path={`${match.url}/emailsettings`} component={Email} />
        <Route path={`${match.url}/unites`} component={UniteSettings} />
        <Route path={`${match.url}/cat-fournisseur`} component={CatFournisseurs} />
        <Route path={`${match.url}/param-payment`} component={PaymentIDPage} />
        <Route path={`${match.url}/param-bakehouse-list/:uuid`} component={ListParamsBakehouse} />
        <Route path={`${match.url}/currencysettings`} component={Currency} />
        {/* <Route path={`${match.url}/grouppermissions`} component={Grouppermission} /> */}
        {/* <Route path={`${match.url}/createpermission`} component={Createpermission} /> */}
        <Route path={`${match.url}/editpermission`} component={Editpermission} />
        <Route path={`${match.url}/taxrates`} component={Taxrates} />

        <Route path={`${match.url}/permissions`} component={PermissionPage} />
        <Route path={`${match.url}/roles-list`} component={Grouppermission} />
        <Route path={`${match.url}/create-roles`} component={Createpermission} />
        <Route path={`${match.url}/edit-role/:id`} component={EditRolepermission} />


        <Route path={`${match.url}/logs`} component={LogSettings} />

    </Switch>
)

export default UserIndex