/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Table from "../../EntryFile/datatable";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import Tabletop from "../../EntryFile/tabletop";
import Swal from "sweetalert2";
import "react-datepicker/dist/react-datepicker.css";
import {
  ClosesIcon,
  Excel,
  Filter,
  Pdf,
  Eye1,
  Calendar,
  Printer,
  search_whites,
  Search,
  PlusIcon,
  EditIcon,
  Dollar1,
  plusCircle,
  Download,
  delete1,
  DeleteIcon,
  datepicker,
} from "../../EntryFile/imagePath";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import axios from "axios";
import separateNumber from "../../utils/separateur";
import BaseUrl from "../../utils/BaseUrl";
import { toast } from "react-toastify";
import moment from "moment";
import can from "../../utils/Can";

const url = BaseUrl();
const HistoriqueSaleList = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [startDate1, setStartDate1] = useState(new Date());
  const [inputfilter, setInputfilter] = useState(false);
  const [excelData,setExcelData] = useState({});

  const [retours,setRetours] = useState([]);

  const togglefilter = (value) => {
    setInputfilter(value);
  };


  const options = [
    { id: 1, text: "Completed", text: "Completed" },
    { id: 2, text: "Paid", text: "Paid" },
  ];
  const options1 = [
    { id: 1, text: "Cash", text: "Cash" },
    { id: 2, text: "Online", text: "Online" },
    { id: 3, text: "Inprogess", text: "Inprogess" },
  ]; 

  useEffect(()=>{
    try {
      axios.get(url.base+'/me',{
               headers:{
                   'Content-Type':'application/json',
                   "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                   
               },
           }).then((resp)=>{

              if(!can(resp.data.data.permissions,"historique-ventes") ){
                  window.location.href = '/boulangerie/dashboard';
              }
  
           })
       } catch (error) {
           // setLoading(false);
       }
  },[]);
  const columns = [
    {
      title: "Date",
      className:"text-center",
      dataIndex: "date",
      render: (text, record) => (
        <>
        <div>{moment(record.created_at).format("Do MMMM YYYY HH:mm")} </div>
       </>
      ),
      sorter: (a, b) => a.date.length - b.date.length,
    },
    {
      title: "Reférence",
      className:"text-center",
      dataIndex: "reference",
      sorter: (a, b) => a.reference.length - b.reference.length,
    },
    // {
    //   title: "Total achat en FCFA",
    //   dataIndex: "total_amount",
    //   className:"text-center",
    //   render: (text, record) => (
    //     <>
    //     <b className="fw-bold text-success">{separateNumber(text)}</b>
    //     </>
    //   ),
    //   sorter: (a, b) => a.total_amount.length - b.total_amount.length,
    // },

    // {
    //   title: "Payer par le client",
    //   className:"text-center",
    //   dataIndex: "paid_amount",
    //   render: (text, record) => (
    //     <>
    //       <b className="fw-bold text-success">{separateNumber(text)}</b>
    //     </>
    //   ),
    //   sorter: (a, b) => a.paid_amount.length - b.paid_amount.length,
    // },
    // {
    //   title: "Monnaie rendu",
    //   className:"text-center",
    //   dataIndex: "balance",
    //   render: (text, record) => (
    //     <>
    //       <b className="fw-bold text-success">{separateNumber(text)}</b>
    //     </>
    //   ),
    //   sorter: (a, b) => a.balance.length - b.balance.length,
    // },
    {
      title: "Auteur",
      className:"text-center",
      dataIndex: "auteur",
      render: (text, record) => (
        <>
          <b className="fw-bold">{text.first_name} {text.last_name}</b>
        </>
      ),
      sorter: (a, b) => a.auteur.length - b.auteur.length,
    },
    {
      title: "Nbre produits",
      dataIndex: "sale_details_count",
      className:"text-center",
      render: (text, record) => (
        <>
          <b className="fw-bold text-danger">{text}</b>
        </>
      )
    },

    {
      title: "Action",
      className:"text-center",
      render: (text, record) => (
        <>
            <Link className="btn btn-sm btn-secondary me-2"  to={`/boulangerie/production/sales-datail/${record.uuid}`}> <i className="fa fa-eye"></i>  </Link>
           
            {/* <button className="btn btn-sm btn-danger me-2" onClick={()=>deleteItem(record.id)}> <i className="fa fa-trash"></i>  </button> */}
        </>
      ),
    },
  ];

  useEffect(()=>{
    fetchItems();
  },[]);

  const fetchItems = () =>{
      try {
        axios.get(url.base+'/sales',{
            headers:{
                'Content-Type':'application/json',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                
            },
        }).then((resp)=>{

            if(resp.status == 200){
              setRetours(resp.data.data);
              const transArray = [];
              resp.data.data.forEach(item => { 
                transArray.push({
                  "Date":moment(item.created_at).format("Do MMMM YYYY HH:mm"),
                  "Reference":item.reference,
                  "Total Achats":`${item.total_amount}`,
                  "Espece client":item.paid_amount,
                  "Monnaie client":item.balance,
                  "Vendeur":`${item.auteur.first_name} ${item.auteur.last_name}`,
              });
              });
              setExcelData(transArray)
            }
            // setLoading(false);
        })
    } catch (error) {
        // setLoading(false);
    }
}
const handleSearch = (e) => {
  const searchValue = e.target.value.toLowerCase();
  if (searchValue.trim() === "") {
    fetchItems();
  } else {
    const filtered = retours.filter(item =>
      Object.values(item).some(value =>
        typeof value === 'string' && value.toLowerCase().includes(searchValue)
      )
    );
    setRetours(filtered);
  }

};
const deleteItem=(id)=>{
  Swal.fire({
    title: 'ATTENTION !',
    text: 'Êtes-vous sûrs de vouloir supprimer cet élément.',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonText:"NON",
    confirmButtonText: 'OUI',
    cancelButtonColor:"red"
  }).then((result) => {
    if (result.isConfirmed) {
        try {
            axios.delete(url.base+'/returns-orders/'+id,
                {
                    headers:{
                        'Content-Type':'application/json',
                        "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                        
                    },
                }
            ).then((resp)=>{
                // console.log(resp.data);
                if(resp.status == 200)
                {
                    if(resp.data.status == 600)
                    {
                      toast.error('Un problème est subvenu');
                        // Swal.fire(
                        //     'Attention',
                        //     'Un problème est subvenu',
                        //     'error'
                        //   )
                    }else{
                        toast.success('Retour supprimé avec succès');
                    }
                   
                }
                fetchItems();
            })
        } catch (error) {
            console.log(error);
        }

    
    }
  }); 
}

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Historique des ventes au contoire</h4>
              {/* <h6>Manage your Sales</h6> */}
            </div>
            <div className="page-btn">
              {/* <Link to="/pos" className="btn btn-added">
                <img src={PlusIcon} alt="img" className="me-1" />
                Page de vente
              </Link> */}
            </div>
          </div>
          {/* /product list */}
          <div className="card">
            <div className="card-body">
              <Tabletop inputfilter={inputfilter} togglefilter={togglefilter} excelData={excelData} filename={"historique_vente"} handleSearch={handleSearch}/>
              {/* /Filter */}
              <div
                className={`card mb-0 ${inputfilter ? "toggleCls" : ""}`}
                id="filter_inputs"
                style={{ display: inputfilter ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="form-group">
                        <input type="text" placeholder="Enter Name" />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="form-group">
                        <input type="text" placeholder="Enter Reference No" />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="form-group">
                        <Select2
                          className="select"
                          data={options}
                          options={{
                            placeholder: "Choose Suppliers",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="form-group">
                        <Link to="#" className="btn btn-filters ms-auto">
                          <img src={search_whites} alt="img" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
                <Table
                  props={props} columns={columns}
                  dataSource={retours}
                  rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      <>
        <div
          className="modal fade"
          id="showpayment"
          tabIndex={-1}
          aria-labelledby="showpayment"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Show Payments</h5>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Reference</th>
                        <th>Amount </th>
                        <th>Paid By </th>
                        <th>Paid By </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bor-b1">
                        <td>2022-03-07 </td>
                        <td>INV/SL0101</td>
                        <td>$ 0.00 </td>
                        <td>Cash</td>
                        <td>
                          <Link className="me-2" to="#">
                            <img src={Printer} alt="img" />
                          </Link>
                          <Link
                            className="me-2"
                            to="#"
                            data-bs-target="#editpayment"
                            data-bs-toggle="modal"
                            data-bs-dismiss="modal"
                          >
                            <img src={EditIcon} alt="img" />
                          </Link>
                          <Link className="me-2 confirm-text" to="#">
                            <img src={DeleteIcon} alt="img" />
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* show payment Modal */}
        {/* show payment Modal */}
        <div
          className="modal fade"
          id="createpayment"
          tabIndex={-1}
          aria-labelledby="createpayment"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Create Payment</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="form-group">
                      <label>Customer</label>
                      <div className="input-groupicon">
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                        />
                        <div className="addonset">
                          <img src={Calendar} alt="img" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="form-group">
                      <label>Reference</label>
                      <input type="text" defaultValue="INV/SL0101" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="form-group">
                      <label>Received Amount</label>
                      <input type="text" defaultValue={0.0} />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="form-group">
                      <label>Paying Amount</label>
                      <input type="text" defaultValue={0.0} />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="form-group">
                      <label>Payment type</label>
                      <Select2
                        className="select"
                        data={options1}
                        options={{
                          placeholder: "Choose Suppliers",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group mb-0">
                      <label>Note</label>
                      <textarea className="form-control" defaultValue={""} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-submit">
                  Submit
                </button>
                <button
                  type="button"
                  className="btn btn-cancel"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* show payment Modal */}
        {/* edit payment Modal */}
        <div
          className="modal fade"
          id="editpayment"
          tabIndex={-1}
          aria-labelledby="editpayment"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Payment</h5>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="form-group">
                      <label>Customer</label>
                      <div className="input-groupicon">
                        <DatePicker
                          selected={startDate1}
                          onChange={(date) => setStartDate1(date)}
                        />
                        <div className="addonset">
                          <img src={datepicker} alt="img" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="form-group">
                      <label>Reference</label>
                      <input type="text" defaultValue="INV/SL0101" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="form-group">
                      <label>Received Amount</label>
                      <input type="text" defaultValue={0.0} />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="form-group">
                      <label>Paying Amount</label>
                      <input type="text" defaultValue={0.0} />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="form-group">
                      <label>Payment type</label>
                      <Select2
                        className="select"
                        data={options1}
                        options={{
                          placeholder: "Choose Suppliers",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group mb-0">
                      <label>Note</label>
                      <textarea className="form-control" defaultValue={""} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-submit">
                  Submit
                </button>
                <button
                  type="button"
                  className="btn btn-cancel"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default HistoriqueSaleList;
