/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Table from "../../EntryFile/datatable";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import Tabletop from "../../EntryFile/tabletop";
import Swal from "sweetalert2";
import "react-datepicker/dist/react-datepicker.css";
import {
  ClosesIcon,
  Excel,
  Filter,
  Pdf,
  Eye1,
  Calendar,
  Printer,
  search_whites,
  Search,
  PlusIcon,
  EditIcon,
  Dollar1,
  plusCircle,
  Download,
  delete1,
  DeleteIcon,
  datepicker,
} from "../../EntryFile/imagePath";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import BaseUrl from "../../utils/BaseUrl";
import axios from "axios";
import separateNumber from "../../utils/separateur";
import moment from "moment";
import { toast } from "react-toastify";
import can from "../../utils/Can";

const url = BaseUrl();
const PurchaseFournisseurList = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [startDate1, setStartDate1] = useState(new Date());
  const [inputfilter, setInputfilter] = useState(false);
  const [excelData,setExcelData] = useState({});
  const [approvisionnements,setApprovisionnements] = useState([]);

  const togglefilter = (value) => {
    setInputfilter(value);
  };

  const confirmText = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: !1,
    }).then(function (t) {
      t.value &&
        Swal.fire({
          type: "success",
          title: "Deleted!",
          text: "Your file has been deleted.",
          confirmButtonClass: "btn btn-success",
        });
    });
  };
  const options = [
    { id: 1, text: "Completed", text: "Completed" },
    { id: 2, text: "Paid", text: "Paid" },
  ];
  const options1 = [
    { id: 1, text: "Cash", text: "Cash" },
    { id: 2, text: "Online", text: "Online" },
    { id: 3, text: "Inprogess", text: "Inprogess" },
  ];


  const columns = [
    {
      title: "Date",
      dataIndex: "delivery_date",
      className: "text-center",
      render: (text, record) => (
        <div>
          {moment(text).format("Do MMMM YYYY")} {moment(record.created_at).format("HH:mm:ss")}
        </div>
      ),
      sorter: (a, b) => a.delivery_date.length - b.delivery_date.length,
    },
    {
      title: "Reference", 
      dataIndex: "reference",
      className: "text-center",
      sorter: (a, b) => a.reference.length - b.reference.length,
    },
    {
      title: "Boulangerie",
      dataIndex: "bakehouse",
      className: "text-center",
      render: (text, record) => (
        <b>{record.bakehouse?.name}</b>
      ),
      sorter: (a, b) => a.bakehouse.length - b.bakehouse.length,
    },

    {
      title: "Fournisseurs",
      dataIndex: "supplier",
      className: "text-center",
      render: (text, record) => (
        <b>{record.supplier?.first_name} {record.supplier?.last_name} </b>
      ),
      sorter: (a, b) => a.supplier.length - b.supplier.length,
    },
    {
      title: "Statut",
      dataIndex: "status",
      className: "text-center",
      render: (text, record) => (
        <>
          {text === 0 && (
            <span className="badges bg-lightred">En attente</span>
          )}
          {text ===1 && (
            <span className="badges bg-lightgreen">Livré</span>
          )}
        </>
      ),
      sorter: (a, b) => a.status.length - b.status.length,
    },

    {
      title: "Total (FCFA)",
      dataIndex: "total_amount",
      className: "text-center",
      render: (text, record) => (
        <>
          <b>{separateNumber(text)}</b>
        </>
      ),
      sorter: (a, b) => a.total_amount.length - b.total_amount.length,
    },

    {
      title: "Action",
      className: "text-center",
      render: (text, record) => (
        <>
          <div className="text-center">
            <Link
              className="action-set"
              to="#"
              data-bs-toggle="dropdown"
              aria-expanded="true"
            >
              <i className="fa fa-ellipsis-v" aria-hidden="true" />
            </Link>
            <ul className="dropdown-menu">
              <li>
                <Link
                  to={`/boulangerie/approvisionnement/achat-details/${record.uuid}`}
                  className="dropdown-item"
                >
                  <img src={Eye1} className="me-2" alt="img" />
                  {"Détail de l'achat"}
                </Link>
              </li>
          
              <li>
                <a onClick={(e)=>Downloadpdf(e,record.uuid)} className="dropdown-item">
                  <img src={Download} className="me-2" alt="img" />
                  Download pdf
                </a>
              </li>
              {record.status !== 1 &&
                <>
                <li>
                  <Link
                    to={`/boulangerie/approvisionnement/edit-achats/${record.uuid}`}
                    className="dropdown-item"
                  >
                    <img src={EditIcon} className="me-2" alt="img" />
                    {"Modifier l'achat"}
                  </Link>
                </li>
                 <li>
                  {/* <Link
                    to="#"
                    className="dropdown-item confirm-text"
                    onClick={confirmText}
                  >
                    <img src={delete1} className="me-2" alt="img" />
                    {"Supprimer l'achat"}
                  </Link> */}
                  <button className="dropdown-item confirm-text" onClick={()=>deleteItem(record.id)}>
                    <img src={delete1} alt="img" className="text-danger"/>
                    {"Supprimer l'achat"}
                  </button>
               </li>
                </>
              }
             
            </ul>
          </div>
        </>
      ),
    },
  ];

  useEffect(()=>{
    try {
      axios.get(url.base+'/me',{
               headers:{
                   'Content-Type':'application/json',
                   "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                   
               },
           }).then((resp)=>{

              if(!can(resp.data.data.permissions,"voir-page-achats") ){
                  window.location.href = '/boulangerie/dashboard';
              }
  
           })
       } catch (error) {
           // setLoading(false);
       }
    fetchApprovisionnement();
  },[]);

  const fetchApprovisionnement = () =>{
      try {
        axios.get(url.base+'/procurements',{
            headers:{
                'Content-Type':'application/json',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                
            },
        }).then((resp)=>{

            if(resp.status == 200){
              setApprovisionnements(resp.data.data);
              const transArray = [];
              resp.data.data.forEach(item => { 
                transArray.push({
                  "Date":`${moment(item).format("Do MMMM YYYY")} ${moment(item.created_at).format("HH:mm:ss")}`,
                  "Reference":item.reference,
                  "Montant":item.total_amount,
                  "Statut":item.status,
              });
              });
              setExcelData(transArray)
            }
            // setLoading(false);
        })
    } catch (error) {
        // setLoading(false);
    }
}

const deleteItem=(id)=>{
  Swal.fire({
    title: 'ATTENTION !',
    text: 'Êtes-vous sûrs de vouloir supprimer cet élément.',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonText:"NON",
    confirmButtonText: 'OUI',
    cancelButtonColor:"red"
  }).then((result) => {
    if (result.isConfirmed) {
        try {
            axios.delete(url.base+'/procurement-delete/'+id,
                {
                    headers:{
                        'Content-Type':'application/json',
                        "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                        
                    },
                }
            ).then((resp)=>{
                // console.log(resp.data);
                if(resp.status == 200)
                {
                    if(resp.data.status == 600)
                    {
                        // Swal.fire(
                        //     'Attention',
                        //     'Un problème est subvenu',
                        //     'error'
                        //   )
                        toast.error('Un problème est subvenu');
                    }else{
                        toast.success('Suppression effectué avec succès !');
                    }
                   
                }
                fetchApprovisionnement();
            })
        } catch (error) {
            console.log(error);
        }

    
    }
  }); 
}

const Downloadpdf= (e,uuid)=>{
  e.preventDefault();



  try {
      axios.get(url.base+'/export-procurement-pdf/'+uuid,
      {
          headers:{
              'Content-Type':'application/pdf',
              "Authorization": `Bearer ${localStorage.getItem('_token_')}`,
              
          },
          responseType:'blob',
          // credentials:'include'
      }
      ).then((response)=>{
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'achats.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
  } catch (error) {
// console.log(error);  
  }
}

const handleSearch = (e) => {
  const searchValue = e.target.value.toLowerCase();
  if (searchValue.trim() === "") {
    fetchApprovisionnement();
  } else {
    const filtered = approvisionnements.filter(item =>
      Object.values(item).some(value =>
        typeof value === 'string' && value.toLowerCase().includes(searchValue)
      )
    );
    setApprovisionnements(filtered);
  }

};
  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Liste des approvisionnements</h4>
              {/* <h6>Manage your Sales</h6> */}
            </div>
            <div className="page-btn">
              <Link to="/boulangerie/approvisionnement/ajout-achat" className="btn btn-added">
                <img src={PlusIcon} alt="img" className="me-1" />
                Ajouter un achat
              </Link>
            </div>
          </div>
          {/* /product list */}
          <div className="card">
            <div className="card-body">
              <Tabletop inputfilter={inputfilter} togglefilter={togglefilter} excelData={excelData} filename={"achats"} handleSearch={handleSearch}/>
              {/* /Filter */}
              <div
                className={`card mb-0 ${inputfilter ? "toggleCls" : ""}`}
                id="filter_inputs"
                style={{ display: inputfilter ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="form-group">
                        <input type="text" placeholder="Enter Name" />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="form-group">
                        <input type="text" placeholder="Enter Reference No" />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="form-group">
                        <Select2
                          className="select"
                          data={options}
                          options={{
                            placeholder: "Choose Suppliers",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="form-group">
                        <Link to="#" className="btn btn-filters ms-auto">
                          <img src={search_whites} alt="img" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
                <Table
                  props={props} columns={columns}
                  dataSource={approvisionnements}
                  rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      <>
        <div
          className="modal fade"
          id="showpayment"
          tabIndex={-1}
          aria-labelledby="showpayment"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Show Payments</h5>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Reference</th>
                        <th>Amount </th>
                        <th>Paid By </th>
                        <th>Paid By </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bor-b1">
                        <td>2022-03-07 </td>
                        <td>INV/SL0101</td>
                        <td>$ 0.00 </td>
                        <td>Cash</td>
                        <td>
                          <Link className="me-2" to="#">
                            <img src={Printer} alt="img" />
                          </Link>
                          <Link
                            className="me-2"
                            to="#"
                            data-bs-target="#editpayment"
                            data-bs-toggle="modal"
                            data-bs-dismiss="modal"
                          >
                            <img src={EditIcon} alt="img" />
                          </Link>
                          <Link className="me-2 confirm-text" to="#">
                            <img src={DeleteIcon} alt="img" />
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* show payment Modal */}
        {/* show payment Modal */}
        <div
          className="modal fade"
          id="createpayment"
          tabIndex={-1}
          aria-labelledby="createpayment"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Create Payment</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="form-group">
                      <label>Customer</label>
                      <div className="input-groupicon">
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                        />
                        <div className="addonset">
                          <img src={Calendar} alt="img" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="form-group">
                      <label>Reference</label>
                      <input type="text" defaultValue="INV/SL0101" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="form-group">
                      <label>Received Amount</label>
                      <input type="text" defaultValue={0.0} />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="form-group">
                      <label>Paying Amount</label>
                      <input type="text" defaultValue={0.0} />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="form-group">
                      <label>Payment type</label>
                      <Select2
                        className="select"
                        data={options1}
                        options={{
                          placeholder: "Choose Suppliers",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group mb-0">
                      <label>Note</label>
                      <textarea className="form-control" defaultValue={""} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-submit">
                  Submit
                </button>
                <button
                  type="button"
                  className="btn btn-cancel"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* show payment Modal */}
        {/* edit payment Modal */}
        <div
          className="modal fade"
          id="editpayment"
          tabIndex={-1}
          aria-labelledby="editpayment"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Payment</h5>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="form-group">
                      <label>Customer</label>
                      <div className="input-groupicon">
                        <DatePicker
                          selected={startDate1}
                          onChange={(date) => setStartDate1(date)}
                        />
                        <div className="addonset">
                          <img src={datepicker} alt="img" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="form-group">
                      <label>Reference</label>
                      <input type="text" defaultValue="INV/SL0101" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="form-group">
                      <label>Received Amount</label>
                      <input type="text" defaultValue={0.0} />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="form-group">
                      <label>Paying Amount</label>
                      <input type="text" defaultValue={0.0} />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="form-group">
                      <label>Payment type</label>
                      <Select2
                        className="select"
                        data={options1}
                        options={{
                          placeholder: "Choose Suppliers",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group mb-0">
                      <label>Note</label>
                      <textarea className="form-control" defaultValue={""} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-submit">
                  Submit
                </button>
                <button
                  type="button"
                  className="btn btn-cancel"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default PurchaseFournisseurList;
