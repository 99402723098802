/* eslint-disable no-constant-condition */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import FeatherIcon from "feather-icons-react";

const Sidebar = ({permissions}) => {
  const [isSideMenu, setSideMenu] = useState("");
  const [path, setPath] = useState("");
  const history = useHistory();

  const toggleSidebar = (value) => {
    setSideMenu(value);
  };
  const expandMenu = () => {
    document.body.classList.remove("expand-menu");
  };
  const expandMenuOpen = () => {
    document.body.classList.add("expand-menu");
  };
  const pageRefresh = (url, page) => {
    history.push(`/boulangerie/${url}/${page}`);
    window.location.reload();
  };
  const location = useLocation();
  let pathname = location.pathname;

  useEffect(() => {
    document.querySelector(".main-wrapper").classList.remove("slide-nav");
    document.querySelector(".sidebar-overlay").classList.remove("opened");
    document.querySelector(".sidebar-overlay").onclick = function () {
      this.classList.remove("opened");
      document.querySelector(".main-wrapper").classList.remove("slide-nav");
    };
  }, [pathname]);
  const exclusionArray = [
    "/reactjs/template/boulangerie/index-three",
    "/reactjs/template/boulangerie/index-four",
    "/reactjs/template/boulangerie/index-two",
    "/reactjs/template/boulangerie/index-one",
  ];
  if (exclusionArray.indexOf(window.location.pathname) >= 0) {
    return "";
  }

  const logout=(e)=>{
    e.preventDefault();
    console.log('ok')
    localStorage.removeItem('_token_');
    window.location.href = '/signIn';
  }

  const can = (permission) => (permissions || []).find((p) => p==permission) ? true :false;

  return (
    <>
      <div className={`sidebar index-4 ${pathname.includes("/index-three")?"d-none":""}`} id="sidebar">
        <Scrollbars>
          <div className="slimScrollDiv">
          <div className="sidebar-inner slimscroll">
            <div
              id="sidebar-menu"
              className="sidebar-menu"
              onMouseOver={expandMenuOpen}
              onMouseLeave={expandMenu}
            >
              <ul>
                {can("voir-dashbord") && 
                    <li className="submenu-open">
                          <h6 className="submenu-hdr">Dashboard</h6>
                          <ul>
                            <li
                              className={pathname.includes("dashboard") ? "active" : ""}
                            >
                              <Link to="/boulangerie/dashboard">
                                {/* <i data-feather="grid" /> */}
                                <FeatherIcon icon="grid" />
                                <span>Tableau de bord</span>
                              </Link>
                            </li>
                              {/* <li className="submenu">
                              <Link
                                to="#"
                                className={
                                  pathname.includes("/boulangerie/application")
                                    ? "subdrop active"
                                    : "" || isSideMenu == "Application"
                                    ? "subdrop active"
                                    : ""
                                }
                                onClick={() =>
                                  toggleSidebar(
                                    isSideMenu == "Application" ? "" : "Application"
                                  )
                                }
                              >
                                
                                <FeatherIcon icon="smartphone" />
                                <span> Application</span>{" "}
                                <span className="menu-arrow" />
                              </Link>
                              {isSideMenu == "Application" ? (
                                <ul>
                                  <li>
                                    <Link
                                      to="/boulangerie/application/chat"
                                      className={
                                        pathname.includes("chat") ? "active" : ""
                                      }
                                    >
                                      Chat
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="/boulangerie/application/calendar"
                                      className={
                                        pathname.includes("calendar") ? "active" : ""
                                      }
                                    >
                                      Calendar
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="/boulangerie/application/email"
                                      className={
                                        pathname.includes("email") ? "active" : ""
                                      }
                                    >
                                      Email
                                    </Link>
                                  </li>
                                </ul>
                              ) : (
                                ""
                              )}
                            </li>  */}
                          </ul>
                      </li>
                }

                <li className="submenu-open">
                  <h6 className="submenu-hdr">Gestion des produits</h6>
                  <ul>
                  {/* <li
                      className={
                        pathname.includes("productlist-product") ? "active" : ""
                      }
                    >
                      <Link
                        className={
                          pathname.includes("productlist-") ? "active" : ""
                        }
                        to="/boulangerie/product/productlist-product"
                      >
                        <FeatherIcon icon="box" />
                        <span>Matières prémière</span>
                      </Link>
                    </li>  */}
                    {can("voir-page-fournisseur") && 
                          <li className={
                            pathname.includes("fournisseurs") ? "active" : ""
                          }
                        >
                          <Link
                            className={
                              pathname.includes("fournisseurs") ? "active" : ""
                            }
                            to="/boulangerie/transfer/fournisseurs"
                          >
                            <FeatherIcon icon="plus-square" />
                            <span>Founisseurs</span>
                          </Link>
                        </li>
                    }
                    {can("voir-page-achats") && 
                      <li
                      className={
                        pathname.includes("list-achat-fournisseur")
                          ? "active" 
                          : ""
                      }
                    >
                      <Link
                        className={
                          pathname.includes("list-achat-fournisseur") ? "active" : ""
                        }
                        to="/boulangerie/approvisionnement/list-achat-fournisseur"
                      >
                        <FeatherIcon icon="codepen" />
                        <span>Mes Achats</span>
                      </Link>
                    </li>
                    }
                  
                     {/* <li
                      className={
                        pathname.includes("brandlist-product") ? "active" : ""
                      }
                    >
                      <Link
                        className={
                          pathname.includes("brandlist-") ? "active" : ""
                        }
                        to="/boulangerie/product/brandlist-product"
                      >
                     
                        <FeatherIcon icon="tag" />
                        <span>Produits</span>
                      </Link>
                    </li>  */}
                    {can("gestion-produit") && 
                        <li className="submenu">
                        <Link
                          to="#"
                          className={
                            pathname.includes("/boulangerie/transfer")
                              ? "subdrop active"
                              : "" || isSideMenu == "transfer"
                              ? "subdrop active"
                              : ""
                          }
                          onClick={() =>
                            toggleSidebar(
                              isSideMenu == "transfer" ? "" : "transfer"
                            )
                          }
                        >
                          {" "}
                          <FeatherIcon icon="shuffle" />
                          <span>Mes produits</span>{" "}
                          <span className="menu-arrow"></span>
                        </Link>
                        {isSideMenu == "transfer" ? (
                          <ul>
                            {/* <li>
                              <Link
                                className={
                                  pathname.includes("transferlist-")
                                    ? "active"
                                    : ""
                                }
                                to="/boulangerie/transfer/transferlist-transfer"
                              >
                                Familles
                              </Link>
                            </li> */}
                            {/* <li>
                              <Link
                                className={
                                  pathname.includes("sous-")
                                    ? "active"
                                    : ""
                                }
                                to="/boulangerie/transfer/sous-familles"
                              >
                                Sous-famille
                              </Link>
                            </li> */}
                            <li>
                              <Link
                                className={
                                  pathname.includes("produits-boulangerie")
                                    ? "active"
                                    : ""
                                }
                                to="/boulangerie/transfer/produits-boulangerie"
                              >
                                Matieres prémiere
                              </Link>
                            </li>
                            <li>
                              <Link
                                className={
                                  pathname.includes("produits-de-ventes")
                                    ? "active"
                                    : ""
                                }
                                to="/boulangerie/transfer/produits-de-ventes"
                              >
                                Produits de vente
                              </Link>
                            </li>
                            <li>
                              <Link
                                className={
                                  pathname.includes("produits-stocks")
                                    ? "active"
                                    : ""
                                }
                                to="/boulangerie/transfer/produits-stocks"
                              >
                                Stock des matières
                              </Link>
                            </li>
  
                            <li>
                              <Link
                                className={
                                  pathname.includes("history-products")
                                    ? "active"
                                    : ""
                                }
                                to="/boulangerie/transfer/history-products"
                              >
                                Listes des appro.
                              </Link>
                            </li>
                          </ul>
                        ) : (
                          ""
                        )}
                      </li>
                    }

                    {/* <li
                      className={
                        pathname.includes("subcategorytable-product")
                          ? "active"
                          : ""
                      }
                    >
                       <Link
                        className={
                          pathname.includes("subcategorytable-") ? "active" : ""
                        }
                        to="/boulangerie/product/subcategorytable-product"
                      >
                        <FeatherIcon icon="speaker" />
                        <span>Sub Category</span>
                      </Link> 
                    </li>
                     <li
                      className={
                        pathname.includes("printbarcode-product")
                          ? "active"
                          : ""
                      }
                    >
                      <Link
                        className={
                          pathname.includes("printbarcode-") ? "active" : ""
                        }
                        to="/boulangerie/product/printbarcode-product"
                      >
                 
                        <FeatherIcon icon="align-justify" />
                        <span>Print Barcode</span>
                      </Link>
                    </li>
                    <li
                      className={
                        pathname.includes("importproduct-product")
                          ? "active"
                          : ""
                      }
                    >
                      <Link
                        className={
                          pathname.includes("importproduct-") ? "active" : ""
                        }
                        to="/boulangerie/product/importproduct-product"
                      >
                       
                        <FeatherIcon icon="minimize-2" />
                        <span>Import Products</span>
                      </Link>
                    </li>  */}
                  </ul>
                </li>
                <li className="submenu-open">
                  <h6 className="submenu-hdr">Gestion de la production</h6>
                  <ul>
                  {can("mes-productions") && 
                          <li
                          className={pathname.includes("production-list") ? "active" : ""}
                        >
                          <Link
                            className={
                              pathname.includes("production-list") ? "active" : ""
                            }
                            to="/boulangerie/production/production-list"
                          >
                            <i data-feather="shopping-cart" />
                            <FeatherIcon icon="save" />
                            <span>Mes productions</span>
                          </Link>
                        </li>
                  }
                  {can("mes-commandes") && 
                              <li
                              className={
                                pathname.includes("commandes-list") ? "active" : ""
                              }
                            >
                              <Link
                                to="/boulangerie/production/commandes-list"
                                className={
                                  pathname.includes("commande-list") ? "active" : ""
                                }
                              >
                                {/* <i data-feather="file-text" /> */}
                                <FeatherIcon icon="file-text" />
                                <span>Mes commandes</span>
                              </Link>
                            </li>
                  }

                  {can("mes-livraisons") && 
                          <li
                          className={
                            pathname.includes("livraisons-list") ? "active" : ""
                          }
                        >
                          <Link
                            to="/boulangerie/production/livraisons-list"
                            className={
                              pathname.includes("livraisons-list") ? "active" : ""
                            }
                          >
                            {/* <i data-feather="file-text" /> */}
                            <FeatherIcon icon="file-text" />
                            <span>Mes Livraisons</span>
                          </Link>
                        </li>
                  }

                    {can("vente-au-contoirs") && 
                        <li>
                        <Link to="/pos">
                          <FeatherIcon icon="hard-drive" />
                          <span>Vente au contoire</span>
                        </Link>
                      </li>
                    }
                    {can("historique-ventes") && 
                          <li
                          className={
                            pathname.includes("sales-list") ? "active" : ""
                          }
                        >
                          <Link
                            to="/boulangerie/production/sales-list"
                            className={
                              pathname.includes("sales-list") ? "active" : ""
                            }
                          >
                            {/* <i data-feather="file-text" /> */}
                            <FeatherIcon icon="file-text" />
                            <span>Historique des ventes</span>
                          </Link>
                        </li>
                    }
                    {can("mes-ventes-caisse") && 
                    <li
                          className={
                            pathname.includes("sales-caisse-list") ? "active" : ""
                          }
                        >
                          <Link
                            to="/boulangerie/production/sales-caisse-list"
                            className={
                              pathname.includes("sales-caisse-list") ? "active" : ""
                            }
                          >
                            {/* <i data-feather="file-text" /> */}
                            <FeatherIcon icon="file-text" />
                            <span>Mes ventes</span>
                          </Link>
                        </li>
                      }
                    {can("stock-production") && 
                  <li>
                      <Link
                        className={
                          pathname.includes("stock-production") ? "active" : ""
                        }
                        to="/boulangerie/production/stock-production"
                      >
                        <FeatherIcon icon="shopping-cart" />
                        <span>Stock production</span>
                      </Link>
                    </li>
                    }
                   {/* <li>
                      <Link to="/pos">
                        <FeatherIcon icon="hard-drive" />
                        <span>POS</span>
                      </Link>
                    </li>*/}
                     {can("ajustement-retour-produit") && 
                         <li className="submenu">
                         <Link
                           to="#"
                           className={
                             pathname.includes("/boulangerie/production")
                               ? "subdrop active"
                               : "" || isSideMenu == "production"
                               ? "subdrop active"
                               : ""
                           }
                           onClick={() =>
                             toggleSidebar(
                               isSideMenu == "production" ? "" : "production"
                             )
                           }
                         >
                           {" "}
                           <FeatherIcon icon="shuffle" />
                           <span>Gaspillages</span>{" "}
                           <span className="menu-arrow"></span>
                         </Link>
                         {isSideMenu == "production" ? (
                           <ul>
                             <li>
                               <Link
                                 className={
                                   pathname.includes("production-retour-list")
                                     ? "active"
                                     : ""
                                 }
                                 to="/boulangerie/production/production-retour-list"
                               >
                                 Retour
                               </Link>
                             </li>
                             {can('retour-vendu-list') &&
                                  <li>
                                  <Link
                                    className={
                                      pathname.includes("vente-retour-list")
                                        ? "active"
                                        : ""
                                    }
                                    to="/boulangerie/production/vente-retour-list"
                                  >
                                    Vente Retour
                                  </Link>
                                </li>
                             }
                           
                             <li>
                               <Link
                                 className={
                                   pathname.includes("production-ajustement-list")
                                     ? "active"
                                     : ""
                                 }
                                 to="/boulangerie/production/production-ajustement-list"
                               >
                                 Ajustement du stock
                               </Link>
                             </li>
                           </ul>
                         ) : (
                           ""
                         )}
                       </li> 
                     }
                
                    {/* <li className="submenu">
                      <Link
                        to="#"
                        className={
                          pathname.includes("/boulangerie/return")
                            ? "subdrop active"
                            : "" || isSideMenu == "return"
                            ? "subdrop active"
                            : ""
                        }
                        onClick={() =>
                          toggleSidebar(isSideMenu == "return" ? "" : "return")
                        }
                      >
                        {" "}
                        <FeatherIcon icon="corner-up-left" />{" "}
                        <span>Return</span> <span className="menu-arrow"></span>
                      </Link>
                      {isSideMenu == "return" ? (
                        <ul>
                          <li>
                            <Link
                              className={
                                pathname.includes("salesreturnlist-")
                                  ? "active"
                                  : ""
                              }
                              to="/boulangerie/return/salesreturnlist-return"
                            >
                              Sales Return
                            </Link>
                          </li>
                          <li>
                            <Link
                              className={
                                pathname.includes("purchasereturnlist-")
                                  ? "active"
                                  : ""
                              }
                              to="/boulangerie/return/purchasereturnlist-return"
                            >
                              Purchase Return
                            </Link>
                          </li>
                        </ul>
                      ) : (
                        ""
                      )}
                    </li> */}
                  </ul>
                  </li>
                 {/* <li className="submenu-open">
                  <h6 className="submenu-hdr">Purchases</h6>
                  <ul>
                    <li
                      className={
                        pathname.includes("purchaselist-purchase")
                          ? "active"
                          : ""
                      }
                    >
                      <Link
                        className={
                          pathname.includes("purchaselist-") ? "active" : ""
                        }
                        to="/boulangerie/purchase/purchaselist-purchase"
                      >
                    
                        <FeatherIcon icon="shopping-bag" />
                        <span>Purchases</span>
                      </Link>
                    </li>
                    <li
                      className={
                        pathname.includes("importpurchase-purchase")
                          ? "active"
                          : ""
                      }
                    >
                      <Link
                        className={
                          pathname.includes("importpurchase-") ? "active" : ""
                        }
                        to="/boulangerie/purchase/importpurchase-purchase"
                      >
                        <FeatherIcon icon="minimize-2" />
                        <span>Import Purchases</span>
                      </Link>
                    </li>
                    <li
                      className={
                        pathname.includes("purchaseorderreport") ? "active" : ""
                      }
                    >
                      <Link
                        to="/boulangerie/report/purchaseorderreport"
                        className={
                          pathname.includes("purchaseorderreport")
                            ? "active"
                            : ""
                        }
                      >
                        <FeatherIcon icon="file-minus" />
                        <span>Purchase Order</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/boulangerie/return/purchasereturnlist-return">
                        <FeatherIcon icon="refresh-cw" />
                        Purchase Return
                      </Link>
                    </li>
                  </ul>
                </li> */}
                {/* <li className="submenu-open">
                  <h6 className="submenu-hdr">Finance &amp; Dépense</h6>
                  <ul>
                    <li className="submenu">
                      <Link
                        to="#"
                        className={
                          pathname.includes("/boulangerie/expense")
                            ? "subdrop active"
                            : "" || isSideMenu == "expense"
                            ? "subdrop active"
                            : ""
                        }
                        onClick={() =>
                          toggleSidebar(
                            isSideMenu == "expense" ? "" : "expense"
                          )
                        }
                      >
                        <FeatherIcon icon="file-text" />
                        <span>Expense</span>
                        <span className="menu-arrow" />
                      </Link>
                      {isSideMenu == "expense" ? (
                        <ul>
                          <li>
                            <Link
                              className={
                                pathname.includes("expenselist-")
                                  ? "active"
                                  : ""
                              }
                              to="/boulangerie/expense/expenselist-expense"
                            >
                              Expenses
                            </Link>
                          </li>
                          <li>
                            <Link
                              className={
                                pathname.includes("expensecategory-")
                                  ? "active"
                                  : ""
                              }
                              to="/boulangerie/expense/expensecategory-expense"
                            >
                              Expenses Category
                            </Link>
                          </li>
                        </ul>
                      ) : (
                        ""
                      )}
                    </li>
                  </ul> 
                </li>  */}
                              {can("voir-depenses") | can("voir-versement") ?
                                 <li className="submenu-open">
                                 <h6 className="submenu-hdr">Finance &amp; Dépense</h6>
                                 <ul>
                                 {can("voir-depenses") && 
                                      <li className="submenu">
                                      <Link
                                        to="#"
                                        className={
                                          pathname.includes("/boulangerie/depense")
                                            ? "subdrop active"
                                            : "" || isSideMenu == "depense"
                                            ? "subdrop active"
                                            : ""
                                        }
                                        onClick={() =>
                                          toggleSidebar(
                                            isSideMenu == "depense" ? "" : "depense"
                                          )
                                        }
                                      >
                                        <FeatherIcon icon="file-text" />
                                        <span>Depenses</span>
                                        <span className="menu-arrow" />
                                      </Link>
                                      {isSideMenu == "depense" ? (
                                        <ul>
                                          <li>
                                            <Link
                                              className={
                                                pathname.includes("boutiques-depense")
                                                  ? "active"
                                                  : ""
                                              }
                                              to="/boulangerie/finances/depenses"
                                            >
                                              Liste depenses
                                            </Link>
                                          </li>
                                          <li>
                                            <Link
                                              className={
                                                pathname.includes("categories-depense")
                                                  ? "active"
                                                  : ""
                                              }
                                              to="/boulangerie/finances/categories-depense"
                                            >
                                              Catégories depenses
                                            </Link>
                                          </li>
                                        </ul>
                                      ) : (
                                        ""
                                      )}
                                    </li>
                                 }
                               
                                 {can("voir-versement") && 
                                      <li className="submenu">
                                      <Link
                                        to="#"
                                        className={
                                          pathname.includes("/boulangerie/expense")
                                            ? "subdrop active"
                                            : "" || isSideMenu == "expense"
                                            ? "subdrop active"
                                            : ""
                                        }
                                        onClick={() =>
                                          toggleSidebar(
                                            isSideMenu == "expense" ? "" : "expense"
                                          )
                                        }
                                      >
                                        <FeatherIcon icon="pie-chart" />
                                        <span>Encaissement</span>
                                        <span className="menu-arrow" />
                                      </Link>
                                      {isSideMenu == "expense" ? (
                                        <ul>
                                          <li>
                                            <Link
                                              className={
                                                pathname.includes("versement-customers")
                                                  ? "active"
                                                  : ""
                                              }
                                              to="/boulangerie/finances/versement-customers"
                                            >
                                              Encaissement clients
                                            </Link>
                                          </li>
                                          <li>
                                            <Link
                                              className={
                                                pathname.includes("versement-livreurs")
                                                  ? "active"
                                                  : ""
                                              }
                                              to="/boulangerie/finances/versement-livreurs"
                                            >
                                              Encaissement Revendeurs
                                            </Link>
                                          </li>
                                          {can("voir-encaissement-jour") &&
                                          <li>
                                            <Link
                                              className={
                                                pathname.includes("transactions-todays")
                                                  ? "active"
                                                  : ""
                                              }
                                              to="/boulangerie/finances/transactions-todays"
                                            >
                                              Encaissements du jour
                                            </Link>
                                          </li>
                                            }
                                          {can("voir-tous-les-encaissements") &&
                                          <li>
                                            <Link
                                              className={
                                                pathname.includes("transactions-all-listes")
                                                  ? "active"
                                                  : ""
                                              }
                                              to="/boulangerie/finances/transactions-all-listes"
                                            >
                                              Tous les encaissements
                                            </Link>
                                          </li>
                                           }
                                        </ul>
                                      ) : (
                                        ""
                                      )}
                                    </li>
                                 }

                                 </ul>
                               </li> 
                 : ""}

                <li className="submenu-open">
                  <h6 className="submenu-hdr">Gestion des comptes</h6>
                  <ul>
                  {can("gestion-boulangerie") && 
                          <li
                          className={
                            pathname.includes("boulangerie-list") ? "active" : ""
                          }
                        >
                          <Link
                            className={
                              pathname.includes("boulangerie-list") ? "active" : ""
                            }
                            to="/boulangerie/comptes/boulangerie-list"
                          >
                            <FeatherIcon icon="home" />
                            <span>Boulangeries</span>
                          </Link>
                      </li> 
                  }
                {can("gestion-utilisateurs") && 
                      <li
                      className={
                        pathname.includes("users-list") ? "active" : ""
                      }
                    >
                      <Link
                        className={
                          pathname.includes("users-list") ? "active" : ""
                        }
                        to="/boulangerie/comptes/users-list"
                      >
                        <FeatherIcon icon="users" />
                        <span>Utilisateurs</span>
                      </Link>
                    </li>
                }
                {can("gestion-livreurs") && 
                  <li
                  className={
                    pathname.includes("delivries-list") ? "active" : ""
                  }
                >
                  <Link
                    className={
                      pathname.includes("delivries-list") ? "active" : ""
                    }
                    to="/boulangerie/comptes/delivries-list"
                  >
                    {/* <i data-feather="user" /> */}
                    <FeatherIcon icon="user" />
                    <span>Revendeurs</span>
                  </Link>
                </li>
                }

                    {/* <li
                      className={
                        pathname.includes("userlist-people") ? "active" : ""
                      }
                    >
                      <Link
                        className={
                          pathname.includes("userlist-") ? "active" : ""
                        }
                        to="/boulangerie/people/userlist-people"
                      >
                 
                        <FeatherIcon icon="user-check" />
                        <span>Users</span>
                      </Link>
                    </li> */}

                  </ul>
                </li>
                {/* <li className="submenu-open">
                  <h6 className="submenu-hdr">Rapports</h6>
                  <ul>
                    <li
                      className={
                        pathname.includes("salesreport") ? "active" : ""
                      }
                    >
                      <Link
                        to="/boulangerie/report/salesreport"
                        className={
                          pathname.includes("salesreport") ? "active" : ""
                        }
                      >
                        <FeatherIcon icon="bar-chart-2" />
                        <span>Ventes</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/boulangerie/report/purchaseorderreport"
                        className={
                          pathname.includes("purchaseorderreport")
                            ? "active"
                            : ""
                        }
                      >
                        <FeatherIcon icon="pie-chart" />
                        <span>Versements</span>
                      </Link>
                    </li>
                <li
                      className={
                        pathname.includes("inventoryreport") ? "active" : ""
                      }
                    >
                      <Link
                        to="/boulangerie/report/inventoryreport"
                        className={
                          pathname.includes("inventoryreport") ? "active" : ""
                        }
                      >
         
                        <FeatherIcon icon="credit-card" />
                        <span>Inventory Report</span>
                      </Link>
                    </li>
                 <li
                      className={
                        pathname.includes("invoicereport") ? "active" : ""
                      }
                    >
                      <Link
                        to="/boulangerie/report/invoicereport"
                        className={
                          pathname.includes("invoicereport") ? "active" : ""
                        }
                      >
                        <FeatherIcon icon="file" />
                        <span>Invoice Report</span>
                      </Link>
                    </li>
                    <li
                      className={
                        pathname.includes("purchasereport") ? "active" : ""
                      }
                    >
                      <Link
                        to="/boulangerie/report/purchasereport"
                        className={
                          pathname.includes("purchasereport") ? "active" : ""
                        }
                      >
                        <FeatherIcon icon="bar-chart" />
                        <span>Purchase Report</span>
                      </Link>
                    </li>
                    <li
                      className={
                        pathname.includes("supplierreport") ? "active" : ""
                      }
                    >
                      <Link
                        to="/boulangerie/report/supplierreport"
                        className={
                          pathname.includes("supplierreport") ? "active" : ""
                        }
                      >
                        
                        <FeatherIcon icon="database" />
                        <span>Supplier Report</span>
                      </Link>
                    </li>
                    <li
                      className={
                        pathname.includes("customerreport") ? "active" : ""
                      }
                    >
                      <Link
                        to="/boulangerie/report/customerreport"
                        className={
                          pathname.includes("customerreport") ? "active" : ""
                        }
                      >
                   
                        <FeatherIcon icon="pie-chart" />
                        <span>Customer Report</span>
                      </Link>
                    </li>  
                  </ul>
                </li> */}
                 {/* <li className="submenu-open">
                  <h6 className="submenu-hdr">User Management</h6>
                  <ul>
                    <li className="submenu">
                      <Link
                        to="#"
                        className={
                          pathname.includes("/boulangerie/users")
                            ? "subdrop active"
                            : "" || isSideMenu == "Users"
                            ? "subdrop active"
                            : ""
                        }
                        onClick={() =>
                          toggleSidebar(isSideMenu == "Users" ? "" : "Users")
                        }
                      >
                        <FeatherIcon icon="users" />
                        <span>Manage Users</span>{" "}
                        <span className="menu-arrow" />
                      </Link>
                      {isSideMenu == "Users" ? (
                        <ul>
                          <li>
                            <Link
                              to="/boulangerie/users/newuser"
                              className={
                                pathname.includes("newuser") ? "active" : ""
                              }
                            >
                              New User{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/boulangerie/users/userlists"
                              className={
                                pathname.includes("userlists") ? "active" : ""
                              }
                            >
                              Users List
                            </Link>
                          </li>
                        </ul>
                      ) : (
                        ""
                      )}
                    </li>
                  </ul>
                </li>
                <li className="submenu-open">
                  <h6 className="submenu-hdr">Pages</h6>
                  <ul>
                    <li className="submenu">
                      <Link
                        to="#"
                        className={
                          pathname.includes("/boulangerie/authentication")
                            ? "subdrop active"
                            : "" || isSideMenu == "authentication"
                            ? "subdrop active"
                            : ""
                        }
                        onClick={() =>
                          toggleSidebar(
                            isSideMenu == "authentication"
                              ? ""
                              : "authentication"
                          )
                        }
                      >
                        <FeatherIcon icon="shield" />
                        <span>Authentication</span>
                        <span className="menu-arrow" />
                      </Link>
                      {isSideMenu == "authentication" ? (
                        <ul>
                          <li>
                            <Link
                              to="/signIn"
                              className={
                                pathname.includes("signIn") ? "active" : ""
                              }
                            >
                              Log in
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/signUp"
                              className={
                                pathname.includes("signUp") ? "active" : ""
                              }
                            >
                              Register
                            </Link>
                          </li>
                          <li>
                            <Link to="/forgetpassword">Forgot Password</Link>
                          </li>

                        </ul>
                      ) : (
                        " "
                      )}
                    </li>
                    <li className="submenu">
                      <Link
                        to="#"
                        className={
                          isSideMenu == "error pages" ? "subdrop active" : ""
                        }
                        onClick={() =>
                          toggleSidebar(
                            isSideMenu == "error pages" ? "" : "error pages"
                          )
                        }
                      >
                        {" "}
                        <FeatherIcon icon="file-minus" />
                        <span> Error Pages </span>{" "}
                        <span className="menu-arrow" />
                      </Link>
                      {isSideMenu == "error pages" ? (
                        <ul>
                          <li>
                            <Link to="/error-404">404 Error </Link>
                          </li>
                          <li>
                            <Link to="/error-500">500 Error </Link>
                          </li>
                        </ul>
                      ) : (
                        ""
                      )}
                    </li>
                    <li className="submenu">
                      <Link
                        to="#"
                        className={
                          pathname.includes("/boulangerie/places")
                            ? "subdrop active"
                            : "" || isSideMenu == "places"
                            ? "subdrop active"
                            : ""
                        }
                        onClick={() =>
                          toggleSidebar(isSideMenu == "places" ? "" : "places")
                        }
                      >
                        {" "}
                        <FeatherIcon icon="map" />
                        <span>Places</span> <span className="menu-arrow"></span>
                      </Link>
                      {isSideMenu == "places" ? (
                        <ul>
                          <li>
                            <Link
                              className={
                                pathname.includes("countrylist-")
                                  ? "active"
                                  : ""
                              }
                              to="/boulangerie/places/countrylist-places"
                            >
                              Countries
                            </Link>
                          </li>
                          <li>
                            <Link
                              className={
                                pathname.includes("statelist-") ? "active" : ""
                              }
                              to="/boulangerie/places/statelist-places"
                            >
                              States
                            </Link>
                          </li>
                        </ul>
                      ) : (
                        ""
                      )}
                    </li>
                    <li
                      className={pathname.includes("blankpage") ? "active" : ""}
                    >
                      <Link
                        to="/boulangerie/blankpage"
                        onClick={() =>
                          toggleSidebar(isSideMenu == "" ? "" : "")
                        }
                      >
                        <FeatherIcon icon="file" />
                        <span>Blank Page</span>{" "}
                      </Link>
                    </li>
                    <li
                      className={
                        pathname.includes("components") ? "active" : ""
                      }
                    >
                      <Link
                        to="/boulangerie/components"
                        onClick={() =>
                          toggleSidebar(isSideMenu == "" ? "" : "")
                        }
                      >
                        <FeatherIcon icon="pen-tool" />
                        <span>Components</span>{" "}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="submenu-open">
                  <h6 className="submenu-hdr">UI Interface</h6>
                  <ul>
                    <li className="submenu">
                      <Link
                        to="#"
                        className={
                          pathname.includes("/boulangerie/elements")
                            ? "subdrop active"
                            : "" || isSideMenu == "elements"
                            ? "subdrop active"
                            : ""
                        }
                        onClick={() =>
                          toggleSidebar(
                            isSideMenu == "elements" ? "" : "elements"
                          )
                        }
                      >
                        <FeatherIcon icon="box" />
                        <span>Elements </span> <span className="menu-arrow" />
                      </Link>
                      {isSideMenu == "elements" ? (
                        <ul>
                          <li>
                            <Link
                              to="/boulangerie/elements/sweetalerts"
                              className={
                                pathname.includes("sweetalerts") ? "active" : ""
                              }
                            >
                              Sweet Alerts
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/boulangerie/elements/tooltip"
                              className={
                                pathname.includes("tooltip") ? "active" : ""
                              }
                            >
                              Tooltip
                            </Link>
                          </li>
                          <li>
                            <Link
                              className={
                                pathname.includes("popover") ? "active" : ""
                              }
                              to="/boulangerie/elements/popover"
                            >
                              Popover
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/boulangerie/elements/ribbon"
                              className={
                                pathname.includes("ribbon") ? "active" : ""
                              }
                            >
                              Ribbon
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/boulangerie/elements/clipboard"
                              className={
                                pathname.includes("clipboard") ? "active" : ""
                              }
                            >
                              Clipboard
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/boulangerie/elements/drag-drop"
                              className={
                                pathname.includes("drag-drop") ? "active" : ""
                              }
                            >
                              Drag &amp; Drop
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/boulangerie/elements/rangeslider"
                              className={
                                pathname.includes("rangeslider") ? "active" : ""
                              }
                              onClick={(e) =>
                                pageRefresh("elements", "rangeslider")
                              }
                            >
                              Range Slider
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/boulangerie/elements/rating"
                              className={
                                pathname.includes("rating") ? "active" : ""
                              }
                            >
                              Rating
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/boulangerie/elements/toastr"
                              className={
                                pathname.includes("toastr") ? "active" : ""
                              }
                            >
                              Toastr
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/boulangerie/elements/text-editor"
                              className={
                                pathname.includes("text-editor") ? "active" : ""
                              }
                            >
                              Text Editor
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/boulangerie/elements/counter"
                              className={
                                pathname.includes("counter") ? "active" : ""
                              }
                            >
                              Counter
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/boulangerie/elements/scrollbar"
                              className={
                                pathname.includes("scrollbar") ? "active" : ""
                              }
                            >
                              Scrollbar
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/boulangerie/elements/spinner"
                              className={
                                pathname.includes("spinner") ? "active" : ""
                              }
                            >
                              Spinner
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/boulangerie/elements/notification"
                              className={
                                pathname.includes("notification")
                                  ? "active"
                                  : ""
                              }
                            >
                              Notification
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/boulangerie/elements/lightbox"
                              className={
                                pathname.includes("lightbox") ? "active" : ""
                              }
                            >
                              Lightbox
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/boulangerie/elements/stickynote"
                              className={
                                pathname.includes("stickynote") ? "active" : ""
                              }
                            >
                              Sticky Note
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/boulangerie/elements/timeline"
                              className={
                                pathname.includes("timeline") ? "active" : ""
                              }
                            >
                              Timeline
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/boulangerie/elements/form-wizard"
                              className={
                                pathname.includes("form-wizard") ? "active" : ""
                              }
                              onClick={(e) =>
                                pageRefresh("elements", "form-wizard")
                              }
                            >
                              Form Wizard
                            </Link>
                          </li>
                        </ul>
                      ) : (
                        ""
                      )}
                    </li>
                    <li className="submenu">
                      <Link
                        to="#"
                        className={
                          pathname.includes("/boulangerie/charts")
                            ? "subdrop active"
                            : "" || isSideMenu == "Charts"
                            ? "subdrop active"
                            : ""
                        }
                        onClick={() =>
                          toggleSidebar(isSideMenu == "Charts" ? "" : "Charts")
                        }
                      >
                        <FeatherIcon icon="bar-chart-2" />
                        <span> Charts</span> <span className="menu-arrow" />
                      </Link>
                      {isSideMenu == "Charts" ? (
                        <ul>
                          <li>
                            <Link
                              to="/boulangerie/charts/chart-apex"
                              className={
                                pathname.includes("chart-apex") ? "active" : ""
                              }
                            >
                              Apex Charts
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/boulangerie/charts/chart-js"
                              className={
                                pathname.includes("chart-js") ? "active" : ""
                              }
                            >
                              Chart Js
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/boulangerie/charts/chart-morris"
                              className={
                                pathname.includes("chart-morris")
                                  ? "active"
                                  : ""
                              }
                            >
                              Morris Charts
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/boulangerie/charts/chart-flot"
                              className={
                                pathname.includes("chart-flot") ? "active" : ""
                              }
                            >
                              Flot Charts
                            </Link>
                          </li>
                        </ul>
                      ) : (
                        ""
                      )}
                    </li>
                    <li className="submenu">
                      <Link
                        to="#"
                        className={
                          pathname.includes("/boulangerie/icons")
                            ? "subdrop active"
                            : "" || isSideMenu == "Icons"
                            ? "subdrop active"
                            : ""
                        }
                        onClick={() =>
                          toggleSidebar(isSideMenu == "Icons" ? "" : "Icons")
                        }
                      >
                        <FeatherIcon icon="award" />
                        <span> Icons</span> <span className="menu-arrow" />
                      </Link>
                      {isSideMenu == "Icons" ? (
                        <ul>
                          <li>
                            <Link
                              to="/boulangerie/icons/icon-fontawesome"
                              className={
                                pathname.includes("fontawesome") ? "active" : ""
                              }
                            >
                              Fontawesome Icons
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/boulangerie/icons/icon-feather"
                              className={
                                pathname.includes("feather") ? "active" : ""
                              }
                            >
                              Feather Icons
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/boulangerie/icons/icon-ionic"
                              className={
                                pathname.includes("ionic") ? "active" : ""
                              }
                            >
                              Ionic Icons
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/boulangerie/icons/icon-material"
                              className={
                                pathname.includes("material") ? "active" : ""
                              }
                            >
                              Material Icons
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/boulangerie/icons/icon-pe7"
                              className={
                                pathname.includes("icon-pe7") ? "active" : ""
                              }
                            >
                              Pe7 Icons
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/boulangerie/icons/icon-simpleline"
                              className={
                                pathname.includes("simpleline") ? "active" : ""
                              }
                            >
                              Simpleline Icons
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/boulangerie/icons/icon-themify"
                              className={
                                pathname.includes("themify") ? "active" : ""
                              }
                            >
                              Themify Icons
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/boulangerie/icons/icon-weather"
                              className={
                                pathname.includes("weather") ? "active" : ""
                              }
                            >
                              Weather Icons
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/boulangerie/icons/icon-typicon"
                              className={
                                pathname.includes("typicon") ? "active" : ""
                              }
                            >
                              Typicon Icons
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/boulangerie/icons/icon-flag"
                              className={
                                pathname.includes("icon-flag") ? "active" : ""
                              }
                            >
                              Flag Icons
                            </Link>
                          </li>
                        </ul>
                      ) : (
                        ""
                      )}
                    </li>
                    <li className="submenu">
                      <Link
                        to="#"
                        className={
                          pathname.includes("/boulangerie/forms")
                            ? "subdrop active"
                            : "" || isSideMenu == "Forms"
                            ? "subdrop active"
                            : ""
                        }
                        onClick={() =>
                          toggleSidebar(isSideMenu == "Forms" ? "" : "Forms")
                        }
                      >
                        <FeatherIcon icon="edit" />
                        <span> Forms</span> <span className="menu-arrow" />
                      </Link>
                      {isSideMenu == "Forms" ? (
                        <ul>
                          <li>
                            <Link
                              to="/boulangerie/forms/form-basic-inputs"
                              className={
                                pathname.includes("form-basic-inputs")
                                  ? "active"
                                  : ""
                              }
                            >
                              Basic Inputs{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/boulangerie/forms/form-input-groups"
                              className={
                                pathname.includes("form-input-groups")
                                  ? "active"
                                  : ""
                              }
                            >
                              Input Groups{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/boulangerie/forms/form-horizontal"
                              className={
                                pathname.includes("horizontal") ? "active" : ""
                              }
                            >
                              Horizontal Form{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/boulangerie/forms/form-vertical"
                              className={
                                pathname.includes("form-vertical")
                                  ? "active"
                                  : ""
                              }
                            >
                              {" "}
                              Vertical Form{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/boulangerie/forms/form-mask"
                              className={
                                pathname.includes("form-mask") ? "active" : ""
                              }
                            >
                              Form Mask{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/boulangerie/forms/form-validation"
                              className={
                                pathname.includes("validation") ? "active" : ""
                              }
                            >
                              Form Validation{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/boulangerie/forms/form-select"
                              className={
                                pathname.includes("form-select")
                                  ? "active"
                                  : ""
                              }
                            >
                              Form Select2{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/boulangerie/forms/form-fileupload"
                              className={
                                pathname.includes("fileupload") ? "active" : ""
                              }
                            >
                              File Upload{" "}
                            </Link>
                          </li>
                        </ul>
                      ) : (
                        ""
                      )}
                    </li>
                    <li className="submenu">
                      <Link
                        to="#"
                        className={
                          pathname.includes("/boulangerie/table")
                            ? "subdrop active"
                            : "" || isSideMenu == "Table"
                            ? "subdrop active"
                            : ""
                        }
                        onClick={() =>
                          toggleSidebar(isSideMenu == "Table" ? "" : "Table")
                        }
                      >
                        <FeatherIcon icon="layout" />
                        <span> Table</span> <span className="menu-arrow" />
                      </Link>
                      {isSideMenu == "Table" ? (
                        <ul>
                          <li>
                            <Link
                              to="/boulangerie/table/tables-basic"
                              className={
                                pathname.includes("tables-basic")
                                  ? "active"
                                  : ""
                              }
                            >
                              Basic Tables{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/boulangerie/table/data-tables"
                              className={
                                pathname.includes("data-tables") ? "active" : ""
                              }
                            >
                              Data Table{" "}
                            </Link>
                          </li>
                        </ul>
                      ) : (
                        ""
                      )}
                    </li>
                  </ul>
                </li>  */}
                <li className="submenu-open">
                  <h6 className="submenu-hdr">Systèmes</h6>
                  <ul>
                    <li className="submenu">
                      <Link
                        to="#"
                        className={
                          pathname.includes("/boulangerie/settings")
                            ? "subdrop active"
                            : "" || isSideMenu == "Settings"
                            ? "subdrop active"
                            : ""
                        }
                        onClick={() =>
                          toggleSidebar(
                            isSideMenu == "Settings" ? "" : "Settings"
                          )
                        }
                      >
                        {/* <img src={settings} alt="img" /> */}
                        <FeatherIcon icon="settings" />
                        <span> Paramètres</span> <span className="menu-arrow" />
                      </Link>
                      {isSideMenu == "Settings" ? (
                        <ul>
                           {can("info-boulangerie") && 
                              <li>
                              <Link
                                to="/boulangerie/settings/store-info"
                                className={
                                  pathname.includes("store-info")
                                    ? "active"
                                    : ""
                                }
                              >
                                Ma Boulangerie
                              </Link>
                            </li>
                           }

                          {can("voir-unite") && 
                          <li>
                            <Link
                              to="/boulangerie/settings/unites"
                              className={
                                pathname.includes("unites")
                                  ? "active"
                                  : ""
                              }
                            >
                              Unités
                            </Link>
                          </li>}
                          {can("voir-roles") && 
                          <li>
                            <Link
                              to="/boulangerie/settings/roles-list"
                              className={
                                pathname.includes("currencysettings")
                                  ? "active"
                                  : ""
                              }
                            >
                              Rôles
                            </Link>
                          </li>
                          }
                          {can("voir-permissions") && 
                          <li>
                            <Link
                              to="/boulangerie/settings/permissions"
                              className={
                                pathname.includes("permissions") ? "active" : ""
                              }
                            >
                              Permissions
                            </Link>
                          </li>}
                          {can("voir-categorie-fournisseur") &&
                          <li>
                            <Link
                              to="/boulangerie/settings/cat-fournisseur"
                              className={
                                pathname.includes("cat-fournisseur") ? "active" : ""
                              }
                            >
                              Catégorie fournisseur
                            </Link>
                          </li>
                          
                            
                          }
                          {can("paiement-id-param") &&
                            <li>
                              <Link
                                to="/boulangerie/settings/param-payment"
                                className={
                                  pathname.includes("param-payment") ? "active" : ""
                                }
                              >
                                Paiement ID
                              </Link>
                            </li>
                          }
                          {can("log-page") &&
                          <li>
                            <Link
                              to="/boulangerie/settings/logs"
                              className={
                                pathname.includes("logs") ? "active" : ""
                              }
                            >
                              Logs
                            </Link>
                          </li>}

                          {/* <li>
                            <Link
                              to="/boulangerie/settings/grouppermissions"
                              className={
                                pathname.includes("permission") ? "active" : ""
                              }
                            >
                              Permissions
                            </Link>
                          </li> */}
                          {/* <li>
                            <Link
                              to="/boulangerie/settings/taxrates"
                              className={
                                pathname.includes("taxrates") ? "active" : ""
                              }
                            >
                              Tax Rates
                            </Link>
                          </li> */}
                        </ul>
                      ) : (
                        ""
                      )}
                    </li>
                    {/* <li>
                      <Link
                        to="#"
                        onclick={(e)=>logout(e)}
                        className={pathname.includes("signIn") ? "active" : ""}
                      >
                        <FeatherIcon icon="log-out" />
                        <span>Se Deconnecter</span>{" "}
                      </Link>
                    </li> */}
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          </div>
        </Scrollbars>
      </div>
    </>
  );
};

export default withRouter(Sidebar);
