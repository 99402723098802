/* eslint-disable react/prop-types */
import React from 'react'
import { Redirect, Route,Switch } from 'react-router-dom';
import CustomerList from './DeliveryList'
import AddCustomer from './AddDelivery';
import EditCustomer from './EditCustomer';
import AddSupplier from './AddUsers';
import EditSupplier from './EditSupplier';
import UserList from './UserList';
import AddUser from './AddUser';
import EditUser from './EditUser';
import StoreList from './StoreList';
import AddStore from './AddStore';
import EditStore from './EditStore';
import UsersList from './UsersList';
import AddUsers from './AddUsers';
import DeliveryList from './DeliveryList';
import AddDelivery from './AddDelivery';
import EditUsers from './EditUsers';
import EditDelivery from './EditDelivery';

const CompteIndex = ({ match}) =>(
    <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/boulangerie-list`} />
        <Route path={`${match.url}/customerlist-people`} component={CustomerList} />
        <Route path={`${match.url}/addcustomer-people`} component={AddCustomer} />
        <Route path={`${match.url}/editcustomer-people`} component={EditCustomer} />
        {/* <Route path={`${match.url}/supplierlist-people`} component={SupplierList} /> */}
        <Route path={`${match.url}/addsupplier-people`} component={AddSupplier} />
        <Route path={`${match.url}/editsupplier-people`} component={EditSupplier} />
        <Route path={`${match.url}/userlist-people`} component={UserList} />
        <Route path={`${match.url}/adduser-people`} component={AddUser} />
        <Route path={`${match.url}/edituser-people`} component={EditUser} />
        
        {/* MPI */}
        <Route path={`${match.url}/boulangerie-list`} component={StoreList} />
        <Route path={`${match.url}/create-boulangerie`} component={AddStore} />
        <Route path={`${match.url}/edit-boulangerie/:uuid`} component={EditStore} />
        <Route path={`${match.url}/users-list`} component={UsersList} />
        <Route path={`${match.url}/add-users`} component={AddUsers} />
        <Route path={`${match.url}/edit-users/:id/:name`} component={EditUsers} />

        <Route path={`${match.url}/delivries-list`} component={DeliveryList} />
        <Route path={`${match.url}/add-delivery`} component={AddDelivery} />
        <Route path={`${match.url}/edit-delivery/:id/:name`} component={EditDelivery} />



    </Switch>
)

export default CompteIndex