/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Table from "../../EntryFile/datatable";
import { Link } from "react-router-dom";
import Tabletop from "../../EntryFile/tabletop"
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import {
  ClosesIcon,
  Excel,
  Filter,
  Pdf,
  PlusIcon,
  Printer,
  Search,
  search_whites,
  EditIcon,
  DeleteIcon,
  plusCircle,
  EyeIcon,
} from "../../EntryFile/imagePath";
import Swal from "sweetalert2";
import axios from "axios";
import BaseUrl from "../../utils/BaseUrl";
import separateNumber from "../../utils/separateur";
import moment from "moment";
import can from "../../utils/Can";


const url = BaseUrl();
const VersementClients = () => {
  const [inputfilter, setInputfilter] = useState(false);
  const [itemList,setItemList] = useState([]);
  const [loading, setLoading] = useState(false);
 const [excelData,setExcelData] = useState({});

 
useEffect(()=>{
  try {
    axios.get(url.base+'/me',{
             headers:{
                 'Content-Type':'application/json',
                 "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                 
             },
         }).then((resp)=>{

            if(!can(resp.data.data.permissions,"voir-versement") ){
                window.location.href = '/boulangerie/dashboard';
            }

         })
     } catch (error) {
         // setLoading(false);
     }
},[]);


  useEffect(()=>{
    fetcItemList()
  },[]);

  const fetcItemList = async ()=>{
    setLoading(true);
    try {
        axios.get(url.base+'/transactions/versement-customers',{
            headers:{
                'Content-Type':'application/json',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                
            },
        }).then((resp)=>{

            if(resp.status == 200){
                setItemList(resp.data?.data);
                  const transArray = [];
                  resp.data.data.forEach(item => { 
                    transArray.push({
                      "Client":`${item.first_name} ${item.last_name}`,
                      "Téléphone":item.phone,
                      "Commande totale":item.orders_sum_total_amount??0,
                      "Montant à verser":item.orders_sum_total_amount??0,
                      "Total versé":item.transactions_sum_total_amount??0,
                      "Date de creation":moment(item.created_at).format("Do MMMM YYYY HH:mm"),

                  });
                  });
                  setExcelData(transArray)
            }
            setLoading(false);
        })
    } catch (error) {
        setLoading(false);
    }
  }
  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    if (searchValue.trim() === "") {
      fetcItemList();
    } else {
      const filtered = itemList.filter(item =>
        Object.values(item).some(value =>
          typeof value === 'string' && value.toLowerCase().includes(searchValue)
        )
      );
      setItemList(filtered);
    }
  
};

  const options = [
    { id: 1, text: "Choose Product", text: "Choose Product" },
    { id: 2, text: "Macbook pro", text: "Macbook pro" },
    { id: 3, text: "Orange", text: "Orange" },
  ];
  const options2 = [
    { id: 1, text: "Choose Category", text: "Choose Category" },
    { id: 2, text: "Computers", text: "Computers" },
    { id: 3, text: "Fruits", text: "Fruits" },
  ];
  const options3 = [
    { id: 1, text: "Choose Sub Category", text: "Choose Sub Category" },
    { id: 2, text: "Computers", text: "Computers" },
  ];
  const options4 = [
    { id: 1, text: "Brand", text: "Brand" },
    { id: 2, text: "N/D", text: "N/D" },
  ];
  const options5 = [
    { id: 1, text: "Price", text: "Price" },
    { id: 2, text: "150.00", text: "150.00" },
  ];

  const confirmText = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: !1,
    }).then(function (t) {
      t.value &&
        Swal.fire({
          type: "success",
          title: "Deleted!",
          text: "Your file has been deleted.",
          confirmButtonClass: "btn btn-success",
        });
    });
  };
  const togglefilter = (value) => {
    setInputfilter(value);
  };

  const [data] = useState([
    {
      id: 1,
      categoryName: "Nom du Revendeur",
      reference: "34444",
      date: "10 000",
      status: "70 000",
      amount: "550",
      description: "80 000",
    },

  ]);

  const columns = [
    {
      title: "Nom du client",
      className: "text-center",
      dataIndex: "first_name",
      render: (text, record) => (
          <b className="fw-bold">{record.first_name} {record.last_name}</b>
      ),
      sorter: (a, b) => a.first_name.length - b.first_name.length,
    },
    {
      title: "Téléphone du client",
      className: "text-center",
      dataIndex: "phone",
      render: (text, record) => (
          <b className="fw-bold">{record.phone}</b>
      ),
      sorter: (a, b) => a.phone.length - b.phone.length,
    },
    {
      title: "Montant total des commandes",
      className: "text-center",
      dataIndex: "orders_sum_total_amount",
      render:(text, record) => (
        <b className="fw-bold ">{separateNumber(text)}</b>
      ),
      sorter: (a, b) => a.orders_sum_total_amount.length - b.orders_sum_total_amount.length,
    },


    {
      title: "Montant à payer",
      className: "text-center",
      render:(text, record) => (
        <b className="fw-bold text-success">{separateNumber(parseInt(record.orders_sum_total_amount)) }</b>
      ),
    },

    {
      title: "Montant total versé",
      className: "text-center",
      dataIndex: "transactions_sum_total_amount",
      render:(text, record) => (
        <>
                {text == null && <b className="fw-bold text-success">0</b>}
                {text !== null && <b className="fw-bold text-success">{separateNumber(text) }</b>}
        </>

        
      ),
      sorter: (a, b) => a.transactions_sum_total_amount.length - b.transactions_sum_total_amount.length,
    },



    {
      title: "Reste à verser",
      className: "text-center",
      render:(text, record) => (
        <>
                <b className={parseInt(record.orders_sum_total_amount) - record.transactions_sum_total_amount == 0 ? "fw-bold text-warning" : "fw-bold text-danger"}>{separateNumber(parseInt(record.orders_sum_total_amount) - record.transactions_sum_total_amount)  }</b>
        </>

        
      ),
      sorter: (a, b) => a.description.length - b.description.length,
    },
    {
      title: "Action",
      className: "text-center",
      render: (text, record) => (
        <>
          {/* <Link className="btn me-2 btn-warning" to={`/boulangerie/finances/versement-add-customers/${record.id}/${record.first_name.toLowerCase()}`}>
            <i className="fa fa-money"></i>
          </Link> */}
          <Link className="btn btn-secondary" to={`/boulangerie/finances/transactions-customers/${record.id}/${record.first_name.toLowerCase()}`} >
          <i className="fa fa-eye"></i>
          </Link>
        </>
      ),
    },
  ];



  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Liste des Versements clients</h4>
            </div>
            {/* <div className="page-btn">
              <Link
                to="/boulangerie/expense/addexpense-expense"
                className="btn btn-added"
              >
                <img src={PlusIcon} alt="img" className="me-1" />
                Faire un versement
              </Link>
            </div> */}
          </div>
          {/* /product list */}
          <div className="card">
            <div className="card-body">
              <Tabletop inputfilter={inputfilter} togglefilter={togglefilter} excelData={excelData} filename={"versement-clients"} handleSearch={handleSearch}/>
              {/* /Filter */}
              <div
                className={`card mb-0 ${ inputfilter ? "toggleCls" : ""}`}
                id="filter_inputs"
                style={{ display: inputfilter ? "block" :"none"}}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-12 col-sm-12">
                      <div className="row">
                        <div className="col-lg col-sm-6 col-12">
                          <div className="form-group">
                            <Select2
                              className="select"
                              data={options}
                              options={{
                                placeholder: "Choose Product",
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg col-sm-6 col-12">
                          <div className="form-group">
                            <Select2
                              className="select"
                              data={options2}
                              options={{
                                placeholder: "Choose Category",
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg col-sm-6 col-12">
                          <div className="form-group">
                            <Select2
                              className="select"
                              data={options3}
                              options={{
                                placeholder: "Choose Sub Category",
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg col-sm-6 col-12">
                          <div className="form-group">
                            <Select2
                              className="select"
                              data={options4}
                              options={{
                                placeholder: "Brand",
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg col-sm-6 col-12 ">
                          <div className="form-group">
                            <Select2
                              className="select"
                              data={options5}
                              options={{
                                placeholder: "Price",
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-1 col-sm-6 col-12">
                          <div className="form-group">
                            <a className="btn btn-filters ms-auto">
                              <img src={search_whites} alt="img" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
                <Table columns={columns} dataSource={itemList} />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </>
  );
};
export default VersementClients;
