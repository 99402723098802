/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Table from "../../EntryFile/datatable";
import Tabletop from "../../EntryFile/tabletop";
import Swal from "sweetalert2";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import { Link } from "react-router-dom";
import {
  ClosesIcon,
  Excel,
  Filter,
  Pdf,
  PlusIcon,
  Printer,
  Search,
  search_whites,
  EditIcon,
  DeleteIcon,
} from "../../EntryFile/imagePath";
import axios from "axios";
import BaseUrl from "../../utils/BaseUrl";
import Select from "react-select";
import {ToastContainer, toast } from "react-toastify";
import Validation from "../../utils/Validate";
import { Helmet } from "react-helmet";
import ExportExcel from "../../utils/ExcelExport";
import can from "../../utils/Can";


const url = BaseUrl();
const FournisseursPage = () => {
  const [errors,setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [itemData,setItemData] = useState({
    "phone":"",
    "first_name":"",
    "last_name":"",
    "address":"",
    "email":"",
    "company":"",
  });
  const [editItem,setEditItem] = useState(null);

  const [itemList,setItemList] = useState([]);
  const [familles,setFamilles] = useState([]);
  const [optFamille,setOptFamille] = useState(null);
  const [excelData,setExcelData] = useState({});
  const [bakehouses,setBakehouses] = useState([]);
  const [selectBakehouse,setSelectBakehouse] = useState(null);


  useEffect(()=>{
    try {
      axios.get(url.base+'/me',{
               headers:{
                   'Content-Type':'application/json',
                   "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                   
               },
           }).then((resp)=>{
            setBakehouses(resp.data.data.bakehouses)
              if(!can(resp.data.data.permissions,"voir-page-fournisseur") ){
                  window.location.href = '/boulangerie/dashboard';
              }
  
           })
       } catch (error) {
           // setLoading(false);
       }
    fetchFournisseurList();
    fetchFamille();
  },[])


  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    if (searchValue.trim() === "") {
      fetchFournisseurList();
    } else {
      const filtered = itemList.filter(item =>
        Object.values(item).some(value =>
          typeof value === 'string' && value.toLowerCase().includes(searchValue)
        )
      );
      setItemList(filtered);
    }

  };

  const handleChange=(e)=>{
    setItemData({
        ...itemData,
        [e.target.name]:e.target.value
    });
  }



  const optionfamilles = familles.map((opt)=>({
    value: opt.id,
    label:`${opt.name}`
  }));

  const optionbakehouses = bakehouses?.map((option) => ({
    label:`${option.name}`,
    value:option.id
  }));

  const handleChangeFamille=(option)=>{
    setOptFamille(option.value);
  }

  const handleChangeBakehouse=(option)=>{
    setSelectBakehouse(option.value);
  }
  const showModalEdit=(unit)=>{
    setEditItem(unit);
    setOptFamille(unit.famille_id);
    setSelectBakehouse(unit.bakehouse_id);
    setItemData({
      'first_name':unit.first_name,
      'phone':unit.phone,
      'last_name':unit.last_name,
      'address':unit.address,
      'email':unit.email,
      'company':unit.company,
    });

    window.$("#editpayment").modal('show');
  }

  const showModal=()=>{
      setErrors({});
    setItemData({
      'first_name':'',
      'phone':'',
      'last_name':'',
      'address':'',
      'email':'',
      'company':'',
    });
    window.$("#addpayment").modal('show');
  }

  const columns = [
    {
      title: "Nom",
      dataIndex: "first_name",
      className: "text-center",
      render: (text, record) => <div className="text-center">{text}</div>,
      sorter: (a, b) => a.first_name.length - b.first_name.length,
    },
    {
      title: "Prénoms",
      dataIndex: "last_name",
      className: "text-center",
      render: (text, record) => <div className="text-center">{text}</div>,
      sorter: (a, b) => a.last_name.length - b.last_name.length,
    },
    {
      title: "Contact",
      dataIndex: "phone",
      className: "text-center",
      render: (text, record) => <div className="text-center">{text}</div>,
      sorter: (a, b) => a.phone.length - b.phone.length,
    },
    {
      title: "Boulangerie",
      dataIndex: "boulangerie",
      className: "text-center",
      render: (text, record) => <div className="text-center">{text}</div>,
      sorter: (a, b) => a.boulangerie.length - b.boulangerie.length,
    },
    {
      title: "Entreprise",
      dataIndex: "company",
      className: "text-center",
      render: (text, record) => <div className="text-center">{text}</div>,
      sorter: (a, b) => a.company.length - b.company.length,
    },
    {
      title: "Catégorie",
      dataIndex: "category",
      className: "text-center",
      render: (text, record) => <div className="text-center">{text}</div>,
      sorter: (a, b) => a.category.length - b.category.length,
    },
    {
      title: "Adresse",
      dataIndex: "address",
      className: "text-center",
      render: (text, record) => <div className="text-center">{text}</div>,
      sorter: (a, b) => a.address.length - b.address.length,
    },

    {
      title: "Action",
      className: "text-center",
      render: (text, record) => (
        <div className="text-center">
          <button
            className="me-3 btn"
            onClick={()=>showModalEdit(record)}
          >
            <img src={EditIcon} alt="img" />
          </button>
          <button className="me-2 confirm-text btn" onClick={()=>deleteItem(record.id)}>
            <img src={DeleteIcon} alt="img" />
          </button>
        </div>
      ),
      // sorter: (a, b) => a.Action.length - b.Action.length,
    },
  ];

  const fetchFournisseurList = async ()=>{
    setLoading(true);
    try {
        axios.get(url.base+'/suppliers',{
            headers:{
                'Content-Type':'application/json',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                
            },
        }).then((resp)=>{
          setLoading(false);
            if(resp.status == 200){
              const transArray = [];

                setItemList(resp.data.data);
                resp.data.data.forEach(item => { 
                  transArray.push({
                    "Nom":item.first_name,
                    "Prénoms":item.last_name,
                    "Email":item.email,
                    "Téléphone":item.phone,
                    "Entreprise":item.company,
                    "Adresse":item.address,
                    "Date de création":item.created_at,
                });
                });
                setExcelData(transArray)
            }
        })
    } catch (error) {
        setLoading(false);
    }
}

const fetchFamille= async()=> {
  try {
    axios.get(url.base+'/familles',{
             headers:{
                 'Content-Type':'application/json',
                 "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                 
             },
         }).then((resp)=>{

            setFamilles(resp.data.data)

         })
     } catch (error) {
         // setLoading(false);
     }
}

  const submitForm= (e)=>{
    e.preventDefault();

    const pattern_email = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/;

    if(itemData.first_name !="" && optFamille != null && selectBakehouse != null && itemData.phone !="" && (/^\d{9,10}$/).test(itemData.phone) && itemData.last_name !=""){

      const _formData = new FormData();
      _formData.append('first_name',itemData.first_name);
      _formData.append('phone',itemData.phone);
      _formData.append('last_name',itemData.last_name);
      _formData.append('address',itemData.address);
      _formData.append('bakehouse_id',selectBakehouse);

      _formData.append('email',itemData.email);
      _formData.append('company',itemData.company);
      _formData.append('famille_id',optFamille);
  
      setLoading(true);
      try {
          axios.post(url.base+'/suppliers',_formData,
          {
              headers:{
                  'Content-Type':'application/json',
                  "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                  
              },
              // credentials:'include'
          }
          ).then((resp)=>{
              setLoading(false);
              if(resp.status == 200){
  
                  setErrors({});
                  toast.success("Enregistrer avec succès");

                    setItemData({
                      'first_name':'',
                      'phone':'',
                      'last_name':'',
                      'address':'',
                      'email':'',
                      'company':'',
                    });
                    fetchFournisseurList()
              }else{
                setLoading(false);
                toast.error("Echec de l'enregistrement");

              }
          })
      } catch (error) {
          setLoading(false);         
      }
    }else{
      if(itemData.first_name == ""){
        toast.error("Le nom est obligatoire");
      }else if(itemData.last_name == ""){
        toast.error("Le prénoms est obligatoire");
      }else if(selectBakehouse == null){
        toast.error("La boulangerie est obligatoire");
      }else if(optFamille == null){
        toast.error("La catégorie est obligatoire");
      }else if(itemData.phone == ""){
        toast.error("Le numéro de téléphone est obligatoire");
      }else if(!(/^\d{9,10}$/).test(itemData.phone)){
        toast.error("Le numéro de téléphone saisi est invalid");
      }
    }

  }

  const submitFormEdit= (e)=>{
    e.preventDefault();

    const pattern_email = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/;

    if(itemData.first_name !="" && itemData.phone !="" && (/^\d{9,10}$/).test(itemData.phone) && itemData.last_name !="" ){

    const _formData = new FormData();
    _formData.append('first_name',itemData.first_name);
    _formData.append('phone',itemData.phone );
    _formData.append('last_name',itemData.last_name);
    _formData.append('address',itemData.address !== null ? itemData.address :"");
    _formData.append('email',itemData.email !== null ? itemData.email :"");
    _formData.append('company',itemData.company !== null ? itemData.company :"");
    _formData.append('famille_id',optFamille);
    _formData.append('bakehouse_id',selectBakehouse);

    setLoading(true);
    try {
        axios.put(url.base+'/suppliers/'+editItem?.id,_formData,
        {
            headers:{
                'Content-Type':'application/json',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                
            },
            // credentials:'include'
        }
        ).then((resp)=>{
            setLoading(false);
            if(resp.status == 200){

                setErrors({});
                toast.success("Modification effectuée avec succès");
                  setItemData({
                    'first_name':'',
                    'phone':'',
                    'last_name':'',
                    'address':'',
                    'email':'',
                    'company':'',
                  });
                  window.$("#editpayment").modal('hide');
                  fetchFournisseurList()
            }else{
              toast.error("Echec de la modification");
                // Swal.fire({
                //     position: 'top-end',
                //     icon: 'error',
                //     title:  "Echec de la modification",
                //     showConfirmButton: false,
                //     timer: 3000,
                //     toast:true,
                //     position:'top-right',
                //     timerProgressBar:true
                //   });
            }
        })
    } catch (error) {
        setLoading(false);
    }

  }else{
      if(itemData.first_name == ""){
        toast.error("Le nom est obligatoire");
      }else if(itemData.last_name == ""){
        toast.error("Le prénoms est obligatoire");
      }else if(itemData.phone == ""){
        toast.error("Le numéro de téléphone est obligatoire");
      }else if(!(/^\d{9,10}$/).test(itemData.phone)){
        toast.error("Le numéro de téléphone saisi est invalid");
      }
    }
  }

  const deleteItem=(id)=>{
    Swal.fire({
      title: 'ATTENTION !',
      text: 'Êtes-vous sûrs de vouloir supprimer cet élément.',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonText:"NON",
      confirmButtonText: 'OUI',
      cancelButtonColor:"red"
    }).then((result) => {
      if (result.isConfirmed) {
          try {
              axios.delete(url.base+'/suppliers/'+id,
                  {
                      headers:{
                          'Content-Type':'application/json',
                          "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                          
                      },
                  }
              ).then((resp)=>{
                  // console.log(resp.data);
                  if(resp.status == 200)
                  {
                      if(resp.data.status == 600)
                      {
                          Swal.fire(
                              'Attention',
                              'Un problème est subvenu',
                              'error'
                            )
                      }else{
                          toast.success('Fournisseur supprimé avec succès');
                      }
                     
                  }
                  fetchFournisseurList();
              })
          } catch (error) {
              console.log(error);
          }

      
      }
    }); 
  }

  var fileDownload = require('js-file-download');
  const Downloadpdf= (e)=>{
    e.preventDefault();



    setLoading(true);
    try {
        axios.get(url.base+'/export-supplier-pdf',
        {
            headers:{
                'Content-Type':'application/pdf',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`,
                
            },
            responseType:'blob',
            // credentials:'include'
        }
        ).then((response)=>{
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'fournisseurs.pdf');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
    } catch (error) {
        setLoading(false);
// console.log(error);  
    }
  }
  return (
    <>
      <Helmet>
        <title>Fournisseurs</title>
        <meta name="description" content="Fournisseurs page" />
      </Helmet>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Gestion des Fournisseurs</h4>
              {/* <h6>Manage Payment Settings</h6> */}
            </div>
            <div className="page-btn">
              <a
                className="btn btn-added"
            onClick={()=>showModal()}
              >
                <img src={PlusIcon} alt="img" className="me-2" />
                Ajouter un fournisseur
              </a>
            </div>
          </div>
          {/* /product list */}
          <div className="card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      className="form-control form-control-sm search-icon"
                      type="search"
                      placeholder="Recherche..."
                      onChange={handleSearch}
                    />
                    <a className="btn btn-searchset">
                      <img src={Search} alt="img" />
                    </a>
                  </div>
                </div>
                <div className="wordset">
                  <ul>
                    <li>
                      <a onClick={(e)=>Downloadpdf(e)}
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="pdf"
                      >
                        <img src={Pdf} alt="img" />
                      </a>
                    </li>
                    <li>
                      
                    </li>
                    {/* <li>
                      <a
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="print"
                      >
                        <img src={Printer} alt="img" />
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={itemList}
                  rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      {/* add Modal */}
      <div
        className="modal fade"
        id="addpayment"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Ajouter fournisseur</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <form onSubmit={submitForm}>
              <div className="modal-body">
              
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label>Nom <span className="text-danger">*</span></label>
                      <input type="text" name="first_name" onChange={handleChange} value={itemData.first_name}/>
                      {errors && errors.first_name && (
                            <span className="text-danger">{errors.first_name}</span>
                        )}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label>Prénoms<span className="text-danger">*</span></label>
                      <input type="text" name="last_name" onChange={handleChange} value={itemData.last_name}/>
                      {errors && errors.last_name && (
                            <span className="text-danger">{errors.last_name}</span>
                        )}
                    </div>
                  </div>
                  <div className="col-6 mb-3">
                      <label>Boulangerie</label>
                      <Select options={optionbakehouses} onChange={handleChangeBakehouse}/>
                  </div>
                  <div className="col-6 mb-3">
                      <label>Catégorie</label>
                      <Select options={optionfamilles} onChange={handleChangeFamille}/>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label>Email</label>
                      <input type="email" name="email" className="form-control" onChange={handleChange} value={itemData.email}/>
                     
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label>Adresse</label>
                      <input type="text" name="address" onChange={handleChange} value={itemData.address}/>
                     
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label>Téléphone<span className="text-danger">*</span></label>
                      <input type="text" name="phone" onChange={handleChange} value={itemData.phone}/>
                      {errors && errors.phone && (
                            <span className="text-danger">{errors.phone}</span>
                        )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label>Entreprise</label>
                      <input type="text" name="company" onChange={handleChange} value={itemData.company}/>
                      
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer ">
              {loading &&
                  <button className="btn btn-submit" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        En chargement...
                    </button>
                }
                {!loading &&
                  <button type="submit" className="btn btn-submit">
                    Enregister
                  </button>
                }
                <button
                  type="button"
                  className="btn btn-cancel"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </form>
          
          </div>
        </div>
      </div>
      {/* edit Modal */}
      <div
        className="modal fade"
        id="editpayment"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Editer fournisseur</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <form onSubmit={submitFormEdit}>
              <div className="modal-body">
              
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label>Nom <span className="text-danger">*</span></label>
                      <input type="text" name="first_name" onChange={handleChange} value={itemData.first_name}/>
                      {errors && errors.first_name && (
                            <span className="text-danger">{errors.first_name}</span>
                        )}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label>Prénoms<span className="text-danger">*</span></label>
                      <input type="text" name="last_name" onChange={handleChange} value={itemData.last_name}/>
                      {errors && errors.last_name && (
                            <span className="text-danger">{errors.last_name}</span>
                        )}
                    </div>
                  </div>
                  <div className="col-6 mb-3">
                      <label>Boulangerie</label>
                      <Select 
                      value={optionbakehouses.find(option1 => option1.value === selectBakehouse)}
                      options={optionbakehouses} 
                      defaultValue={[{label: selectBakehouse === selectBakehouse ? optionbakehouses.find(obj1 => obj1.value === selectBakehouse) : ""}]}
                      onChange={handleChangeBakehouse}/>
                  </div>  
                  <div className="col-6 mb-3">
                      <label>Catégorie</label>
                      <Select 
                      value={optionfamilles.find(option => option.value === optFamille)}
                      options={optionfamilles} 
                      defaultValue={[{label: optFamille == optFamille ? optionfamilles.find(obj => obj.value === optFamille) : ""}]}
                      onChange={handleChangeFamille}/>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label>Email<span className="text-danger">*</span></label>
                      <input type="email" name="email" className="form-control" onChange={handleChange} value={itemData.email}/>
                     
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label>Adresse<span className="text-danger">*</span></label>
                      <input type="text" name="address" onChange={handleChange} value={itemData.address}/>
                     
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label>Téléphone<span className="text-danger">*</span></label>
                      <input type="text" name="phone" onChange={handleChange} value={itemData.phone}/>
                      
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label>Entreprise</label>
                      <input type="text" name="company" onChange={handleChange} value={itemData.company}/>
                    
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer ">
              {loading &&
                  <button className="btn btn-submit" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        En chargement...
                  </button>
                }
                {!loading &&
                  <button type="submit" className="btn btn-submit">
                    Enregister
                  </button>
                }
                <button
                  type="button"
                  className="btn btn-cancel"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </form>
          
          </div>
        </div>
      </div>
    </>
  );
};
export default FournisseursPage;
