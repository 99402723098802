/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Table from "../../EntryFile/datatable";
import Tabletop from "../../EntryFile/tabletop";
import Swal from "sweetalert2";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import { Link } from "react-router-dom";
import {
  ClosesIcon,
  Excel,
  Filter,
  Pdf,
  PlusIcon,
  Printer,
  Search,
  search_whites,
  EditIcon,
  DeleteIcon,
} from "../../EntryFile/imagePath";
import axios from "axios";
import BaseUrl from "../../utils/BaseUrl";
import Select from "react-select";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ExportExcel from "../../utils/ExcelExport";
import can from "../../utils/Can";

const url = BaseUrl();
const ProductBakeHouses = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();


  const [itemList,setItemList] = useState([]);
  const [excelData,setExcelData] = useState({});


  useEffect(()=>{
    try {
      axios.get(url.base+'/me',{
               headers:{
                   'Content-Type':'application/json',
                   "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                   
               },
           }).then((resp)=>{

              if(!can(resp.data.data.permissions,"gestion-produit") ){
                  window.location.href = '/boulangerie/dashboard';
              }
  
           })
       } catch (error) {
           // setLoading(false);
       }
  },[]);

  useEffect(()=>{
    fetchProductsList()
  },[])

  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    if (searchValue.trim() === "") {
      fetchProductsList();
    } else {
      const filtered = itemList.filter(item =>
        Object.values(item).some(value =>
          typeof value === 'string' && value.toLowerCase().includes(searchValue)
        )
      );
      setItemList(filtered);
    }
  
  };


  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      className: "text-center",
      render: (text, record) => <img src={`${url.image+text}`} width={50} height={50} className="rounded"/>,
      sorter: (a, b) => a.image.length - b.image.length,
    },
    {
      title: "Libelle",
      dataIndex: "name",
      className: "text-center",
      render: (text, record) => <div className="text-center">{text}</div>,
      sorter: (a, b) => a.name.length - b.name.length,
    },
   
    {
      title: "Prix unitaire",
      dataIndex: "price",
      className: "text-center",
      render: (text, record) => <div className="text-center">{text}</div>,
      sorter: (a, b) => a.price.length - b.price.length,
    },

    {
      title: "Boulangerie",
      dataIndex: "bakehouse",
      className: "text-center",
      render: (text, record) => <div className="text-center">{record.bakehouse?.name}</div>,
      sorter: (a, b) => a.bakehouse.length - b.bakehouse.length,
    },

    {
      title: "Catégorie",
      dataIndex: "type",
      render: (text, record) => (
        <>
          {text == 1 &&
            <div>Produit de vente</div>
          }
          {text == 0 &&
            <div>Matière première</div>
          }
        </>
      ),
      sorter: (a, b) => a.type.length - b.type.length,
    },
    {
      title: "Stock alert",
      dataIndex: "stock_alert",
      className: "text-center",
      render: (text, record) => <div className="text-center">{text}</div>,
      sorter: (a, b) => a.stock_alert.length - b.stock_alert.length,
    },
    // {
    //   title: "Statut",
    //   dataIndex: "is_deleted",
    //   render: (text, record) => (
    //     <>
    //       <div className="status-toggle d-flex justify-content-between align-items-center">
    //         <input
    //           type="checkbox"
    //           id="user1"
    //           className="check"
    //           defaultChecked={text==0 ? text = 1 : text = 0}
    //         />
    //         <label htmlFor="user1" className="checktoggle">
    //           checkbox
    //         </label>
    //       </div>
    //     </>
    //   ),
    //   sorter: (a, b) => a.is_deleted.length - b.is_deleted.length,
    // },
    {
      title: "Action",
      className: "text-center",
      render: (text, record) => (
        <div className="text-center">
          <Link
            className="me-3 btn"
            to={`/boulangerie/transfer/edit-product-house/${record.uuid}`}
          >
            <img src={EditIcon} alt="img" />
          </Link>
          {/* <button className="me-2 confirm-text btn" onClick={()=>deleteItem(record.id)}>
            <img src={DeleteIcon} alt="img" />
          </button> */}
        </div>
      ),
      // sorter: (a, b) => a.Action.length - b.Action.length,
    },
  ];

  const fetchProductsList = async ()=>{
    setLoading(true);
    try {
        axios.get(url.base+'/products-procurement',{
            headers:{
                'Content-Type':'application/json',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                
            },
        }).then((resp)=>{

            if(resp.status == 200){
                setItemList(resp.data.data);

                const transArray = [];
                resp.data.data.forEach(item => { 
                  transArray.push({
                    "Nom du produit":item.name,
                    "Prix unitaire":item.price,
                    "Catégorie":item.type,
                    "Stock alert":item.stock_alert,
                    "Date de création":item.created_at,
                });
                });
                setExcelData(transArray)
            }
            setLoading(false);
        })
    } catch (error) {
        setLoading(false);
    }
}

  const deleteItem=(id)=>{
    Swal.fire({
      title: 'ATTENTION !',
      text: 'Êtes-vous sûrs de vouloir supprimer cet élément.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonText:"NON",
      confirmButtonText: 'OUI',
      cancelButtonColor:"red"
    }).then((result) => {
      if (result.isConfirmed) {
          try {
              axios.delete(url.base+'/products/'+id,
                  {
                      headers:{
                          'Content-Type':'application/json',
                          "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                          
                      },
                  }
              ).then((resp)=>{
                  // console.log(resp.data);
                  if(resp.status == 200)
                  {
                      if(resp.data.status == 600)
                      {
                          Swal.fire(
                              'Attention',
                              'Un problème est subvenu',
                              'error'
                            )
                      }else{
                          Swal.fire(
                              'Supprimé',
                              'Unité supprimé avec succès',
                              'success'
                            )
                      }
                     
                  }
                  fetchProductsList();
              })
          } catch (error) {
              console.log(error);
          }

      
      }
    }); 
  }


  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Gestion des produits</h4>
              {/* <h6>Manage Payment Settings</h6> */}
            </div>
            <div className="page-btn">
              <Link className="btn btn-added" to="/boulangerie/transfer/add-product-house" >
                <img src={PlusIcon} alt="img" className="me-2" />
                Ajouter
              </Link>
            </div>
          </div>
          {/* /product list */}
          <div className="card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      className="form-control form-control-sm search-icon"
                      type="search"
                      placeholder="Recherche..."
                      onChange={handleSearch}
                    />
                    <a className="btn btn-searchset">
                      <img src={Search} alt="img" />
                    </a>
                  </div>
                </div>
                <div className="wordset">
                  <ul>
                    {/* <li>
                      <a
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="pdf"
                      >
                        <img src={Pdf} alt="img" />
                      </a>
                    </li> */}
                    <li>
                    <ExportExcel excelData={excelData} filename={"Product_matiere"}/>
                    </li>
                    {/* <li>
                      <a
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="print"
                      >
                        <img src={Printer} alt="img" />
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={itemList}
                  rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>

    </>
  );
};
export default ProductBakeHouses;
