/* eslint-disable no-unused-vars */
/* eslint-disable no-dupe-keys */
import React, { useEffect, useState } from 'react'
import { Upload } from '../../EntryFile/imagePath';
import Select2 from 'react-select2-wrapper';
import 'react-select2-wrapper/css/select2.css';
import Select from 'react-select';
import axios from 'axios';
import BaseUrl from '../../utils/BaseUrl';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import can from '../../utils/Can';
// import { useNavigate} from 'react-router-dom';


const url = BaseUrl();
const AddProductBoulangerie = () => {

  const [errors,setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [itemData,setItemData] = useState({
    "name":"",
    "stock_alert":"10",
    "price":"0",
    "quantity":"0",
    "image":""
  });
  const [editItem,setEditItem] = useState(null);
  const [itemList,setItemList] = useState([]);
  const [units,setUnits] = useState([]);
  const [optunits,setOptUnits] = useState(null);
  const [optcat,setOptCat] = useState();
  // const navigate = useNavigate();
  const history = useHistory();
  const [bakehouses,setBakehouses] = useState([]);
  const [selectBakehouse,setSelectBakehouse] = useState(null);
  useEffect(()=>{
    try {
      axios.get(url.base+'/me',{
               headers:{
                   'Content-Type':'application/json',
                   "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                   
               },
           }).then((resp)=>{
            setBakehouses(resp.data.data.bakehouses)
              if(!can(resp.data.data.permissions,"gestion-produit") ){
                  window.location.href = '/boulangerie/dashboard';
              }
  
           })
       } catch (error) {
           // setLoading(false);
       }
  },[]);


  useEffect(()=>{
    try {
      axios.get(url.base+'/units',{
          headers:{
              'Content-Type':'application/json',
              "Authorization": `Bearer ${localStorage.getItem('_token_')}`
              
          },
      }).then((resp)=>{

          if(resp.status == 200){
            setUnits(resp.data.data);
          }
          // setLoading(false);
      })
  } catch (error) {
      // setLoading(false);
  }
  },[]);

  const optionunits = units.map((opt)=>({
    value: opt.id,
    label:`${opt.name}`
  }));

  const categories =[
    {value: 1, label: "Produit à vendre"},
    {value: 0, label: "Matière prémière"},
  ]

  const handleChange=(e)=>{
    setItemData({
        ...itemData,
        [e.target.name]:e.target.value
    });
  }


  const handleFileChange=(event)=>{
    setItemData({
        ...itemData,
        [event.target.name]:event.target.files[0]
    });
    }
const handleChangeUnits=(option)=>{
  setOptUnits(option.value);
}

const handleChangeCat=(option)=>{
    setOptCat(option.value);
}

const optionbakehouses = bakehouses?.map((option) => ({
    label:`${option.name}`,
    value:option.id
  }));
  const handleChangeBakehouse=(option)=>{
    setSelectBakehouse(option.value);
  }


const submitForm= (e)=>{
  e.preventDefault();

  if(itemData.name !=="" && optunits !== null){
    const _formData = new FormData();
    _formData.append('name',itemData.name);
    _formData.append('stock_alert',itemData.stock_alert);
    _formData.append('unit_id',optunits != null ? optunits : "");
    _formData.append('price',itemData.price);
    _formData.append('quantity',itemData.quantity);
    _formData.append('type',0);
    _formData.append('bakehouse_id',selectBakehouse);
    
    if(itemData.image !=""){
      const currentTimeInSeconds = Math.floor(new Date().getTime() / 1000);
      const originalExtension = itemData.image.name.split('.').pop();
      const newFileName = `${currentTimeInSeconds}_image_.${originalExtension}`;
      const photo = new File([itemData.image], newFileName, { type: itemData.image.type });
      
      _formData.append("image",photo);
      console.log(_formData);
      setLoading(true);
      try {
          axios.post(url.base+'/products',_formData,
          {
              headers:{
                  'Content-Type':'multipart/form-data',
                  "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                  
              },
              // credentials:'include'
          }
          ).then((resp)=>{
              setLoading(false);
              if(resp.status == 200){
    
                  setErrors({});
                  toast.success("Enregistrer avec succès");
                //   Swal.fire({
                //       position: 'top-end',
                //       icon: 'success',
                //       title:  "Enregistrer avec succès",
                //       showConfirmButton: false,
                //       timer: 3000,
                //       toast:true,
                //       position:'top-right',
                //       timerProgressBar:true
                //     });
                    history.push('/boulangerie/transfer/produits-boulangerie')
              }else{
                toast.error("Echec de l'enregistrement");
                //   Swal.fire({
                //       position: 'top-end',
                //       icon: 'error',
                //       title:  "Echec de l'enregistrement",
                //       showConfirmButton: false,
                //       timer: 3000,
                //       toast:true,
                //       position:'top-right',
                //       timerProgressBar:true
                //     });
              }
          }).catch((error)=>{  
            // console.log(error.response);              
              setLoading(false);
    
              if (error.response.status == 422) {
                  setErrors(error.response.data.errors);
              }
              // console.log(errors);
          })
      } catch (error) {
          setLoading(false);
    // console.log(error);  
      }
    }else{
        toast.error("L'image du produit est obligatoire");
    }
  

  }else{
    if(itemData.name =="" ){toast.error("Nom du produit est obligatoire");} 
    if(optunits == null){toast.error("Le champs unité est vide");} 
   
  }

}
    return (
        <>
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="page-title">
                        <h4>Ajouter une matière primière </h4>
                        {/* <h6>Create new product</h6> */}
                    </div>
                </div>
                {/* /add */}
                <div className="card">
                    <div className="card-body">
                        <form className="row" onSubmit={submitForm}>
                            <div className="col-lg-6 col-sm-12 col-6">
                                <div className="form-group">
                                    <label>Nom du produit</label>
                                    <input type="text" name='name' onChange={handleChange} value={itemData.name}/>
                                    {errors && errors.name && (
                                        <span className="text-danger">{errors.name[0]}</span>
                                    )}
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-6 col-12 mb-3">
                                    <label className='mb-2'>Boulangerie</label>
                                    <Select
                                        className="select"
                                        options={optionbakehouses}
                                        onChange={handleChangeBakehouse}
                                        placeholder="Choisir ..."
                                        />
                                       
                                
                            </div>

                            <div className="col-lg-6 col-sm-6 col-12">
                                <div className="form-group">
                                    <label>Prix {"d'achat"}(facultatif)</label>
                                    <input type="text" name='price' onChange={handleChange} value={itemData.price}/>
                                    {errors && errors.price && (
                                            <span className="text-danger">{errors.price[0]}</span>
                                        )}
                                </div>
                            </div>
                            
                            <div className="col-lg-6 col-sm-6 col-12">
                                <div className="form-group">
                                    <label>Stock alert</label>
                                    <input type="text" name='stock_alert' onChange={handleChange} value={itemData.stock_alert}/>
                                    {errors && errors.stock_alert && (
                                            <span className="text-danger">{errors.stock_alert[0]}</span>
                                        )}
                                </div>
                            </div>
                                        <div className="col-lg-6 col-sm-6 col-12 mb-3">
                                    <label className='mb-2'>Unité</label>
                                    <Select
                                        className="select"
                                        options={optionunits}
                                        onChange={handleChangeUnits}
                                        placeholder="Choisir ..."
                                        />
                                        {errors && errors.sous_famille_id && (
                                            <span className="text-danger">{errors.sous_famille_id[0]}</span>
                                        )}
                                
                            </div>
                            <div className="col-lg-6 col-sm-6 col-12">
                                <div className="form-group">
                                    <label>Quantité</label>
                                    <input type="text" name='quantity' onChange={handleChange} value={itemData.quantity}/>
                                    {errors && errors.quantity && (
                                            <span className="text-danger">{errors.quantity[0]}</span>
                                        )}
                                </div>
                            </div>
                            


                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label> Image du produit </label>
                                    <div className="image-upload">
                                        <input type="file" name='image' onChange={handleFileChange}/>
                                        <div className="image-uploads">
                                            
                                            <h4>Associer une image au produit</h4>
                                        </div>
                                        {itemData.image &&  <img src={itemData.image ? URL.createObjectURL(itemData.image): null} width="20%" height="30%" className='border'/>} 
                                    </div>
                                    {errors && errors.image && (
                                            <span className="text-danger">{errors.image[0]}</span>
                                        )}
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <button className="btn btn-submit me-2" type='submit'>
                                    Enregistrer
                                </button>
                                <button className="btn btn-cancel">
                                    Retour
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                {/* /add */}
            </div>
        </div>
        </>
    )
}
export default AddProductBoulangerie;