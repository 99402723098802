/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AvocatImage,
  Dash1,
  Dash2,
  Dash3,
  Dash4,
  Dropdown,
  OrangeImage,
  PineappleImage,
  EarpodIcon,
  StawberryImage,
  IphoneIcon,
  SamsungIcon,
  MacbookIcon,
} from "../EntryFile/imagePath";
import Table from "../EntryFile/datatables"
import Chart from "react-apexcharts";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import CountUp from "react-countup";
import { Helmet } from "react-helmet";
import RightSideBar from "../components/rightSidebar";
import axios from "axios";
import BaseUrl from "../utils/BaseUrl";
import separateNumber from "../utils/separateur";
import can from "../utils/Can";
import Select from 'react-select';
import { toast } from "react-toastify";
// import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";



const url = BaseUrl();

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [depenseTotale,setDepenseTotale] = useState(0);
  const [venteTotale,setventeTotale] = useState(0);
  const [EncaisseTotale,setEncaisseTotale] = useState(0);
  const [ReliquatTotale,setReliquatTotale] = useState(0);

  const [customerTotal,setCustomerTotal] = useState(0);
  const [supplierTotale,setsupplierTotale] = useState(0);
  const [deliveryTotale,setdeliveryTotale] = useState(0);
  const [technicalSheetTotale,setechnicalSheetTotale] = useState(0);
  const [spendProduct,setSpendProduct] = useState(0);
  const [indirectSpend,setIndirectSpend] = useState(0);

  const [productionTotal,setProductionTotal] = useState(0);

  const [deliverSerie,setdeliverSerie] = useState({series: [], labels: []});
  const [returnDeliverySerie,setreturnDeliverySerie] = useState({series: [], labels: []});
  const [permissions,setPermissions] = useState();

  const [stockList,setStockList] = useState([]);
  const [stockListTwo,setStockListTwo] = useState([]);
  const [livreurList,setLivreurList] = useState([]);
  const [DepenseList,setDepenseList] = useState([]);
  const [statsToday,setStatsToday] = useState([]);
  const [deliveryByDay,setDeliveryByDay] = useState([]);
  const [permissionTab,setPermissionTab] = useState([]);
  const [productionLiist,setProductionList] = useState([]);

  const [delivriesLiist,setDeliveriesList] = useState([]);

  const [bakehouses,setBakehouses] = useState([]);
  const [selectBakehouse,setSelectBakehouse] = useState(null);

  const [bakhouseData,setBakehouseData] = useState("");
  const [beginData,setBeginData] = useState("");
  const [endData,setEndData] = useState("");

  const [loadingDashboard,setLoadingDashboard] = useState(false);
  const [activeBtn,setActiveBtn] = useState(false);


  const expiredProductColumns = [
    {
      title: "Désignation",
      dataIndex:"designation",
      sorter: (a, b) => a.designation.length - b.designation.length,
    },
    {
      title: "Montant",
      dataIndex: "chiffre",
      className: "text-center",
      render: (text, record) => (
        <b className="fw-bold text-success"> {separateNumber(parseInt(text))} FCFA</b>
         
      ),
      sorter: (a, b) => a.chiffre.length - b.chiffre.length,
    },


  ];

  const recentDataColumns = [
    {
      title: "SNo",
      dataIndex:"id",
      sorter: (a, b) => a.id.length - b.id.length,
    },
    {
      title: "Products",
      dataIndex: "products",
      render: (text, record) => (
        <div className="productimgname">
          <Link to="#" className="product-img">
            <img alt="" src={record.image} />
          </Link>
          <Link to="#" style={{ fontSize: "14px" }}>
            {record.products}
          </Link>
        </div>
      ),
      sorter: (a, b) => a.products.length - b.products.length,
      width: "250px",
    },
    {
      title: "Price",
      dataIndex: "price",
      render: (text, record) => <div style={{ fontSize: "14px" }}>{text}</div>,
      sorter: (a, b) => a.price.length - b.price.length,
    },
  ];

  const stockProductColumn = [
    {
      title: "Nom du produit",
      // className: "text-center",
      dataIndex: "product_name",
      render: (text, record) => (
        <div className="productimgname">
      
            <img src={`${url.image+record.image}`} width={50} height={50} className="rounded"/>
    
          <Link to="#" style={{ fontSize: "15px", marginLeft: "10px" }}>
            {record.product_name} 
          </Link>
        </div>
      ),
      sorter: (a, b) => a.productName.length - b.productName.length,
    },
    {
      title: "Boulangerie",
      dataIndex: "bakehouse",
      className: "text-center",
      render: (text, record) => (
        <>
              <b className="text-center fw-bold">{text}</b> 
        </>
      ),
      sorter: (a, b) => a.bakehouse.length - b.bakehouse.length,
    },
    {
      title: "Stock",
      dataIndex: "quantity",
      className: "text-center",
      render: (text, record) => (
        <>
              <b className={text > 10 ? "text-center fw-bold text-success" : "text-center fw-bold text-danger"}>{separateNumber(text)}</b> {record.unit_name}
        </>
      ),
      sorter: (a, b) => a.quantity.length - b.quantity.length,
    },
    {
      title: "Stock retours",
      dataIndex: "quantity_return",
      className: "text-center",
      render: (text, record) => (
        <>
              <b className="text-center fw-bold text-danger">{text}</b> 
        </>
      ),
      sorter: (a, b) => a.quantity_return.length - b.quantity_return.length,
    },

  ];

  const LivraisonProductColumn = [
    {
      title: "Nom du produit",
      // className: "text-center",
      dataIndex: "name",
      render: (text, record) => (
        <div className="productimgname">
      
            <img src={`${url.image+record.image}`} width={50} height={50} className="rounded"/>
    
          <Link to="#" style={{ fontSize: "15px", marginLeft: "10px" }}>
            {record.name} 
          </Link>
        </div>
      ),
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Boulangerie",
      dataIndex: "bakehouse",
      className: "text-center",
      render: (text, record) => (
        <>
              <b className="text-center fw-bold">{text}</b> 
        </>
      ),
      sorter: (a, b) => a.bakehouse.length - b.bakehouse.length,
    },
    {
      title: "Quantités",
      dataIndex: "quantity",
      className: "text-center",
      render: (text, record) => (
        <>
              <b className="text-center fw-bold text-danger">{separateNumber(text)}</b> 
        </>
      ),
      sorter: (a, b) => a.quantity.length - b.quantity.length,
    },

    // {
    //   title: "Montant",
    //   dataIndex: "price",
    //   className: "text-center",
    //   render: (text, record) => (
    //     <>
    //           <b className="text-center fw-bold text-danger">{separateNumber(text*record.quantity)}</b> 
    //     </>
    //   ),
    //   sorter: (a, b) => a.price.length - b.price.length,
    // },

  ];

  const ProductionColumn = [
    {
      title: "Nom du produit",
      // className: "text-center",
      dataIndex: "name",
      render: (text, record) => (
        <div className="productimgname">
      
            <img src={`${url.image+record.image}`} width={50} height={50} className="rounded"/>
    
          <Link to="#" style={{ fontSize: "15px", marginLeft: "10px" }}>
            {record.name} 
          </Link>
        </div>
      ),
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Boulangerie",
      dataIndex: "bakehouse",
      className: "text-center",
      render: (text, record) => (
        <>
              <b className="text-center fw-bold">{text}</b> 
        </>
      ),
      sorter: (a, b) => a.bakehouse.length - b.bakehouse.length,
    },
    {
      title: "Quantités",
      dataIndex: "quantity",
      className: "text-center",
      render: (text, record) => (
        <>
              <b className="text-center fw-bold text-danger">{text}</b> 
        </>
      ),
      sorter: (a, b) => a.quantity.length - b.quantity.length,
    },

    {
      title: "Montant total",
      dataIndex: "amount",
      className: "text-center",
      render: (text, record) => (
        <>
              <b className="text-center fw-bold text-danger">{separateNumber(text)} FCFA</b> 
        </>
      ),
      sorter: (a, b) => a.amount.length - b.amount.length,
    },

  ];

  const versementLivreurColumn = [
    {
      title: "Nom du Revendeur",
      className: "text-center",
      dataIndex: "first_name",
      render: (text, record) => (
          <b className="fw-bold">{record.first_name} {record.last_name}</b>
      ),
      sorter: (a, b) => a.first_name.length - b.first_name.length,
    },
    {
      title: "Téléphone",
      className: "text-center",
      dataIndex: "phone",
      render: (text, record) => (
          <b className="fw-bold">{record.phone}</b>
      ),
      sorter: (a, b) => a.phone.length - b.phone.length,
    },
    // {
    //   title: "Montant total des livraisons",
    //   className: "text-center",
    //   dataIndex: "livraisons_sum_total_amount",
    //   render:(text, record) => (
    //     <b className="fw-bold ">{separateNumber(text??0)}</b>
    //   ),
    //   sorter: (a, b) => a.livraisons_sum_total_amount.length - b.livraisons_sum_total_amount.length,
    // },
    // {
    //   title: "Montant total des retours",
    //   className: "text-center",
    //   dataIndex: "retours_sum_total_amount",
    //   render:(text, record) => (
    //     <b className="fw-bold ">{separateNumber(parseInt(text??0)) }</b>
    //   ),
    //   sorter: (a, b) => a.retours_sum_total_amount.length - b.retours_sum_total_amount.length,
    // },

    // {
    //   title: "Montant à payer",
    //   className: "text-center",
    //   render:(text, record) => (
    //     <b className="fw-bold text-success">{separateNumber(record.livraisons_sum_total_amount - record.retours_sum_total_amount) }</b>
    //   ),
    // },

    // {
    //   title: "Montant total versé",
    //   className: "text-center",
    //   dataIndex: "transactions_sum_total_amount",
    //   render:(text, record) => (
    //     <>
    //             {text == null && <b className="fw-bold text-success">0</b>}
    //             {text !== null && <b className="fw-bold text-success">{separateNumber(text) }</b>}
    //     </>

        
    //   ),
    //   sorter: (a, b) => a.transactions_sum_total_amount.length - b.transactions_sum_total_amount.length,
    // },



    {
      title: "Montant de la dette",
      className: "text-center",
      render:(text, record) => (
        <>
                <b className={(record.livraisons_sum_total_amount - record.retours_sum_total_amount) - record.transactions_sum_total_amount == 0 ? "fw-bold text-warning" : "fw-bold text-danger"}>{separateNumber((record.livraisons_sum_total_amount - record.retours_sum_total_amount) - record.transactions_sum_total_amount)  }</b>
        </>

        
      ),
      sorter: (a, b) => a.description.length - b.description.length,
    },

  ];

  const DepenseProductColumn = [
    {
      title: "Nom du produit",
      // className: "text-center",
      dataIndex: "product_name",
      render: (text, record) => (
        <div className="productimgname">
      
            <img src={`${url.image+record.image}`} width={50} height={50} className="rounded"/>
    
          <Link to="#" style={{ fontSize: "15px", marginLeft: "10px" }}>
            {record.product_name} 
          </Link>
        </div>
      ),
      sorter: (a, b) => a.product_name.length - b.product_name.length,
    },


    {
      title: "Boulangerie",
      dataIndex: "bakehouse",
      className: "text-center",
      render: (text, record) => (
        <>
              <b className="text-center fw-bold">{text}</b> 
        </>
      ),
      sorter: (a, b) => a.bakehouse.length - b.bakehouse.length,
    },

    {
      title: "Total depensée",
      className: "text-center",
      dataIndex: "procurement_details_sum_total_amount",
      render: (text, record) => (
        <>
          <b className="text-center fw-bold">{separateNumber(text??0) } FCFA</b>
        </>
      ),
      sorter: (a, b) => a.procurement_details_sum_total_amount.length - b.procurement_details_sum_total_amount.length,
    },





  ];

  const state = {
    series: [
      {
        name: "Livraisons",
        data: deliverSerie.series.map((serie)=>serie),
      },
      {
        name: "Retours",
        data: returnDeliverySerie.series.map((serie)=>serie),
      },
    ],
    options: {
      colors: ["#28C76F", "#EA5455"],
      chart: {
        type: "bar",
        height: 300,
        stacked: true,
  
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 280,
          options: {
            legend: {
              position: "bottom",
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "20%",
          borderRadius: 5,
          borderRadiusTop: 5,
        },
      },
      xaxis: {
        categories: deliverSerie.labels,
      },
      legend: {
        position: "top",
      },
      fill: {
        opacity: 1,
      },
    },
  };
  useEffect(()=>{
    try {
      axios.get(url.base+'/me',{
               headers:{
                   'Content-Type':'application/json',
                   "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                   
               },
           }).then((resp)=>{
            setBakehouses(resp.data.data.bakehouses)
              setPermissions(resp.data.data.permissions);
  
           })
       } catch (error) {
           // setLoading(false);
       }
    fetchDataSynthese();
  },[]);

  const fetchDataSynthese = async ()=>{
    setLoading(true);
    try {
        axios.get(url.base+'/bakehouses/dashbord',{
            headers:{
                'Content-Type':'application/json',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                
            },
        }).then((resp)=>{

            if(resp.status == 200){
              setDeliveriesList(resp.data.data.deliveryProducts);
              setIndirectSpend(resp.data.data.otherDepense);
              setSpendProduct(resp.data.data.depenseProduct);
                setDepenseTotale(resp.data.data.depenseTotal);
                setventeTotale(resp.data.data.venteNetteTotale);
                setEncaisseTotale(resp.data.data.totalEncaisse);
                setReliquatTotale(resp.data.data.reliquat);
                
                setCustomerTotal(resp.data.data.customersTotal);
                setsupplierTotale(resp.data.data.supplierTotal);
                setdeliveryTotale(resp.data.data.deliveryTotal);
                setechnicalSheetTotale(resp.data.data.technicalTotal);

                setProductionTotal(resp.data.data.totalProduction);
                setProductionList(resp.data.data.productionListProduct);

                setStockList(resp.data.data.queryStockListOne);
                setStockListTwo(resp.data.data.queryStockListTwo);
                setLivreurList(resp.data.data.queryDeliveryList);
                setDepenseList(resp.data.data.queryDepenseList);
                setStatsToday(resp.data.data.statsToday);
                setDeliveryByDay(resp.data.data.deliveryByDay);

                const labelMoisArr = Object.keys(resp.data.data.labels).map(key => resp.data.data.labels[key]);

                setdeliverSerie({
                  series:Object.values(resp.data.data.deliverSerie),
                  labels: labelMoisArr.map((label)=>label),
                })
                setreturnDeliverySerie({
                  series:Object.values(resp.data.data.returnDeliverySerie),
                  labels:labelMoisArr.map((label)=>label),
                })

            }
            setLoading(false);
        })
    } catch (error) {
        setLoading(false);
    }
}

const optionbakehouses = bakehouses?.map((option) => ({
  label:`${option.name}`,
  value:option.id
}));
const handleChangeBakehouse=(option)=>{
  setSelectBakehouse(option.value);
}

const handleChangeBegining=(e)=>{
  setBeginData(e.target.value);
}

const handleChangeEnd=(e)=>{
    setEndData(e.target.value);
    if(beginData != ""){
      submitFilterStat(bakhouseData,beginData,e.target.value);
    }
    
}

const handleChangeBakehouseId=(e)=>{
    setBakehouseData(e.target.value);
    submitFilterStat(e.target.value,beginData,endData);
}

const submitFilterStat=(bakhouseId,beginDate,endDate)=>{
  setLoadingDashboard(true);

  console.log(bakhouseData,beginData,endData);
try {
  axios.get(url.base+'/dashbord-search-data/?bakehouse_id='+bakhouseId+'&beginning='+beginDate+'&end_date='+endDate,{
      headers:{
          'Content-Type':'application/json',
          "Authorization": `Bearer ${localStorage.getItem('_token_')}`
          
      },
  }).then((resp)=>{
    setLoadingDashboard(false);

      if(resp.status == 200){
        // console.log(resp.data.data.depenseTotal);
        setDeliveriesList(resp.data.data.deliveryProducts);
        setIndirectSpend(resp.data.data.otherDepense);
        setSpendProduct(resp.data.data.depenseProduct);
          setDepenseTotale(resp.data.data.depenseTotal);
          setventeTotale(resp.data.data.venteNetteTotale);
          setEncaisseTotale(resp.data.data.totalEncaisse);
          setReliquatTotale(resp.data.data.reliquat);
          
          setCustomerTotal(resp.data.data.customersTotal);
          setsupplierTotale(resp.data.data.supplierTotal);
          setdeliveryTotale(resp.data.data.deliveryTotal);
          setechnicalSheetTotale(resp.data.data.technicalTotal);

          setProductionTotal(resp.data.data.totalProduction);
          setProductionList(resp.data.data.productionListProduct);

          setStockList(resp.data.data.queryStockListOne);
          setStockListTwo(resp.data.data.queryStockListTwo);
          setLivreurList(resp.data.data.queryDeliveryList);
          setDepenseList(resp.data.data.queryDepenseList);
          setStatsToday(resp.data.data.statsToday);
          setDeliveryByDay(resp.data.data.deliveryByDay);

          const labelMoisArr = Object.keys(resp.data.data.labels).map(key => resp.data.data.labels[key]);

          setdeliverSerie({
            series:Object.values(resp.data.data.deliverSerie),
            labels: labelMoisArr.map((label)=>label),
          })
          setreturnDeliverySerie({
            series:Object.values(resp.data.data.returnDeliverySerie),
            labels:labelMoisArr.map((label)=>label),
          })

      }
  })
} catch (error) {
setLoadingDashboard(false);
}
// }else{
//   setLoadingDashboard(false);
//   toast.error("La date debut et fin sont obligatoire");
// }

}

  return (
    <>
      <div className="page-wrapper">
        <Helmet>
          <title>Tableau de bord</title>
          <meta name="description" content="Dashboard page" />
        </Helmet>
        {permissions?.length > 0 && can(permissions,"voir-dashbord") &&
            <>
              
              <div className="content">
        {can(permissions,'filtre-dashboad-boulangerie') | can(permissions,'filtre-dashboad-periode') ?
            <div className="row mb-2">
            <div className="col-md-2 mb-1"></div>
              <div className="col-md-3 mb-1">
              {can(permissions,'filtre-dashboad-boulangerie') &&
                <select className="custom-select form-control me-3" onChange={handleChangeBakehouseId}>
                    <option selected value="">Toutes les boulangerie</option>
                    {bakehouses?.map((bakehouse,index)=>
                        <option value={bakehouse.id} key={index}>{bakehouse.name}</option>
                    )}
                    
                </select>
              }
                
              </div>
              <div className="col-md-3 mb-1">
              {can(permissions,'filtre-dashboad-periode') && <input type="date" className="form-control" onChange={handleChangeBegining}/>}
                    
              </div>
      
              <div className="col-md-3 mb-1">
                {can(permissions,'filtre-dashboad-periode') &&  <input type="date" className="form-control" onChange={handleChangeEnd}/>  }
                  
              </div>
              {/* <div className="col-md-1 mb-1">
                  <button type="button" className="btn btn-submit btn-sm pb-0 form-control" onClick={()=>submitFilterStat()} >
                    <span className="mb-3">Recherche</span>
                  </button>
              </div> */}
            </div> : ""
        }
                

                <hr className="text-white"/>
                
              {loadingDashboard && 
                    <div className="d-flex justify-content-center">
                        <div className="spinner-grow" style={{"width": "3rem", "height": "3rem"}} role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                        <div className="spinner-grow" style={{"width": "3rem", "height": "3rem"}} role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                        <div className="spinner-grow" style={{"width": "3rem", "height": "3rem"}} role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                  </div>
              }

          {!loadingDashboard && 
              <>
                <div className="row">
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="dash-widget">
                      <div className="dash-widgetimg">
                        <span>
                          <img src={Dash1} alt="img" />
                        </span>
                      </div>
                      <div className="dash-widgetcontent">
                        <h5>
                          
                          <span className="counters">
                            <CountUp end={depenseTotale} />
                          </span>
                        </h5>
                        <h6>Dépenses totale</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="dash-widget dash1">
                      <div className="dash-widgetimg">
                        <span>
                          <img src={Dash2} alt="img" />
                        </span>
                      </div>
                      <div className="dash-widgetcontent">
                        <h5>
                          
                          <span className="counters">
                            <CountUp end={venteTotale} />
                          </span>
                        </h5>
                        <h6>Vente nette totale</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="dash-widget dash2">
                      <div className="dash-widgetimg">
                        <span>
                          <img src={Dash3} alt="img" />
                        </span>
                      </div>
                      <div className="dash-widgetcontent">
                        <h5>
                          
                          <span className="counters">
                            <CountUp end={EncaisseTotale} />
                          </span>
                        </h5>
                        <h6>Total encaissé</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="dash-widget dash3">
                      <div className="dash-widgetimg">
                        <span>
                          <img src={Dash4} alt="img" />
                        </span>
                      </div>
                      <div className="dash-widgetcontent">
                        <h5>
                          
                          <span className="counters">
                            <CountUp end={ReliquatTotale} />
                          </span>
                        </h5>
                        <h6>Reliquat</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="dash-widget dash3">
                      <div className="dash-widgetimg">
                        <span>
                          <img src={Dash4} alt="img" />
                        </span>
                      </div>
                      <div className="dash-widgetcontent">
                        <h5>
                          
                          <span className="counters">
                            <CountUp end={spendProduct} />
                          </span>
                        </h5>
                        <h6>Approvisionnement Total</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="dash-widget dash3">
                      <div className="dash-widgetimg">
                        <span>
                          <img src={Dash4} alt="img" />
                        </span>
                      </div>
                      <div className="dash-widgetcontent">
                        <h5>
                          
                          <span className="counters">
                            <CountUp end={indirectSpend} />
                          </span>
                        </h5>
                        <h6>Dépenses indirecte</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="dash-widget dash3">
                      <div className="dash-widgetimg">
                        <span>
                          <img src={Dash4} alt="img" />
                        </span>
                      </div>
                      <div className="dash-widgetcontent">
                        <h5>
                          
                          <span className="counters">
                            <CountUp end={productionTotal} />
                          </span>
                        </h5>
                        <h6>Quantités totales produites</h6>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-3 col-sm-6 col-12">
                    <div className="dash-widget dash3">
                      <div className="dash-widgetimg">
                        <span>
                          <img src={Dash4} alt="img" />
                        </span>
                      </div>
                      <div className="dash-widgetcontent">
                        <h5>
                          
                          <span className="counters">
                            <CountUp end={ReliquatTotale} />
                          </span>
                        </h5>
                        <h6>Montant de la production (FCFA)</h6>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-lg-3 col-sm-6 col-12 d-flex">
                    <div className="dash-count das3">
                      <div className="dash-counts">
                        <h4>{technicalSheetTotale}</h4>
                        <h5>Production totale</h5>
                      </div>
                      <div className="dash-imgs">
                        <FeatherIcon icon="file" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12 d-flex">
                    <div className="dash-count">
                      <div className="dash-counts">
                        <h4>{customerTotal}</h4>
                        <h5>Clients</h5>
                      </div>
                      <div className="dash-imgs">
                        <FeatherIcon icon="user" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12 d-flex">
                    <div className="dash-count das1">
                      <div className="dash-counts">
                        <h4>{supplierTotale}</h4>
                        <h5>Fournisseurs</h5>
                      </div>
                      <div className="dash-imgs">
                        <FeatherIcon icon="user-check" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12 d-flex">
                    <div className="dash-count das2">
                      <div className="dash-counts">
                        <h4>{deliveryTotale}</h4>
                        <h5>Revendeurs</h5>
                      </div>
                      <div className="dash-imgs">
                        <FeatherIcon icon="user-check" />
                      </div>
                    </div>
                  </div>
                  
                </div>
        {/* Button trigger modal */}
                <div className="row">
                  <div className="col-lg-12 col-sm-12 col-12 d-flex">
                    <div className="card flex-fill">
                      <div className="card-header pb-0 d-flex justify-content-between align-items-center">
                        <h5 className="card-title mb-0"> Livraisons &amp; Retours </h5>
                        <div className="graph-sets">
                          <div className="dropdown">
                            {/* <button
                              className="btn btn-white btn-sm dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              2022
                              <img src={Dropdown} alt="img" className="ms-2" />
                            </button> */}
                            {/* <ul
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <li>
                                <Link to="#" className="dropdown-item">
                                  2022
                                </Link>
                              </li>
                              <li>
                                <Link to="#" className="dropdown-item">
                                  2021
                                </Link>
                              </li>
                              <li>
                                <Link to="#" className="dropdown-item">
                                  2020
                                </Link>
                              </li>
                            </ul> */}
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <Chart
                          options={state.options}
                          series={state.series}
                          type="line"
                          height={350}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-5 col-sm-12 col-12 d-flex">
                    <div className="card flex-fill">
                      <div className="card-header pb-0 d-flex justify-content-between align-items-center">
                        <h4 className="card-title mb-0">Meilleur ventes du jour</h4>
                        <div className="dropdown dropdown-action profile-action">
                          <Link
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            className="dropset"
                          >
                          </Link>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <li>
                              <Link
                                to="/boulangerie/product/productlist-product"
                                className="dropdown-item"
                              >
                                Product List
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/boulangerie/product/addproduct-product"
                                className="dropdown-item"
                              >
                                Product Add
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive dataview">
                          <Table
                            className="bg-denger"
                            columns={recentDataColumns}
                            dataSource={recentData}
                            pagination={false}
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                  {can(permissions,"stats-du-jour") &&
                  <div className="card mb-3">
                    <div className="card-body">
                      <h4 className="card-title">Statistique du jour</h4>
                      <div className="table-responsive dataview">
                        <Table
                          columns={expiredProductColumns}
                          dataSource={statsToday}
                        />
                      </div>
                    </div>
                  </div>
                  }
                  <div className="card mb-3">
                    <div className="card-body">
                      <h4 className="card-title">Livraison du jour par produit</h4>
                      <div className="table-responsive dataview">
                        <Table
                          columns={LivraisonProductColumn}
                          dataSource={deliveryByDay}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card mb-3">
                    <div className="card-body">
                      <h4 className="card-title">Productions par produits</h4>
                      <div className="table-responsive dataview">
                        <Table
                          columns={ProductionColumn}
                          dataSource={productionLiist}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card mb-3">
                    <div className="card-body">
                      <h4 className="card-title">Livraisons par produits</h4>
                      <div className="table-responsive dataview">
                        <Table
                          columns={LivraisonProductColumn}
                          dataSource={delivriesLiist}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card mb-3">
                    <div className="card-body">
                      <h4 className="card-title">Depenses par produit</h4>
                      <div className="table-responsive dataview">
                        <Table
                          columns={DepenseProductColumn}
                          dataSource={DepenseList}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">

                      <div className="card mb-3 col-6 me-0">
                        <div className="card-body">
                          <h4 className="card-title">Stock Matière prémiere</h4>
                          <div className="table-responsive dataview">
                            <Table
                              columns={stockProductColumn}
                              dataSource={stockList}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="card mb-3 col-6">
                        <div className="card-body">
                          <h4 className="card-title">Stock produit de vente</h4>
                          <div className="table-responsive dataview">
                            <Table
                              columns={stockProductColumn}
                              dataSource={stockListTwo}
                            />
                          </div>
                        </div>
                      </div>

                  </div>


                  <div className="card mb-0">
                    <div className="card-body">
                      <h4 className="card-title">Listes des Revendeurs</h4>
                      <div className="table-responsive dataview">
                        <Table
                          columns={versementLivreurColumn}
                          dataSource={livreurList}
                        />
                      </div>
                    </div>
                  </div>
              </>
            }
              </div>

          </>
        }

      </div>
      <RightSideBar />
    </>
  );
};

export default Dashboard;
