import React, { useEffect, useState } from "react";
import BaseUrl from "../../utils/BaseUrl";
import axios from "axios";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import can from "../../utils/Can";


const url = BaseUrl();
const Createpermission = () => {
  const history = useHistory();
  const [permissions,setPermissions] = useState([]);
  const [name,setName] = useState('');
  const [tabCheck,setTabCheck] = useState([]);
  const [loading,setLoading] = useState(false);

  useEffect(()=>{
    try {
      axios.get(url.base+'/me',{
               headers:{
                   'Content-Type':'application/json',
                   "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                   
               },
           }).then((resp)=>{
  
              if(!can(resp.data.data.permissions,"voir-roles") ){
                  window.location.href = '/boulangerie/dashboard';
              }
  
           })
       } catch (error) {
           // setLoading(false);
       }
  },[]);

  useEffect(()=>{
    fetchDataPermissions();
  },[]);


  const fetchDataPermissions=async()=>{
    try {
  await   axios.get(url.base+'/permissions',{
            headers:{
                'Content-Type':'application/json',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                
            },
        }).then((resp)=>{
            if(resp.status == 200)
            {
                setPermissions(resp.data.data);
            }
        })
    } catch (error) {
        setLoading(false);
    }
}

const handleChangePermissionCheck=(e)=>{
  // console.log(e.target.value);
  if(tabCheck.find((list)=> list == e.target.value)){
      const tab = [...tabCheck];
      const itemIndex = tab.findIndex(item => item === e.target.value);
      tab.splice(itemIndex, 1);

      setTabCheck(tab);
  }else{
    setTabCheck([...tabCheck,e.target.value]);
  }
}

const submitForm=(e)=>{
  e.preventDefault();

  if(name !="" ){
      const _formData = new FormData();
      _formData.append('name',name);
      _formData.append('permissions', tabCheck );

      console.log(_formData);
      setLoading(true);
      try {
          axios.post(url.base+'/roles',_formData,
         {
             headers:{
                 'Content-Type':'application/json',
                 "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                 
             },
             credentials:'include'
         }
         ).then((resp)=>{
            setLoading(false);
             if(resp.status == 200)
             {
              toast.success("Rôle enregistré avec succès");
              history.push('/boulangerie/settings/roles-list')
               }else{
                toast.error("Un problème est subvenu !");
             }

         })
     } catch (error) {
          setLoading(false);
         console.log(error);
     } 
  }else{
    if(name ==""){toast.error("Le rôle est obligatoire")}
    // if(tabCheck.length <= 0 ){toast.error("Désolé vous n'avez pas selectionné de permission.")}
  }
}


  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Ajouter un rôle</h4>
            <h6 className="text-danger">Veuillez associer un ou plusieurs permissions svp (*)</h6>
          </div>
        </div>
        {/* /product list */}
        <div className="card">
          <form className="card-body" onSubmit={submitForm}>
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="form-group">
                  <label>Rôle</label>
                  <input type="text" name="name" onChange={(e)=>setName(e.target.value)} value={name}/>
                </div>
              </div>
              {/* <div className="col-12 mb-3">
                <div className="input-checkset">
                  <ul>
                    <li>
                      <label className="inputcheck">
                        tout selectionner
                        <input type="checkbox" id="select-all" />
                        <span className="checkmark" />
                      </label>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
            <div className="row">
              <div className="col-12">
                <div className="productdetails product-respon">
                  <ul>
                  {permissions.map((permission,index)=>
                    <li key={index}>
                      <h4>{permission.name}</h4>
                      <div className="input-checkset">
                        <ul>
                          <li>
                            <label className="inputcheck">
                              <input type="checkbox" value={permission.name} onChange={handleChangePermissionCheck} checked={tabCheck.find((list)=> list == permission.name) ? "checked" : ""} />
                              <span className="checkmark"  />
                            </label>
                          </li>

                        </ul>
                      </div>
                    </li>
                  )} 
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-12 mt-3">
              {loading &&
                  <button className="btn btn-submit me-2" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        En chargement...
                    </button>
                }
                {!loading &&
                <button type="submit" className="btn btn-submit me-2" >
                  Enregistrer
                </button>
                }
                <Link to="/boulangerie/settings/roles-list" className="btn btn-cancel">
                  Retour
                </Link>
              </div>
          </form>
        </div>
        {/* /product list */}
      </div>
    </div>
  );
};

export default Createpermission;