/* eslint-disable react/prop-types */
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom';
import AddTransfer from './AddTransfer';
import AddProductBoulangerie from './addProdHouse';
import FamillePage from './TransferList';
import EditTransfer from './EditTransfer';
import SousFamillePage from './sousFamille';
import ProductBakeHouses from './product_backhouse'; 
import EditProductBoulangerie from './editProdHouse';
import ProductStocks from './product_stocks';
import AddProductStock from './addProdStock';
import EditProductStock from './editProdStock';
import FournisseursPage from './fournisseurs';
import VariationdeStockDetail from './variationstock_detail';
import ProductToSale from './product_vente';


const TransferRoute = ({ match}) =>(
    <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/transferlist-transfer`} />
        <Route path={`${match.url}/transferlist-transfer`} component={FamillePage} />
        <Route path={`${match.url}/sous-familles`} component={SousFamillePage} />

        <Route path={`${match.url}/produits-boulangerie`} component={ProductBakeHouses} />
        <Route path={`${match.url}/produits-de-ventes`} component={ProductToSale} />

        <Route path={`${match.url}/add-product-house`} component={AddProductBoulangerie} />
        <Route path={`${match.url}/add-produits-de-ventes`} component={AddProductStock} />
        <Route path={`${match.url}/edit-product-house/:productId`} component={EditProductBoulangerie} />

        <Route path={`${match.url}/produits-stocks`} component={ProductStocks} />
        <Route path={`${match.url}/add-product-stock`} component={AddProductStock} />
        <Route path={`${match.url}/edit-product-stock/:productId`} component={EditProductStock} />

        <Route path={`${match.url}/fournisseurs`} component={FournisseursPage} />

        <Route path={`${match.url}/addtransfer-transfer`} component={AddTransfer} />
        <Route path={`${match.url}/edittransfer-transfer`} component={EditTransfer} />

        <Route path={`${match.url}/history-products`} component={VariationdeStockDetail} />

    </Switch>
)

export default TransferRoute;