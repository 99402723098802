/* eslint-disable react/prop-types */
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom';
import PurchaseFournisseurList from './list';
import AddAchatFournisseur from './addsales';
import AchatFournisseurDetail from './saledetails';
import EditAchatFournisseur from './editsales';








const SupplierIndex = ({ match}) =>(
    <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/purchase-fournisseur`} />
        <Route path={`${match.url}/list-achat-fournisseur`} component={PurchaseFournisseurList} />
        <Route path={`${match.url}/ajout-achat`} component={AddAchatFournisseur} />        
        <Route path={`${match.url}/achat-details/:uuid`} component={AchatFournisseurDetail} />

        <Route path={`${match.url}/edit-achats/:uuid`} component={EditAchatFournisseur} />
        {/*
        <Route path={`${match.url}/salesreturnlist-return`} component={SalesReturnList} />
        <Route path={`${match.url}/addsalesreturn-return`} component={AddSalesReturn} /> */}



    </Switch>
)

export default SupplierIndex