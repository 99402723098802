/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
// import Header from "./posheader";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import POS from "./posleft";
import Transactions from "./transactions";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import {

  delete2,
  ellipise1
} from "../../EntryFile/imagePath";
import axios from "axios";
import BaseUrl from "../../utils/BaseUrl";
import { toast } from "react-toastify";
import separateNumber from "../../utils/separateur";
import can from "../../utils/Can";
import Header from "../Sidebar/Header";

const url = BaseUrl();
const Pos = () => {

  const [productsList,setProductList] = useState([]);
  const [productsSearch,setProductSearch] = useState([]);
  const [listCart,setListCart] = useState([]);
  const [sousTotal,setSousTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [montantClt,setMontantClt] = useState(0);
  const [moneyClt,setMoneyClt] = useState(0);
  const [searchValue,setSearchValue] = useState(null);
  const [sumSale,setSumSale] = useState(0);
  const [user,setUser] = useState([]);
  const [urlPayment,setUrlPayment] = useState(null);


  const confirmText = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: !1,
    }).then(function (t) {
      t.value &&
        Swal.fire({
          type: "success",
          title: "Deleted!",
          text: "Your file has been deleted.",
          confirmButtonClass: "btn btn-success",
        });
    });
  };


  useEffect(()=>{
    try {
      axios.get(url.base+'/me',{
               headers:{
                   'Content-Type':'application/json',
                   "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                   
               },
           }).then((resp)=>{
            setUser(resp.data.data);
              if(!can(resp.data.data.permissions,"vente-au-contoirs") ){
                  window.location.href = '/boulangerie/dashboard';
              }
  
           })
       } catch (error) {
           // setLoading(false);
       }
  },[]);

  useEffect(()=>{
    productList();
    salesSum();
  },[])


  function productList(){
    try {
      axios.get(url.base+'/sale-stock',{
          headers:{
              'Content-Type':'application/json',
              "Authorization": `Bearer ${localStorage.getItem('_token_')}`
              
          },
      }).then((resp)=>{
  
          if(resp.status == 200){
            setProductList(resp.data.data);
          }
          // setLoading(false);
      })
  } catch (error) {
      // setLoading(false); 
  }
  }

  function salesSum(){
    try {
      axios.get(url.base+'/sale-sum-today',{
          headers:{
              'Content-Type':'application/json',
              "Authorization": `Bearer ${localStorage.getItem('_token_')}`
              
          },
      }).then((resp)=>{
  
          if(resp.status == 200){
            setSumSale(resp.data.data);
          }
          // setLoading(false);
      })
  } catch (error) {
      // setLoading(false); 
  }
  }

  const handleChangeProduct=(option)=>{
    
    let product_check = productsList.find((product) => product.product_id === option);

    if(product_check.quantity > 0){
      if(listCart.find((list)=> list.product_id === product_check.product_id)){
        console.log("oops")
      }else{
        
        
       
        const itemCart = {
          "product_id":product_check.product_id,
          "image":product_check.image,
          "product_name":product_check.product_name,
          "quantity":1,
          "unit_price":product_check.price,
          "stock":product_check.quantity,
          "sub_total":product_check.price * 1
        }
        
        setMoneyClt(montantClt - (sousTotal +product_check.price));
        setSousTotal(sousTotal + product_check.price)
        
        setListCart([...listCart, itemCart]);
      }
    }else{
      toast.error("Avertissement, la valeur est superieur au stock");
    }


  }

  const removeItem = (i) => {
    const updatedProd = [...listCart];

    
    setSousTotal(sousTotal - updatedProd[i].sub_total);
    setMoneyClt(moneyClt + updatedProd[i].sub_total);
    updatedProd.splice(i, 1);
    
    setListCart(updatedProd);
  };

  const CounterItem =(i,value)=> {
    const updatedProd = [...listCart];

    console.log(value,i);
    if(value == 1){
      updatedProd[i].quantity  = updatedProd[i].quantity + 1;
      updatedProd[i].sub_total= updatedProd[i].quantity * updatedProd[i].unit_price

      const newSousTotal = updatedProd.reduce((acc, item) => acc + item.sub_total, 0);
      if(updatedProd[i].stock >= updatedProd[i].quantity){
        console.log("ok")
      }else{
        updatedProd[i].quantity = 0
        toast.error("Avertissement, la valeur est superieur au stock");
      }
      setMoneyClt(montantClt - newSousTotal);
      setSousTotal(newSousTotal);
      setListCart(updatedProd);
    }else{
      updatedProd[i].quantity = updatedProd[i].quantity - 1;
      updatedProd[i].sub_total= updatedProd[i].quantity * updatedProd[i].unit_price

      const newSousTotal = updatedProd.reduce((acc, item) => acc + item.sub_total, 0);
      if(updatedProd[i].quantity < 0){
        updatedProd[i].quantity = 0;
        setMoneyClt(0);
        setSousTotal(0);
        setListCart(updatedProd);
        toast.error("Avertissement, la valeur est inférieur à 0");
      }else{
        setMoneyClt(montantClt - newSousTotal);
        setSousTotal(newSousTotal);
        setListCart(updatedProd);
      }


    }



  }

  const handleChangeQty=(e,i)=>{

    const updatedProd = [...listCart];

    if(updatedProd[i].stock >= e ){
      updatedProd[i].quantity =+ e;
      updatedProd[i].sub_total= updatedProd[i].quantity * updatedProd[i].unit_price
  
      const newSousTotal = updatedProd.reduce((acc, item) => acc + item.sub_total, 0);
      
      setMoneyClt(montantClt - newSousTotal);
      setSousTotal(newSousTotal);
    
      setListCart(updatedProd)
    }else{
      updatedProd[i].quantity = 0;
      updatedProd[i].sub_total= updatedProd[i].quantity * updatedProd[i].unit_price
  
      const newSousTotal = updatedProd.reduce((acc, item) => acc + item.sub_total, 0);
      
      setMoneyClt(montantClt - newSousTotal);
      setSousTotal(newSousTotal);
    
      setListCart(updatedProd)
      toast.error("Avertissement, la valeur est superieur au stock");
    }

  
  }
  const handleChangeMontantClt = (value)=>{
     if(value >= 0){
        setMoneyClt(value - sousTotal);
        setMontantClt(value);
     }else{
        setMoneyClt(0);
        setMontantClt(0);
        toast.error("La valeur est inférieur à 0");
     }
  }

  const handleChangeProductFilter=(value)=>{

    var tableau = [];
    setSearchValue(value);
    if(value !== ""){
          tableau = productsList.filter((item) =>
            item.product_name.toLowerCase().includes(value.toLowerCase())
          );

     
          setProductSearch(tableau);
        

    }else{
      setSearchValue(null);
      setProductSearch([]);
    }


    
  }

  const clearCart = ()=>{
    setListCart([]);
    setMoneyClt(0);
    setMontantClt(0);
    setSousTotal(0);
  }

  const submitForm=(e)=>{
    e.preventDefault();
    const _formData = new FormData();
    _formData.append("paid_amount",montantClt);
    _formData.append("total_amount",sousTotal);
    _formData.append("balance",moneyClt);
    _formData.append("sale_items",JSON.stringify(listCart));

    setLoading(true);
console.log(_formData);
      try {
        axios.post(url.base+'/sale-store',_formData,
        {
            headers:{
                'Content-Type':'application/json',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                
            },
        }
        ).then((resp)=>{
          console.log(resp.status);
            setLoading(false);
            if(resp.status === 200){
              toast.success("Vente enregistrée avec succès");
              productList();
              salesSum();
              setListCart([]);
              setMoneyClt(0);
              setMontantClt(0);
              setSousTotal(0);
            }else{
              toast.error("Echec de l'enregistrement");

            }
        }).catch((error)=>{  
          // console.log(error.response);              
            setLoading(false);

            if (error.response.status == 422) {
                setErrors(error.response.data.errors);
            }
            // console.log(errors);
        })
    } catch (error) {
        setLoading(false);
  console.log(error);  
    }


  }

  const generateUrlPayment=()=>{

        const _formData = new FormData();
        _formData.append("paid_amount",montantClt);
        _formData.append("total_amount",sousTotal);
        _formData.append("balance",moneyClt);
        _formData.append("sale_items",JSON.stringify(listCart));

      // startInterval()
      setLoading(true);
      try {
        axios.post(url.base+'/sale-store-epayment',_formData,
        {
            headers:{
                'Content-Type':'application/json',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                
            },
            // credentials:'include'
        }
        ).then((resp)=>{
          
            setLoading(false);
            if(resp.data.data.code !== 400){
              if(resp.data.data.code == 201){
                setUrlPayment(resp.data.data.payment_url);
                window.$("#validateUrl").modal('show');

                const intervalId = setInterval(()=>{

                  const _formData = new FormData();
                  _formData.append('transaction_id',resp.data.data.transaction_id);
                  _formData.append('sale_id',resp.data.data.sale_id);
              
                  try {
                    axios.post(url.base+'/sale-update-epayment',_formData,
                    {
                        headers:{
                            'Content-Type':'application/json',
                            "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                            
                        },
                        // credentials:'include'
                    }
                    ).then((resp)=>{
                      
                        if(resp.data.data == "OK"){
                          console.log(resp.data.data);
                          window.$("#validateUrl").modal('hide');
                          toast.success("Votre paiement est effectué avec succès !");
                          
                          
                          setTimeout(() => {
                            window.location.reload();
                            // history.push('/boulangerie/production/commandes-list');
                          }, 1000);

                        }else if(resp.data.data == "ECHEC"){
                          
                          Swal.fire({
                            title: 'Désolé',
                            text: 'Le paiement à echoué, veuillez reessayer svp !',
                            confirmButtonText:"OK,compris!",
                            confirmButtonColor:"red"
                          }).then((result) => {
                            if (result.isConfirmed) {
                              window.location.reload();              
                            
                            }
                          }); 
                          setTimeout(() => {
                            window.location.reload();
                          }, 2 * 60 * 1000);
                        }else{
                          console.log(resp.data.data);
                        }
                    })
                } catch (error) {
                  console.log(error);  
                }
                },3000);

                setTimeout(() => {
                  clearInterval(intervalId);

                  toast.info("Désolé le delai de paiement est passé.");
                  window.$("#validateUrl").modal('hide');
                  history.push('/boulangerie/production/commandes-list');
                },5 * 60 * 1000);
              }else{
                toast.error("Un problème est subvenu, veuillez resseyer plus tard.");
              }

            }else{
              toast.error(resp.data.data.msg);

            }
        })
    } catch (error) {
        setLoading(false);
      console.log(error);  
    }

  }
  
  return (
    <>
      <div className="main-wrappers">
        <Header user={user}/>
        <div className="page-wrapper ms-0">
          <div className="content">
            <div className="row">
            <div className="col-lg-8 col-sm-12 tabs_wrapper">
              <div className="page-header ">
                <div className="page-title">
                  <h4>Page de vente en caisse <Link className="btn btn-secondary btn-sm" to="/boulangerie/production/sales-caisse-list">Historique des ventes</Link></h4>
                  {/* <h6>Manage your purchases</h6> */}
                </div>
              </div>
              <ul className=" tabs owl-carousel owl-theme owl-product  border-0 ">
                  <input className="form-control" placeholder="Rechercher un produit" onChange={(e)=>handleChangeProductFilter(e.target.value)}/>
              </ul>
              <div className="tabs_container">
                <div className="tab_content active" >
                  <div className="row ">
                    {searchValue == null && productsSearch.length == 0 &&
                      productsList.map((product,index)=>
                        <div className="col-lg-3 col-sm-6 d-flex " key={index} onClick={()=>handleChangeProduct(product.product_id)}>
                          <div className="productset flex-fill">
                            <div className="productsetimg" style={{"height":"200px"}}>
                              <img src={`${url.image}${product.image}`} alt="img" height={100}/>
                              <h6>Qty: {product.quantity}</h6>
                              <div className="check-product">
                                <i className="fa fa-check" />
                              </div>
                            </div>
                            <div className="productsetcontent" >
                              {/* <h5>Fruits</h5> */}
                              <b className="fw-bold"><u>{product.product_name}</u></b>
                              <h6>{product.price}</h6>
                            </div>
                          </div>
                      </div>
                      )
                    }

                {searchValue !== null && productsSearch.length > 0 &&
                      productsSearch.map((product,index)=>
                        <div className="col-lg-3 col-sm-6 d-flex " key={index} onClick={()=>handleChangeProduct(product.product_id)}>
                          <div className="productset flex-fill">
                            <div className="productsetimg" style={{"height":"200px"}}>
                              <img src={`${url.image}${product.image}`} alt="img" height={100}/>
                              <h6>Qty: {product.quantity}</h6>
                              <div className="check-product">
                                <i className="fa fa-check" />
                              </div>
                            </div>
                            <div className="productsetcontent" >
                              {/* <h5>Fruits</h5> */}
                              <b className="fw-bold"><u>{product.product_name}</u></b>
                              <h6>{product.price}</h6>
                            </div>
                          </div>
                      </div>
                      )
                    }

                {searchValue !== null && productsSearch.length == 0 && 
                    <b className="text-danger fw-bold">Aucun produit ne correspont à <b className="text-success fw-bold">{`"${searchValue}"`}</b></b>
                }
                    
                   
                  </div>
                </div>
              
              </div>
            </div>
              <div className="col-lg-4 col-sm-12 ">
               <div className="order-list">
                  <div className="orderid">
                    
                    <h5 className="fw-bold">Total vendu : {separateNumber(sumSale)} FCFA</h5>
                  </div>
                  {/*<div className="actionproducts">
                     <ul>
                      <li>
                        <Link
                          to="#"
                          className="deletebg confirm-text"
                          onClick={confirmText}
                        >
                          Liste
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          className="dropset"
                        >
                          <img src={ellipise1} alt="img" />
                        </Link>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                          data-popper-placement="bottom-end"
                        >
                          <li>
                            <Link to="#" className="dropdown-item">
                              Action
                            </Link>
                          </li>
                          <li>
                            <Link to="#" className="dropdown-item">
                              Another Action
                            </Link>
                          </li>
                          <li>
                            <Link to="#" className="dropdown-item">
                              Something Elses
                            </Link>
                          </li>
                        </ul>
                      </li> 
                    </ul>
                  </div>*/}
                </div> 
                <form className="card card-order" onSubmit={submitForm}>
                  {/* <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <Link
                          to="#"
                          className="btn btn-adds"
                          data-bs-toggle="modal"
                          data-bs-target="#create"
                        >
                          <i className="fa fa-plus me-2" />
                          Add Customer
                        </Link>
                      </div>
                      <div className="col-lg-12">
                        <div className="select-split ">
                          <div className="select-group w-100">
                            <Select2
                              className="select"
                              data={options}
                              options={{
                                placeholder: "Walk-in Customer",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="select-split">
                          <div className="select-group w-100">
                            <Select2
                              className="select"
                              data={options1}
                              options={{
                                placeholder: "Product",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="text-end">
                          <Link to="#" className="btn btn-scanner-set">
                            <img src={scanner1} alt="img" className="me-2" />
                            Scan bardcode
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="split-card"></div>
                  <div className="card-body pt-0">
                    <div className="totalitem">
                      <h4>Total Produits : {listCart.length}</h4>
                      <Link to="#" onClick={()=>clearCart()}>Tout supprimer</Link>
                    </div>
                    <div className="product-table">
                      {listCart?.map((item,index)=>
                          <ul className="product-lists" key={index}>
                          <li>
                            <div className="productimg">
                              <div className="productimgs">
                                <img src={`${url.image}${item.image}`} alt="img" />
                              </div>
                              <div className="productcontet">
                                <h4>{item.product_name}</h4>
                                <div className="productlinkset">
                                  <h5>Prix : {item.unit_price} F</h5>
                                </div>
                                <div className="increment-decrement">
                                  <div className="input-groups">
                                    <input
                                      onClick={() => CounterItem(index,0)}
                                      type="button"
                                      defaultValue="-"
                                      className="button-minus dec button"
                                      
                                    />
                                    <input
                                      type="text"
                                      onChange={(e)=>handleChangeQty(e.target.value,index)}
                                      value={item.quantity}
                                      className="quantity-field"
                                    />
                                    <input
                                      onClick={() => CounterItem(index,1)}
                                      type="button"
                                      defaultValue="+"
                                      className="button-plus inc button "
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>{separateNumber(item.sub_total)} FCFA</li>
                          <li>
                            <button
                              type="button"
                              className="confirm-text btn"
                              onClick={()=>removeItem(index)}
                            >
                              <img src={delete2} alt="img" />
                            </button>
                          </li>
                        </ul>
                      )}
                      
               
                    </div>
                  </div>
                  <div className="split-card"></div>
                  <div className="card-body pt-0 pb-2">
                    <div className="setvalue">
                      <ul>
                         <li className="total-value">
                          <h5>Total </h5>
                          <h6>{separateNumber(sousTotal)} FCFA</h6>
                        </li> 
                         <li>
                          <h5 >Montant remis </h5>
                          <h6>
                            <input className="form-control text-end" type="text" onChange={(e)=>handleChangeMontantClt(e.target.value)} value={montantClt}/>
                          </h6>
                        </li> 
                        <li className="total-value">
                          <h5 className={moneyClt < 0 ? "text-danger" : "text-success"}>Rendu</h5>
                          <h6 className={moneyClt < 0 ? "text-danger" : "text-success"}>{moneyClt} FCFA</h6>
                        </li>
                      </ul>
                    </div>
                    {/* <div className="setvaluecash">
                      <ul>
                        <li>
                          <Link to="#" className="paymentmethod">
                            <img src={cash} alt="img" className="me-2" />
                            Cash
                          </Link>
                        </li>
                        <li>
                          <Link to="#" className="paymentmethod">
                            <img src={debitcard} alt="img" className="me-2" />
                            Debit
                          </Link>
                        </li>
                        <li>
                          <Link to="#" className="paymentmethod">
                            <img src={scan} alt="img" className="me-2" />
                            Scan
                          </Link>
                        </li>
                      </ul>
                    </div> */}
                     {loading &&
                      <button className="btn btn-primary form-control" type="button" disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            En chargement...
                        </button>
                    }
                    {!loading &&
                    <>
                    <button className="btn btn-primary form-control" disabled={sousTotal <= 0 | moneyClt < 0  ? "disabled" : ""}>
                        VENTE CASH
                    </button>
                    <button type="button" className="btn btn-danger mt-3 form-control " onClick={()=>generateUrlPayment()} disabled={sousTotal <= 0 | moneyClt < 0  ? "disabled" : ""} style={{"backgroundColor":"red"}}>
                      E-PAYMENT
                    </button>
                    </>
                      

                      
                    }
                    {/* <div className="btn-pos">
                      <ul>
                        <li>
                          <Link to="#" className="btn">
                            <img src={pause1} alt="img" className="me-1" />
                            Hold
                          </Link>
                        </li>
                        <li>
                          <Link to="#" className="btn">
                            <img src={Edit6} alt="img" className="me-1" />
                            Quotation
                          </Link>
                        </li>
                        <li>
                          <Link to="#" className="btn">
                            <img src={trash12} alt="img" className="me-1" />
                            Void
                          </Link>
                        </li>
                        <li>
                          <Link to="#" className="btn">
                            <img src={wallet1} alt="img" className="me-1" />
                            Payment
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="#"
                            className="btn"
                            data-bs-toggle="modal"
                            data-bs-target="#recents"
                          >
                            <img src={transcation} alt="img" className="me-1" />{" "}
                            Transaction
                          </Link>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="create"
        tabIndex={-1}
        aria-labelledby="create"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Create</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-6 col-sm-12 col-12">
                  <div className="form-group">
                    <label>Customer Name</label>
                    <input type="text" />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12 col-12">
                  <div className="form-group">
                    <label>Email</label>
                    <input type="text" />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12 col-12">
                  <div className="form-group">
                    <label>Phone</label>
                    <input type="text" />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12 col-12">
                  <div className="form-group">
                    <label>Country</label>
                    <input type="text" />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12 col-12">
                  <div className="form-group">
                    <label>City</label>
                    <input type="text" />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12 col-12">
                  <div className="form-group">
                    <label>Address</label>
                    <input type="text" />
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <Link to="#" className="btn btn-submit me-2">
                  Submit
                </Link>
                <Link to="#" className="btn btn-cancel" data-bs-dismiss="modal">
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
              className="modal fade"
              id="validateUrl"
              tabIndex={-1}
              aria-labelledby="validateUrl"
              aria-hidden="true"
              data-bs-backdrop="static" 
              // data-keyboard="false"
            >
              <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">E-PAYMENT</h5>
                    <button
                      type="button"
                      className="btn-close"
                      // data-bs-dismiss="modal"
                      // aria-label="Close"
                      onClick={()=>window.location.reload()}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                  <iframe src={urlPayment} width="100%" height="600" allow="payment; fullscreen">
                    </iframe>
                    
                  </div>
              
                </div>
              </div>
        </div>
      <Transactions />
    </>
  );
};

export default Pos;
