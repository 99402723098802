// import React, { useState } from "react";
// import { Upload } from "../../EntryFile/imagePath";

import axios from "axios";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";
import BaseUrl from "../../utils/BaseUrl";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import can from "../../utils/Can";

const url = BaseUrl();
const AddStore = () => {
  // const [passwordShown, setPasswordShown] = useState(false);
  // const togglePassword = () => {
  //   setPasswordShown(!passwordShown);
  // };
  const history = useHistory();
  const [loading,setLoading] = useState(false);
  const [boulangerieData,setBoulangerie] = useState({
    "name":"",
    "address":"",
    "phone":"",
    "responsable":"",
  });

  useEffect(()=>{
    try {
      axios.get(url.base+'/me',{
               headers:{
                   'Content-Type':'application/json',
                   "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                   
               },
           }).then((resp)=>{
  
              if(!can(resp.data.data.permissions,"gestion-boulangerie") ){
                  window.location.href = '/boulangerie/dashboard';
              }
  
           })
       } catch (error) {
           // setLoading(false);
       }
  },[]);


  const handleChange=(event)=>{
    setBoulangerie({
      ...boulangerieData,
      [event.target.name]:event.target.value
    })
  }

  const submitForm=(e)=>{
    e.preventDefault();

    if(boulangerieData.name !=="" && boulangerieData.phone !=="" && (/^\d{9,10}$/).test(boulangerieData.phone) && boulangerieData.responsable !==""  && boulangerieData.address !==""){
      const _formData = new FormData();
      _formData.append('name',boulangerieData.name);
      _formData.append('address',boulangerieData.address);
      _formData.append('phone',boulangerieData.phone);
      _formData.append('responsable',boulangerieData.responsable);
  
  
      console.log(_formData);
      setLoading(true);
      try {
        axios.post(url.base+'/bakehouses',_formData,
        {
            headers:{
                'Content-Type':'application/json',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                
            },
            // credentials:'include'
        }
        ).then((resp)=>{
            setLoading(false);
            if(resp.status == 200){
  
                  toast.success("Enregistrement effectué avec succès");
                  history.push('/boulangerie/comptes/boulangerie-list')
            }else{
                toast.error("Un problème est subvenu !");
            }
        }).catch((error)=>{  
          console.log(error.response);              
            setLoading(false);  
          
        })
    } catch (error) {
        setLoading(false);
        console.log(error);  
    }
    }else{
      
      if(boulangerieData.name == ""){
        toast.error("Le nom de la boulangerie est obligatoire");
      }else if(boulangerieData.phone == ""){
        toast.error("Le numéro de téléphone est obligatoire");
      }else if(!(/^\d{9,10}$/).test(boulangerieData.phone)){
        toast.error("Le numéro de téléphone saisi est invalid");
      }else if(boulangerieData.address == ""){
        toast.error("L'addresse est obligatoire");
      }else if(boulangerieData.responsable == ""){
        toast.error("Le nom du respnsable est obligatoire");
      }
    }


  }
  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Ajouter une boulangerie</h4>
              {/* <h6>Add/Update Store</h6> */}
            </div>
          </div>
          {/* /add */}
          <div className="card">
            <form className="card-body" onSubmit={submitForm}>
              <div className="row">
                <div className="col-lg-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label>Nom de la boulanagerie <span className="text-danger">*</span> </label>
                    <input type="text" name="name" onChange={handleChange} value={boulangerieData.name}/>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Nom et prénoms du responsable <span className="text-danger">*</span> </label>
                    <input type="text" name="responsable" onChange={handleChange} value={boulangerieData.responsable}/>
                  </div>
                </div>
                {/* <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Password</label>
                    <div className="pass-group">
                      <input
                        type={passwordShown ? "text" : "password"}
                        className=" pass-input"
                      />
                      <span
                        className={`fas toggle-password ${
                          passwordShown ? "fa-eye" : "fa-eye-slash"
                        }`}
                        onClick={togglePassword}
                      />
                    </div>
                  </div>
                </div> */}
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Téléphone <span className="text-danger">*</span></label>
                    <input type="text" name="phone" onChange={handleChange} value={boulangerieData.phone}/>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Adresse (zone géographique) <span className="text-danger">*</span></label>
                    <input type="text" name="address" onChange={handleChange} value={boulangerieData.address}/>
                  </div>
                </div>

               

                <div className="col-lg-12">
                {loading &&
                  <button className="btn btn-submit me-2" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        En chargement...
                    </button>
                }
                {!loading &&
                <button type="submit" className="btn btn-submit me-2" >
                  Enregistrer
                </button>
                }
                  <Link className="btn btn-cancel" to="/boulangerie/comptes/boulangerie-list">Retour</Link>
                </div>
              </div>
            </form>
          </div>
          {/* /add */}
        </div>
      </div>
    </>
  );
};

export default AddStore;
