/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Table from "../../EntryFile/datatable";
import Tabletop from "../../EntryFile/tabletop";
import Swal from "sweetalert2";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import { Link } from "react-router-dom";
import {
  ClosesIcon,
  Excel,
  Filter,
  Pdf,
  PlusIcon,
  Printer,
  Search,
  search_whites,
  EditIcon,
  DeleteIcon,
  EyeIcon
} from "../../EntryFile/imagePath";
import axios from "axios";
import BaseUrl from "../../utils/BaseUrl";
import Select from "react-select";
import { toast } from "react-toastify";
import separateNumber from "../../utils/separateur";
import moment from "moment";
import ExportExcel from "../../utils/ExcelExport";
import can from "../../utils/Can";

const url = BaseUrl();
const DepensePage = () => {
  const [errors,setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [itemData,setItemData] = useState({
    "libelle":"",
    "total_amount":"",
    "comment":"",
    "justification":""
  });
  const [editItem,setEditItem] = useState(null);

  const [itemList,setItemList] = useState([]);
  const [familles,setFamilles] = useState([]);
  const [optFamille,setOptFamille] = useState(null);
  const [excelData,setExcelData] = useState({});
  const [bakehouses,setBakehouses] = useState([]);
  const [selectBakehouse,setSelectBakehouse] = useState(null);


  useEffect(()=>{
    try {
      axios.get(url.base+'/me',{
               headers:{
                   'Content-Type':'application/json',
                   "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                   
               },
           }).then((resp)=>{
            setBakehouses(resp.data.data.bakehouses)
              if(!can(resp.data.data.permissions,"voir-depenses") ){
                  window.location.href = '/boulangerie/dashboard';
              }
  
           })
       } catch (error) {
           // setLoading(false);
       }
  },[]);


  useEffect(()=>{
    fetcItemList()
  },[])


  const handleChange=(e)=>{
    setItemData({
        ...itemData,
        [e.target.name]:e.target.value
    });
  }

  const handleFileChange=(event)=>{
    setItemData({
        ...itemData,
        [event.target.name]:event.target.files[0]
    });
  }
  const optionbakehouses = bakehouses?.map((option) => ({
    label:`${option.name}`,
    value:option.id
  }));
  const handleChangeBakehouse=(option)=>{
    setSelectBakehouse(option.value);
  }
  useEffect(()=>{
    fetcFamilleList();
  },[]);

  const fetcFamilleList=()=>{
    try {
      axios.get(url.base+'/expenses-categories',{
          headers:{
              'Content-Type':'application/json',
              "Authorization": `Bearer ${localStorage.getItem('_token_')}`
              
          },
      }).then((resp)=>{

          if(resp.status == 200){
            setFamilles(resp.data.data);
          }
          // setLoading(false);
      })
  } catch (error) {
      // setLoading(false);
  }
  }
  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    if (searchValue.trim() === "") {
      fetcItemList();
    } else {
      const filtered = itemList.filter(item =>
        Object.values(item).some(value =>
          typeof value === 'string' && value.toLowerCase().includes(searchValue)
        )
      );
      setItemList(filtered);
    }
  
};
  const optionfamilles = familles.map((opt)=>({
    value: opt.id,
    label:`${opt.name}`
  }));

  // console.log(optionfamilles);
  const handleChangeFamille=(option)=>{
    console.log(option);
    setOptFamille(option.value);
  }

  const showModalEdit=(unit)=>{

    setEditItem(unit);
    setItemData({
      'first_name':unit.first_name,
      'phone':unit.phone,
      'last_name':unit.last_name,
      'address':unit.address,
      'email':unit.email,
      'company':unit.company,
    });

    window.$("#editpayment").modal('show');
  }

  const seeImageJustif=(record)=>{
    if(record.justification !== null){
      window.open(`${url.image}${record.justification}`,'_blank')
    }else{
      toast.error("Pas d'image")
    }

  }
  const columns = [
    {
      title: "Libellé",
      dataIndex: "libelle",
      className: "text-center",
      render: (text, record) => <div className="text-center">{text}</div>,
      sorter: (a, b) => a.libelle.length - b.libelle.length,
    },
    {
      title: "Montant engagé en FCFA",
      dataIndex: "total_amount",
      className: "text-center",
      render: (text, record) => <div className="text-center fw-bold">{separateNumber(text) }</div>,
      sorter: (a, b) => a.total_amount.length - b.total_amount.length,
    },
    {
      title: "Catégorie depense",
      dataIndex: "name",
      className: "text-center",
      render: (text, record) => <div className="text-center">{text}</div>,
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Boulangerie",
      dataIndex: "bakehouse",
      className: "text-center",
      render: (text, record) => <div className="text-center">{text}</div>,
      sorter: (a, b) => a.bakehouse.length - b.bakehouse.length,
    },
    {
      title: "Reference",
      dataIndex: "comment",
      className: "text-center",
      render: (text, record) => <div className="text-center">{text}</div>,
      sorter: (a, b) => a.comment.length - b.comment.length,
    },
    // {
    //   title: "Email",
    //   dataIndex: "email",
    //   className: "text-center",
    //   render: (text, record) => <div className="text-center">{text}</div>,
    //   sorter: (a, b) => a.email.length - b.email.length,
    // },
    // {
    //   title: "Entreprise",
    //   dataIndex: "company",
    //   className: "text-center",
    //   render: (text, record) => <div className="text-center">{text}</div>,
    //   sorter: (a, b) => a.company.length - b.company.length,
    // },
    // {
    //   title: "Adresse",
    //   dataIndex: "address",
    //   className: "text-center",
    //   render: (text, record) => <div className="text-center">{text}</div>,
    //   sorter: (a, b) => a.address.length - b.address.length,
    // },
    // {
    //   title: "Statut",
    //   dataIndex: "is_deleted",
    //   render: (text, record) => (
    //     <>
    //       <div className="status-toggle d-flex justify-content-between align-items-center">
    //         <input
    //           type="checkbox"
    //           id="user1"
    //           className="check"
    //           defaultChecked={text==0 ? text = 1 : text = 0}
    //         />
    //         <label htmlFor="user1" className="checktoggle">
    //           checkbox
    //         </label>
    //       </div>
    //     </>
    //   ),
    //   sorter: (a, b) => a.is_deleted.length - b.is_deleted.length,
    // },
    {
      title: "Action",
      className: "text-center",
      render: (text, record) => (
        <div className="text-center">
           <button title="Justification de depenses"
            className="me-0 btn"
          //  to={`${url.image}${record.justification}`}
           onClick={()=>seeImageJustif(record)}
          //  target="_blank"
          >
            <i className="fa fa-file"></i>
          </button> 
          <button className="me-1s confirm-text btn" onClick={()=>deleteItem(record.id)}>
            <img src={DeleteIcon} alt="img" />
          </button>
        </div>
      ),
      // sorter: (a, b) => a.Action.length - b.Action.length,
    },
  ];

  const fetcItemList = async ()=>{
    setLoading(true);
    try {
        axios.get(url.base+'/expenses',{
            headers:{
                'Content-Type':'application/json',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                
            },
        }).then((resp)=>{

            if(resp.status == 200){
                setItemList(resp.data?.data);
                const transArray = [];
                resp.data.data.forEach(item => { 
                  transArray.push({
                    "Date":moment(item.created_at).format("Do MMMM YYYY HH:mm"),
                    "Libelle":item.libelle,
                    "Catégorie":item.name,
                    "Montant":item.total_amount,
                    "Reference":item.comment,
                });
                });
                setExcelData(transArray)
            }
            setLoading(false);
        })
    } catch (error) {
        setLoading(false);
    }
}

  const submitForm= (e)=>{
    e.preventDefault();

    if(itemData.libelle !=="" && itemData.total_amount !=="" && optFamille !==null ){
      const _formData = new FormData();
      _formData.append('libelle',itemData.libelle);
      _formData.append('total_amount',itemData.total_amount);
      _formData.append('expense_category_id',optFamille);
      _formData.append('comment',itemData.comment);
      _formData.append('bakehouse_id',selectBakehouse);

      if(itemData.justification !=""){
        const currentTimeInSeconds = Math.floor(new Date().getTime() / 1000);
        const originalExtension = itemData.justification.name.split('.').pop();
        const newFileName = `${currentTimeInSeconds}_justification_.${originalExtension}`;
        const photo = new File([itemData.justification], newFileName, { type: itemData.justification.type });
        
        _formData.append("justification",photo);
      }
      
      console.log(_formData);
      setLoading(true);
      try {
          axios.post(url.base+'/expenses',_formData,
          {
              headers:{
                  'Content-Type':'multipart/form-data',
                  "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                  
              },
              // credentials:'include'
          }
          ).then((resp)=>{
              setLoading(false);
              if(resp.status == 200){
  
                  setErrors({});
                    toast.success("Enregistrement avec succès.")
                    setItemData({
                      "libelle":"",
                      "total_amount":"",
                      "comment":"",
                    });
                    setOptFamille(null);
                    fetcItemList()
              }else{
                toast.error("Un problème est subvenu !")
              }
          })
      } catch (error) {
          setLoading(false);
  // console.log(error);  
      }
    }else{
      if(itemData.libelle =="" ){
        toast.error("Le libellé est obligatoire")
      }else if(itemData.total_amount ==""){
        toast.error("Le montant est obligatoire")
      }else if(optFamille ==null){
        toast.error("La catégorie est obligatoire")
      }
    }

  }

  const submitFormEdit= (e)=>{
    e.preventDefault();
    if(itemData.libelle !=="" && itemData.total_amount !=="" && optFamille !==null && itemData.comment !==""){
    const _formData = new FormData();
    _formData.append('first_name',itemData.first_name);
    _formData.append('phone',itemData.phone);
    _formData.append('last_name',itemData.last_name);
    _formData.append('address',itemData.address);
    _formData.append('email',itemData.email);
    _formData.append('company',itemData.company);

    setLoading(true);
    try {
        axios.put(url.base+'/expenses/'+editItem?.id,_formData,
        {
            headers:{
                'Content-Type':'application/json',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                
            },
            // credentials:'include'
        }
        ).then((resp)=>{
            setLoading(false);
            if(resp.status == 200){

                setErrors({});
                toast.success("Modification effectué avec succès");
                  setItemData({
                    'first_name':'',
                    'phone':'',
                    'last_name':'',
                    'address':'',
                    'email':'',
                    'company':'',
                  });
                  window.$("#editpayment").modal('hide');
                  fetcItemList()
            }else{
              toast.error("Désolé, un problème est subvenu");
            }
        })
    } catch (error) {
        setLoading(false);
// console.log(error);  
    }
  }else{
      if(itemData.libelle =="" ){
        toast.error("Le libellé est obligatoire")
      }else if(itemData.total_amount ==""){
        toast.error("Le montant est obligatoire")
      }else if(optFamille ==null){
        toast.error("La catégorie est obligatoire")
      }else if(itemData.comment ==""){
        toast.error("Le commentaire est obligatoire")
      }
    }
  }

  const deleteItem=(id)=>{
    Swal.fire({
      title: 'ATTENTION !',
      text: 'Êtes-vous sûrs de vouloir supprimer cet élément.',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonText:"NON",
      confirmButtonText: 'OUI',
      cancelButtonColor:"red"
    }).then((result) => {
      if (result.isConfirmed) {
          try {
              axios.delete(url.base+'/expenses/'+id,
                  {
                      headers:{
                          'Content-Type':'application/json',
                          "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                          
                      },
                  }
              ).then((resp)=>{
                  // console.log(resp.data);
                  if(resp.status == 200)
                  {
                      if(resp.data.status == 600)
                      {
                         toast.error('Un problème est subvenu');
                      }else{
                         toast.success('Unité supprimé avec succès');
                      }
                     
                  }
                  fetcItemList();
              })
          } catch (error) {
              console.log(error);
          }

      
      }
    }); 
  }
  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Gestion des Dépenses</h4>
              {/* <h6>Manage Payment Settings</h6> */}
            </div>
            <div className="page-btn">
              <a
                className="btn btn-added"
                data-bs-toggle="modal"
                data-bs-target="#addpayment"
              >
                <img src={PlusIcon} alt="img" className="me-2" />
                Ajouter une depense
              </a>
            </div>
          </div>
          {/* /product list */}
          <div className="card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      className="form-control form-control-sm search-icon"
                      type="search"
                      placeholder="Recherche..."
                      onChange={handleSearch}
                    />
                    <a className="btn btn-searchset">
                      <img src={Search} alt="img" />
                    </a>
                  </div>
                </div>
                <div className="wordset">
                  <ul>
                    <li>
                      <a
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="pdf"
                      >
                        <img src={Pdf} alt="img" />
                      </a>
                    </li>
                    <li>
                    <ExportExcel excelData={excelData} filename={"depenses"}/>
                    </li>
                    {/* <li>
                      <a
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="print"
                      >
                        <img src={Printer} alt="img" />
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={itemList}
                  rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      {/* add Modal */}
      <div
        className="modal fade"
        id="addpayment"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Ajouter une depense</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <form onSubmit={submitForm}>
              <div className="modal-body">
              
                <div className="row">
                  <div className="col-6 ">
                    <div className="form-group">
                      <label>Libellé de la depense <span className="text-danger">*</span></label>
                      <input type="text" name="libelle" onChange={handleChange} value={itemData.libelle}/>
                      {errors && errors.name && (
                            <span className="text-danger">{errors.libelle[0]}</span>
                        )}
                    </div>
                  </div>
                  <div className="col-6 mt-2">
                      <label>Boulangerie</label>
                      <Select options={optionbakehouses} onChange={handleChangeBakehouse}/>
                  </div>
                  <div className="col-6 mt-2">
                    {/* <div className="form-group"> */}
                      <label>Catégorie de depense<span className="text-danger">*</span></label>
                      <Select options={optionfamilles} onChange={handleChangeFamille}/>
                      {errors && errors.expense_category_id && (
                            <span className="text-danger">{errors.expense_category_id[0]}</span>
                        )}
                    {/* </div> */}
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label>Montant de la dépense<span className="text-danger">*</span></label>
                      <input type="text" name="total_amount" onChange={handleChange} value={itemData.total_amount}/>
                      {errors && errors.total_amount && (
                          <span className="text-danger">{errors.total_amount[0]}</span>
                        )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label>Réference</label>
                      <input type="text" name="comment" onChange={handleChange} value={itemData.comment}/>
                      {errors && errors.comment && (
                            <span className="text-danger">{errors.comment[0]}</span>
                      )}
                    </div>
                  </div>
                   <div className="col-12">
                    <div className="form-group">
                      <label>Justif.(img,png)<span className="text-danger">*</span></label>
                      <input type="file" name="justification" className="form-control" onChange={handleFileChange}/>
                    </div>
                  </div>
                {/*  <div className="col-12">
                    <div className="form-group">
                      <label>Entreprise</label>
                      <input type="text" name="company" onChange={handleChange} value={itemData.company}/>
                      {errors && errors.company && (
                            <span className="text-danger">{errors.company[0]}</span>
                        )}
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="modal-footer ">
                <button type="submit" className="btn btn-submit">
                  Enregister
                </button>
                <button
                  type="button"
                  className="btn btn-cancel"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </form>
          
          </div>
        </div>
      </div>
      {/* edit Modal */}
      <div
        className="modal fade"
        id="editpayment"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{"Page d'edition"}</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <form onSubmit={submitFormEdit}>
              <div className="modal-body">
              
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label>Nom <span className="text-danger">*</span></label>
                      <input type="text" name="first_name" onChange={handleChange} value={itemData.first_name}/>
                      {errors && errors.name && (
                            <span className="text-danger">{errors.first_name[0]}</span>
                        )}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label>Prénoms<span className="text-danger">*</span></label>
                      <input type="text" name="last_name" onChange={handleChange} value={itemData.last_name}/>
                      {errors && errors.name && (
                            <span className="text-danger">{errors.last_name[0]}</span>
                        )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label>Email<span className="text-danger">*</span></label>
                      <input type="text" name="email" onChange={handleChange} value={itemData.email}/>
                      {errors && errors.name && (
                            <span className="text-danger">{errors.email[0]}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label>Adresse<span className="text-danger">*</span></label>
                      <input type="text" name="address" onChange={handleChange} value={itemData.address}/>
                      {errors && errors.address && (
                            <span className="text-danger">{errors.address[0]}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label>Téléphone<span className="text-danger">*</span></label>
                      <input type="text" name="phone" onChange={handleChange} value={itemData.phone}/>
                      {errors && errors.name && (
                            <span className="text-danger">{errors.phone[0]}</span>
                        )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label>Entreprise</label>
                      <input type="text" name="company" onChange={handleChange} value={itemData.company}/>
                      {errors && errors.company && (
                            <span className="text-danger">{errors.company[0]}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer ">
                <button type="submit" className="btn btn-submit">
                  Enregister
                </button>
                <button
                  type="button"
                  className="btn btn-cancel"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </form>
          
          </div>
        </div>
      </div>
    </>
  );
};
export default DepensePage;
