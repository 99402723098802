/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Calendar,
  Plus,
  Scanner,
  DeleteIcon,
  EditIcon,
  MacbookIcon,
  EarpodIcon,
  Dollar,
} from "../../EntryFile/imagePath";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import BaseUrl from "../../utils/BaseUrl";
import separateNumber from "../../utils/separateur";
import { toast } from "react-toastify";
import can from "../../utils/Can";

const url = BaseUrl();
const AddVersementCustomers = () => {
  const {id} = useParams();
  const {name} = useParams();
  const [startDate, setStartDate] = useState(new Date());
  const [montantVerse,setMontantVerse] = useState(0);
  const [description,setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors,setErrors] = useState({});
  const history = useHistory();

  const [livreur,setLivreur]= useState([]);


  
useEffect(()=>{
  try {
    axios.get(url.base+'/me',{
             headers:{
                 'Content-Type':'application/json',
                 "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                 
             },
         }).then((resp)=>{

            if(!can(resp.data.data.permissions,"voir-versement") ){
                window.location.href = '/boulangerie/dashboard';
            }

         })
     } catch (error) {
         // setLoading(false);
     }
},[]);


  useEffect(()=>{
    livreurList();
  },[]);

  
  function livreurList(){
    try {
      axios.get(url.base+'/transactions/versement-view-customers/'+id,{
          headers:{
              'Content-Type':'application/json',
              "Authorization": `Bearer ${localStorage.getItem('_token_')}`
              
          },
      }).then((resp)=>{
  
          if(resp.status == 200){
            setLivreur(resp.data.data);
          }
          // setLoading(false);
      })
  } catch (error) {
      // setLoading(false); 
  }
  }

  const handleChangeMontant=(value)=>{
    // var value = e.target.value;
    const restaverse = parseInt(livreur.orders_sum_total_amount) - livreur.transactions_sum_total_amount;
    if(value > restaverse){
    //  setMontantVerse(restaverse);
      toast.error("Attention, montant supperieur au reste à verser.")
    }else{
      setMontantVerse(value);
    }
  }

  const submitForm= (e)=>{
    e.preventDefault();

    const _formData = new FormData();
    _formData.append('total_amount',montantVerse);
    _formData.append('customer_id',id);
    _formData.append('note',description);
    _formData.append('type_payment',0);
    
    console.log(_formData);

    if(montantVerse > 0){
      setLoading(true);
      try {
          axios.post(url.base+'/transaction-store',_formData,
          {
              headers:{
                  'Content-Type':'application/json',
                  "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                  
              },
              // credentials:'include'
          }
          ).then((resp)=>{
              setLoading(false);
              if(resp.status == 200){
                  toast.success("Versement effectué avec succès.");

                  history.push('/boulangerie/finances/versement-customers')
              }else{
                toast.error("Un problème est subvenu !")
              }
          }).catch((error)=>{  
            // console.log(error.response);              
              setLoading(false);
  
              if (error.response.status == 422) {
                  setErrors(error.response.data.errors);
              }
              // console.log(errors);
          })
      } catch (error) {
          setLoading(false);
  // console.log(error);  
      }
    }else{
      toast.error("Avertissemnet, Le montant est inférieur ou egale à 0");
    }

  }


  



  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4><u>Versement du client :</u> {name.toUpperCase()} </h4>
            </div>
          </div>
          <div className="card">
            <form className="card-body" onSubmit={submitForm}>
              <div className="row">
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="dash-widget">
                    <div className="dash-widgetimg">
                      <span>
                        <img src="/static/media/dash1.92d4928cc799519b625e6119719019f5.svg" alt="img" />
                      </span>
                    </div>
                    <div className="dash-widgetcontent">
                      <h5><span className="counters"><span>{separateNumber(parseInt(livreur.orders_sum_total_amount)) }</span></span></h5>
                      <h6>Total des commandes</h6>
                    </div>
                    </div>
                  
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="dash-widget">
                    <div className="dash-widgetimg">
                      <span>
                        <img src="/static/media/dash2.012e22b0d7c7bfa645803351faf8f8cf.svg" alt="img" />
                      </span>
                    </div>
                    <div className="dash-widgetcontent">
                      <h5><span className="counters"><span>{separateNumber(parseInt(livreur.orders_sum_total_amount) )}</span></span></h5>
                      <h6>Total à verser</h6>
                    </div>
                    </div>
                  
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="dash-widget">
                    <div className="dash-widgetimg">
                      <span>
                        <img src="/static/media/dash1.92d4928cc799519b625e6119719019f5.svg" alt="img" />
                      </span>
                    </div>
                    <div className="dash-widgetcontent">
                      <h5><span className="counters"><span>{separateNumber(parseInt(livreur.transactions_sum_total_amount))}</span></span></h5>
                      <h6>Total versé</h6>
                    </div>
                    </div>
                  
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="dash-widget">
                    <div className="dash-widgetimg">
                      <span>
                      <img src="/static/media/dash2.012e22b0d7c7bfa645803351faf8f8cf.svg" alt="img" />
                      </span>
                    </div>
                    <div className="dash-widgetcontent">
                      <h5 className="text-danger"><span className="counters"><span>{separateNumber(parseInt(livreur.orders_sum_total_amount) - livreur.transactions_sum_total_amount)}</span></span></h5>
                      <h6>Reste à verser</h6>
                    </div>
                    </div>
                  
                </div>

                <div className="col-lg-12 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Montant à verser</label>
                    <div className="input-groupicon">
                      <input type="text" className="text-end" name="montantVerse"  onChange={(e)=>handleChangeMontant(e.target.value)} value={montantVerse}/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Description</label>
                    <textarea className="form-control" onChange={(e)=>setDescription(e.target.value)} rows="10"/>
                  </div>
                </div>
                <div className="col-lg-12">
                    {loading &&
                      <button className="btn btn-submit" type="button" disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            En chargement...
                        </button>
                    }
                    {!loading &&
                    <button type="submit" className="btn btn-submit me-2" disabled={montantVerse <= 0 | montantVerse > (livreur.livraisons_sum_total_amount - livreur.retours_sum_total_amount) - livreur.transactions_sum_total_amount  ? "disabled" : ""}>
                      Valider le versement
                    </button>
                    }
                  <button type="button" className="btn btn-cancel">Annuler</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddVersementCustomers;
