/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Table from "../../EntryFile/datatable";
import { Link } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import Tabletop from "../../EntryFile/tabletop";
import "react-select2-wrapper/css/select2.css";
import Swal from "sweetalert2";
import {
  ClosesIcon,
  Excel,
  Filter,
  Pdf,
  PlusIcon,
  Printer,
  Search,
  search_whites,
  EditIcon,
  DeleteIcon,
} from "../../EntryFile/imagePath";
import axios from "axios";
import BaseUrl from "../../utils/BaseUrl";
import can from "../../utils/Can";
import { toast } from "react-toastify";

const url = BaseUrl();
const StoreList = () => {
  const [inputfilter, setInputfilter] = useState(false);
  const [bakehouses,setBakehouses] = useState([]);
const [excelData,setExcelData] = useState({});
const [permissions,setPermissions] = useState();


useEffect(()=>{
  try {
    axios.get(url.base+'/me',{
             headers:{
                 'Content-Type':'application/json',
                 "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                 
             },
         }).then((resp)=>{
          setPermissions(resp.data.data.permissions);
            if(!can(resp.data.data.permissions,"gestion-boulangerie") ){
                window.location.href = '/boulangerie/dashboard';
            }

         })
     } catch (error) {
         // setLoading(false);
     }
},[]);



  useEffect(()=>{
    bakehouseList();
  },[]);

  function bakehouseList(){
    try {
      axios.get(url.base+'/bakehouses',{
          headers:{
              'Content-Type':'application/json',
              "Authorization": `Bearer ${localStorage.getItem('_token_')}`
              
          },
      }).then((resp)=>{
  
          if(resp.status == 200){
            setBakehouses(resp.data.data);
          const transArray = [];
          resp.data.data.forEach(item => { 
            transArray.push({
              "NOM":`${item.name}`,
              "RESPONSABLE":item.responsable,
              "TELEPHONE":item.phone,
              "ADRESSE":item.address,

          });
                  });
        setExcelData(transArray)
          }
          // setLoading(false);
      })
  } catch (error) {
      // setLoading(false);
  }
  }

  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    if (searchValue.trim() === "") {
      bakehouseList();
    } else {
      const filtered = bakehouses.filter(item =>
        Object.values(item).some(value =>
          typeof value === 'string' && value.toLowerCase().includes(searchValue)
        )
      );
      setBakehouses(filtered);
    }
  
};
  const togglefilter = (value) => {
    setInputfilter(value);
  };
  const options = [
    { id: 1, text: "Disable", text: "Disable" },
    { id: 2, text: "Enable", text: "Enable" },
  ];
  const [data] = useState([
    {
      id: 1,
      storeName: "Thomas",
      userName: "Thomas71",
      phone: "+12163547758",
      email: "thomas@example.com",
      status: true,
    },
    {
      id: 2,
      storeName: "Benjamin",
      userName: "Benjamin23",
      phone: "123-456-776",
      email: "benjamin@example.com",
      status: false,
    },

  ]);

  const columns = [
    {
      title: "Nom de la boulangerie",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Nom du responsable",
      dataIndex: "responsable",
      sorter: (a, b) => a.responsable.length - b.responsable.length,
    },
    {
      title: "Téléphone",
      dataIndex: "phone",
      sorter: (a, b) => a.phone.length - b.phone.length,
    },
    {
      title: "Adresse",
      dataIndex: "address",
      sorter: (a, b) => a.address.length - b.address.length,
    },

    // {
    //   title: "Nombre de livreurs",
    //   dataIndex: "nb_delivery_person",
    //   className:"text-center",
    //   sorter: (a, b) => a.nb_delivery_person.length - b.nb_delivery_person.length,
    // },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   render: (e, record) => (
    //     <div className="status-toggle d-flex justify-content-between align-items-center">
    //       <input
    //         type="checkbox"
    //         id="user18"
    //         className="check"
    //         defaultChecked={e}
    //       />
    //       <label htmlFor="user18" className="checktoggle">
    //         checkbox
    //       </label>
    //     </div>
    //   ),
    // },
    {
      title: "Action",
      render: (text,record) => (
        <>
          <Link className="me-3" to={`/boulangerie/comptes/edit-boulangerie/${record.uuid}`}>
            <img src={EditIcon} alt="img" />
          </Link>
          {can(permissions,"supprimer boulangerie") &&
              <button className="btn btn-danger btn-sm pb-0 mb-1" onClick={()=>deleteFunctionUser(record.id)}>
                  <i className="fa fa-trash"></i>
              </button>
          }
          
        </>
      ),
    },
  ];

  const deleteFunctionUser=(id)=>{
    Swal.fire({
      title: 'INFO !',
      text: 'Cette action va activer cette boulangerie.',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonText:"NON",
      confirmButtonText: 'OUI',
      cancelButtonColor:"red"
    }).then((result) => {
      if (result.isConfirmed) {
          try {
              axios.get(url.base+'/deleted-bakehouses/'+id,
                  {
                      headers:{
                          'Content-Type':'application/json',
                          "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                          
                      },
                  }
              ).then((resp)=>{
                  // console.log(resp.data);
                  if(resp.status == 200)
                  {
             
                      toast.success('Boulangerie supprimée avec succès');
                     
                  }
                  bakehouseList();
              })
          } catch (error) {
              console.log(error);
          }

      
      }
    }); 
  }

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Liste des boulangeries</h4>
              {/* <h6>Manage your Store</h6> */}
            </div>
            <div className="page-btn">
              <Link to="/boulangerie/comptes/create-boulangerie" className="btn btn-added">
                <img src={PlusIcon} alt="img" className="me-1" />
                Ajouter une nouvelle boulangerie
              </Link>
            </div>
          </div>
          {/* /product list */}
          <div className="card">
            <div className="card-body">
            <Tabletop inputfilter={inputfilter} togglefilter={togglefilter} excelData={excelData} filename={"boutiques"} handleSearch={handleSearch}/>
              {/* /Filter */}
              <div
                className={`card mb-0 ${ inputfilter ? "toggleCls" : ""}`}
                id="filter_inputs"
                style={{ display: inputfilter ? "block" :"none"}}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="form-group">
                        <input type="text" placeholder="Enter User Name" />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="form-group">
                        <input type="text" placeholder="Enter Phone" />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="form-group">
                        <input type="text" placeholder="Enter Email" />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="form-group">
                        <Select2
                          className="select"
                          data={options}
                          options={{
                            placeholder: "Disable",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-1 col-sm-6 col-12 ms-auto">
                      <div className="form-group">
                        <a className="btn btn-filters ms-auto">
                          <img src={search_whites} alt="img" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
                <Table columns={columns} dataSource={bakehouses} />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </>
  );
};
export default StoreList;
