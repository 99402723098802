/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Table from "../../EntryFile/datatable";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  ClosesIcon,
  Excel,
  Filter,
  Pdf,
  Calendar,
  Printer,
  search_whites,
  Search,
  MacbookIcon,
  OrangeImage,
  PineappleImage,
  StawberryImage,
  AvocatImage,
  Product1,
  Product7,
  Product8,
  Product9,
  PlusIcon,
  Eye1,
EditIcon,
Download,
delete1
} from "../../EntryFile/imagePath";
import axios from "axios";
import BaseUrl from "../../utils/BaseUrl";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import moment from "moment";
import separateNumber from "../../utils/separateur";
import ExportExcel from "../../utils/ExcelExport";
import can from "../../utils/Can";

const url = BaseUrl();
const LivraisonList =  (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [startDate1, setStartDate1] = useState(new Date());
  const [inputfilter, setInputfilter] = useState(false);
  const [orderList,setOrderList] = useState([]);
  const [excelData,setExcelData] = useState({});
  const [permissions,setPermissions] = useState();

  const togglefilter = (value) => {
    setInputfilter(value);
  };

  useEffect(()=>{
    try {
      axios.get(url.base+'/me',{
               headers:{
                   'Content-Type':'application/json',
                   "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                   
               },
           }).then((resp)=>{
              setPermissions(resp.data.data.permissions);
              if(!can(resp.data.data.permissions,"mes-livraisons") ){
                  window.location.href = '/boulangerie/dashboard';
              }
  
           })
       } catch (error) {
           // setLoading(false);
       }
  },[]);


  useEffect(()=>{
    productList();
  },[]);


  function productList(){
    try {
      axios.get(url.base+'/deliveries',{
          headers:{
              'Content-Type':'application/json',
              "Authorization": `Bearer ${localStorage.getItem('_token_')}`
              
          },
      }).then((resp)=>{
  
          if(resp.status == 200){
            setOrderList(resp.data.data);
            const transArray = [];
            resp.data.data.forEach(item => { 
              transArray.push({
                "Date":moment(item.created_at).format("Do MMMM YYYY HH:mm"),
                "Reference":item.reference,
                "Revendeur":`${item.delivery_person.first_name} ${item.delivery_person.last_name}`,
                "Montant":item.total_amount,
                "Statut":item.status,
            });
            });
            setExcelData(transArray)
          }
          // setLoading(false);
      })
  } catch (error) {
      // setLoading(false);
  }
  }
  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    if (searchValue.trim() === "") {
      productList();
    } else {
      const filtered = orderList.filter(item =>
        Object.values(item).some(value =>
          typeof value === 'string' && value.toLowerCase().includes(searchValue)
        )
      );
      setOrderList(filtered);
    }
  
  };


  const columns3 = [
    {
      title: "Date",
      dataIndex: "created_at",
      className:"text-center",
      render: (text, record) => (
        <>
            <div>{moment(text).format("Do MMMM YYYY HH:mm")}</div>
        </>
      ),
      sorter: (a, b) => a.created_at.length - b.created_at.length,
    },
    {
      title: "Reference",
      dataIndex: "reference",
      className:"text-center",
      sorter: (a, b) => a.reference.length - b.reference.length,
    },
    {
      title: "Boulangerie",
      dataIndex: "bakehouse",
      className: "text-center",
      render: (text, record) => (
        <b>{record.bakehouse?.name}</b>
      ),
      sorter: (a, b) => a.bakehouse.length - b.bakehouse.length,
    },
    {
      title: "Nom et prenom du revendeur",
      dataIndex: "delivery_person",
      className:"text-center",
      render: (text, record) => (
        <div>
            {record.delivery_person.first_name} {record.delivery_person.last_name}
        </div> 
      ),
      sorter: (a, b) => a.delivery_person.length - b.delivery_person.length,
    },
    {
      title: "Montant en FCFA",
      dataIndex: "total_amount",
      className:"text-center",
      render: (text, record) => (
        <>
          <div>
            {separateNumber(text)}
          </div> 
        </>
      ),
      sorter: (a, b) => a.total_amount.length - b.total_amount.length,
    },

    {
      title: "Statut",
      dataIndex: "status",
      className:"text-center",
      render: (text, record) => (
        <>
          {text == 1 && (
            <span className="badges bg-lightgreen">Livré</span>
          )}
          {text == 0 && (
            <span className="badges bg-danger">En attente</span>
          )}
      
        </>
      ),
      sorter: (a, b) => a.status.length - b.status.length,
    },



    {
      title: "Action",
      className:"text-center",
      render: (text, record) => (
        <>
        {record.status == 1 &&
          <>
            <Link className="btn btn-sm btn-secondary me-2"  to={`/boulangerie/production/livraisons-details/${record.uuid}`}> <i className="fa fa-eye"></i>  </Link>
          </>
        }
        {record.status == 0 &&
          <>
            <Link className="btn btn-sm btn-secondary me-2"  to={`/boulangerie/production/livraisons-details/${record.uuid}`}> <i className="fa fa-eye"></i>  </Link>
              {can(permissions,'validation-livraison') &&
                              <>
                  <button className="btn btn-sm btn-warning me-2" onClick={()=>validationItem(record.id)}> <i className="fa fa-pencil"></i> Valider </button>
                  <button className="btn btn-sm btn-danger me-2" onClick={()=>deleteItem(record.id)}> <i className="fa fa-trash"></i>  </button>
                  </>
                    }
          </>
        }
        

        
          {/* <div className="text-center">
            <Link
              className="action-set"
              to="#"
              data-bs-toggle="dropdown"
              aria-expanded="true"
            >
              <i className="fa fa-ellipsis-v" aria-hidden="true" />
            </Link>
            <ul className="dropdown-menu">
              <li>
                <Link
                  to={`/boulangerie/approvisionnement/achat-details/${record.uuid}`}
                  className="dropdown-item"
                >
                  <img src={Eye1} className="me-2" alt="img" />
                  {"Détail de l'achat"}
                </Link>
              </li>
              <li>
                <Link
                  to={`/boulangerie/approvisionnement/edit-achats/${record.uuid}`}
                  className="dropdown-item"
                >
                  <img src={EditIcon} className="me-2" alt="img" />
                  {"Modifier l'achat"}
                </Link>
              </li>
              <li>
                <Link to="#" className="dropdown-item">
                  <img src={Download} className="me-2" alt="img" />
                  Download pdf
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="dropdown-item confirm-text"
                  onClick={null}
                >
                  <img src={delete1} className="me-2" alt="img" />
                  {"Supprimer l'achat"}
                </Link>
              </li>
            </ul>
          </div> */}
        </>
      ),
    },
  ];
  const deleteItem=(id)=>{
    Swal.fire({
      title: 'ATTENTION !',
      text: 'Êtes-vous sûrs de vouloir supprimer cette livraison.',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonText:"NON",
      confirmButtonText: 'OUI',
      cancelButtonColor:"red"
    }).then((result) => {
      if (result.isConfirmed) {
          try {
              axios.delete(url.base+'/deliveries/'+id,
                  {
                      headers:{
                          'Content-Type':'application/json',
                          "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                          
                      },
                  }
              ).then((resp)=>{
                  // console.log(resp.data);
                  if(resp.status == 200)
                  {
                      if(resp.data.status == 600)
                      {
                        toast.error('Un problème est subvenu');
                          // Swal.fire(
                          //     'Attention',
                          //     'Un problème est subvenu',
                          //     'error'
                          //   )
                      }else{
                          toast.success('Livraison supprimé avec succès');
                      }
                     
                  }
                  productList();
              })
          } catch (error) {
              console.log(error);
          }

      
      }
    }); 
  }

  const validationItem=(id)=>{
    Swal.fire({
      title: 'INFO !',
      text: 'Êtes-vous sûrs de vouloir valider cette livraison.',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonText:"NON",
      confirmButtonText: 'OUI',
      cancelButtonColor:"red"
    }).then((result) => {
      if (result.isConfirmed) {
          try {
              axios.get(url.base+'/deliveries-validate/'+id,
                  {
                      headers:{
                          'Content-Type':'application/json',
                          "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                          
                      },
                  }
              ).then((resp)=>{
                  // console.log(resp.data);
                  if(resp.status == 200)
                  {
                      if(resp.data.status == 600)
                      {
                        toast.error('Un problème est subvenu');
                          // Swal.fire(
                          //     'Attention',
                          //     'Un problème est subvenu',
                          //     'error'
                          //   )
                      }else{
                          toast.success('Livraison Validée avec succès');
                      }
                     
                  }
                  productList();
              })
          } catch (error) {
              console.log(error);
          }

      
      }
    }); 
  }
  const Downloadpdf= (e)=>{
    e.preventDefault();
  
  
  
    try {
        axios.get(url.base+'/export-livraison-list-pdf/',
        {
            headers:{
                'Content-Type':'application/pdf',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`,
                
            },
            responseType:'blob',
            // credentials:'include'
        }
        ).then((response)=>{
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'commande_liste.pdf');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
    } catch (error) {
  // console.log(error);  
    }
  }
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Liste des livraisons</h4>
          </div>
          {can(permissions,'ajout-livraison') &&
            <div className="page-btn">
              <Link to="/boulangerie/production/livraisons-add" className="btn btn-added">
                <img src={PlusIcon} alt="img" className="me-1" />
              Ajouter une livraison
              </Link>
          </div>
          }
      
        </div>
        {/* /product list */}
        <div className="card">
          <div className="card-body">
            <div className="tabs-set">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                {/* <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="purchase-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#purchase"
                    type="button"
                    role="tab"
                    aria-controls="purchase"
                    aria-selected="true"
                  >
                    Toutes les livraisons (7)
                  </button>
                </li> */}
                {/* <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="payment-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#payment"
                    type="button"
                    role="tab"
                    aria-controls="payment"
                    aria-selected="false"
                  >
                    Comandes livreurs (3)
                  </button>
                </li> */}
                {/* <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="return-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#return"
                    type="button"
                    role="tab"
                    aria-controls="return"
                    aria-selected="false"
                  >
                    Nouvelle livraisons (4)
                  </button>
                </li> */}
              </ul>
              <div className="table-top">
                <div className="search-set">
                  <div className="search-path">
                    <a
                      className={` btn ${inputfilter ? "btn-filter setclose" : "btn-filter"
                        } `}
                      id="filter_search"
                      onClick={() => togglefilter(!inputfilter)}
                    >
                      <img src={Filter} alt="img" />
                      <span>
                        <img src={ClosesIcon} alt="img" />
                      </span>
                    </a>
                  </div>
                  <div className="search-input">
                    <input
                      className="form-control form-control-sm search-icon"
                      type="search"
                      placeholder="Recherche..."
                      onChange={handleSearch}
                    />
                    <a className="btn btn-searchset">
                      <img src={Search} alt="img" />
                    </a>
                  </div>
                </div>
                <div className="wordset">
                  <ul>
                    <li>
                      <a onClick={(e)=>Downloadpdf(e)}
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="pdf"
                      >
                        <img src={Pdf} alt="img" />
                      </a>
                    </li>
                     <li>
                     <ExportExcel excelData={excelData} filename={"livraisons"}/>
                    </li>
                    {/* <li>
                      <a
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="print"
                      >
                        <img src={Printer} alt="img" />
                      </a>
                    </li>  */}
                  </ul>
                </div>
              </div>
              {/* /Filter */}
              <div
                className={`card mb-0 ${inputfilter ? "toggleCls" : ""}`}
                id="filter_inputs"
                style={{ display: inputfilter ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="form-group">
                        <div className="input-groupicon">
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                          />
                          <div className="addonset">
                            <img src={Calendar} alt="img" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="form-group">
                        <div className="input-groupicon">
                          <DatePicker
                            selected={startDate1}
                            onChange={(date) => setStartDate1(date)}
                          />
                          <div className="addonset">
                            <img src={Calendar} alt="img" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1 col-sm-6 col-12 ms-auto">
                      <div className="form-group">
                        <a className="btn btn-filters ms-auto">
                          <img src={search_whites} alt="img" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="tab-content" id="myTabContent">
                {/* <div
                  className="tab-pane fade show active"
                  id="purchase"
                  role="tabpanel"
                  aria-labelledby="purchase-tab"
                >
                  <div className="table-responsive">
                    <Table
                      columns={columns1}
                      dataSource={data1}
                      rowKey={(record) => record.id}
                    />
                  </div>
                </div> */}
                {/* <div className="tab-pane fade show active" id="payment" role="tabpanel">
                  <div className="table-responsive">
                    <Table
                      columns={columns2}
                      dataSource={data2}
                      rowKey={(record) => record.id}
                    />
                  </div>
                </div> */}
                <div className="tab-pane fade show active" id="return" role="tabpanel">
                  <div className="table-responsive">
                    <Table
                    props={props}
                      columns={columns3}
                      dataSource={orderList}
                      rowKey={(record) => record.id}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>
    </div>
  );
};

export default LivraisonList;
