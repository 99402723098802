/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Table from "../../EntryFile/datatable";
import Tabletop from "../../EntryFile/tabletop";
import Swal from "sweetalert2";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import { Link } from "react-router-dom";
import {
  ClosesIcon,
  Excel,
  Filter,
  Pdf,
  PlusIcon,
  Printer,
  Search,
  search_whites,
  EditIcon,
  DeleteIcon,
} from "../../EntryFile/imagePath";
import axios from "axios";
import BaseUrl from "../../utils/BaseUrl";
import ExportExcel from "../../utils/ExcelExport";
import moment from "moment";
import { toast } from "react-toastify";
import can from "../../utils/Can";

const url = BaseUrl();

const CategoryDepense = () => {
    const [errors,setErrors] = useState({});
    // const [loading, setLoading] = useState(false);
    const [itemData,setItemData] = useState({
      "name":""
    });
    // const [loading, setLoading] = useState(false);
    const [editItem,setEditItem] = useState(null);
  
    const [itemList,setItemList] = useState([]);
    const [excelData,setExcelData] = useState({});
  
    useEffect(()=>{
      try {
        axios.get(url.base+'/me',{
                 headers:{
                     'Content-Type':'application/json',
                     "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                     
                 },
             }).then((resp)=>{
  
                if(!can(resp.data.data.permissions,"voir-depenses") ){
                    window.location.href = '/boulangerie/dashboard';
                }
    
             })
         } catch (error) {
             // setLoading(false);
         }
    },[]);


    useEffect(()=>{
      fetcItemList()
    },[])
  
    const handleSearch = (e) => {
      const searchValue = e.target.value.toLowerCase();
      if (searchValue.trim() === "") {
        fetcItemList();
      } else {
        const filtered = itemList.filter(item =>
          Object.values(item).some(value =>
            typeof value === 'string' && value.toLowerCase().includes(searchValue)
          )
        );
        setItemList(filtered);
      }
    
  };
    const handleChange=(e)=>{
      setItemData({
          ...itemData,
          [e.target.name]:e.target.value
      });
    }
  
  
  
    const showModalEdit=(unit)=>{
  
      setEditItem(unit);

      setItemData({
        "name":unit.name
      });
      window.$("#editpayment").modal('show');
    }
  
    const columns = [
      {
        title: "Libelle",
        dataIndex: "name",
        className: "text-center",
        render: (text, record) => <div className="text-center">{text}</div>,
        sorter: (a, b) => a.name.length - b.name.length,
      },
      {
        title: "Statut",
        dataIndex: "is_deleted",
        render: (text, record) => (
          <>
            <div className="status-toggle d-flex justify-content-between align-items-center">
              <input
                type="checkbox"
                id="user1"
                className="check"
                defaultChecked={text==0 ? text = 1 : text = 0}
              />
              <label htmlFor="user1" className="checktoggle">
                checkbox
              </label>
            </div>
          </>
        ),
        sorter: (a, b) => a.is_deleted.length - b.is_deleted.length,
      },
      {
        title: "Action",
        className: "text-center",
        render: (text, record) => (
          <div className="text-center">
            <button
              className="me-3 btn"
              onClick={()=>showModalEdit(record)}
            >
              <img src={EditIcon} alt="img" />
            </button>
            <button className="me-2 confirm-text btn" onClick={()=>deleteItem(record.id)}>
              <img src={DeleteIcon} alt="img" />
            </button>
          </div>
        ),
        // sorter: (a, b) => a.Action.length - b.Action.length,
      },
    ];
  
    const fetcItemList = async ()=>{
    //   setLoading(true);
      try {
          axios.get(url.base+'/expenses-categories',{
              headers:{
                  'Content-Type':'application/json',
                  "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                  
              },
          }).then((resp)=>{
  
              if(resp.status == 200){
                  setItemList(resp.data.data);
                  const transArray = [];
                  resp.data.data.forEach(item => { 
                    transArray.push({
                      "Date de creation":moment(item.created_at).format("Do MMMM YYYY HH:mm"),
                      "Libelle":item.name,

                  });
                  });
                  setExcelData(transArray)
              }
              
          })
      } catch (error) {
          console.log(error)
      }
  }
  
    const submitForm= (e)=>{
      e.preventDefault();
  
      const _formData = new FormData();
      _formData.append('name',itemData.name);
  
      if(itemData.name !== ""){
        try {
          axios.post(url.base+'/expenses-categories',_formData,
              {
                  headers:{
                      'Content-Type':'application/json',
                      "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                      
                  },
                  // credentials:'include'
              }
              ).then((resp)=>{
                  if(resp.status == 200){
      
                      setErrors({});
                      toast.success("Enregistrement effectué avec succès");
                        setItemData({
                          'name':''
                        });
                        fetcItemList()
                  }else{
                    toast.error("Désolé, un problème est subvenu");
                  }
              })
          } catch (error) {
              
      // console.log(error);  
          }
      }else{
        if(itemData.name == ""){
          toast.error("Le libellé est obligatoire");
        }
      }

    }
  
    const submitFormEdit= (e)=>{
      e.preventDefault();
  
      const _formData = new FormData();
      _formData.append('name',itemData.name);
  
      if(itemData.name !== ""){
      try {
          axios.put(url.base+'/expenses-categories/'+editItem?.id,_formData,
          {
              headers:{
                  'Content-Type':'application/json',
                  "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                  
              },
              // credentials:'include'
          }
          ).then((resp)=>{
              
              if(resp.status == 200){
  
                  setErrors({});
                  toast.success("Modification effectué avec succès");
                    setItemData({
                      'name':''
                    });
                    window.$("#editpayment").modal('hide');
                    fetcItemList()
              }else{
                toast.error("Désolé, un problème est subvenu");
              }
          })
      } catch (error) {
          
  // console.log(error);  
      }
    }else{
      if(itemData.name == ""){
        toast.error("Le libellé est obligatoire");
      }
    }
    }
  
    const deleteItem=(id)=>{
      Swal.fire({
        title: 'ATTENTION !',
        text: 'Êtes-vous sûrs de vouloir supprimer cet élément.',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonText:"NON",
        confirmButtonText: 'OUI',
        cancelButtonColor:"red"
      }).then((result) => {
        if (result.isConfirmed) {
            try {
                axios.delete(url.base+'/expenses-categories/'+id,
                    {
                        headers:{
                            'Content-Type':'application/json',
                            "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                            
                        },
                    }
                ).then((resp)=>{
                    // console.log(resp.data);
                    if(resp.status == 200)
                    {
                        if(resp.data.status == 600)
                        {
                            toast.error("Attention ! Un problème est subvenu")
                        }else{
                            toast.success("Suppression effectuée avec succès !")
                        }
                       
                    }
                    fetcItemList();
                })
            } catch (error) {
                console.log(error);
            }
  
        
        }
      }); 
    }

    return (
        <>
    <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Gestion des catégories de dépenses</h4>
              {/* <h6>Manage Payment Settings</h6> */}
            </div>
            <div className="page-btn">
              <a
                className="btn btn-added"
                data-bs-toggle="modal"
                data-bs-target="#addpayment"
              >
                <img src={PlusIcon} alt="img" className="me-2" />
                Ajouter une catégorie
              </a>
            </div>
          </div>
          {/* /product list */}
          <div className="card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      className="form-control form-control-sm search-icon"
                      type="search"
                      placeholder="Recherche..."
                      onChange={handleSearch}
                    />
                    <a className="btn btn-searchset">
                      <img src={Search} alt="img" />
                    </a>
                  </div>
                </div>
                <div className="wordset">
                  <ul>
                    <li>
                      <a
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="pdf"
                      >
                        <img src={Pdf} alt="img" />
                      </a>
                    </li>
                    <li>
                    <ExportExcel excelData={excelData} filename={"cat-depenses"}/>
                    </li>
                    {/* <li>
                      <a
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="print"
                      >
                        <img src={Printer} alt="img" />
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={itemList}
                  rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
    </div>
      {/* add Modal */}
      <div
        className="modal fade"
        id="addpayment"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Ajouter une catégorie de dépense</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <form onSubmit={submitForm}>
              <div className="modal-body">
              
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label>Libelle</label>
                      <input type="text" name="name" onChange={handleChange} value={itemData.name}/>
                      {errors && errors.name && (
                            <span className="text-danger">{errors.name[0]}</span>
                        )}
                    </div>
                  </div>
                  {/* <div className="col-12">
                    <div className="form-group mb-0">
                      <label>Status</label>
                      <Select2
                        className="select"
                        data={options}
                        options={{
                          placeholder: "Choose Status",
                        }}
                      />
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="modal-footer ">
                <button type="submit" className="btn btn-submit">
                  Enregister
                </button>
                <button
                  type="button"
                  className="btn btn-cancel"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </form>
          
          </div>
        </div>
      </div>
      {/* edit Modal */}
      <div
        className="modal fade"
        id="editpayment"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Editer </h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <form onSubmit={submitFormEdit}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label>Libelle</label>
                      <input type="text" name="name" onChange={handleChange} value={itemData.name}/>
                    </div>
                  </div>
                  {/* <div className="col-12">
                    <div className="form-group mb-0">
                      <label>Status</label>
                      <Select2
                        className="select"
                        data={options}
                        options={{
                          placeholder: "Choose Status",
                        }}
                      />
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-submit">
                  Modifier
                </button>
                <button
                  type="button"
                  className="btn btn-cancel"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
        </>
    )
}

export default CategoryDepense;