/* eslint-disable react/prop-types */

import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom';
import DepensePage from './depensePage';
import CategoryDepense from './CategorieDepensePage';
import VersementLivreur from './VersementLivreur';
import AddVersementLivreur from './addVersementLivreur';
import HistoriqueVersement from './histo_versement';
import VersementClients from './VersementClients';
import AddVersementCustomers from './addVersementCustomer';
import HistoriqueVersementCustomer from './histo_versement_customers';
import HistoriqueVersementToday from './histo_versement_today';
import HistoriqueVersementAll from './histo_versement_all_list';

const DepenseRoute = ({match}) => (
    <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/finances`} />

        <Route path={`${match.url}/depenses`} component={DepensePage} />
        <Route path={`${match.url}/categories-depense`} component={CategoryDepense} />

        <Route path={`${match.url}/versement-livreurs`} component={VersementLivreur} />
        <Route path={`${match.url}/versement-customers`} component={VersementClients} />
        <Route path={`${match.url}/versement-add-livreurs/:id/:name`} component={AddVersementLivreur} />
        <Route path={`${match.url}/versement-add-customers/:id/:name`} component={AddVersementCustomers} />

        <Route path={`${match.url}/transactions-livreurs/:id/:name`} component={HistoriqueVersement} />
        <Route path={`${match.url}/transactions-customers/:id/:name`} component={HistoriqueVersementCustomer} />
        <Route path={`${match.url}/transactions-todays`} component={HistoriqueVersementToday} />
        <Route path={`${match.url}/transactions-all-listes`} component={HistoriqueVersementAll} />

    </Switch>
)

export default DepenseRoute;